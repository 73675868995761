import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all settings for the current user
 * @returns The response is an array of objects.
 */
export async function getAllSettings() {
  try {
    const response = await axios.get(api_url + "settings/" + getSession("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It sends a post request to the server to add a new user.
 * @param code - The code of the user
 * @param workHoure - the number of hours you work per day
 * @param startHoure - startHoure,
 * @param endHoure - endHoure,
 * @param dayPerWeeks - number of days per week
 * @param breakTime - break time in minutes
 * @param totalConge - total conge in month
 * @param monthRetard - number of month of retard
 * @param dayRetard - number of days of retard
 * @returns The response is an object that contains the data, status, and other information returned by
 * the server.
 */
export async function postSettings(code, workHoure, startHoure, endHoure, dayPerWeeks, breakTime, totalConge, monthRetard, dayRetard) {
  const credentiel = {
    code_generated: code,
    workHoure: workHoure,
    startHoure: startHoure,
    endHoure: endHoure,
    dayPerWeeks: dayPerWeeks,
    breakTime: breakTime,
    totalConge: totalConge,
    monthRetard: monthRetard,
    dayRetard: dayRetard,
  };
  try {
    const response = await axios.post(api_url + "settings", credentiel, config);
    //toast.success("Utilisateur ajouté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
