import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { toast } from "react-toastify";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all the contracts of a user
 * @param id - The id of the user.
 * @returns The response is an array of objects.
 */
export async function getContratByUserId(id) {
  try {
    const response = await axios.get(api_url + "contrat/userid/" + id, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add a new contract to the database
 * @param userid - the id of the user
 * @param type - type of contrat
 * @param debut - date of the beginning of the contract
 * @param fin - date de fin de contrat
 * @returns The response from the server.
 */
export async function addContrat(userid, type, debut, fin) {
  const credentiel = {
    code_generated: getSession("code_generated"),
    user_id: userid,
    type: type,
    date_embauche: debut,
    date_fin_contrat: fin,
  };

  try {
    const response = await axios.post(api_url + "contrat", credentiel, config);
    console.log(response);
    // toast.success("département ajouté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It deletes a contrat by user_id.
 * @param user_id - The id of the user you want to delete.
 * @returns The response is an object with a status code and the data.
 */
export async function deleteContratByUserId(user_id) {
  try {
    const response = await axios.delete(api_url + "contrat/" + user_id, config);
    //toast.info("contrat ajouté");
    return response;
  } catch (error) {
    console.error(error);
  }
}
