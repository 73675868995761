import React, { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { editMarque, getMarqueById } from "../../../Services/Facturation/FacturationMarqueApi";

function EditerMarque({ tableId, getData }) {
  const hideRef = useRef();
  const [plafond, setPlafond] = useState("");
  const [libelle, setlibelle] = useState("");

  useEffect(() => {
    getMarqueById(tableId).then((res) => {
      setlibelle(res.data.libelle);
      setPlafond(res.data.plafond_credit);
    });
  }, [tableId]);

  /**
   * * Edit a marque
   * @param e - The event object.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (libelle == "") {
      toast.error("nom vide");
    } else if (plafond == "") {
      toast.error("plafond vide");
    } else if (plafond !== "" && isNaN(plafond)) {
      toast.error("plafond doit etre un nombre");
    } else {
      editMarque(tableId, libelle, plafond).then((res) => {
        // history.push("/facturation/marque")
        hideRef.current.click();
        getData();
      });
    }
  };

  return (
    <div
      key={tableId}
      className="modal fade"
      id="editMarqueModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              éditer marque
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={hideRef}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <ToastContainer></ToastContainer>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Nom marque</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="..."
                  onChange={(e) => setlibelle(e.target.value)}
                  value={libelle || ""}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Plafond crédit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="..."
                  onChange={(e) => setPlafond(e.target.value)}
                  value={plafond || ""}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Enregistrer
              </button>
            </form>
          </div>
          {/* <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Save changes
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default EditerMarque;
