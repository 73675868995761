import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { addVaccin } from "../../Services/Pointeuse/VaccinApi";

function AddVaccin() {
  const [users, setusers] = useState(null);
  const [userId, setuserId] = useState(null);
  const [numDose, setnumDose] = useState(null);
  const [dateDose1, setdateDose1] = useState(null);
  const [dateDose2, setdateDose2] = useState(null);
  const [dateDose3, setdateDose3] = useState(null);
  const [numInscri, setnumInscri] = useState(null);
  const [centreVaccin, setcentreVaccin] = useState(null);
  const [nomVaccin, setnomVaccin] = useState(null);
  const [pdf] = useState(
    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
  );
  const [pdfUrl, setpdfUrl] = useState(null);
  const [pdfName, setpdfName] = useState(null);

  const [image] = useState(
    "https://w7.pngwing.com/pngs/619/184/png-transparent-qr-code-barcode-scanners-scanner-q-text-rectangle-logo.png"
  );
  // const [imageUrl, setImageUrl] = useState("");
  // const [imageName, setimageName] = useState("");

  useEffect(() => {
    getUsersByCode().then((res) => {
      setusers(res.data);
    });
  }, []);

  /**
   * *This function is used to handle the file upload event. It takes in the file and sets the file name
   * and file url.*
   * @param event - The event that triggered the function.
   */
  const handlePdf = (event) => {
    setpdfUrl(event.target.files[0]);
    setpdfName(event.target.files[0].name);
    console.log(event.target.files);
  };

  /**
   * It sets the value of the userId state variable to the value of the input field.
   * @param e - event object
   */
  const handleChangeCollab = (e) => {
    setuserId(e.target.value);
  };
  /**
   * It adds a new vaccine to the database.
   */
  const submitVaccin = () => {
    addVaccin(userId, nomVaccin, numDose, dateDose1, dateDose2, dateDose3, numInscri, centreVaccin, pdfUrl);
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Vaccination</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/addvaccin" className="btn btn-primary mr-3">
                Ajouter vaccination
              </Link>
              <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                <i className="feather feather-mail" />
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                <i className="feather feather-phone-call" />
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                <i className="feather feather-info" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Détail vaccin</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>
                  Nom de vaccin<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  onChange={(e) => setnomVaccin(e.target.value)}
                >
                  <option value={"default"}>Choisir Vaccin</option>
                  <option value={"Pfizer/BioNTech (USA, Allemagne)"}>Pfizer/BioNTech (USA, Allemagne)</option>
                  <option value={"Sanofi (France, Royaume-Uni)"}>Sanofi (France, Royaume-Uni)</option>
                  <option value={"Moderna (USA)"}>Moderna (USA)</option>
                  <option value={"AstraZeneka/Université d''Oxford (Royaume-Uni)"}>
                    AstraZeneka/Université d''Oxford (Royaume-Uni)
                  </option>
                  <option value={"Johson&Johson - Janssen (USA)"}>Johson&Johson - Janssen (USA)</option>
                  <option value={"Gameleya Research Institute, Spoutnik V (Russie)"}>
                    Gameleya Research Institute, Spoutnik V (Russie)
                  </option>
                  <option value={"CureVac (Allemagne)"}>CureVac (Allemagne)</option>
                  <option value={"Novavax (USA)"}>Novavax (USA)</option>
                  <option value={"Sinovac (China)"}>Sinovac (China)</option>
                  <option value={"Biotech (China)"}>Biotech (China)</option>
                  <option value={"CanSino (China)"}>CanSino(China)</option>
                  <option value={"CanSino (China)"}>CanSino(China)</option>
                  <option value={"China Biotics (China)"}>China Biotics (China)</option>
                  <option value={"Sinopharm (China)"}>Sinopharm (China)</option>
                </select>
              </div>
              <div className="form-group">
                <label>
                  Nombre des doses <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  onChange={(e) => setnumDose(e.target.value)}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
              </div>
              <div className="form-group">
                <label>
                  Date dose 1 <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="date" required onChange={(e) => setdateDose1(e.target.value)} />
              </div>
              <div className="form-group">
                <label>
                  Date dose 2<span className="text-danger">*</span>
                </label>
                <input className="form-control" type="date" required onChange={(e) => setdateDose2(e.target.value)} />
              </div>
              <div className="form-group">
                <label>
                  Date dose 3 <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="date" required onChange={(e) => setdateDose3(e.target.value)} />
              </div>
              {/* <div className="form-group">
                <label>
                  Numéro d'inscription<span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" required onChange={(e) => setnumInscri(e.target.value)} />
              </div> */}
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
            <a href="https://select2.github.io/">Select2 documentation</a> for
            more examples and information about the plugin. */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Centre de vaccination</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                {/* <label>
                  Collaborateur <span className="text-danger">*</span>
                </label> */}
                {users && (
                  <select className="form-control" id="exampleFormControlSelect1" onChange={handleChangeCollab}>
                    <option value={"default"}>Choisir Collaborateurs</option>
                    {users.map((option) => (
                      <option value={option.id}>
                        {option.nom} {option.prenom}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div className="form-group">
                <label>
                  Numéro d'inscription<span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" required onChange={(e) => setnumInscri(e.target.value)} />
              </div>
              <div className="form-group">
                <label>
                  Centre de vaccination<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  onChange={(e) => setcentreVaccin(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Certificat PDF</label>
                <div className="d-flex justify-content-center">
                  <img alt="image produit" src={pdf} style={{ width: 60 }} />
                </div>
              </div>

              <div className="input-group mb-5 file-browser">
                <input
                  type="text"
                  className="form-control browse-file"
                  placeholder={pdfName || "choisir un fichier ..."}
                />
                <label className="input-group-append">
                  <span className="btn btn-primary">
                    choisir fichier <input type="file" style={{ display: "none" }} onChange={handlePdf} />
                  </span>
                </label>
              </div>
              {/* <div className="form-group">
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      className="custom-file-input"
                      accept="application/pdf"
                      type="file"
                      id="exampleInputFile"
                      onChange={handlePdf}
                    />
                    <label className="custom-file-label" htmlFor="exampleInputFile">
                      {pdfName}
                    </label>
                  </div>
                </div>
              </div> */}
              {/* <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">QR code</label>
                <div className="d-flex justify-content-center">
                  <img alt="image produit" src={image} style={{ width: 60 }} />
                </div>
              </div> */}
              {/* <div className="form-group">
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      className="custom-file-input"
                      accept="image/*"
                      type="file"
                      id="exampleInputFile"
                      onChange={handleQrCode}
                    />
                    <label className="custom-file-label" htmlFor="exampleInputFile">
                      {imageName}
                    </label>
                  </div>
                </div>
              </div> */}
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
            <a href="https://select2.github.io/">Select2 documentation</a> for
            more examples and information about the plugin. */}
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn-danger text-center mr-2">
            <i className="fas fa-trash-alt" /> Annuler
          </button>
          <button type="button" className="btn btn-primary text-center" onClick={submitVaccin}>
            {/* <i className="far fa-credit-card" /> */}
            Enregistrer
          </button>
        </div>
      </div>
    </>
  );
}

export default AddVaccin;
