import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLogin } from "../Utils/SessionUtils";

function PrivateRoute({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => <Layout>{isLogin() ? <Component {...props}></Component> : <Redirect to="/login" />}</Layout>}
    ></Route>
  );
}

export default PrivateRoute;
