import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It gets the entreprise by code.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "code": "SOCIETE_CODE",
 *   "name": "SOCIETE_NAME",
 *   "address": "SOCIETE_ADDRESS",
 *   "city": "SOCIETE_CITY",
 *   "country
 */
export async function getEntrepriseByCode() {
  try {
    const response = await axios.get(api_url + "societe/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * Update the logo of the company
 * @param logo - The logo file
 * @param code_generated - The code generated by the API
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "code": 200,
 *   "message": "Success",
 *   "data": {
 *     "code_generated": "",
 *     "logo": "",
 *     "id": "",
 *     "name": "",
 *     "address": "",
 *     "city":
 */

export async function updateLogo(logo, code_generated) {
  const frmData = new FormData();
  frmData.append("code_generated", code_generated);
  frmData.append("logo", logo);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.put(api_url + "societe/img", frmData, options);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Edit an entreprise
 * @param nom - the name of the entreprise
 * @param adresse - the address of the entreprise
 * @param matricule_fiscal - the fiscal code of the company
 * @param numero_cnss - the CNSS number of the entreprise
 * @param rib - the bank account number
 * @param banque - the name of the bank
 * @param mobile - the phone number of the entreprise
 * @param fax - the fax number of the entreprise
 * @param website_url - the website URL of the company
 * @param secteur_activite - Secteur d'activité de l'entreprise
 * @param email - the email of the entreprise
 * @param nb_employe - number of employees
 * @param start_year - the year the company was founded
 * @param code_generated - the code generated by the API
 * @returns The response from the server.
 */
export async function editEntreprise(
  nom,
  adresse,
  matricule_fiscal,
  numero_cnss,
  rib,
  banque,
  mobile,
  fax,
  website_url,
  secteur_activite,
  email,
  nb_employe,
  start_year,
  latitude,
  longitude,
  code_generated
) {
  const credentiel = {
    code_generated: code_generated,
    nom: nom,
    adresse: adresse,
    latitude: latitude,
    longitude: longitude,
    matricule_fiscal: matricule_fiscal,
    numero_cnss: numero_cnss,
    rib: rib,
    banque: banque,
    mobile: mobile,
    fax: fax,
    website_url: website_url,
    email: email,
    secteur_activite: secteur_activite,
    nb_employe: nb_employe,
    start_year: start_year,
  };

  try {
    const response = await axios.put(api_url + "societe", credentiel, config);
    console.log(response);
    toast.success("mise à jour");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Edit a ligne_facture
 * @param ligne_facture_1 - ligne_facture_1,
 * @param ligne_facture_2 - the id of the ligne_facture
 * @param ligne_facture_3 - the id of the ligne_facture
 * @param ligne_facture_4 - the id of the ligne_facture
 * @returns The response is an object with the following properties:
 */
export async function editLigneFacture(ligne_facture_1, ligne_facture_2, ligne_facture_3, ligne_facture_4) {
  const credentiel = {
    ligne_facture_1: ligne_facture_1,
    ligne_facture_2: ligne_facture_2,
    ligne_facture_3: ligne_facture_3,
    ligne_facture_4: ligne_facture_4,
    code_generated: getSession("code_generated"),
  };

  try {
    const response = await axios.put(api_url + "societe/lignefacture", credentiel, config);
    console.log(response);
    toast.success("mise à jour");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function addEntreprise(code, latitude, longitude, nom) {
  const credentiel = {
    code_generated: code,
    latitude: latitude,
    longitude: longitude,
    nom: nom,
  };
  try {
    const response = await axios.post(api_url + "societe", credentiel, config);
    console.log(response);
    //toast.success("ajouté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
