import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getEnteteByCode, uploadDechargeFacuteByCode } from "../../Services/Facturation/FacturationEnteteApi";
import "./UploadDecharge.css";

function UploadDecharge(props) {
  const [imageUrl, setimageUrl] = useState("");
  const [imageName, setimageName] = useState("");
  const [imageObject, setimageObject] = useState("assets/images/icons/add.png");
  const [isLodedImage, setisLodedImage] = useState(false);

  useEffect(() => {
    getEnteteByCode(props.match.params.id).then((res) => {
      console.log(res.data);
      if (res.data.image) {
        setimageObject(localStorage.getItem("baseUrl") + res.data.image);
        setisLodedImage(true);
      }
    });
  }, []);

  /**
   * It sets the image name, image url, and image object.
   * @param e - The event object.
   */
  const handleChangeImage = (e) => {
    setimageName(e.target.files[0].name);
    setimageUrl(e.target.files[0]);
    setimageObject(URL.createObjectURL(e.target.files[0]));
    setisLodedImage(true);
  };

  /**
   * Uploads the image to the decharge facute by code
   */
  const uploadImage = () => {
    uploadDechargeFacuteByCode(imageUrl, props.match.params.id);
  };
  return (
    <div className="row mt-4">
      <div className="col-md-12">
        <div className="card card-primary">
          <div className="card-header">
            <h3 className="card-title">Sélectionnez décharge facture</h3>
            <div className="card-tools">
              {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
              <i className="fas fa-minus" />
            </button>
            <button type="button" className="btn btn-tool" data-card-widget="remove">
              <i className="fas fa-times" />
            </button> */}
            </div>
          </div>
          {/* /.card-header */}
          <div className="card-body">
            <div className="container">
              <div className="panel panel-default">
                <div className="panel-body">
                  {/* Standar Form */}
                  <h4>Sélectionnez décharge facture</h4>
                  <form action method="post" encType="multipart/form-data" id="js-upload-form"></form>
                  {/* Drop Zone */}

                  <div className="form-group files">
                    <label>Téléchargez votre fichier </label>
                    <input accept="image/*" type="file" className="form-control" onChange={handleChangeImage} />
                  </div>

                  <div className="form-inline">
                    <button type="submit" className="btn btn-sm btn-primary" id="js-upload-submit" onClick={uploadImage}>
                      Ajouter document
                    </button>
                  </div>
                  <div className="progress mt-4 mb-4">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow={60}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "60%" }}
                    >
                      <span className="sr-only">60% Complete</span>
                    </div>
                  </div>
                  {/* Upload Finished */}
                  <div className="js-upload-finished">
                    <h3>Fichiers traités</h3>
                    <div className="list-group">
                      <Link to="#" className="list-group-item list-group-item-info">
                        <span className="badge alert-default pull-right">Image</span> {imageName}
                      </Link>
                    </div>
                  </div>
                  <div className="container mt-2 border">{isLodedImage && <img src={imageObject} alt="decharge"></img>}</div>
                </div>
              </div>
            </div>
          </div>
          {/* /.card-body */}
          <div className="card-footer">
            {/* Visit{" "}
          <a href="https://select2.github.io/">Select2 documentation</a> for
          more examples and information about the plugin. */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadDecharge;
