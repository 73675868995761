import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  getClientByCodeId,
  getClientById,
  getFacturationClientByCode,
} from "../../../../../Services/Facturation/FacturationClientApi";
import { getAllProduct, getProduitById } from "../../../../../Services/Facturation/FacturationProduitApi";
import { getEntrepriseByCode } from "../../../../../Services/Pointeuse/EntrepriseApi";
import { calculateHt, calculateRemise, MultiplicateNumber, calulateTva } from "../../../../../Utils/NumberUtils";
import { getGoupedValue } from "../../../../../Utils/ObjectUtils";
import { PDFViewer } from "@react-pdf/renderer";
import MyDocument from "../../../invoiceReports/MyDocument";
import {
  addEntete,
  deleteEnteteByCode,
  getEnteteByCode,
  getEnteteCommercialVenteCommande,
} from "../../../../../Services/Facturation/FacturationEnteteApi";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addLigne, getLigneByCode } from "../../../../../Services/Facturation/FacturationLigneApi";
import { Link, useHistory } from "react-router-dom";
import { getSequenceByCode } from "../../../../../Services/Facturation/FacturationSequenceApi";

function EditerCommandeVente(props) {
  const history = useHistory();
  const [showResults, setShowResults] = React.useState(false);
  const [dataFournisseur, setDataFournisseur] = useState([]);
  const [fournisseurFullName, setFournisseurFullName] = useState("");
  const [fournisseurCode, setFournisseurCode] = useState("");
  const [fournisseurId, setFournisseurId] = useState("");
  //
  const [sousTotalHt, setsousTotalht] = useState(0);
  const [actionRemiseEntete, setactionRemiseEntete] = useState("1");
  const [totalHt, setTotalHt] = useState("");
  const [listTva, setlistTva] = useState([]);
  const [totalTcc, setTotalTTC] = useState("");
  //product detail
  const [products, setProducts] = useState([]);
  const [codeProduct, setCodeProduct] = useState("");
  const [idProduct, setIdProduct] = useState("");
  const [libelleProduct, setLibelleProduct] = useState("");
  const [tva, setTva] = useState("");
  const [prixVente1, setPrixVente1] = useState("");
  const [qteMin, setQteMin] = useState("");
  const [tvaProduct, setTvaProduct] = useState("");
  // The first commit of Material-UI
  const [selectedDateToday, setSelectedDateToday] = React.useState();
  const [selectedDateDeadline, setSelectedDateDeadline] = React.useState();
  //code entete
  const [codeEntete, setcodeEntete] = useState("");
  const [objectToPdf, setobjectToPdf] = useState();
  // company details
  const [nomCompany, setNomCompany] = useState("");
  const [adresseCompany, setAdresseCompany] = useState("");
  const [mobileCompany, setMobileCompany] = useState("");
  const [emailCompany, setEmailCompany] = useState("");
  const [logoCompany, setLogoCompany] = useState("");
  const [matriculeFiscal, setmatriculeFiscal] = useState("");
  const [webUrl, setwebUrl] = useState("");
  // client detail
  const [nomClient, setNomClient] = useState("");
  const [prenomClient, setPrenomClient] = useState("");
  const [emailClient, setEmailClient] = useState("");
  const [fixeClient, setFixeClient] = useState("");
  const [imageClient, setImageClient] = useState("");
  const [adrFacturationClient, setAdrFacturationClient] = useState("");
  const [localiteClient, setLocaliteClient] = useState("");
  const [regionClient, setRegionClient] = useState("");
  const [gouvernoratClient, setGouvernoratClient] = useState("");
  // facture detail
  const [numFacture, setnumFacture] = useState("");
  const [numCommande, setnumCommande] = useState("");
  const [remise, setRemise] = useState("0");
  // facture footer
  const [noteFacture, setNoteFacture] = useState("");
  const [footerFacture, setFooterFacture] = useState("");
  // entete
  const [suivie, setsuivie] = useState("");
  const [document, setdocument] = useState("");
  // dynamic list product
  const [inputList, setInputList] = useState([
    {
      codeProduit: "",
      libelleProduit: "",
      qte: "",
      prixHt: "",
      remise: "0",
      tvaProduct: "",
      actionRemise: "1",
      totalHt: "",
      suivie_stock: "",
    },
  ]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    let sum = calculateRemise(
      MultiplicateNumber(list[index]["prixHt"], list[index]["qte"]),
      list[index]["remise"],
      list[index]["actionRemise"]
    );
    list[index]["totalHt"] = sum;
    setInputList(list);

    var sumTotal = 0;
    for (const [key, value] of Object.entries(list)) {
      sumTotal += value.totalHt;
    }
    setsousTotalht(sumTotal.toFixed(3));
    var totalHtValue = calculateRemise(sumTotal, remise, actionRemiseEntete).toFixed(3);
    setTotalHt(totalHtValue);

    let totalHtPrice = getGoupedValue(list, actionRemiseEntete, remise, sumTotal.toFixed(3));
    setlistTva(totalHtPrice);

    let sumTtc = 0;
    for (let i = 0; i < totalHtPrice.length; i++) {
      sumTtc += Number(totalHtPrice[i].split(" ")[3]);
    }
    let calculateTotalTtc = Number(sumTtc) + Number(totalHtValue);
    var addTimbreFiscale = Number(calculateTotalTtc) + Number(0.6);
    setTotalTTC(addTimbreFiscale.toFixed(3));
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    var sumTotal = 0;
    for (const [key, value] of Object.entries(list)) {
      sumTotal += value.totalHt;
    }
    setsousTotalht(sumTotal.toFixed(3));
    setTotalHt(calculateRemise(sumTotal, remise, actionRemiseEntete).toFixed(3));

    let totalHtPrice = getGoupedValue(list, actionRemiseEntete, remise, sumTotal.toFixed(3));
    setlistTva(totalHtPrice);
    let sumTtc = 0;
    for (let i = 0; i < totalHtPrice.length; i++) {
      sumTtc += Number(totalHtPrice[i].split(" ")[3]);
      //console.log(calculateRemise(sum, remise, actionRemiseEntete))
    }
    let calculateTotalTtc = sumTtc + calculateRemise(sumTotal, remise, actionRemiseEntete);
    var addTimbreFiscale = Number(calculateTotalTtc) + Number(0.6);
    setTotalTTC(addTimbreFiscale.toFixed(3));
  };

  // handle click event of the Add button
  // create new object
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        codeProduit: "",
        libelleProduit: "",
        qte: "",
        prixHt: "",
        remise: "0",
        tvaProduct: "",
        actionRemise: "1",
        totalHt: "",
        suivie_stock: "",
      },
    ]);
  };

  // fetch data
  useEffect(() => {
    var remiseInitial = "";
    var remiseActionInitial = "";
    getEnteteByCode(props.match.params.id).then((res) => {
      setnumFacture(res.data.facture_code);
      setFournisseurCode(res.data.client_code);
      setFournisseurFullName(res.data.client_nom);
      setnumCommande(res.data.code_commande);
      setRemise(res.data.remise_valeur);
      setSelectedDateToday(moment(res.data.date).format("YYYY-MM-DD"));
      setSelectedDateDeadline(moment(res.data.date_prevu_livraison).format("YYYY-MM-DD"));
      setactionRemiseEntete(res.data.remise_action);
      setcodeEntete(props.match.params.id);
      setNoteFacture(res.data.commentaire);
      setdocument(res.data.dossier);

      remiseInitial = res.data.remise_valeur;
      remiseActionInitial = res.data.remise_action;

      getClientByCodeId(res.data.client_code).then((res) => {
        setNomClient(res.data.nom);
        setPrenomClient(res.data.prenom);
        setEmailClient(res.data.email);
        setFixeClient(res.data.fixe);
        setAdrFacturationClient(res.data.adresse_facturation);
      });

      getLigneByCode(props.match.params.id).then((res) => {
        const result = res.data;
        const options = result.map((d) => ({
          codeProduit: d.produit_code,
          libelleProduit: d.libelleProduit,
          qte: d.quantite,
          prixHt: d.pu_ht,
          remise: d.remise_valeur,
          tvaProduct: d.taux_tva,
          actionRemise: d.actionRemise,
          totalHt: calculateRemise(MultiplicateNumber(d.pu_ht, d.quantite), d.remise_valeur, d.actionRemise),
          suivie_stock: d.suivieStock,
        }));

        setInputList(options);

        /**
         * detect change block
         */

        var sumTotal = 0;
        for (const [key, value] of Object.entries(options)) {
          sumTotal += value.totalHt;
        }
        setsousTotalht(sumTotal.toFixed(3));
        var totalHtValue = calculateRemise(sumTotal, remiseInitial, remiseActionInitial).toFixed(3);
        setTotalHt(totalHtValue);
        let totalHtPrice = getGoupedValue(options, remiseActionInitial, remiseInitial, sumTotal.toFixed(3));
        setlistTva(totalHtPrice);
        let sumTtc = 0;
        for (let i = 0; i < totalHtPrice.length; i++) {
          sumTtc += Number(totalHtPrice[i].split(" ")[3]);
        }
        let calculateTotalTtc = Number(sumTtc) + Number(totalHtValue);
        var addTimbreFiscale = Number(calculateTotalTtc) + Number(0.6);
        setTotalTTC(addTimbreFiscale.toFixed(3));
      });
    });

    getFacturationClientByCode().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.code,
        label: d.nom + " " + d.prenom,
        secondValue: d.id,
      }));
      setDataFournisseur(options);
    });

    getAllProduct().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.code,
        label: d.libelle,
        secondValue: d.id,
        thirdValue: d.prix_vente_ht_1,
        fourthValue: d.tva,
        suivieValue: d.suivie_stock,
      }));
      setProducts(options);
    });

    getEntrepriseByCode().then((res) => {
      setNomCompany(res.data.nom);
      setAdresseCompany(res.data.adresse);
      setMobileCompany(res.data.mobile);
      setEmailCompany(res.data.email);
      setLogoCompany(res.data.logo);
      setmatriculeFiscal(res.data.matricule_fiscal);
      setwebUrl(res.data.website_url);
    });
  }, []);
  //select fournisseur
  const handleChangeFournisseur = (event) => {
    setFournisseurCode(event.value);
    setFournisseurFullName(event.label);
    setFournisseurId(event.secondValue);

    getClientById(event.secondValue).then((res) => {
      setNomClient(res.data.nom);
      setPrenomClient(res.data.prenom);
      setEmailClient(res.data.email);
      setFixeClient(res.data.fixe);
      setImageClient(res.data.image);
      setAdrFacturationClient(res.data.adresse_facturation);
      setLocaliteClient(res.data.localite);
      setRegionClient(res.data.region);
      setGouvernoratClient(res.data.gouvernorat);
    });
  };
  //select fournisseur
  const handleChangeProduct = (event, index) => {
    const list = [...inputList];
    list[index]["libelleProduit"] = event.label;
    list[index]["codeProduit"] = event.value;
    list[index]["prixHt"] = event.thirdValue;
    list[index]["tvaProduct"] = event.fourthValue;
    list[index]["suivie_stock"] = event.suivieValue;

    setInputList(list);

    setCodeProduct(event.value);
    setLibelleProduct(event.label);
    setIdProduct(event.secondValue);
    getProduitById(event.secondValue).then((res) => {
      setPrixVente1(res.data.prix_vente_1);
      setTva(res.data.tva);
      setQteMin(res.data.qte_min);
      setTvaProduct(event.fourthValue);
      setsuivie(event.suivieValue);
    });
  };
  // change drobdown remise
  const handleChangeRemiseEntete = (event) => {
    setactionRemiseEntete(event.target.value);
    setTotalHt(calculateRemise(sousTotalHt, remise, event.target.value).toFixed(3));

    //
    let totalHtPrice = getGoupedValue(inputList, event.target.value, remise, sousTotalHt);
    setlistTva(totalHtPrice);
    let sumTtc = 0;
    for (let i = 0; i < totalHtPrice.length; i++) {
      sumTtc += Number(totalHtPrice[i].split(" ")[3]);
      //console.log(calculateRemise(sum, remise, actionRemiseEntete))
    }
    let calculateTotalTtc =
      Number(sumTtc) + Number(calculateRemise(sousTotalHt, remise, event.target.value).toFixed(3));
    var addTimbreFiscale = Number(calculateTotalTtc) + Number(0.6);
    setTotalTTC(addTimbreFiscale.toFixed(3));
  };
  // change entete remise value
  const handleChangeRemiseValue = (event) => {
    setRemise(event.target.value);
    setTotalHt(calculateRemise(sousTotalHt, event.target.value, actionRemiseEntete).toFixed(3));

    let totalHtPrice = getGoupedValue(inputList, actionRemiseEntete, event.target.value, sousTotalHt);
    setlistTva(totalHtPrice);
    let sumTtc = 0;
    for (let i = 0; i < totalHtPrice.length; i++) {
      sumTtc += Number(totalHtPrice[i].split(" ")[3]);
    }
    let calculateTotalTtc =
      Number(sumTtc) + Number(calculateRemise(sousTotalHt, event.target.value, actionRemiseEntete).toFixed(3));
    var addTimbreFiscale = Number(calculateTotalTtc) + Number(0.6);
    setTotalTTC(addTimbreFiscale.toFixed(3));
  };

  const handleSaveEntete = () => {
    deleteEnteteByCode(props.match.params.id).then((res) => {
      let sumRemise = 0;
      for (const [key, value] of Object.entries(inputList)) {
        sumRemise += Number(value.qte) * Number(value.prixHt) - Number(value.totalHt);
      }
      var remise_commande = Number(sousTotalHt) - Number(totalHt);
      var remise_total = Number(sumRemise) + remise_commande;

      var tva1 = 0;
      var tva2 = 0;
      var tva3 = 0;
      var tvaPourcent1 = 0;
      var tvaPourcent2 = 0;
      var tvaPourcent3 = 0;

      if (listTva.length === 1) {
        tva1 = listTva[0].split(" ")[3];
        tvaPourcent1 = listTva[0].split(" ")[2].slice(0, -1);
      } else if (listTva.length === 2) {
        tva1 = listTva[0].split(" ")[3];
        tvaPourcent1 = listTva[0].split(" ")[2].slice(0, -1);
        tva2 = listTva[1].split(" ")[3];
        tvaPourcent2 = listTva[1].split(" ")[2].slice(0, -1);
      } else if (listTva.length === 3) {
        tva1 = listTva[0].split(" ")[3];
        tvaPourcent1 = listTva[0].split(" ")[2].slice(0, -1);
        tva2 = listTva[1].split(" ")[3];
        tvaPourcent2 = listTva[1].split(" ")[2].slice(0, -1);
        tva3 = listTva[2].split(" ")[3];
        tvaPourcent3 = listTva[2].split(" ")[2].slice(0, -1);
      } else {
        console.log("empty listTva");
      }

      let montantTotalTva = Number(tva1) + Number(tva2) + Number(tva3);
      let codeEntete = parseInt(Date.now() * Math.random());
      let net_payer = Number(totalTcc) + Number(0.6);

      if (fournisseurCode == "") {
        toast.error("Veuillez choisir un client");
      } else {
        //check numéro facture
        // TODO : remove seq
        var seq = "";
        var numFactureValue = "";
        getSequenceByCode().then((res) => {
          seq = res.data.seq_commande;
          getEnteteCommercialVenteCommande().then((result) => {
            var lastCodeFacture = result.data[0].facture_code.split("_").pop();
            numFactureValue = seq + "" + (Number(lastCodeFacture) + 1);
            setnumFacture(numFactureValue);

            //save entete
            // TODO : change vente to achat
            // TODO : change numFactureValue to numFacture
            addEntete(
              codeEntete,
              numCommande,
              numFactureValue,
              "vente",
              document,
              "commande",
              fournisseurCode,
              fournisseurFullName,
              moment(selectedDateToday).format("YYYY-MM-DD"),
              tvaPourcent1,
              tva1,
              tvaPourcent2,
              tva2,
              tvaPourcent3,
              tva3,
              "0.600",
              totalHt,
              totalTcc,
              montantTotalTva,
              net_payer,
              remise_total,
              remise_commande,
              actionRemiseEntete,
              remise,
              adrFacturationClient,
              moment(selectedDateDeadline).format("YYYY-MM-DD"),
              noteFacture
            ).then(() => {
              var remise_totale_ligne = 0;

              for (const [key, value] of Object.entries(inputList)) {
                remise_totale_ligne += Number(value.qte) * Number(value.prixHt) - Number(value.totalHt);

                var sumQte = 0;
                if (value.suivie_stock == "1") {
                  sumQte += Number(value.qte);
                } else {
                  console.log("not tracked in stock");
                }
                //save ligne
                addLigne(
                  codeEntete,
                  value.codeProduit,
                  value.qte,
                  value.prixHt,
                  calulateTva(value.totalHt, value.tvaProduct, actionRemiseEntete, remise, sousTotalHt),
                  value.tvaProduct,
                  calculateHt(value.totalHt, actionRemiseEntete, remise, sousTotalHt),
                  remise_totale_ligne,
                  remise_commande,
                  value.libelleProduit,
                  value.actionRemise,
                  value.remise,
                  value.suivie_stock
                ).then(() => {});
              }
            });
          });
        });
      }
    });
  };

  // const handleConvertCommande = () => {
  //   let sumRemise = 0;
  //   for (const [key, value] of Object.entries(inputList)) {
  //     sumRemise +=
  //       Number(value.qte) * Number(value.prixHt) - Number(value.totalHt);
  //   }
  //   var remise_commande = Number(sousTotalHt) - Number(totalHt);
  //   var remise_total = Number(sumRemise) + remise_commande;

  //   var tva1 = 0;
  //   var tva2 = 0;
  //   var tva3 = 0;
  //   var tvaPourcent1 = 0;
  //   var tvaPourcent2 = 0;
  //   var tvaPourcent3 = 0;

  //   if (listTva.length === 1) {
  //     tva1 = listTva[0].split(" ")[3];
  //     tvaPourcent1 = listTva[0].split(" ")[2].slice(0, -1);
  //   } else if (listTva.length === 2) {
  //     tva1 = listTva[0].split(" ")[3];
  //     tvaPourcent1 = listTva[0].split(" ")[2].slice(0, -1);
  //     tva2 = listTva[1].split(" ")[3];
  //     tvaPourcent2 = listTva[1].split(" ")[2].slice(0, -1);
  //   } else if (listTva.length === 3) {
  //     tva1 = listTva[0].split(" ")[3];
  //     tvaPourcent1 = listTva[0].split(" ")[2].slice(0, -1);
  //     tva2 = listTva[1].split(" ")[3];
  //     tvaPourcent2 = listTva[1].split(" ")[2].slice(0, -1);
  //     tva3 = listTva[2].split(" ")[3];
  //     tvaPourcent3 = listTva[2].split(" ")[2].slice(0, -1);
  //   } else {
  //     console.log("empty listTva");
  //   }

  //   let montantTotalTva = Number(tva1) + Number(tva2) + Number(tva3);
  //   let codeEntete = parseInt(Date.now() * Math.random());
  //   let net_payer = Number(totalTcc) + Number(0.6);

  //   var seq = "";
  //   var numFactureValue = "";
  //   getSequenceByCode().then((res) => {
  //     seq = res.data.seq_facture;
  //     getEnteteCommercialVente().then((result) => {
  //       numFactureValue = seq + "" + (result.data.length + 1);
  //       setnumFacture(numFactureValue);

  //       //TODO : change vente to achat
  //       addEntete(
  //         codeEntete,
  //         numCommande,
  //         numFactureValue,
  //         "vente",
  //         document,
  //         "facture",
  //         fournisseurCode,
  //         fournisseurFullName,
  //         moment(selectedDateToday).format("YYYY-MM-DD"),
  //         tvaPourcent1,
  //         tva1,
  //         tvaPourcent2,
  //         tva2,
  //         tvaPourcent3,
  //         tva3,
  //         "0.600",
  //         totalHt,
  //         totalTcc,
  //         montantTotalTva,
  //         net_payer,
  //         remise_total,
  //         remise_commande,
  //         actionRemiseEntete,
  //         remise,
  //         adrFacturationClient,
  //         moment(selectedDateDeadline).format("YYYY-MM-DD"),
  //         noteFacture
  //       ).then((res) => {
  //         var remise_totale_ligne = 0;

  //         for (const [key, value] of Object.entries(inputList)) {
  //           remise_totale_ligne +=
  //             Number(value.qte) * Number(value.prixHt) - Number(value.totalHt);

  //           var sumQte = 0;
  //           if (value.suivie_stock == "1") {
  //             sumQte += Number(value.qte);
  //           } else {
  //             console.log("not tracked in stock");
  //           }
  //           //save ligne
  //           addLigne(
  //             codeEntete,
  //             value.codeProduit,
  //             value.qte,
  //             value.prixHt,
  //             calulateTva(
  //               value.totalHt,
  //               value.tvaProduct,
  //               actionRemiseEntete,
  //               remise,
  //               sousTotalHt
  //             ),
  //             value.tvaProduct,
  //             calculateHt(
  //               value.totalHt,
  //               actionRemiseEntete,
  //               remise,
  //               sousTotalHt
  //             ),
  //             remise_totale_ligne,
  //             remise_commande,
  //             value.libelleProduit,
  //             value.actionRemise,
  //             value.remise,
  //             value.suivie_stock
  //           ).then(() => {
  //             //add mouvement
  //             //TODO : change vente to achat
  //             addMouvement(
  //               value.codeProduit,
  //               value.qte,
  //               value.prixHt,
  //               margePercentage(value.totalHt, value.tvaProduct),
  //               value.tvaProduct,
  //               "vente",
  //               codeEntete
  //             ).then(() => {
  //               //update stock
  //               // TODO : change to incrementStockQauntity
  //               decrementStockQauntity(value.codeProduit, sumQte).then(
  //                 () => {}
  //               );
  //             });
  //           });
  //         }
  //         //move to commande
  //         history.push("/facturation/editervente/" + codeEntete);
  //       });
  //     });
  //   });
  // };

  const handleShowFacture = () => {
    setobjectToPdf({
      id: "5df3180a09ea16dc4b95f910",
      invoice_no: numFacture,
      commande_no: numCommande,
      balance: "$2,283.74",
      company: "MANTRIX",
      email: "susanafuentes@mantrix.com",
      phone: "+1 (872) 588-3809",
      address: "922 Campus Road, Drytown, Wisconsin, 1986",
      title: "Commande",
      trans_date: moment(selectedDateToday).format("YYYY-MM-DD"),
      due_date: moment(selectedDateDeadline).format("YYYY-MM-DD"),
      sender_name: nomCompany,
      sender_adr: adresseCompany,
      sender_email: emailCompany,
      sender_mobile: mobileCompany,
      sender_logo: logoCompany,
      matricule_fiscal: matriculeFiscal,
      website_url: webUrl,
      receiver_name: nomClient,
      receiver_prenom: prenomClient,
      receiver_adr: adrFacturationClient,
      receiver_email: emailClient,
      receiver_mobile: fixeClient,
      sous_total_ht: sousTotalHt,
      remise: remise,
      totalHt: totalHt,
      totalTTC: totalTcc,
      note: noteFacture,
      footer: footerFacture,
      items: inputList,
      tva: listTva,
    });
    setShowResults(!showResults);
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Commande vente</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/addclient" className="btn btn-primary mr-3">
                Ajouter client
              </Link>
              <Link to="/admin/addproduct" className="btn btn-primary mr-3">
                Ajouter produit
              </Link>
              <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                <i className="feather feather-mail" />
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                <i className="feather feather-phone-call" />
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                <i className="feather feather-info" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12 col-lg-12">
        <div className="card">
          <div className="card-header  border-0">
            <h4 className="card-title">éditer commande de vente</h4>
          </div>
          <div className="card-body">
            <div className="content m-4">
              <ToastContainer></ToastContainer>
              <div className="row">
                <div className="col-sm-8 mb-4">
                  <h4 className="page-title">Editer commande vente</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>Client : {fournisseurFullName}</label>
                        <Select onChange={handleChangeFournisseur} options={dataFournisseur} />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <label>Remise</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <select
                            value={actionRemiseEntete}
                            className="form-control btn btn-outline-secondary"
                            onChange={handleChangeRemiseEntete}
                          >
                            <option value="1">Pourcent</option>
                            <option value="2">Montant</option>
                          </select>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          aria-label="Text input with dropdown button"
                          value={remise}
                          onChange={handleChangeRemiseValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>Numéro de facture</label>
                        <input className="form-control" type="text" value={numFacture} readOnly />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>Numéro de commande</label>
                        <input
                          className="form-control"
                          type="text"
                          value={numCommande}
                          onChange={(e) => setnumCommande(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>
                          Date d'aujourd'hui <span className="text-danger">*</span>
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control"
                            type="date"
                            format="yyyy-MM-dd"
                            value={selectedDateToday}
                            onChange={(e) => setSelectedDateToday(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>
                          Date d'échéance <span className="text-danger">*</span>
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control"
                            type="date"
                            format="yyyy-MM-dd"
                            value={selectedDateDeadline}
                            onChange={(e) => setSelectedDateDeadline(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row" style={{ marginTop: 20 }}>
              {JSON.stringify(inputList)}
            </div> */}
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="table-responsive" id="mycustomtable">
                        <table className="table table-hover table-white">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Choisir produit</th>
                              <th>Libelle</th>
                              <th>Quantité</th>
                              <th>Prix HT</th>
                              <th>Remise</th>
                              <th>Total HT</th>
                              <th>TVA %</th>
                              <th> </th>
                            </tr>
                          </thead>
                          <tbody>
                            {inputList.map((x, i) => (
                              <tr key={i}>
                                <td>{i}</td>
                                <td style={{ minWidth: 200, zIndex: 99 }}>
                                  <Select
                                    className="fix_scrol_facture"
                                    onChange={(e) => handleChangeProduct(e, i)}
                                    options={products}
                                  />
                                </td>
                                <td style={{ minWidth: 200, width: 950 }}>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="libelleProduit"
                                    value={x.libelleProduit || libelleProduct}
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                </td>
                                <td style={{ minWidth: 70, width: 70 }}>
                                  <input
                                    className="form-control"
                                    type="number"
                                    name="qte"
                                    value={x.qte}
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                </td>
                                <td style={{ minWidth: 150, width: 200 }}>
                                  <input
                                    className="form-control"
                                    type="number"
                                    name="prixHt"
                                    value={x.prixHt}
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                </td>
                                <td style={{ minWidth: 250, width: 200 }}>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <select
                                        defaultValue="1"
                                        className="form-control btn btn-outline-secondary"
                                        name="actionRemise"
                                        value={x.actionRemise}
                                        onChange={(e) => handleInputChange(e, i)}
                                      >
                                        <option value="1">Pourcent</option>
                                        <option value="2">Montant</option>
                                      </select>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control"
                                      aria-label="Text input with dropdown button"
                                      name="remise"
                                      value={x.remise}
                                      onChange={(e) => handleInputChange(e, i)}
                                    />
                                  </div>
                                </td>
                                <td style={{ minWidth: 150, width: 200 }}>
                                  <input
                                    className="form-control"
                                    type="text"
                                    readOnly
                                    name="totalHt"
                                    value={x.totalHt}
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                </td>
                                <td style={{ minWidth: 100, width: 200 }}>
                                  <input className="form-control" readOnly type="text" value={x.tvaProduct + " %"} />
                                </td>
                                <td>
                                  {inputList.length - 1 === i && (
                                    <Link
                                      to="#"
                                      className="text-success font-18 mr-2"
                                      title="Add"
                                      onClick={handleAddClick}
                                    >
                                      <i className="fa fa-plus" />
                                    </Link>
                                  )}
                                  {inputList.length !== 1 && (
                                    <Link
                                      to="#"
                                      className="text-danger font-18"
                                      title="remove"
                                      onClick={() => handleRemoveClick(i)}
                                    >
                                      <i className="fa fa-minus" />
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-hover table-white">
                          <tbody>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td className="text-right">Sous Total HT</td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: 30,
                                  width: 230,
                                }}
                              >
                                {sousTotalHt}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5} className="text-right">
                                Remise {actionRemiseEntete === "1" ? "%" : ""}
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: 30,
                                  width: 230,
                                }}
                              >
                                <input readOnly className="form-control text-right" type="text" value={remise} />
                              </td>
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td className="text-right">Total HT</td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: 30,
                                  width: 230,
                                }}
                              >
                                {totalHt}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5} className="text-right"></td>
                              <td colSpan={5} className="text-right">
                                {listTva.map(function (d, idx) {
                                  return (
                                    <li key={idx}>
                                      <strong>{d}</strong>
                                    </li>
                                  );
                                })}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5} className="text-right">
                                Timbre Fiscale
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: 30,
                                  width: 230,
                                }}
                              >
                                <input className="form-control text-right" value="0.600" readOnly type="text" />
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={5} style={{ textAlign: "right", fontWeight: "bold" }}>
                                Total TTC
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: 30,
                                  fontWeight: "bold",
                                  fontSize: 16,
                                  width: 230,
                                }}
                              >
                                {totalTcc}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Ajouter note</label>
                            <textarea
                              onChange={(e) => setNoteFacture(e.target.value)}
                              className="form-control"
                              value={noteFacture}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row no-print mt-4">
                    <div className="col-12">
                      {/* <a
                href="invoice-print.html"
                rel="noopener"
                target="_blank"
                className="btn btn-default"
              >
                <i className="fas fa-print" /> Print
              </a> */}
                      <button type="button" className="btn btn-primary float-right" onClick={handleSaveEntete}>
                        <i className="far fa-credit-card" /> Enregistrer
                      </button>
                      <button
                        type="button"
                        className="btn btn-info float-right"
                        style={{ marginRight: 5 }}
                        onClick={handleShowFacture}
                      >
                        <i className="fas fa-file-invoice" /> Afficher commande
                      </button>
                    </div>
                  </div>

                  {/* <button className="btn btn-primary" onClick={handleSaveEntete}>
            Enregister
          </button>
          <button className="btn btn-info">Afficher facture</button> */}

                  <div className="row mt-4">
                    <div className="col-12">
                      {showResults ? (
                        <PDFViewer style={{ width: "100%", height: "800px" }}>
                          <MyDocument invoice={objectToPdf} />
                        </PDFViewer>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditerCommandeVente;
