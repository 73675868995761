import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllCongeByCode } from "../../Services/Pointeuse/CongeApi";
import { getAllDeplacementsByCode, getDepalacementByUserId } from "../../Services/Pointeuse/DeplacementApi";
import { getAllPointageHistory, getPointageByCode } from "../../Services/Pointeuse/PointageApi";
import { getRetardByCode } from "../../Services/Pointeuse/RetardApi";
import { getUsersActifByCode, getUsersPersonnelByCode } from "../../Services/Pointeuse/UsersApi";
import { filterUserByWord } from "../../Utils/SortObject";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";

import AddPointage from "./AddPointage";
import SinglePointage from "./SinglePointage";

var dateObj = new Date();
var result = dateObj.toISOString().split("T")[0];
var month = result.split("-")[1];
var year = result.split("-")[0];
var newdate = year + "-" + month;

function PointageManquant() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(20);
  const [date, setdate] = useState(newdate);
  const [totalConge, settotalConge] = useState(null);
  const [cp, setcp] = useState(null);
  const [monthRetard, setmonthRetard] = useState(null);
  const [workDay, setworkDay] = useState(null);
  const [breakTime, setbreakTime] = useState(null);
  //
  const [dataRetards, setDataRetards] = useState(null);
  const [dataPointages, setDataPointages] = useState(null);
  const [dataConges, setdataConges] = useState(null);
  const [dataDeplacements, setdataDeplacements] = useState(null);

  /**
   * The function sets the size of the data to be retrieved from the API.
   * @param e - The event object that contains the data for the event.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  /**
   * It gets the data from the API and sets the data to the state.
   */
  const getData = () => {
    setLoading(true);
    getUsersActifByCode()
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  /**
   * The onChangePage function is called when the user clicks on the page numbers.
   * It takes the page of items returned by the paginate function as a parameter and
   * uses that to update the currentData state
   * @param pageOfItems - an array of items on the current page
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the search word.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    const dataSearch = filterUserByWord(allInitialData, e.target.value);
    setdata(dataSearch);
  };

  /**
   * The function takes in a date and sets the date state to that date
   * @param date - The date that the user has selected.
   */
  const handleChangeDate = (date) => {
    setLoading(true);
    setdate(date);
    setLoading(false);
  };

  //getall retard
  useEffect(() => {
    getRetardByCode().then((res) => {
      setDataRetards(res.data);
    });
  }, []);
  useEffect(() => {
    getAllPointageHistory().then((res) => {
      setDataPointages(res.data);
    });
  }, []);

  useEffect(() => {
    getAllCongeByCode().then((res) => {
      setdataConges(res.data);
    });
  }, []);
  useEffect(() => {
    getAllDeplacementsByCode().then((res) => {
      setdataDeplacements(res.data);
    });
  }, []);

  return (
    <div>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Pointage manquant")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="#" className="btn ripple btn-primary" data-toggle="modal" data-target="#addPointageModal">
                {t("Pointage d'entrée")}
              </Link>
              <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                <i className="feather feather-mail" />
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                <i className="feather feather-phone-call" />
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                <i className="feather feather-info" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 col-lg-3">
                  <div className="form-group">
                    <label className="form-label">{t("Choisir le moins")}:</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="feather feather-calendar" />
                        </div>
                      </div>
                      <input
                        className="form-control fc-datepicker"
                        placeholder="DD-MM-YYYY"
                        type="month"
                        onChange={(e) => handleChangeDate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              {/* <div className="row">
                <div className="col-md-12">
                  <div className="hr-checkall">
                    <label className="custom-control custom-checkbox mb-0">
                      <input type="checkbox" className="custom-control-input" id="checkAll" />
                      <span className="custom-control-label">Check All</span>
                    </label>
                  </div>
                </div>
              </div> */}
              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>
                          {/* <ExportCsv data={data} name={"List_collaborateurs"} />
                          <ExportPdf data={data} name={"List_collaborateurs"} columns={["nom", "prenom", "email"]} /> */}
                          {/* <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0 w-5"># ID</th>
                              <th className="border-bottom-0">{t("Nom/Prénom")}</th>
                              <th className="border-bottom-0">{t("Présence")}</th>
                              <th className="border-bottom-0">{t("Absence")}</th>
                              <th className="border-bottom-0">{t("Retard")}</th>
                              <th className="border-bottom-0">{t("Pointage de sortie")}</th>
                              <th className="border-bottom-0">{t("Actions")}</th>
                              {/* <th className="border-bottom-0">Nb-J Congé</th>
                              <th className="border-bottom-0">Type Contrat</th> */}
                            </tr>
                          </thead>
                          {dataRetards && dataPointages && dataConges && dataDeplacements && (
                            <tbody>
                              {currentData.map((el, idx) => (
                                <SinglePointage
                                  key={idx}
                                  user={el}
                                  currentDate={date}
                                  totalConge={totalConge}
                                  cp={cp}
                                  workDay={workDay}
                                  monthRetard={monthRetard}
                                  breakTime={breakTime}
                                  getData={getData}
                                  dataRetards={dataRetards}
                                  dataPointages={dataPointages}
                                  dataDeplacements={dataDeplacements}
                                  dataConges={dataConges}
                                ></SinglePointage>
                              ))}
                            </tbody>
                          )}

                          <tfoot>
                            <tr>
                              <th className="border-bottom-0 w-5"># ID</th>
                              <th className="border-bottom-0">{t("Nom/Prénom")}</th>
                              <th className="border-bottom-0">{t("Présence")}</th>
                              <th className="border-bottom-0">{t("Absence")}</th>
                              <th className="border-bottom-0">{t("Retard")}</th>
                              <th className="border-bottom-0">{t("Pointage de sortie")}</th>
                              <th className="border-bottom-0">{t("Actions")}</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          {t("Affichage de 1 à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* <div className="card-footer">
              <a href="#" className="btn btn-primary float-right">
                Save All
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <AddPointage getData={getData} />
    </div>
  );
}

export default PointageManquant;
