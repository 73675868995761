import React, { useEffect, useRef, useState } from "react";
import { getAllMessages, senFcmTopics } from "../../Services/Messaging/ChatApi";
import { getUserById } from "../../Services/Pointeuse/UsersApi";
import { sendMessage } from "../../Services/Messaging/ChatApi";
import firebase from "../../firebase";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import { Link } from "react-router-dom";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import socket from "../../Connection/ws";
import { getSession } from "../../Utils/SessionUtils";
import Picker, { SKIN_TONE_NEUTRAL } from "emoji-picker-react";
import moment from "moment";
import CustomOption from "./CustomOption";
//wysiwyg
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// FilePond
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FileImageComponent from "../Styles/FileImageComponent";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function Message(props) {
  const divRref = useRef(null);
  const refCloseModal = useRef(null);
  const [data, setdata] = useState([]);
  const [senderName, setsenderName] = useState("");
  const [receiverName, setreceiverName] = useState("");
  const [senderImg, setsenderImg] = useState("assets/images/users/avatar.png");
  const [receiverImg, setreceiverImg] = useState("");
  const [isShowWy, setisShowWy] = useState(false);
  const [arrivalMessage, setarrivalMessage] = useState(null);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isShowPicker, setisShowPicker] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [message, setMessage] = useState(null);
  const [onlines, setonlines] = useState(null);
  const [isOnline, setisOnline] = useState(null);
  const [isTyping, setisTyping] = useState(false);
  //wyswing
  const [wtext, setwtext] = useState(null);
  let editorState = EditorState.createEmpty();
  const [wdescription, setwDescription] = useState(editorState);

  useEffect(() => {
    socket.emit("addUser", localStorage.getItem("id"));
    socket.on("getUsers", (users) => {
      console.log(users);
      const isOnline = users.some((el) => el.userId == props.match.params.id);
      setonlines(users);
      setisOnline(isOnline);
    });
  }, []);

  const onEmojiClick = (event, emojiObject) => {
    setisShowPicker(false);
    setChosenEmoji(emojiObject);
    setMessage(emojiObject.emoji);
    //console.log(emojiObject.emoji);
  };

  const getData = () => {
    setLoading(true);
    const currentUser = localStorage.getItem("id");
    getAllMessages()
      .then((res) => {
        scrollEnd();
        const currentMessages = res.data.filter((el) => {
          return (
            (el.id_receiver == currentUser || el.id_sender == currentUser) &&
            (el.id_receiver == props.match.params.id || el.id_sender == props.match.params.id)
          );
        });
        setdata(currentMessages);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  // useEffect(() => {
  //   const onChat = (result) => {
  //     if (result.id_sender == getSession("id")) {
  //       result["date"] = moment(result.date).format("YYYY-MM-DD HH:mm:ss");
  //       result["id"] = parseInt(Date.now() * Math.random());
  //       setdata((data) => [...data, result]);
  //       scrollEnd();
  //     }
  //   };

  //   socket.on("chat", onChat);
  //   return () => socket.off("/chat", onChat);
  // }, []);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    scrollEnd();
  }, [data, loading]);

  const scrollEnd = () => {
    divRref?.current?.scrollIntoView({
      behavior: "instant",
    });
  };

  useEffect(() => {
    localStorage.getItem("img")
      ? setreceiverImg("assets/images/users/avatar.png")
      : setreceiverImg(localStorage.getItem("baseUrl") + localStorage.getItem("img"));

    setreceiverName(localStorage.getItem("nom") + " " + localStorage.getItem("prenom"));
    getUserById(props.match.params.id).then((res) => {
      console.log(res.data);
      setsenderName(res.data.nom + " " + res.data.prenom);
      if (res.data.img) {
        setsenderImg(localStorage.getItem("baseUrl") + res.data.img);
      }
    });
  }, []);

  const handleSendMsg = () => {
    var text = null;
    if (isShowWy) {
      text = wtext?.value;
    } else {
      text = message;
    }

    if (text) {
      var object = {
        id: parseInt(Date.now() * Math.random()),
        code_generated: getSession("code_generated"),
        id_sender: localStorage.getItem("id"),
        id_receiver: props.match.params.id,
        msg: text,
        date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };

      const newObject = [...data, object];
      setdata(newObject);
      sendMessage(object.id_sender, object.id_receiver, text).then(() => {
        setMessage("");
        let editorState = EditorState.createEmpty();
        setwDescription(editorState);
        trigerFirebase(text);
        socket.emit("sendMessage", {
          senderId: localStorage.getItem("id"),
          receiverId: props.match.params.id,
          text: text,
        });
        //!isOnline && getData();
      });
    }
  };

  const handleSendFiles = () => {
    var list = [];
    for (let i = 0; i < files.length; i++) {
      console.log(files[i]);
      const file = files[i].file;

      // const newObject = [...data, object];
      // setdata(newObject);
      sendMessage(localStorage.getItem("id"), props.match.params.id, "", file).then((res) => {
        console.log(res.data);

        var object = {
          id: parseInt(Date.now() * Math.random()),
          code_generated: getSession("code_generated"),
          id_sender: localStorage.getItem("id"),
          id_receiver: props.match.params.id,
          file: res.data.file,
          date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        };
        //list.push(object);

        socket.emit("sendMessage", {
          senderId: localStorage.getItem("id"),
          receiverId: props.match.params.id,
          text: "",
          file: res.data.file,
        });
        // setMessage("");
        // let editorState = EditorState.createEmpty();
        // setwDescription(editorState);
        // trigerFirebase(text);
        //getData();
        var newData = [...data, object];
        //const array3 = data.concat(list);
        setdata(newData);
        //console.log(array3);
        trigerFirebase(files[0].file.name);
      });

      //socket.emit("sendMessage", array3);
      //!isOnline && getData();
    }
    refCloseModal.current.click();
    setFiles(null);
  };

  useEffect(() => {
    socket.on("getMessage", (rec) => {
      var object = {
        id: parseInt(Date.now() * Math.random()),
        code_generated: getSession("code_generated"),
        id_sender: rec.senderId,
        id_receiver: localStorage.getItem("id"),
        msg: rec.text,
        file: rec.file,
        date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };

      setarrivalMessage(object);

      console.log("should stop");
    });
  }, []);

  const handleKeyDown = (event) => {
    if (message) {
      if (event.key === "Enter") {
        var object = {
          id: parseInt(Date.now() * Math.random()),
          code_generated: getSession("code_generated"),
          id_sender: localStorage.getItem("id"),
          id_receiver: props.match.params.id,
          msg: message,
          date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        };

        const newObject = [...data, object];
        console.log(data);
        setdata(newObject);
        console.log(newObject);
        sendMessage(object.id_sender, object.id_receiver, message).then(() => {
          //settext("");
          socket.emit("sendMessage", {
            senderId: localStorage.getItem("id"),
            receiverId: props.match.params.id,
            text: message,
          });

          setMessage("");
          let editorState = EditorState.createEmpty();
          //const editorState = EditorState.push(editorState, ContentState.createFromText(""));
          setwDescription(editorState);

          //getData();
          trigerFirebase(message);
        });
      }
    }
  };

  useEffect(() => {
    arrivalMessage && props.match.params.id == arrivalMessage.id_sender && setdata([...data, arrivalMessage]);
  }, [arrivalMessage]);

  const handleChangeWy = () => {
    setisShowWy(!isShowWy);
  };

  const trigerFirebase = (text) => {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then(() => {
        senFcmTopics(receiverName, text, props.match.params.id);
        scrollEnd();
        setLoading(false);
        divRref.current.scrollIntoView({
          behavior: "instant",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeMessage = (value) => {
    setMessage(value);
    socket.emit("istyping", props.match.params.id);
  };

  useEffect(() => {
    socket.on("gettyping", (el) => {
      console.log("should start " + el);
      setisTyping(el);
      scrollEnd();
    });
  }, []);

  // useEffect(() => {
  //   socket.on("stoptyping", (el) => {
  //     console.log("should stop");
  //     setisTyping(el);
  //     scrollEnd();
  //   });
  // }, []);

  return (
    <div>
      <div className="page-header d-lg-flex d-block">
        {/* <div className="page-leftheader">
          <h4 className="page-title">Chat 02</h4>
        </div> */}
        <div className="page-rightheader ml-md-auto">
          <div className=" btn-list">
            <ButtonHeaderComponent />
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="card">
        <div className="row no-gutters">
          <div className="col-xl-12 col-lg-12">
            <div className="border-left">
              <div className="main-content-body main-content-body-chat">
                <div className="main-chat-header p-3">
                  <div className="main-img-user">
                    <img alt="" src={senderImg} className="avatar avatar-md brround" />
                  </div>
                  <div className="main-chat-msg-name">
                    <h6>{senderName}</h6>
                    {isOnline ? <small className="text-success">en ligne</small> : <small className="test-default">hors ligne</small>}
                  </div>
                  <nav className="nav">
                    {/* <a className="nav-link" href="#">
                      <i className="icon ion-md-more" />
                    </a>
                    <a className="nav-link" data-toggle="tooltip" href="#" title="Call">
                      <span className="feather feather-phone-call" />
                    </a>
                    <a className="nav-link" data-toggle="tooltip" href="#" title="Archive">
                      <span className="feather feather-folder-plus" />
                    </a>
                    <a className="nav-link" data-toggle="tooltip" href="#" title="Trash">
                      <span className="feather feather-trash-2" />
                    </a> */}
                    <Link to="#" className="nav-link" data-toggle="tooltip" title="Voir info">
                      <span className="feather feather-info" />
                    </Link>
                  </nav>
                </div>
                {/* main-chat-header */}
                {loading && <CustomSyncLoader></CustomSyncLoader>}
                {!loading && error && <ErrorCustum></ErrorCustum>}
                {!loading && !error && data && (
                  <div className="main-chat-body" id="ChatBody">
                    {data.map((el, idx) => (
                      <div key={idx} ref={divRref} className="content-inner">
                        {/* <label className="main-chat-time">
                          <span>Yesterday</span>
                        </label> */}

                        {el.id_sender == localStorage.getItem("id") ? (
                          <div className="media">
                            <div className="main-img-user online">
                              <img alt="" src={receiverImg} className="avatar avatar-md brround" />
                            </div>
                            <div className="media-body">
                              <div className="main-msg-wrapper">
                                {" "}
                                <div className="post__description" dangerouslySetInnerHTML={{ __html: el.msg }} />
                                {el.file && <FileImageComponent file={el.file} />}
                              </div>
                              <div>
                                <span>{el.date.split(" ")[1]}</span>{" "}
                                <a href="#">
                                  <i className="icon ion-android-more-horizontal" />
                                </a>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="media flex-row-reverse mt-1">
                            <div className={isOnline ? "main-img-user online" : "main-img-user"}>
                              <img alt="" src={senderImg} className="avatar avatar-md brround" />
                            </div>
                            <div className="media-body">
                              <div className="main-msg-wrapper">
                                <div className="post__description" dangerouslySetInnerHTML={{ __html: el.msg }} />
                                {el.file && <FileImageComponent file={el.file} />}
                              </div>
                              <div>
                                <span>
                                  {el.date.split(" ")[1]} | {el.date.split(" ")[0]}
                                </span>{" "}
                                <a href="#">
                                  <i className="icon ion-android-more-horizontal" />
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}

                    {isTyping && (
                      <div className="media flex-row-reverse mt-1 mr-4">
                        <div className={isOnline ? "main-img-user online" : "main-img-user"}>
                          <img alt="" src={senderImg} className="avatar avatar-md brround" />
                        </div>
                        <div className="media-body">
                          <div className="main-msg-wrapper">
                            <div className="post__description" dangerouslySetInnerHTML={{ __html: "..." }} />
                          </div>
                          <div>
                            <span></span>{" "}
                            <a href="#">
                              <i className="icon ion-android-more-horizontal" />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {isShowWy ? (
                  <div className="d-flex" style={{ marginBottom: "75px" }}>
                    <div className="form-group col-md-12 editor">
                      <Editor
                        className="form-control"
                        editorState={wdescription}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={(e) => setwDescription(e)}
                        toolbarCustomButtons={[<CustomOption handleSendMsg={handleSendMsg} handleChangeWy={handleChangeWy} />]}
                        onTab={(event) => alert("hhhhhh")}
                        hashtag={{
                          separator: " ",
                          trigger: "#",
                        }}
                        mention={{
                          separator: " ",
                          trigger: "@",
                          suggestions: [
                            { text: "APPLE", value: "apple", url: "apple" },
                            { text: "BANANA", value: "banana", url: "banana" },
                            { text: "CHERRY", value: "cherry", url: "cherry" },
                            { text: "DURIAN", value: "durian", url: "durian" },
                            { text: "EGGFRUIT", value: "eggfruit", url: "eggfruit" },
                            { text: "FIG", value: "fig", url: "fig" },
                            { text: "GRAPEFRUIT", value: "grapefruit", url: "grapefruit" },
                            { text: "HONEYDEW", value: "honeydew", url: "honeydew" },
                          ],
                        }}
                      />
                      <textarea
                        style={{ display: "none" }}
                        disabled
                        ref={(val) => setwtext(val)}
                        value={draftToHtml(convertToRaw(wdescription.getCurrentContent()))}
                      />
                    </div>
                    {/* <Link className="main-msg-send" to="#" onClick={handleSendMsg}>
                      <span className="feather feather-send" />
                    </Link> */}
                  </div>
                ) : (
                  <div className="main-chat-footer">
                    <nav className="nav mt-1">
                      <Link
                        to="#"
                        className="nav-link text-danger"
                        data-toggle="tooltip"
                        title="Editeur du text"
                        onClick={() => setisShowWy(!isShowWy)}
                      >
                        <span className="fa fa-pencil" />
                      </Link>

                      <Link to="#" className="nav-link text-warning" data-toggle="modal" data-target="#exampleModalCenter">
                        <span className="fa fa-paperclip" />
                      </Link>

                      <Link to="#" className="nav-link" data-toggle="tooltip" title="Emoji" onClick={() => setisShowPicker(!isShowPicker)}>
                        <span className="fa fa-smile-o" />
                      </Link>

                      <Link to="#" className="nav-link" data-toggle="tooltip" title="Attach" onClick={() => setisShowPicker(!isShowPicker)}>
                        <span className="fa fa-smile-o" />
                      </Link>
                    </nav>
                    {isShowPicker && (
                      <Picker
                        style={{ hight: "80em" }}
                        onEmojiClick={onEmojiClick}
                        disableAutoFocus={true}
                        skinTone={SKIN_TONE_NEUTRAL}
                        groupNames={{ smileys_people: "PEOPLE" }}
                        native
                      />
                    )}
                    <input
                      className="form-control"
                      placeholder="Taper votre message..."
                      type="text"
                      value={message}
                      onChange={(e) => handleChangeMessage(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />{" "}
                    <Link className="main-msg-send text-success" to="#" onClick={handleSendMsg}>
                      <span className="feather feather-send" />
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal */}
      <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Ajouter des fichiers
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={refCloseModal}>
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <FilePond
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={true}
                maxFiles={10}
                name="files"
                labelIdle='Faites glisser et déposez vos fichiers ou <span class="filepond--label-action">Parcourir</span>'
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={handleSendFiles}>
                Envoyer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Message;
