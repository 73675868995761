import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";
import Pagination from "../../Others/Pagination";
import { deleteEncaissementById, getEncaissementByCode, validateEncaissementById } from "../../../Services/Facturation/FacturationEncaissementApi";
import { convertToDigit } from "../../../Utils/NumberUtils";
import { sumMontant, sumMontantHT } from "../../../Utils/ObjectOperation";
import { filterEncaissement } from "../../../Utils/SortObject";
import $ from "jquery";
import ButtonHeaderComponent from "../../Styles/ButtonHeaderComponent";
import ImageComponent from "../../Styles/ImageComponent";
import swal from "sweetalert";
import ExportCsv from "../../Exports/ExportCsv";
import ExportPdf from "../../Exports/ExportPdf";
import CopieContent from "../../Exports/CopieContent";

function ListEncaissement() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [idAction, setidAction] = useState(null);
  const myRefValidate = useRef(null);
  const myRefDelete = useRef(null);
  const [size, setsize] = useState(20);
  const [isCollapsed, setisCollapsed] = useState(false);

  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getEncaissementByCode()
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  // const handleDelete = (id) => {
  //   //$(".modalDeleteEncaissement").hide();
  //   myRefDelete.current.click();
  //   deleteEncaissementById(id);
  // };

  const handleDelete = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteEncaissementById(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    const dataSearch = filterEncaissement(allInitialData, e.target.value);
    setdata(dataSearch);
  };

  const filterByDate = (value) => {
    const filterd = allInitialData.filter((el) => {
      if (el.created_at) {
        return el.created_at.split(" ")[0] == value;
      }
    });

    if (filterd.length === 0) {
      setdata(allInitialData);
    } else {
      setdata(filterd);
    }
  };

  const handleValidateEncaissement = (id) => {
    // $(".modalValidateEncaissement").hide();
    myRefValidate.current.click();
    validateEncaissementById(1, id).then(() => {
      getData();
    });
  };

  const addIdToAction = (id) => {
    setidAction(id);
  };

  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Encaissement</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/addEncaissement" className="btn btn-primary mr-3">
                Ajouter encaissement
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">Liste des encaissements</h4>
              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">Note:</label>
                </div>
                <div>
                  {/* <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> activé
                  </span> */}
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> Total
                  </span>
                  {/* <span className="badge badge-warning-light mr-2">
                  <i className="fa fa-star text-warning" /> ---&gt; Holiday
                </span>
                <span className="badge badge-orange-light mr-2">
                  <i className="fa fa-adjust text-orange" /> ---&gt; Half Day
                </span> */}
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            Afficher{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            entrées
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Recherche..."
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv data={data} name={"List_encaissements"} />
                          <ExportPdf
                            data={data}
                            name={"List_encaissements"}
                            columns={["code_facture", "num_facture", "idClient", "nom", "prenom", "montant", "date_echeance"]}
                          />
                          <CopieContent />

                          {/* <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0 w-5">Facture</th>
                              <th className="border-bottom-0">Ref Facture</th>
                              <th className="border-bottom-0">Nom du client</th>
                              <th className="border-bottom-0">Référence</th>
                              <th className="border-bottom-0">Méthode</th>
                              <th className="border-bottom-0">Source</th>
                              <th className="border-bottom-0">
                                Montant<br></br> <span className="badge badge-pill badge-danger">{sumMontant(data)}</span>
                              </th>
                              {/* <th className="border-bottom-0">Statut</th> */}
                              <th className="border-bottom-0">Date d'échéance</th>
                              <th className="border-bottom-0">Date de réception</th>

                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el, idx) => (
                              <tr key={idx}>
                                <td>
                                  {el.type === "facture" && <Link to={"/admin/editerfacturevente/" + el.code_facture}>{el.num_facture}</Link>}
                                  {el.type === "devis" && <Link to={"/admin/editerdevisvente/" + el.code_facture}>{el.num_facture}</Link>}
                                  {el.type === "bl" && <Link to={"/admin/editerblvente/" + el.code_facture}>{el.num_facture}</Link>}
                                  {el.type === "commande" && <Link to={"/admin/editercommandevente/" + el.code_facture}>{el.num_facture}</Link>}
                                </td>
                                <td>{el.code_facture}</td>
                                <td>
                                  <Link to={"/admin/client/" + el.idClient}>
                                    {el.nom}
                                    {el.prenom}
                                  </Link>
                                </td>
                                <td>{el.reference_banque}</td>
                                <td>{el.mode_paiement}</td>
                                <td>{el.banque}</td>
                                <td>{convertToDigit(el.montant)}</td>
                                {/* <td className="text-center">
                                  {el.status === "payer" && <span className="badge badge-success">Payé</span>}
                                  {el.status === "partiel" && <span className="badge badge-warning">Partiel</span>}
                                  {el.status === "" && <span className="badge badge-danger"></span>}
                                </td> */}
                                <td className="text-right">{el.date_echeance}</td>
                                <td className="text-right">{el.date_reception}</td>
                                <td className="text-left">
                                  {el.validation == 0 && (
                                    <Link
                                      to="#"
                                      data-toggle="modal"
                                      data-target="#modalValidateEncaissement"
                                      onClick={() => addIdToAction(el.id)}
                                      className="action-btns"
                                      data-tip="editer"
                                    >
                                      <i className="feather feather-check text-success" />
                                    </Link>
                                    // <Link
                                    //   to="#"
                                    //   className="btn btn-success btn-xs mr-1"
                                    //   data-toggle="modal"
                                    //   data-target="#modalValidateEncaissement"
                                    //   onClick={() => addIdToAction(el.id)}
                                    // >
                                    //   <i className="far fa-check-circle"></i>
                                    // </Link>
                                  )}

                                  {el.validation == 0 && (
                                    <Link to={"/admin/facturation/editencaissement/" + el.id} className="action-btns">
                                      <i className="feather feather-edit text-info" />
                                    </Link>
                                  )}

                                  {el.validation == 1 && (
                                    <Link to={"/admin/facturation/editencaissement/" + el.id} className="action-btns">
                                      <i className="feather feather-eye text-default" />
                                    </Link>
                                  )}

                                  {el.validation == 0 && (
                                    <Link to="#" className="action-btns" onClick={() => handleDelete(el.id)}>
                                      <i className="feather feather-trash text-danger" />
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          Affichage de 1 à {size} sur {data.length} entrées
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* modal enc */}
      <div
        className="modal fade"
        id="modalDeleteEncaissement"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="modalDeleteEncaissementTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Suppression
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">Voulez vous vraiment supprimé ce encaissement ?</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={myRefDelete}>
                Annuler
              </button>
              <button type="button" className="btn btn-primary" onClick={() => handleDelete(idAction)}>
                Supprimé
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListEncaissement;
