import React, { Component } from "react";
import { editEntreprise, getEntrepriseByCode, updateLogo } from "../../Services/Pointeuse/EntrepriseApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BillParams from "../Facturation/invoiceReports/BillParams";
import { Link } from "react-router-dom";

export default class Entreprise extends Component {
  state = {
    adresse: "",
    banque: "",
    code_generated: "",
    email: "",
    fax: "",
    id: "",
    logo: "",
    matricule_fiscal: "",
    mobile: "",
    nb_employe: "",
    nom: "",
    numero_cnss: "",
    rib: "",
    secteur_activite: "",
    start_year: "",
    website_url: "",
    latitude: "",
    longitude: "",
  };

  componentDidMount() {
    getEntrepriseByCode().then((res) => {
      this.setState({ adresse: res.data.adresse });
      this.setState({ banque: res.data.banque });
      this.setState({ code_generated: res.data.code_generated });
      this.setState({ email: res.data.email });
      this.setState({ fax: res.data.fax });
      this.setState({ id: res.data.id });
      if (res.data.logo) {
        console.log("me");
        this.setState({
          logo: localStorage.getItem("baseUrl") + res.data.logo,
        });
      } else {
        console.log("not");
        this.setState({ logo: "/assets/images/company/company.png" });
      }
      this.setState({ matricule_fiscal: res.data.matricule_fiscal });
      this.setState({ mobile: res.data.mobile });
      this.setState({ nb_employe: res.data.nb_employe });
      this.setState({ nom: res.data.nom });
      this.setState({ numero_cnss: res.data.numero_cnss });
      this.setState({ rib: res.data.rib });
      this.setState({ secteur_activite: res.data.secteur_activite });
      this.setState({ start_year: res.data.start_year });
      this.setState({ website_url: res.data.website_url });
      this.setState({ latitude: res.data.latitude });
      this.setState({ longitude: res.data.longitude });
    });
  }

  handelNom = (event) => {
    this.setState({ nom: event.target.value });
  };
  handelAdresse = (event) => {
    this.setState({ adresse: event.target.value });
  };
  handelMatriculeFiscale = (event) => {
    this.setState({ matricule_fiscal: event.target.value });
  };
  handelNumCnss = (event) => {
    this.setState({ numero_cnss: event.target.value });
  };
  handelRib = (event) => {
    this.setState({ rib: event.target.value });
  };
  handelBanque = (event) => {
    this.setState({ banque: event.target.value });
  };
  handelMobile = (event) => {
    this.setState({ mobile: event.target.value });
  };
  handelFax = (event) => {
    this.setState({ fax: event.target.value });
  };
  handelWebsiteUrl = (event) => {
    this.setState({ website_url: event.target.value });
  };
  handelSecteurActivity = (event) => {
    this.setState({ secteur_activite: event.target.value });
  };
  handelEmail = (event) => {
    this.setState({ email: event.target.value });
  };
  handelNbEmploye = (event) => {
    this.setState({ nb_employe: event.target.value });
  };
  handelStartYear = (event) => {
    this.setState({ start_year: event.target.value });
    console.info(event.target.value);
  };

  handleLogo = (event) => {
    console.log(URL.createObjectURL(event.target.files[0]));
    this.setState({ logo: URL.createObjectURL(event.target.files[0]) });
    updateLogo(event.target.files[0], this.state.code_generated)
      .then((res) => {
        toast.success("image ajouté");
      })
      .catch((err) => {
        toast.error("Erreur");
      });
  };

  handelSave = () => {
    editEntreprise(
      this.state.nom,
      this.state.adresse,
      this.state.matricule_fiscal,
      this.state.numero_cnss,
      this.state.rib,
      this.state.banque,
      this.state.mobile,
      this.state.fax,
      this.state.website_url,
      this.state.secteur_activite,
      this.state.email,
      this.state.nb_employe,
      this.state.start_year,
      this.state.latitude,
      this.state.longitude,
      localStorage.getItem("code_generated")
    ).then((res) => {
      console.log(res);
    });
  };

  render() {
    return (
      <div>
        <div className="page-header d-xl-flex d-block">
          <div className="page-leftheader">
            <h4 className="page-title">Entreprise</h4>
          </div>
          <div className="page-rightheader ml-xl-auto">
            <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
              <div className="btn-list">
                <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                  {" "}
                  <i className="feather feather-mail" />{" "}
                </button>
                <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                  {" "}
                  <i className="feather feather-phone-call" />{" "}
                </button>
                <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                  {" "}
                  <i className="feather feather-info" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*End Page header*/}
        {/*Row*/}
        <div className="row">
          <div className="col-xl-12">
            <div className="tab-content adminsetting-content" id="setting-tabContent">
              <div className="tab-pane fade show active" id="tab-1" role="tabpanel">
                <div className="card">
                  <div className="card-header  border-0">
                    <h4 className="card-title">Modifier information entreprise</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <form>
                          {/* <h4 className="page-title">Détails entreprise</h4> */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              className="m-4"
                              src={this.state.logo}
                              // onClick={this.handleLogo}
                              style={{ width: 100, height: 100 }}
                            ></img>
                          </div>

                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label>
                                  Nom <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  onChange={this.handelNom}
                                  value={this.state.nom}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label>Adresse</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={this.state.adresse}
                                  onChange={this.handelAdresse}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label>Latitude</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={this.state.latitude}
                                  onChange={(e) => this.setState({ latitude: e.target.value })}
                                />
                              </div>
                            </div>

                            <div className="col-sm-4">
                              <div className="form-group">
                                <label>Longitude</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={this.state.longitude}
                                  onChange={(e) => this.setState({ longitude: e.target.value })}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Matricule Fiscal</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={this.state.matricule_fiscal}
                                  onChange={this.handelMatriculeFiscale}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Secteur d'activité</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={this.state.secteur_activite}
                                  onChange={this.handelSecteurActivity}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label>Numéro cnss</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={this.state.numero_cnss}
                                  onChange={this.handelNumCnss}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label>RIB</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={this.state.rib}
                                  onChange={this.handelRib}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label>Banque</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={this.state.banque}
                                  onChange={this.handelBanque}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Fax</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={this.state.fax}
                                  onChange={this.handelFax}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Site web</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={this.state.website_url}
                                  onChange={this.handelWebsiteUrl}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Email de contact</label>
                                <input
                                  className="form-control"
                                  type="email"
                                  value={this.state.email}
                                  onChange={this.handelEmail}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Mobile</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={this.state.mobile}
                                  onChange={this.handelMobile}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Nombre Employees</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={this.state.nb_employe}
                                  onChange={this.handelNbEmploye}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Date de lancement</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={this.state.start_year}
                                  onChange={this.handelStartYear}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer float-md-right">
                    <Link to="#" className="btn btn-primary float-md-right" onClick={this.handelSave}>
                      Modifier
                    </Link>
                    {/* <a href="#" className="btn btn-danger">
                      Cancel
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="tab-2" role="tabpanel">
                <div className="card">
                  <div className="card-header  border-0">
                    <h4 className="card-title">Modification paramètre facture</h4>
                  </div>
                  <div className="card-body">
                    <BillParams></BillParams>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
