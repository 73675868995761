import React, { useEffect, useState } from "react";
import { getClientByCodeId, getFacturationClientByCode } from "../../../Services/Facturation/FacturationClientApi";
import { getEncaissementByClientCode } from "../../../Services/Facturation/FacturationEncaissementApi";
import { getEnteteByClientCode } from "../../../Services/Facturation/FacturationEnteteApi";
import ClientFacture from "./ClientFacture";
import ClientEncaissement from "./ClientEncaissement";
import { sumMontant, sumMontantHT, sumMontantTTC, sumMontantTVA } from "../../../Utils/ObjectOperation";
import { convertToDigit, soustractMontant } from "../../../Utils/NumberUtils";
import ClientChart from "./ClientChart";
import ClientChartEncaissement from "./ClientChart copy";
import { Link } from "react-router-dom";
import ButtonHeaderComponent from "../../Styles/ButtonHeaderComponent";
import SpotsSparklineFacture from "../../Charts/CustumSparkline/SpotsSparklineFacture";
import SpotsSparklineEncaissement from "../../Charts/CustumSparkline/SpotsSparklineEncaissement";
function ClientActivity(props) {
  const [user, setUser] = useState(null);
  const [factures, setFactures] = useState([]);
  const [encaissements, setEncaissements] = useState([]);

  useEffect(() => {
    getEnteteByClientCode(props.match.params.id).then((res) => {
      setFactures(res.data);
      const newFiltredList = res.data.filter((el) => {
        return el.type === "facture";
      });
      setFactures(newFiltredList);
    });
    getEncaissementByClientCode(props.match.params.id).then((res) => {
      setEncaissements(res.data);
    });
    getClientByCodeId(props.match.params.id).then((res) => {
      setUser(res.data);
    });
  }, []);
  return (
    <div>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">
            <span className="font-weight-normal text-muted mr-2">Client #{props.match.params.id}</span> Activitées Client
          </h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/addclient" className="btn btn-primary " data-toggle="modal" data-target="#newprojectmodal">
                <i className="feather feather-plus fs-15 my-auto mr-2" />
                ajouter client
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-3 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <svg
                class="card-custom-icon text-danger icon-dropshadow-danger"
                x="1008"
                y="1248"
                viewBox="0 0 24 24"
                height="100%"
                width="100%"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path d="M17.65,6.35 C16.2,4.9 14.21,4 12,4 C7.58,4 4.01,7.58 4.01,12 C4.01,16.42 7.58,20 12,20 C15.73,20 18.84,17.45 19.73,14 L17.65,14 C16.83,16.33 14.61,18 12,18 C8.69,18 6,15.31 6,12 C6,8.69 8.69,6 12,6 C13.66,6 15.14,6.69 16.22,7.78 L13,11 L20,11 L20,4 L17.65,6.35 Z"></path>
              </svg>
              <p class=" mb-1 ">Impayé</p>
              <h2 className="mb-1 font-weight-bold">{soustractMontant(sumMontantTTC(factures), sumMontant(encaissements))}</h2>
              {/* <span className="mb-1 text-muted">
                <span className="text-danger">
                  <i className="fa fa-caret-down  mr-1" /> 100
                </span>{" "}
                than last month
              </span> */}
              <div className="progress progress-sm mt-3 bg-success-transparent">
                <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger" style={{ width: "100%" }} />
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header  border-0">
              <div className="card-title">Client détail</div>
            </div>
            <div className="card-body pt-2 pl-3 pr-3">
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>
                        <span className="w-50">Nom et prénom</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="font-weight-semibold">
                          {user?.nom}
                          {user?.prenom}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="w-50">email</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="font-weight-semibold">{user?.email}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="w-50">mobile</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="font-weight-semibold">{user?.mobile}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="w-50">Zone</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="font-weight-semibold">{user?.zone}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="w-50">région</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="font-weight-semibold">{user?.region}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="w-50">gouvernerat</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="font-weight-semibold">{user?.gouvernorat}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="w-50">adresse facturation</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="font-weight-semibold">{user?.adresse_facturation}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="w-50">matricule fiscale</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="font-weight-semibold">{user?.matricule_fiscale}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="w-50">Statut</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="badge badge-primary">{user?.statut}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div className="p-3">
                <div className="d-flex align-items-end justify-content-between">
                  <h6 className="fs-12">Project Status</h6>
                  <h6 className="fs-12">80%</h6>
                </div>
                <div className="progress h-2">
                  <div className="progress-bar bg-success w-80" />
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="card">
            <div className="card-header  border-0">
              <div className="card-title">Assigned Team</div>
              <div className="ml-auto">
                <Link to="#" className="btn btn-success btn-sm" data-toggle="modal" data-target="#addteammodal">
                  Add
                </Link>
              </div>
            </div>
            <div className="card-body p-1 pt-2">
              <div className="table-responsive">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <div className="d-flex">
                          <span
                            className="avatar avatar-md brround mr-3"
                            style={{ backgroundImage: "url(assets/images/users/4.jpg)" }}
                          />
                          <div className="mr-3 mt-0 mt-sm-1 d-block">
                            <h6 className="mb-1 fs-14">Melanie Coleman</h6>
                            <p className="text-muted mb-0 fs-12">App Developer</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          className="chart-circle chart-circle-xxs"
                          data-value="0.62"
                          data-thickness={3}
                          data-color="#0dcd94"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Project Status"
                        >
                          <div className="chart-circle-value text-success">62</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex mt-1 text-right">
                          <Link to="#" className="action-btns1" data-toggle="tooltip" data-placement="top" title="View">
                            <i className="feather feather-eye text-primary" />
                          </Link>
                          <a
                            href="#"
                            className="action-btns1  mr-0"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete"
                          >
                            <i className="feather feather-trash-2 text-danger" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex">
                          <span
                            className="avatar avatar-md brround mr-3"
                            style={{ backgroundImage: "url(assets/images/users/15.jpg)" }}
                          />
                          <div className="mr-3 mt-0 mt-sm-1 d-block">
                            <h6 className="mb-1 fs-14">Justin Parr</h6>
                            <p className="text-muted mb-0 fs-12">App Designer</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          className="chart-circle chart-circle-xxs"
                          data-value="0.45"
                          data-thickness={3}
                          data-color="#3366ff"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Project Status"
                        >
                          <div className="chart-circle-value text-primary">45</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex mt-1 text-right">
                          <Link to="#" className="action-btns1" data-toggle="tooltip" data-placement="top" title="View">
                            <i className="feather feather-eye text-primary" />
                          </Link>
                          <a
                            href="#"
                            className="action-btns1  mr-0"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete"
                          >
                            <i className="feather feather-trash-2 text-danger" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex">
                          <span
                            className="avatar avatar-md brround mr-3"
                            style={{ backgroundImage: "url(assets/images/users/5.jpg)" }}
                          />
                          <div className="mr-3 mt-0 mt-sm-1 d-block">
                            <h6 className="mb-1 fs-14">Amelia Russell</h6>
                            <p className="text-muted mb-0 fs-12">App Designer</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          className="chart-circle chart-circle-xxs"
                          data-value="0.53"
                          data-thickness={3}
                          data-color="#f7284a"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Project Status"
                        >
                          <div className="chart-circle-value text-danger">53</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex mt-1 text-right">
                          <Link to="#" className="action-btns1" data-toggle="tooltip" data-placement="top" title="View">
                            <i className="feather feather-eye text-primary" />
                          </Link>
                          <a
                            href="#"
                            className="action-btns1  mr-0"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete"
                          >
                            <i className="feather feather-trash-2 text-danger" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex">
                          <span
                            className="avatar avatar-md brround mr-3"
                            style={{ backgroundImage: "url(assets/images/users/14.jpg)" }}
                          />
                          <div className="mr-3 mt-0 mt-sm-1 d-block">
                            <h6 className="mb-1 fs-14">Ryan Young</h6>
                            <p className="text-muted mb-0 fs-12">App Developer</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          className="chart-circle chart-circle-xxs"
                          data-value="0.67"
                          data-thickness={3}
                          data-color="#fe7f00"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Project Status"
                        >
                          <div className="chart-circle-value text-secondary">67</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex mt-1 text-right">
                          <Link to="#" className="action-btns1" data-toggle="tooltip" data-placement="top" title="View">
                            <i className="feather feather-eye text-primary" />
                          </Link>
                          <a
                            href="#"
                            className="action-btns1  mr-0"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete"
                          >
                            <i className="feather feather-trash-2 text-danger" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
        </div>
        <div className="col-xl-9 col-md-12 col-lg-12">
          <div className="row">
            <div className="col-xl-6 col-md-12 col-lg-6">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <p className="mb-1">Revenu total ({factures.length})</p>
                      <h2 className="mb-0 font-weight-semibold">{sumMontantTTC(factures)}</h2>
                    </div>
                    <div className="col">
                      <SpotsSparklineFacture factures={factures} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12 col-lg-6">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <p className="mb-1">Total des dépenses ({encaissements.length})</p>
                      <h2 className="mb-0 font-weight-semibold">{sumMontant(encaissements)}</h2>
                    </div>
                    <div className="col">
                      <SpotsSparklineEncaissement encaissement={encaissements} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-4 col-md-12 col-lg-6">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <p className="mb-1">Expenses</p>
                      <h2 className="mb-0 font-weight-semibold">$1,678</h2>
                    </div>
                    <div className="col">
                      <div id="spark3" />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div className="tab-menu-heading hremp-tabs p-0 ">
            <div className="tabs-menu1">
              {/* Tabs */}
              <ul className="nav panel-tabs">
                {/* <li className="ml-4">
                  <a href="#tab5" className="active" data-toggle="tab">
                    facture
                  </a>
                </li> */}
                <li>
                  <a href="#tab6" className="active" data-toggle="tab">
                    Facture
                  </a>
                </li>
                <li>
                  <a href="#tab7" data-toggle="tab">
                    Encaissement
                  </a>
                </li>
                {/* <li>
                  <a href="#tab8" data-toggle="tab">
                    Milestones
                  </a>
                </li>
                <li>
                  <a href="#tab9" data-toggle="tab">
                    facture
                  </a>
                </li>
                <li>
                  <a href="#tab10" data-toggle="tab">
                    encaissements
                  </a>
                </li>
                <li>
                  <a href="#tab11" data-toggle="tab">
                    Invoices
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="panel-body tabs-menu-body hremp-tabs1 p-0">
            <div className="tab-content">
              {/* <div className="tab-pane active" id="tab5">
                <div className="card-body">
                  <ClientChart encaissement={encaissements} factures={factures} />
                  <SpotsSparklineEncaissement encaissement={encaissements} />
                </div>
              </div> */}

              <div className="tab-pane active" id="tab6">
                <div className="card-body">
                  {/* <ClientFacture factures={factures}></ClientFacture> */}
                  <div className="table-responsive">
                    {/* <Link to="#" className="btn btn-primary btn-tableview">
                      Add Invoice
                    </Link> */}
                    <table className="table  table-vcenter text-nowrap table-bordered border-bottom border-top" id="invoice-tables">
                      <thead>
                        <tr>
                          <th>N° Facture</th>
                          <th>
                            Montant HT <br></br>
                            <span className="badge badge-pill badge-danger">{sumMontantHT(factures)}</span>
                          </th>
                          <th>
                            Montant TVA <br></br>
                            <span className="badge badge-pill badge-danger">{sumMontantTVA(factures)}</span>
                          </th>
                          <th>
                            Montant TTC <br></br>
                            <span className="badge badge-pill badge-danger">{sumMontantTTC(factures)}</span>
                          </th>

                          <th>Date</th>
                          <th>status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {factures.map((el, idx) => (
                          <tr key={idx}>
                            <td>
                              {" "}
                              <Link
                                to={"/admin/editerfacturevente/" + el.code}
                                // className="btn btn-default"
                                // data-toggle="tooltip"
                                // data-placement="top"
                                // title="Editer document"
                              >
                                {el.facture_code}
                              </Link>
                            </td>
                            <td>{convertToDigit(el.montant_total_ht)}</td>
                            <td>{convertToDigit(el.montant_total_tva)}</td>
                            <td>{convertToDigit(el.montant_total_ttc)}</td>
                            <td>{el.date.split(" ")[0]}</td>
                            <td>
                              {el.solde == el.net_a_payer && <span className="badge badge-success">payée</span>}
                              {el.solde < el.net_a_payer && el.solde > 0 && <span className="badge badge-warning">partiel</span>}
                              {el.solde == 0 && <></>}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {/* <tbody>
                        <tr>
                          <td>
                            <Link to="#">INV-0478</Link>
                          </td>
                          <td>$345.00</td>
                          <td>12-01-2021</td>
                          <td>14-02-2021</td>
                          <td>
                            <span className="text-primary">$345.000</span>
                          </td>
                          <td>
                            <span className="badge badge-success-light">Paid</span>
                          </td>
                          <td>
                            <div className="d-flex">
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="View"
                              >
                                <i className="feather feather-eye  text-primary" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Download"
                              >
                                <i className="feather feather-download   text-success" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Delete"
                              >
                                <i className="feather feather-trash-2 text-danger" />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Link to="#">INV-1245</Link>
                          </td>
                          <td>$834.00</td>
                          <td>12-01-2021</td>
                          <td>14-02-2021</td>
                          <td>
                            <span className="text-primary">$834.000</span>
                          </td>
                          <td>
                            <span className="badge badge-danger-light">UnPaid</span>
                          </td>
                          <td>
                            <div className="d-flex">
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="View"
                              >
                                <i className="feather feather-eye  text-primary" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Download"
                              >
                                <i className="feather feather-download   text-success" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Delete"
                              >
                                <i className="feather feather-trash-2 text-danger" />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Link to="#">INV-5280</Link>
                          </td>
                          <td>$16,753.00</td>
                          <td>21-01-2021</td>
                          <td>15-01-2021</td>
                          <td>
                            <span className="text-primary">$16,753.000</span>
                          </td>
                          <td>
                            <span className="badge badge-success-light">Paid</span>
                          </td>
                          <td>
                            <div className="d-flex">
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="View"
                              >
                                <i className="feather feather-eye  text-primary" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Download"
                              >
                                <i className="feather feather-download   text-success" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Delete"
                              >
                                <i className="feather feather-trash-2 text-danger" />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Link to="#">INV-2876</Link>
                          </td>
                          <td>$297.00</td>
                          <td>05-02-2021</td>
                          <td>21-02-2021</td>
                          <td>
                            <span className="text-primary">$297.000</span>
                          </td>
                          <td>
                            <span className="badge badge-success-light">Paid</span>
                          </td>
                          <td>
                            <div className="d-flex">
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="View"
                              >
                                <i className="feather feather-eye  text-primary" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Download"
                              >
                                <i className="feather feather-download   text-success" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Delete"
                              >
                                <i className="feather feather-trash-2 text-danger" />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Link to="#">INV-1986</Link>
                          </td>
                          <td>$12,897.00</td>
                          <td>01-01-2021</td>
                          <td>24-02-2021</td>
                          <td>
                            <span className="text-primary">$12,897.00</span>
                          </td>
                          <td>
                            <span className="badge badge-danger-light">UnPaid</span>
                          </td>
                          <td>
                            <div className="d-flex">
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="View"
                              >
                                <i className="feather feather-eye  text-primary" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Download"
                              >
                                <i className="feather feather-download   text-success" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Delete"
                              >
                                <i className="feather feather-trash-2 text-danger" />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody> */}
                    </table>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="tab7">
                <div className="card-body">
                  {/* <ClientEncaissement encaissement={encaissements}></ClientEncaissement> */}
                  <div className="table-responsive">
                    {/* <Link to="#" className="btn btn-primary btn-tableview">
                      Add Invoice
                    </Link> */}
                    <table className="table  table-vcenter text-nowrap table-bordered border-bottom border-top" id="invoice-tables">
                      <thead>
                        <tr>
                          <th>N° Facture</th>
                          <th>Ref Facture</th>
                          <th>
                            Montant HT <br></br>
                            <span className="badge badge-pill badge-danger">{sumMontant(encaissements)}</span>
                          </th>
                          <th>Date</th>
                          <th>status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {encaissements.map((el, idx) => (
                          <tr key={idx}>
                            <td>{el.num_facture}</td>
                            <td>
                              {el.type === "facture" && <Link to={"/admin/editerfacturevente/" + el.code_facture}>{el.code_facture}</Link>}
                              {el.type === "devis" && <Link to={"/admin/editerdevisvente/" + el.code_facture}>{el.code_facture}</Link>}
                              {el.type === "bl" && <Link to={"/admin/editerblvente/" + el.code_facture}>{el.code_facture}</Link>}
                              {el.type === "commande" && <Link to={"/admin/editercommandevente/" + el.code_facture}>{el.code_facture}</Link>}
                            </td>
                            <td>{convertToDigit(el.montant)}</td>
                            <td>{el.created_at}</td>
                            <td className="text-center">
                              {el.status === "payer" && <span className="badge badge-success">Payé</span>}
                              {el.status === "partiel" && <span className="badge badge-warning">Partiel</span>}
                              {el.status === "" && <span className="badge badge-danger"></span>}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientActivity;
