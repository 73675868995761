/**
 * filter facture by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const checkEmptyPriceQuantity = (arrayObject) => {
  return arrayObject.some((item) => !item.qte || item.qte == "0" || !item.prixHt || item.prixHt == "0");
};

/**
 * It sums the total HT of all the invoices in the array.
 * @param arrayObject - the array of objects that you want to sum
 * @returns The sum of the montant_total_ht for each object in the array.
 */
export const sumMontantHT = (arrayObject) => {
  let sum = arrayObject
    .map((o) => o.montant_total_ht)
    .reduce((a, c) => {
      return a + c;
    }, 0);
  return Number(sum).toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 3 }
  );
};

/**
 * It sums the total TVA of all the invoices in the array.
 * @param arrayObject - the array of objects that you want to sum
 * @returns The sum of the montant_total_tva of each object in the array.
 */
export const sumMontantTVA = (arrayObject) => {
  let sum = arrayObject
    .map((o) => o.montant_total_tva)
    .reduce((a, c) => {
      return a + c;
    }, 0);
  return Number(sum).toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 3 }
  );
};

/**
 * It sums the total amount of all the invoices in the array.
 * @param arrayObject - the array of objects that you want to sum
 * @returns The sum of the montant_total_ttc for each object in the array.
 */
export const sumMontantTTC = (arrayObject) => {
  let sum = arrayObject
    .map((o) => o.montant_total_ttc)
    .reduce((a, c) => {
      return a + c;
    }, 0);
  return Number(sum).toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 3 }
  );
};

/**
 * It sums the values of the arrayObject and returns the sum as a string.
 * @param arrayObject - the array of objects that you want to sum
 * @returns The sum of all the payments in the array.
 */
export const sumPaiement = (arrayObject) => {
  // arrayObject.forEach((element) => {
  //   console.log(element.montant_paiement);
  // });
  let sum = arrayObject
    .map((o) => o.montant_paiement)
    .reduce((a, c) => {
      return a + (c || 0);
    }, 0);
  return Number(sum).toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 3 }
  );
};

/**
 * It sums the values of the arrayObject.
 * @param arrayObject - the array of objects that you want to sum
 * @returns The sum of the `montant_encaissement` property of each object in the array.
 */
export const sumEncaissement = (arrayObject) => {
  // arrayObject.forEach((element) => {
  //   console.log(element.montant_paiement);
  // });
  let sum = arrayObject
    .map((o) => o.montant_encaissement)
    .reduce((a, c) => {
      return a + (c || 0);
    }, 0);
  return Number(sum).toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 3 }
  );
};

/**
 * It sums the montant property of each object in the array.
 * @param arrayObject - The array of objects that you want to sum.
 * @returns The sum of the montant values in the array.
 */
export const sumMontant = (arrayObject) => {
  let sum = arrayObject
    .map((o) => o.montant)
    .reduce((a, c) => {
      return a + c;
    }, 0);
  return Number(sum).toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 3 }
  );
};

/**
 * It sums the montant_encaissement and the montant_total_ttc of the arrayObject and returns the
 * difference.
 * @param arrayObject - The array of objects that you want to sum.
 * @returns The sum of the solde of the invoices.
 */
export const sumSolde = (arrayObject) => {
  let sumMontantSolde = arrayObject
    .map((o) => o.montant_encaissement)
    .reduce((a, c) => {
      return a + (c || 0);
    }, 0);

  let sumMontantTTC = arrayObject
    .map((o) => o.montant_total_ttc)
    .reduce((a, c) => {
      return a + (c || 0);
    }, 0);

  var solde = Number(sumMontantTTC) - Number(sumMontantSolde);
  //console.log(solde);
  return Number(solde).toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 3 }
  );
};

/**
 * It sums the montant_paiement and montant_total_ttc of the arrayObject and returns the difference.
 * @param arrayObject - the array of objects that you want to sum
 * @returns The sum of the solde_paiement and the sum of the montant_total_ttc.
 */
export const sumSoldePaiement = (arrayObject) => {
  let sumMontantSolde = arrayObject
    .map((o) => o.montant_paiement)
    .reduce((a, c) => {
      return a + (c || 0);
    }, 0);

  let sumMontantTTC = arrayObject
    .map((o) => o.montant_total_ttc)
    .reduce((a, c) => {
      return a + (c || 0);
    }, 0);

  var solde = Number(sumMontantTTC) - Number(sumMontantSolde);
  //console.log(solde);
  return Number(solde).toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 3 }
  );
};

/**
 * It sums the total montant_total_ht for each object in the arrayObject.
 * @param arrayObject - The array of objects that you want to sum.
 * @param currentMonth - the current month
 * @returns The sum of all the montant_total_ht for each month.
 */
export const sumMonthAbonnement = (arrayObject, currentMonth) => {
  let sum = arrayObject
    .map((o) => o.montant_total_ht)
    .reduce((a, c) => {
      return a + c;
    }, 0);

  return Number(sum).toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 3 }
  );
};

/**
 * It sums the prices of the objects in the array.
 * @param arrayObject - The array of objects that you want to sum the prix property of.
 * @returns The sum of the prix of all the objects in the array.
 */
export const sumPrix = (arrayObject) => {
  let sum = arrayObject
    .map((o) => o.prix)
    .reduce((a, c) => {
      return a + c;
    }, 0);
  return Number(sum).toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 3 }
  );
};

/**
 * It sums the salaire of each object in the array and returns the sum as a string with 3 decimal
 * places.
 * @param arrayObject - the array of objects that you want to sum
 * @returns The sum of all the salaires in the array.
 */
export const sumSalaire = (arrayObject) => {
  let sum = arrayObject
    .map((o) => o.salaire)
    .reduce((a, c) => {
      return a + c;
    }, 0);
  return Number(sum).toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 3 }
  );
};

/**
 * It sums the montant of all the virements in the array.
 * @param arrayObject - The array of objects that you want to sum the montant property of.
 * @returns The sum of all the montants in the array.
 */
export const sumMontantVirement = (arrayObject) => {
  let sum = arrayObject
    .map((o) => Number(o.montant))
    .reduce((a, c) => {
      return a + (c || 0);
    }, 0);
  return sum;
};

/**
 * It sums the salaries of the employees in the array.
 * @param arrayObject - the array of objects that you want to sum
 * @returns The sum of the salaries of all employees.
 */
export const sumVirement = (arrayObject) => {
  let sum = arrayObject
    .map((o) => Number(o.salaire))
    .reduce((a, c) => {
      return a + (c || 0);
    }, 0);
  return Number(sum).toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 3 }
  );
};

/**
 * It takes an array of objects and returns an object with the count of each unique value of the property "etat" in the array of objects.
 * @param arrayObject - [{etat: "A"}, {etat: "B"}, {etat: "A"}, {etat: "C"}, {etat: "A"}]
 * @returns { '1': 1, '2': 1, '3': 1, '4': 1, '5': 1 }
 */
export const reduceObject = (arrayObject) => {
  return arrayObject.reduce(function (r, a) {
    r[a.etat] = (r[a.etat] || 0) + 1;
    return r;
  }, {});
};
