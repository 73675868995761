import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It gets the note by id.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "id": "string",
 *   "title": "string",
 *   "content": "string",
 *   "date_created": "string",
 *   "date_modified": "string",
 *   "tags": [
 *     "string"
 *   ]
 * }
 * ```
 */
export async function getNoteById() {
  try {
    const response = await axios.get(api_url + "note/" + localStorage.getItem("id"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Update the status of a note by id
 * @param etat - the new etat of the note
 * @param id - The id of the note to update.
 * @returns The response from the server.
 */
export async function updateEtatNoteById(etat, id) {
  const credentiel = {
    etat: etat,
    id: id,
  };
  try {
    const response = await axios.patch(api_url + "note", credentiel, config);
    // console.log(response);
    // toast.success("Congé accepté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * `deleteNoteById` is a function that deletes a note by its id
 * @param id - The id of the note to delete.
 * @returns The response object contains the status code, the response data, and the response headers.
 */
export async function deleteNoteById(id) {
  try {
    const response = await axios.delete(api_url + "note/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It adds a new note to the database.
 * @param title - The title of the note.
 * @param description - The description of the note.
 * @param date_debut - Date of the beginning of the event
 * @param date_fin - Date of the end of the note
 * @param color - The color of the note.
 * @param image - The image file that you want to upload.
 * @returns The response is an object that contains the data that we want.
 */
export async function ajouterNote(title, description, date_debut, date_fin, color, image) {
  const frmData = new FormData();
  frmData.append("code_generated", localStorage.getItem("code_generated"));
  frmData.append("user_code_id", localStorage.getItem("id"));
  frmData.append("title", title);
  frmData.append("description", description);
  frmData.append("date_debut", date_debut);
  frmData.append("date_fin", date_fin);
  frmData.append("color", color);
  frmData.append("image", image);
  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.post(api_url + "note", frmData, options);
    console.log(response);
    //toast.success("vaccination ajouté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
