import React, { useEffect, useState } from "react";
import { getFacturationClientByCode } from "../../../Services/Facturation/FacturationClientApi";
import { editSoldeEnteteByCode, getEnteteByClientCode } from "../../../Services/Facturation/FacturationEnteteApi";
import { addEncaissement } from "../../../Services/Facturation/FacturationEncaissementApi";
import { ToastContainer } from "react-toastify";
import { getEntrepriseByCode } from "../../../Services/Pointeuse/EntrepriseApi";
import { Prompt } from "react-router";
import { Link, useHistory } from "react-router-dom";

function AddEncaissement() {
  const history = useHistory();
  const [dataFournisseur, setDataFournisseur] = useState([]);
  const [fournisseurCode, setFournisseurCode] = useState("");
  const [dataFacturation, setDataFacturation] = useState([]);
  const [factureCode, setfactureCode] = useState("");
  const [factureNum, setfactureNum] = useState("");
  const [banque, setbanque] = useState("");
  const [modePaiement, setmodePaiement] = useState("cheque");
  const [numPaiement, setnumPaiement] = useState("");
  const [referenceBanque, setreferenceBanque] = useState("");
  const [montant, setmontant] = useState("");
  const [dateReception, setdateReception] = useState("");
  const [dateVersement, setdateVersement] = useState("");
  const [dateEcheance, setdateEcheance] = useState("2021-01-01");
  const [exercice, setexercice] = useState("");
  const [commentaire, setcommentaire] = useState("");
  const [objectFacture] = useState(JSON.parse(localStorage.getItem("facture")));

  useEffect(() => {
    if (objectFacture) {
      getEnteteByClientCode(objectFacture.client_code).then((res) => {
        const result = res.data.filter((el) => {
          //condition problem
          return el.type == "facture" && el.solde <= el.net_a_payer;
        });

        const options = result.map((d) => ({
          value: d.code,
          label: d.date.split("-")[0] + "_" + d.facture_code + "_" + d.montant_total_ttc,
          dateValue: d.date.split("-")[0],
          ttcValue: d.montant_total_ttc,
          numfactureValue: d.facture_code,
        }));
        setDataFacturation(options);
        setFournisseurCode(objectFacture?.client_code);
      });
    }
  }, [objectFacture]);

  const getAllFactures = () => {
    getEnteteByClientCode(objectFacture.client_code).then((res) => {
      const result = res.data.filter((el) => {
        //condition problem
        return el.type == "facture";
      });

      const options = result.map((d) => ({
        value: d.code,
        label: d.date.split("-")[0] + "_" + d.facture_code + "_" + d.montant_total_ttc,
        dateValue: d.date.split("-")[0],
        ttcValue: d.montant_total_ttc,
        numfactureValue: d.facture_code,
      }));
      setDataFacturation(options);
      setFournisseurCode(objectFacture?.client_code);
    });
  };

  useEffect(() => {
    getEntrepriseByCode().then((res) => {
      setbanque(res.data.banque);
    });
  }, []);
  useEffect(() => {
    getFacturationClientByCode().then((res) => {
      setDataFournisseur(res.data);
      console.log(res.data);
    });
  }, []);

  const handleChangeFournisseur = (event) => {
    setFournisseurCode(event.target.value);
    console.log(event.target.value);
    getEnteteByClientCode(event.target.value).then((res) => {
      const result = res.data.filter((el) => {
        return el.type == "facture" && Number(el.solde) < Number(el.net_a_payer);
      });

      console.log("**************");
      console.log(res.data);
      console.log("**************");
      const options = result.map((d) => ({
        value: d.code,
        label: d.date.split("-")[0] + "_" + d.facture_code + "_" + d.montant_total_ttc,
        dateValue: d.date.split("-")[0],
        ttcValue: d.montant_total_ttc,
        numfactureValue: d.facture_code,
      }));
      setDataFacturation(options);
    });
  };
  const handleChangeFacture = (event) => {
    let position = event.nativeEvent.target.selectedIndex;
    let label = event.nativeEvent.target[position].text;
    // console.log(label.split("_").slice(1, -1).join("_"));
    setfactureCode(event.target.value);
    setfactureNum(label.split("_").slice(1, -1).join("_"));
    setexercice(label.split("_")[0]);
  };
  const handleSubmitEncaissement = (e) => {
    e.preventDefault();

    console.log(fournisseurCode);
    addEncaissement(
      banque,
      modePaiement,
      numPaiement,
      referenceBanque,
      montant,
      dateReception,
      dateVersement,
      dateEcheance,
      fournisseurCode,
      factureNum,
      factureCode,
      exercice,
      commentaire,
      ""
    ).then((res) => {
      console.log(res.data);
      editSoldeEnteteByCode(montant, factureCode).then(() => {
        setTimeout(() => {
          history.push("/admin/encaissement");
        }, 2000);
      });
      history.push("/admin/encaissement");
    });
  };
  return (
    <div className="col-xl-12 col-md-12 col-lg-12 mt-4">
      <div className="card">
        <div className="card-header  border-0">
          <h4 className="card-title">Ajouter encaissement</h4>
        </div>
        <div className="card-body">
          <div className="container-fluid">
            <div class="row">
              {/* <div class="col-sm-12 col-lg-6">
          <img style={{ marginTop: 130 }} src="/dist/img/encaissement/encaissement.svg"></img>
        </div> */}
              <div class="col-sm-12 col-lg-12">
                <form style={{ marginTop: 50 }} onSubmit={handleSubmitEncaissement}>
                  <Prompt
                    message={(location, action) => {
                      console.log(action);
                      if (action === "POP") {
                        console.log("Backing up...");
                        localStorage.removeItem("facture");
                      }

                      return "Voulez vous vraiment continuer ?";
                    }}
                  />
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label>Banque</label>
                        <input disabled type="text" className="form-control" value={banque} required onChange={(e) => setbanque(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label>Client</label>
                        {objectFacture?.client_code ? (
                          <>
                            {dataFournisseur && (
                              <select
                                className="form-control"
                                id="exampleFormControlSelect0"
                                value={objectFacture?.client_code ? objectFacture?.client_code : ""}
                                onChange={handleChangeFournisseur}
                              >
                                <option>Choisir Client</option>
                                {dataFournisseur.map((option) => (
                                  <option value={option.code}>
                                    {option.nom} {option.prenom}
                                  </option>
                                ))}
                              </select>
                            )}
                          </>
                        ) : (
                          <>
                            {dataFournisseur && (
                              <select className="form-control" id="exampleFormControlSelect0" onChange={handleChangeFournisseur}>
                                <option>Choisir Client</option>
                                {dataFournisseur.map((option) => (
                                  <option value={option.code}>
                                    {option.nom} {option.prenom}
                                  </option>
                                ))}
                              </select>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label>Facture : </label>
                        <Link to="#" onClick={() => getAllFactures()}>
                          {"     "}
                          afficher tous
                        </Link>
                        {dataFacturation && (
                          <select className="form-control" id="exampleFormControlSelect1" onChange={handleChangeFacture}>
                            <option value={"default"}>Choisir Facture</option>
                            {dataFacturation.map((option) => (
                              <option value={option.value}>{option.label}</option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label>Reference</label>
                        <input type="text" className="form-control" required onChange={(e) => setreferenceBanque(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label>Mode de paiement</label>
                        <select className="form-control" value={modePaiement} required onChange={(e) => setmodePaiement(e.target.value)}>
                          <option value="cheque">Chèque</option>
                          <option value="virement">Virement</option>
                          <option value="traite">Traite</option>
                          <option value="espece">Espèce</option>
                          <option value="rs">RS</option>
                          <option value="autre">Autre</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Numéro paiement</label>
                        <input type="text" className="form-control" required onChange={(e) => setnumPaiement(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Montant</label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          placeholder="ex : 100.100"
                          onChange={(e) => setmontant(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Note</label>
                        <input type="text" className="form-control" required onChange={(e) => setcommentaire(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Date versement</label>
                        <input type="date" className="form-control" required onChange={(e) => setdateVersement(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Date d'échéance</label>
                        <input type="date" className="form-control" required onChange={(e) => setdateEcheance(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Date de réception</label>
                        <input type="date" className="form-control" required onChange={(e) => setdateReception(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  {factureCode && (
                    <button type="submit" className="btn btn-primary">
                      Ajouter
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEncaissement;
