import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addFacturationFamille } from "../../../Services/Facturation/FacturationFamille";
import { getAllGamme } from "../../../Services/Facturation/FacturationGammeApi";

function AddFamille({ getData }) {
  const refHideModal = useRef();
  const [data, setData] = useState([]);
  const [familleCode, setFamilleCode] = useState("");
  const [familleLibelle, setFamilleLibelle] = useState("");
  const [color, setColor] = useState("");
  const [libelle, setLibelle] = useState("");
  const [horsBox, setHorsBox] = useState("");
  const [image, setImage] = useState("/assets/images/product.png");
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setimageName] = useState("");

  useEffect(() => {
    getAllGamme().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.code,
        label: d.libelle,
      }));
      setData(options);
    });
  }, []);

  /**
   * * Add a new famille to the database
   */
  const handleSubmit = () => {
    if (libelle == "") {
      toast.error("nom vide");
    } else if (color == "") {
      toast.error("coleur vide");
    } else if (horsBox == "") {
      toast.error("plafond vide");
    } else if (horsBox !== "" && isNaN(horsBox)) {
      toast.error("hors doit être un nombre");
    } else if (familleCode == "") {
      toast.error("veuillez choisir une gamme");
    } else {
      addFacturationFamille(libelle, familleCode, color, horsBox, imageUrl).then((res) => {
        //history.push("/facturation/famille");
        getData();
        refHideModal.current.click();
      });
    }
  };

  /**
   * It sets the color of the square to the color selected in the color picker.
   * @param event - The event that triggered the callback.
   */
  const handleChangeComplete = (event) => {
    setColor(event.target.value);
  };
  /**
   * * Set the image to the URL of the image file that was uploaded.
   * * Set the imageUrl to the image file that was uploaded.
   * * Set the imageName to the name of the image file that was uploaded
   * @param event - The event that triggered the function.
   */

  const handleImgClient = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageUrl(event.target.files[0]);
    setimageName(event.target.files[0].name);
  };
  /**
   * It sets the familleCode and familleLibelle variables to the value and label of the selected option.
   * @param event - The event object that contains the value of the selected option.
   */
  const handleChange = (event) => {
    setFamilleCode(event.value);
    setFamilleLibelle(event.label);
  };

  return (
    <div
      className="modal fade"
      id="familleModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="familleModalTitle"
      aria-hidden="true"
    >
      <ToastContainer></ToastContainer>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Ajouter gamme
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Nom </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Taper ..."
                  onChange={(e) => setLibelle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Hors box</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="..."
                  onChange={(e) => setHorsBox(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Coleur</label>
                <input type="color" className="form-control" onChange={handleChangeComplete} />
              </div>
              <div className="form-group">
                <p>Choisir gamme : {familleLibelle}</p>
                <Select onChange={handleChange} options={data} />
              </div>
              <div className="form-group">
                {/* <div className="input-group mb-3">
                  <div className="custom-file">
                    <input
                      className="custom-file-input"
                      accept="image/*"
                      type="file"
                      id="inputGroupFile02"
                      onChange={handleImgClient}
                    />
                    <label className="custom-file-label" htmlFor="inputGroupFile02">
                      {imageName}
                    </label>
                  </div>
                </div> */}
                <div className="input-group file-browser">
                  <input
                    type="text"
                    className="form-control browse-file"
                    placeholder={imageName || "choisir un fichier ..."}
                  />
                  <label className="input-group-append">
                    <span className="btn btn-primary">
                      choisir fichier{" "}
                      <input accept="image/*" type="file" style={{ display: "none" }} onChange={handleImgClient} />
                    </span>
                  </label>
                </div>
                <small className="form-text text-muted  mb-5">
                  Documents autorisés: jpg, jpeg, png, pdf. Maximum 1 fichier.
                </small>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={refHideModal}>
              Annuler
            </button>
            <button onClick={handleSubmit} className="btn btn-primary">
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddFamille;
