import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteTasById, getTasksByCode } from "../../Services/Pointeuse/TasksApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { groupByProject } from "../../Utils/GroupeUtils";
import { reduceObject } from "../../Utils/ObjectOperation";
import { filterTasksByWord } from "../../Utils/SortObject";
import PieChartJsTaskStatus from "../Charts/CustumChartJs/PieChartJsTaskStatus";
import PieChartJsTaskType from "../Charts/CustumChartJs/PieChartJsTaskType";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import ImageComponent from "../Styles/ImageComponent";
import AddTask from "./AddTask";
import swal from "sweetalert";
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import CopieContent from "../Exports/CopieContent";

function ListTasks() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [users, setusers] = useState([]);
  const [projetsTask, setprojetsTask] = useState([]);
  const [size, setsize] = useState(14);
  const [isOpened, setIsOpened] = useState(false);
  const [tasks, settasks] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getTasksByCode()
      .then((res) => {
        console.log(res.data);
        setdata(res.data);
        setallInitialData(res.data);
        setprojetsTask(groupByProject(res.data));
        settasks(reduceObject(res.data));
        console.log(reduceObject(res.data));
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getUsersByCode().then((res) => {
      setusers(res.data);
    });
  }, []);

  /**
   * The function takes in a page of items and sets the currentData state to that page of items
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the search term.
   * @param e - The event object that contains the target element.
   */
  const handleSearch = (e) => {
    const dataSearch = filterTasksByWord(allInitialData, e.target.value);
    setdata(dataSearch);
  };
  /**
   * The function sets the size of the data to be retrieved from the API.
   * @param e - The event object that contains the data we want to use.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  const handlePriority = (value) => setdata(allInitialData.filter((el) => el.priority == value));
  const handleStatus = (value) => setdata(allInitialData.filter((el) => el.etat == value));
  const handleStart = (value) => setdata(allInitialData.filter((el) => el.date_debut.split(" ")[0] == value));
  const handleEnd = (value) => setdata(allInitialData.filter((el) => el.date_fin.split(" ")[0] == value));

  const handleDelete = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteTasById(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
        });
      }
    });
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Tâches</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="#" className="btn btn-primary mr-3" data-toggle="modal" data-target="#newtaskmodal">
                Ajouter tâche
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total Employees</span>
                    <h3 className="mb-0 mt-1 text-success">5,678</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="las la-users" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total Homme Employees</span>
                    <h3 className="mb-0 mt-1 text-primary">3,876</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-primary-transparent my-auto  float-right">
                    <i className="las la-male" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total Female Employees</span>
                    <h3 className="mb-0 mt-1 text-secondary">1,396</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-secondary-transparent my-auto  float-right">
                    <i className="las la-female" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total New Employees</span>
                    <h3 className="mb-0 mt-1 text-danger">398</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-danger-transparent my-auto  float-right">
                    <i className="las la-user-friends" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              {tasks && (
                <div className="row mb-0 pb-0">
                  <div className="col-md-6 col-lg-2 text-center py-5">
                    <span className="avatar avatar-md bradius fs-20 bg-primary-transparent">{tasks?.afaire}</span>
                    <h5 className="mb-0 mt-3">à faire</h5>
                  </div>
                  <div className="col-md-6 col-lg-2 text-center py-5 ">
                    <span className="avatar avatar-md bradius fs-20 bg-success-transparent">{tasks?.validee}</span>
                    <h5 className="mb-0 mt-3">validé</h5>
                  </div>
                  <div className="col-md-6 col-lg-2 text-center py-5">
                    <span className="avatar avatar-md bradius fs-20 bg-danger-transparent">{tasks?.tester}</span>
                    <h5 className="mb-0 mt-3">à tester</h5>
                  </div>
                  <div className="col-md-6 col-lg-2 text-center py-5">
                    <span className="avatar avatar-md bradius fs-20 bg-warning-transparent">{tasks?.encours}</span>
                    <h5 className="mb-0 mt-3">en cours</h5>
                  </div>
                  <div className="col-md-6 col-lg-2 text-center py-5 ">
                    <span className="avatar avatar-md bradius fs-20 bg-orange-transparent">{tasks?.terminee}</span>
                    <h5 className="mb-0 mt-3">terminé</h5>
                  </div>
                  <div className="col-md-6 col-lg-2 text-center py-5">
                    <span className="avatar avatar-md bradius fs-20 ">{tasks?.revoir}</span>
                    <h5 className="mb-0 mt-3">à revoir</h5>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-xl-5 col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Début:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input
                            className="form-control fc-datepicker"
                            placeholder="DD-MM-YYYY"
                            type="date"
                            onChange={(e) => handleStart(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Fin:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input
                            className="form-control fc-datepicker"
                            placeholder="DD-MM-YYYY"
                            type="date"
                            onChange={(e) => handleEnd(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Priorité:</label>
                        <select
                          name="attendance"
                          className="form-control custom-select select2"
                          data-placeholder="Choisir priorité"
                          onChange={(e) => handlePriority(e.target.value)}
                        >
                          <option label="Choisir priorité" />
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Choisir Statut:</label>
                        <select
                          name="attendance"
                          className="form-control custom-select select2"
                          data-placeholder="Choisir Statut"
                          onChange={(e) => handleStatus(e.target.value)}
                        >
                          <option label="Choisir Statut" />
                          <option value="afaire">à faire</option>
                          <option value="encours">en cours</option>
                          <option value="revoir">à revoir</option>
                          <option value="validee">validé</option>
                          <option value="terminee">terminé</option>
                          <option value="tester">à tester</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-md-12">
                  <div className="form-group mt-5">
                    <Link to="#" className="btn btn-primary btn-block" onClick={getData}>
                      Réinitialiser
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-9 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-header  border-0">
              <h4 className="card-title">List tâches</h4>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">Note:</label>
                </div>
                <div>
                  <span className="badge badge-primary-light mr-2">
                    <i className="feather feather-check-circle text-primary" /> à faire
                  </span>
                  <span className="badge badge-warning-light mr-2">
                    <i className="feather feather-x-circle text-warning" /> en cours
                  </span>
                  <span className="badge badge-default-light mr-2">
                    <i className="fa fa-star text-default" /> à revoir
                  </span>
                  <span className="badge badge-success-light mr-2">
                    <i className="fa fa-adjust text-success" /> validé
                  </span>
                  <span className="badge badge-secondary-light mr-2">
                    <i className="fa fa-star text-secondary" /> terminé
                  </span>
                  <span className="badge badge-danger-light mr-2">
                    <i className="fa fa-adjust text-danger" /> à tester
                  </span>
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            Afficher{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            entrées
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Recherche..."
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>
                          <ExportCsv data={data} name={"List_tâches"} />
                          <ExportPdf data={data} name={"List_tâches"} columns={["object", "description", "etat", "priority", "date_fin"]} />
                          <CopieContent />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table className="table  table-vcenter text-nowrap table-bordered border-bottom" id="task-list">
                          <thead>
                            <tr>
                              <th className="border-bottom-0 text-center">No</th>
                              <th className="border-bottom-0">Tâche</th>
                              <th className="border-bottom-0">Projet</th>
                              <th className="border-bottom-0">Assigné</th>
                              <th className="border-bottom-0">Priorité</th>
                              <th className="border-bottom-0">Type</th>
                              <th className="border-bottom-0">Date début</th>
                              <th className="border-bottom-0">Date fin</th>
                              <th className="border-bottom-0">Progression</th>
                              <th className="border-bottom-0">Statut</th>
                              <th className="border-bottom-0">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el, idx) => (
                              <tr key={idx}>
                                <td className="text-center"># {el.id}</td>
                                <td>
                                  <Link to={"/admin/task/" + el.id} className="d-flex sidebarmodal-collpase">
                                    <span className="dot-label1 mr-2 mt-1" style={{ backgroundColor: `${el.color}` }} />
                                    <span data-toggle="tooltip" data-placement="top" title={el.description}>
                                      {el.object}
                                    </span>
                                  </Link>
                                </td>
                                <td>
                                  <Link to={"/admin/projects/" + el.projet_id} className="d-flex sidebarmodal-collpase">
                                    {el.projet}
                                  </Link>
                                </td>
                                <td>
                                  <a href="task-profile.html" className="d-flex">
                                    <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={el.img}></ImageComponent>
                                    <div className="mr-3 mt-0 mt-sm-2 d-block">
                                      <h6 className="mb-1 fs-14">
                                        <Link to={"/admin/users/" + el.user_code_id}>
                                          {el.nom} {el.prenom}
                                        </Link>
                                      </h6>
                                    </div>
                                  </a>
                                </td>
                                <td>
                                  {el.priority == 0 && <span className="badge badge-success-light">{el.priority}</span>}
                                  {el.priority == 1 && <span className="badge badge-warning-light">{el.priority}</span>}
                                  {el.priority == 2 && <span className="badge badge-orange-light">{el.priority}</span>}
                                  {el.priority == 3 && <span className="badge badge-danger-light">{el.priority}</span>}
                                </td>
                                <td>
                                  {el.type == "task" && <span className="badge badge-primary-light">tâche</span>}
                                  {el.type == "bug" && <span className="badge badge-danger-light">{el.type}</span>}
                                </td>
                                <td>{el.date_debut && el.date_debut.split(" ")[0]}</td>
                                <td>{el.date_fin && el.date_fin.split(" ")[0]}</td>
                                <td>
                                  <div className="progress progress-sm">
                                    <div className="progress-bar bg-primary w-100" />
                                  </div>
                                </td>
                                <td>
                                  <>
                                    {el.etat === "afaire" && (
                                      <div className="d-flex">
                                        <span className="badge badge-primary">{el.etat}</span>
                                        <div className="mt-1 ml-1">
                                          <span
                                            className="feather feather-info text-primary"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Terminé"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {el.etat === "encours" && (
                                      <div className="d-flex">
                                        <span className="badge badge-warning">{el.etat}</span>
                                        <div className="mt-1 ml-1">
                                          <span
                                            className="feather feather-info text-danger"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Terminé"
                                          />
                                        </div>
                                      </div>
                                    )}

                                    {el.etat === "revoir" && (
                                      <div className="d-flex">
                                        <span className="badge badge-default">{el.etat}</span>
                                        <div className="mt-1 ml-1">
                                          <span
                                            className="feather feather-info text-default"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Terminé"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {el.etat === "validee" && (
                                      <div className="d-flex">
                                        <span className="badge badge-success">{el.etat}</span>
                                        <div className="mt-1 ml-1">
                                          <span
                                            className="feather feather-info text-success"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Terminé"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {el.etat === "terminee" && (
                                      <div className="d-flex">
                                        <span className="badge badge-secondary">{el.etat}</span>
                                        <div className="mt-1 ml-1">
                                          <span
                                            className="feather feather-info text-secondary"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Terminé"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {el.etat === "tester" && (
                                      <div className="d-flex">
                                        <span className="badge badge-danger">{el.etat}</span>
                                        <div className="mt-1 ml-1">
                                          <span
                                            className="feather feather-info text-danger"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Terminé"
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </>
                                </td>

                                <td>
                                  <div className="d-flex">
                                    <Link
                                      to={"/admin/task/" + el.id}
                                      className="action-btns1"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="View Task"
                                    >
                                      <i className="feather feather-eye text-primary" />
                                    </Link>
                                    <Link to={"/admin/task/" + el.id} className="action-btns1">
                                      <i
                                        className="feather feather-edit-2  text-success"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit task"
                                      />
                                    </Link>
                                    <Link
                                      to="#"
                                      className="action-btns1"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete Task"
                                      onClick={() => handleDelete(el.id)}
                                    >
                                      <i className="feather feather-trash-2 text-danger" />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          Affichage de 1 à {size} sur {data.length} entrées
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-header  border-0">
              <h4 className="card-title">Aperçu Tâches</h4>
            </div>
            <div className="card-body">
              <div id="leavesoverview" className="mx-auto pt-2" />
              <div className="row pt-7 pb-5  mx-auto text-center">
                <div className="col-md-12 mx-auto d-block">
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <div className="d-flex font-weight-semibold">
                        <PieChartJsTaskType />
                      </div>
                    </div>

                    {/* <div className="col-md-12 mt-3">
                      <div className="d-flex font-weight-semibold">
                        <span className="dot-label badge-success mr-2 my-auto" />
                        Vacciné
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="d-flex font-weight-semibold">
                        <span className="dot-label bg-danger mr-2 my-auto" />
                        Non vacciné
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header  border-0">
              <h4 className="card-title">Aperçu Tâches</h4>
            </div>
            <div className="card-body">
              <div id="leavesoverview" className="mx-auto pt-2" />
              <div className="row pt-7 pb-5  mx-auto text-center">
                <div className="col-md-12 mx-auto d-block">
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <div className="d-flex font-weight-semibold">
                        <PieChartJsTaskStatus />
                      </div>
                    </div>

                    {/* <div className="col-md-12 mt-3">
                      <div className="d-flex font-weight-semibold">
                        <span className="dot-label badge-success mr-2 my-auto" />
                        Vacciné
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="d-flex font-weight-semibold">
                        <span className="dot-label bg-danger mr-2 my-auto" />
                        Non vacciné
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal */}
      <AddTask getData={getData} from={false}></AddTask>
      {/* modal */}
      {/* {isOpened && (
        <div className="task-fade sidebar-modal">
          <div className="sidebar-dialog task-modal">
            <div className="sidebar-content">
              <div className="sidebar-header">
                <div>
                  <h4>
                    <span className="font-weight-normal text-muted mr-2">Task 01</span> Design Updated{" "}
                  </h4>
                  <label className="mr-2">Priority:</label>
                  <span className="badge badge-danger-light">High</span>
                </div>
                <div className="ml-auto mt-3">
                  <a href="#" className="btn btn-success mr-2">
                    Mark as Complete
                  </a>
                  <a href="#" className="action-btns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="feather feather-more-horizontal" />
                  </a>
                  <ul className="dropdown-menu dropdown-menu-right" role="menu">
                    <li>
                      <a href="#">
                        <i className="feather feather-eye mr-2" />
                        View
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="feather feather-plus-circle mr-2" />
                        Add
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="feather feather-send mr-2" />
                        Send
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="feather feather-edit-2 mr-2" />
                        Edit
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="feather feather-trash-2 mr-2" />
                        Remove
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="feather feather-settings mr-2" />
                        More
                      </a>
                    </li>
                  </ul>
                  <a href="#" className="action-btns dismiss">
                    <i className="feather feather-x" />
                  </a>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-md-8 border-right">
                  <div className="card-body">
                    <h5 className="mb-4  font-weight-semibold">Description</h5>
                    <div className="main-profile-bio mb-0">
                      <span className="fs-14 mt-2 text-muted">
                        Mobile App Ui Designing and Prototyping It is a long established fact that a reader will be
                        distracted by the readable content of a page when looking
                      </span>
                      <p>
                        simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy when an unknown printer took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries nchanged.
                      </p>
                      <h5 className="mb-4 mt-5 font-weight-semibold">Attachments</h5>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="p-3 br-7 border text-center">
                            <a href="#" className="text-center">
                              <img
                                src="assets/images/files/attachments/2.png"
                                alt="img"
                                className="avatar bg-transparent avatar-lg mb-2"
                              />
                              <div>
                                <span className="font-weight-semibold fs-12">
                                  doc.pdf<span className="text-muted ml-2">(23.8 KB)</span>
                                </span>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="p-3 br-7 border text-center mt-4 mt-lg-0">
                            <a href="#" className="text-center">
                              <img
                                src="assets/images/files/attachments/4.png"
                                alt="img"
                                className="avatar bg-transparent avatar-lg mb-2"
                              />
                              <div>
                                <span className="font-weight-semibold fs-12">
                                  Project<span className="text-muted ml-2">(578.6MB)</span>
                                </span>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="p-5 br-7 border text-center mt-4 mt-lg-0">
                            <a href="#" className="text-center fs-35 text-success">
                              <i className="fe feather-plus-circle" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <h5 className="mb-4 mt-5 font-weight-semibold">Add Comment</h5>
                      <div className="form-group">
                        <label className="form-label">Title</label>
                        <input className="form-control" placeholder="text" />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Description</label>
                        <div className="summernote1" />
                      </div>
                      <a href="#" className="btn btn-primary">
                        Submit
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card-body">
                    <div className="form-group mb-5">
                      <label>Assigned To:</label>
                      <div className="d-flex">
                        <span
                          className="avatar avatar-md brround mr-3"
                          style={{ backgroundImage: "url(assets/images/users/1.jpg)" }}
                        />
                        <div className="mr-3 mt-0 mt-sm-1 d-block">
                          <h6 className="mb-1 fs-14">Faith Harris</h6>
                          <p className="text-muted mb-0 fs-12">Web Designer</p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-5">
                      <label className="mb-1">Département:</label>
                      <p className="form-label">Designing</p>
                    </div>
                    <div className="form-group mb-5">
                      <label className="mb-1">Start Date:</label>
                      <p className="form-label">12-02-2021</p>
                    </div>
                    <div className="form-group mb-5">
                      <label className="mb-1">Deadline Date:</label>
                      <p className="form-label">16-06-2021</p>
                    </div>
                    <div className="form-group mb-5">
                      <label className="mb-1">Work Status:</label>
                      <div>
                        <span className="badge badge-warning">On hold</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-end justify-content-between mt-5">
                      <h6>Project Status</h6>
                      <h6 className="font-weight-bold mb-1">30%</h6>
                    </div>
                    <div className="progress progress-sm">
                      <div className="progress-bar bg-success w-30" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}

export default ListTasks;
