import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { filterCaisse, filterFactureByWord } from "../../Utils/SortObject";
import swal from "sweetalert";
import { deleteCaisseById, getCaisseByCode } from "../../Services/Pointeuse/CaisseApi";
import { Link } from "react-router-dom";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import ExportCsv from "../Exports/ExportCsv";
import CopieContent from "../Exports/CopieContent";
import Pagination from "../Others/Pagination";
import { sumObjectCurrentMonth, sumObjectif, sumObjectValue } from "../../Utils/ObjectifUtils";
import { deleteBanqueById, getBanqueByCode } from "../../Services/Pointeuse/BanqueApi";

function ListBanque() {
  let history = useHistory();
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const [actionId, setActionId] = useState(false);
  const [factureCode, setfactureCode] = useState(null);
  const myRefDelete = useRef(null);
  const [size, setsize] = useState(40);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [allEncaiss, setallEncaiss] = useState(null);
  const [resultEnc, setresultEnc] = useState(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getBanqueByCode()
      .then((res) => {
        setallInitialData(res.data);
        setdata(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    const dataSearch = filterCaisse(allInitialData, e.target.value);
    setdata(dataSearch);
  };

  const filterByDate = (value) => {
    const filterd = allInitialData.filter((el) => {
      if (el.date) {
        return el.date == value;
      }
    });

    if (filterd.length === 0) {
      setdata(allInitialData);
    } else {
      setdata(filterd);
    }
  };
  //TODO
  const handleSelectStaus = (e) => {
    const list = [...allInitialData];
    switch (e.target.value) {
      case "1":
        const filterDebit = list.filter((el) => el.operation == "debit");
        setdata(filterDebit);
        break;
      case "2":
        const filterCredit = list.filter((el) => el.operation == "credit");
        setdata(filterCredit);
        break;
      default:
        getData();
    }
  };
  const handleDeleteCaisse = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteBanqueById(id).then((res) => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          //const newData = allInitialData;
          var removeIndex = allInitialData.filter((el) => {
            return el.id != id;
          });
          setdata(removeIndex);
          setallInitialData(removeIndex);
        });
      }
    });
  };

  const handleSelectDate = (e) => {
    const expr = e.target.value;
    if (expr == 0) {
      getData();
    } else {
      const filterByYear = allInitialData.filter((el) => {
        return el.date.split("-")[0] == expr;
      });
      setdata(filterByYear);
    }
  };

  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Suivi des opérations bancaires</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/ajoutbanque" className="btn btn-primary mr-3">
                Ajouter opération
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>

      <div className="flexrow">
        <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total Débits</span>
                    {/* <h3 className="mb-0 mt-1 text-success">{data && sumObjectValue(data, "operation", "debit", "montant_entrant")}</h3> */}
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total crédits</span>
                    {/* <h3 className="mb-0 mt-1 text-primary">{data && sumObjectValue(data, "operation", "credit", "montant_sortant")}</h3> */}
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-primary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Solde d'année</span>
                    {/* <h3 className="mb-0 mt-1 text-secondary">{data && sumObjectCurrentMonth(data)}</h3> */}
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-secondary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-8 col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Date:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date" onChange={(e) => filterByDate(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Choisir année:</label>
                        <select className="form-control custom-select select2" onChange={handleSelectDate}>
                          <option value={0}>Tous</option>
                          <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
                          <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                          <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Choisir Statut:</label>

                        <select className="form-control custom-select select2" onChange={handleSelectStaus}>
                          <option value={0}>Tous</option>
                          <option value={1}>Entrée</option>
                          <option value={2}>Sortie</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">List Opérations bancaires</h4>

              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">Note:</label>
                </div>
                <div>
                  <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> Débit
                  </span>
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> Crédit
                  </span>
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            Afficher{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            entrées
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Recherche..."
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv
                            data={data}
                            name={"List_caisses"}
                            columns={[
                              "facture_code",
                              "client_nom",
                              "montant_total_ht",
                              "montant_total_tva",
                              "montant_total_ttc",
                              "montant_encaissement",
                              "net_a_payer",
                            ]}
                          />
                          <CopieContent />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0">Date opération</th>
                              <th className="border-bottom-0">Abr</th>
                              <th className="border-bottom-0">Réference</th>
                              <th className="border-bottom-0">Libelle</th>
                              <th className="border-bottom-0">Date valeur</th>
                              <th className="border-bottom-0">Beneficiaire</th>
                              <th className="border-bottom-0">Centre cout</th>
                              <th className="border-bottom-0">Opération</th>
                              <th className="border-bottom-0">Débit</th>
                              <th className="border-bottom-0">Crédit</th>

                              <th className="border-bottom-0" style={{ width: 60 }}>
                                Actions
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentData.map((el) => (
                              <tr key={el.id}>
                                <td>{el.date_operation}</td>
                                <td>
                                  <Link to={"/admin/clientactivity/" + el.beneficiaire}>{el.beneficiaire}</Link>
                                </td>

                                <td>{el.abr}</td>
                                <td>{el.reference}</td>
                                <td>{el.libelle}</td>
                                <td>{el.date_valeur}</td>
                                <td>{el.centre_cout}</td>
                                <td>{el.operation}</td>

                                <td>
                                  <span className="badge badge-success-light">{el.debit}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span className="badge badge-danger-light">{el.credit}</span>
                                </td>

                                <td>
                                  {true && (
                                    <div className="btn-group">
                                      <button
                                        style={{ textAlign: "center", padding: 2, width: 40 }}
                                        type="button"
                                        className="btn btn-secondary dropdown-toggle dropdown-icon"
                                        data-toggle="dropdown"
                                      ></button>
                                      <div className="dropdown-menu" role="menu">
                                        <Link to={"/admin/banque/" + el.id} className="dropdown-item">
                                          Editer
                                        </Link>
                                        <div className="dropdown-divider" />
                                        <Link to="#" onClick={() => handleDeleteCaisse(el.id)} className="dropdown-item">
                                          Supprimer
                                        </Link>
                                      </div>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th className="border-bottom-0">Date opération</th>
                              <th className="border-bottom-0">Abr</th>
                              <th className="border-bottom-0">Réference</th>
                              <th className="border-bottom-0">Libelle</th>
                              <th className="border-bottom-0">Date valeur</th>
                              <th className="border-bottom-0">Beneficiaire</th>
                              <th className="border-bottom-0">Cente cout</th>
                              <th className="border-bottom-0">Opération</th>
                              <th className="border-bottom-0">Débit</th>
                              <th className="border-bottom-0">Crédit</th>
                              <th className="border-bottom-0" style={{ width: 60 }}>
                                Actions
                              </th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          Affichage de 1 à {size} sur {data.length} entrées
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ListBanque;
