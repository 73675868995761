import moment from "moment";

/**
 * It filters the array of objects by the month of the createdAt property.
 * @param arrayObject - The array of objects that you want to filter.
 * @param date - The month you want to filter by.
 * @returns An array of objects.
 */
export function filterRetardByMonth(arrayObject, date) {
  let result = arrayObject.filter((el) => {
    return el.createdAt.split("-").slice(0, 2).join("-") === date;
  });
  return result;
}

/**
 * Convert a string of the format "hh:mm:ss" to a number of seconds
 * @param hms - The time string to convert.
 * @returns the number of seconds in the time string.
 */
export function convertTimeToSecond(hms) {
  var a = hms.split(":"); // split it at the colons
  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  return seconds;
}

/**
 * Convert seconds to a time string
 * @param s - The number of seconds to convert.
 * @returns a string of the time in the format of hh:mm:ss.
 */
export function convertSecondToTime(s) {
  return new Date(s * 1000).toISOString().substr(11, 8);
}

export function convertSecondToTimeExtratHoure(s) {
  let totalSeconds = s;
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = totalSeconds % 60;

  console.log("hours: " + hours);
  console.log("minutes: " + minutes);
  console.log("seconds: " + seconds);

  // If you want strings with leading zeroes:
  minutes = String(minutes).padStart(2, "0");
  hours = String(hours).padStart(2, "0");
  seconds = String(seconds).padStart(2, "0");
  console.log(hours + ":" + minutes + ":" + seconds);
  return hours + ":" + minutes + ":" + seconds;
}
/**
 * Given an array of objects, return the sum of the time of all the objects that have the same date
 * @param arrayObject - The array of objects that you want to filter.
 * @param date - The month you want to get the sum of the time.
 * @returns The sum of the time in seconds for the month of the date passed in.
 */
export function sumRetardTimeByMonth(arrayObject, date) {
  let result = arrayObject.filter((el) => {
    return el.createdAt.split("-").slice(0, 2).join("-") === date;
  });
  let sum = result
    .map((o) => convertTimeToSecond(o.time))
    .reduce((a, c) => {
      return a + c;
    }, 0);
  return convertSecondToTime(sum);
}

/**
 * Given a date, return the number of days in the month
 * @param d - The date to get the number of days in the month for.
 * @returns The number of days in the month.
 */
export function getDaysInMonth(d) {
  var month = d.split("-")[1];
  var year = d.split("-")[0];
  month--; // lets fix the month once, here and be done with it
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    // Exclude weekends
    var tmpDate = new Date(date);
    var weekDay = tmpDate.getDay(); // week day
    var day = tmpDate.getDate(); // day
    if (weekDay % 6) {
      // exclude 0=Sunday and 6=Saturday
      days.push(day);
    }
    date.setDate(date.getDate() + 1);
  }
  return days.length;
}

/**
 * Given an array of objects, return a filtered array of objects where the date of the pointage is
 * equal to the date passed in
 * @param arrayObject - the array of objects that you want to filter
 * @param date - The month you want to filter by.
 * @returns An array of objects.
 */
export function filterPointageByMonth(arrayObject, date) {
  let result = arrayObject.filter((el) => {
    return el.pointage.split(",")[0].split("-").slice(0, 2).join("-") === date;
  });
  return result;
}

/**
 * Given an array of objects, return the number of days in the month minus the number of objects in the
 * array
 * @param arrayObject - an array of objects that contain the date and the status of the day.
 * @param date - The date of the month you want to get the absent students.
 * @returns The number of absent days in the month.
 */
export function getAbsentByMonth(arrayObject, date) {
  var dayPerWeek = getDaysInMonth(date);
  return parseInt(dayPerWeek) - parseInt(arrayObject.length);
}

/**
 * Calculate the number of days the employee should have worked
 * @param arrayObject - an array of objects containing the date and the number of days of absence.
 * @param date - The date you want to calculate the number of working days for.
 * @param conge - number of days of leave taken
 * @param cp - The number of days of the current month
 * @param deplacement - number of days of absence
 * @returns The number of days that the employee should be present.
 */
export function getCalculateAbsente(arrayObject, date, conge, cp, deplacement) {
  var dayWorking = calcBusinessDays(date);

  if (calcBusinessDays(date) == 0) {
    dayWorking = 1;
  }
  var cumullAbsence = parseInt(conge) - parseInt(deplacement);
  return parseInt(dayWorking) - parseInt(arrayObject.length) - cumullAbsence;
}
/**
 * Given an array of objects, return the number of objects that have a pointage that ends with a comma
 * @param arrayObject - The array of objects that you want to filter.
 * @returns The number of pointages that are not defined.
 */

export function getLastPointage(arrayObject) {
  const result = arrayObject.filter((el) => {
    return el.pointage.split(",")[1] === undefined;
  });
  return result.length;
}
/**
 * Parse the number of milliseconds into a human readable time
 * @param milliseconds - The number of milliseconds to convert to hours, minutes, and seconds.
 * @returns a string that is the time in hours, minutes, and seconds.
 */

export function parseMillisecondsIntoReadableTime(milliseconds) {
  //Get hours from milliseconds
  var hours = milliseconds / (1000 * 60 * 60);
  var absoluteHours = Math.floor(hours);
  var h = absoluteHours > 9 ? absoluteHours : "0" + absoluteHours;

  //Get remainder from hours and convert to minutes
  var minutes = (hours - absoluteHours) * 60;
  var absoluteMinutes = Math.floor(minutes);
  var m = absoluteMinutes > 9 ? absoluteMinutes : "0" + absoluteMinutes;

  //Get remainder from minutes and convert to seconds
  var seconds = (minutes - absoluteMinutes) * 60;
  var absoluteSeconds = Math.floor(seconds);
  var s = absoluteSeconds > 9 ? absoluteSeconds : "0" + absoluteSeconds;
  return h + ":" + m + ":" + s;
}

/**
 * Given an array of objects, return the total time worked in the array
 * @param arrayObject - The array of objects that you want to get the total hours from.
 * @returns The total time worked by the employee.
 */
export function getTotalHoure(arrayObject) {
  const result = arrayObject.filter((el) => {
    return el.pointage.split(",")[1] != undefined;
  });
  var sum = 0;
  result.forEach((element) => {
    var d1 = new Date(element.pointage.split(",")[0]); //"now"
    var d2 = new Date(element.pointage.split(",")[1]);
    sum += Math.abs(d1 - d2);
    //console.log(Math.abs(d1 - d2));
  });
  return parseMillisecondsIntoReadableTime(sum);
}

/**
 * It calculates the total time without break.
 * @param arrayObject - an array of objects that contains the date and the time of the pointage
 * @param pause - the time in seconds that the employee took a break
 * @returns The total time without break
 */
export function getTotalHoureWithoutBreak(arrayObject, pause) {
  const result = arrayObject.filter((el) => {
    return el.pointage.split(",")[1] != undefined;
  });
  var sum = 0;
  var secondPause = convertTimeToSecond(pause);
  result.forEach((element) => {
    var soustractTimeEnd = element.pointage.split(",").pop();
    var time = soustractTimeEnd.split(" ")[1];
    var date = soustractTimeEnd.split(" ")[0];
    var timeSecond = convertTimeToSecond(time);
    var withoutPause = timeSecond - secondPause;

    var newDate = convertSecondToTime(withoutPause);
    var concatDate = date + " " + newDate;

    var d1 = new Date(element.pointage.split(",")[0]); //"now"
    var d2 = new Date(concatDate);
    sum += Math.abs(d1 - d2);
    //console.log(Math.abs(d1 - d2));
  });

  return parseMillisecondsIntoReadableTime(sum);
}

/**
 * It takes a date, a number of hours, and a cumulative time, and returns the difference between the cumulative time and the number of hours multiplied
 * by the number of business days in the date.
 * @param nbHoure - number of hours per day
 * @param date - "2019-01-01"
 * @param cumule - "00:00:00"
 * @returns The difference between the two times.
 */
export function calculateSuppTime(nbHoure, date, cumule) {
  //nb heure
  console.log(nbHoure);
  //pointage
  console.log(cumule);
  const businessDay = calcBusinessDays(date);
  //business day
  console.log(businessDay);
  const plainTime = businessDay * nbHoure;

  //time to work 160 houres
  console.log(plainTime);
  //soustract plainTime - cumule
  const cummulSecond = convertTimeToSecond(cumule);
  console.log("🚀 ~ houwa gedech 5dem", cummulSecond);

  const plainTimeSecond = plainTime * 3600;
  console.log("🚀 ~ gedech lazem ye5dem", plainTimeSecond);
  const res = Math.abs(plainTimeSecond - cummulSecond);
  console.log("🚀 ~ res", res);

  if (cummulSecond > plainTimeSecond) {
    return convertSecondToTimeExtratHoure(res);
  } else {
    return "-" + convertSecondToTimeExtratHoure(res);
  }
}

export function calculateSuppTimePositive(nbHoure, date, cumule) {
  //nb heure
  console.log(nbHoure);
  //pointage
  console.log(cumule);
  const businessDay = calcBusinessDays(date);
  //business day
  console.log(businessDay);
  const plainTime = businessDay * nbHoure;

  //time to work 160 houres
  console.log(plainTime);
  //soustract plainTime - cumule
  const cummulSecond = convertTimeToSecond(cumule);
  console.log("🚀 ~ houwa gedech 5dem", cummulSecond);

  const plainTimeSecond = plainTime * 3600;
  console.log("🚀 ~ gedech lazem ye5dem", plainTimeSecond);
  const res = Math.abs(plainTimeSecond - cummulSecond);
  console.log("🚀 ~ res", res);

  if (cummulSecond > plainTimeSecond) {
    return convertSecondToTimeExtratHoure(res);
  } else {
    return "00:00:00";
  }
}

/**
 * Given an array of objects, return the average time between each object's start and end pointage
 * @param arrayObject - The array of objects that contain the pointage data.
 * @param breakTime - the break time in minutes
 * @returns The average time of the day.
 */
export function getAverageHoure(arrayObject, breakTime) {
  const result = arrayObject.filter((el) => {
    return el.pointage.split(",")[1] != undefined;
  });
  var list = [];
  result.forEach((element) => {
    var d1 = new Date(element.pointage.split(",")[0]); //"now"
    var d2 = new Date(element.pointage.split(",")[1]);
    list.push(Math.abs(d1 - d2));
  });

  const sum = list.reduce((a, b) => a + b, 0);
  const avg = sum / list.length || 0;
  var breakTimeSecond = convertTimeToSecond(breakTime);
  var breakTimeMillisecond = breakTimeSecond * 1000;
  var finalTime = avg - breakTimeMillisecond;
  return parseMillisecondsIntoReadableTime(finalTime);
}

/**
 * Given an array of objects, return a filtered array of objects where the date property matches the
 * given date
 * @param arrayObject - the array of objects that you want to filter
 * @param date - The month you want to filter by.
 * @returns An array of objects.
 */
export function filterCongesByMonth(arrayObject, date) {
  let result = arrayObject.filter((el) => {
    return el.date.split("-").slice(0, 2).join("-") === date;
  });
  return result;
}
/**
 * Given an array of objects, filter the array by a given date
 * @param arrayObject - the array of objects that you want to filter
 * @param date - The month you want to filter by.
 * @returns An array of objects.
 */
export function filterAutorisationByMonth(arrayObject, date) {
  let result = arrayObject.filter((el) => {
    return el.date.split("-").slice(0, 2).join("-") === date;
  });
  return result;
}

/**
 * Given an array of objects, return a filtered array of objects where the createdAt property of each
 * object matches the given date
 * @param arrayObject - the array of objects that you want to filter
 * @param date - The month you want to filter by.
 * @returns An array of objects.
 */
export function filterDeplacementByMonth(arrayObject, date) {
  let result = arrayObject.filter((el) => {
    return el.createdAt.split("-").slice(0, 2).join("-") === date;
  });
  return result;
}

/**
 * Filter the array of objects by the month of the createdAt property
 * @param arrayObject - the array of objects that you want to filter
 * @param date - The date to filter by.
 * @returns An array of objects.
 */
export function filterQuestionnaireByMonth(arrayObject, date) {
  let result = arrayObject.filter((el) => {
    return el.createdAt.split("-").slice(0, 2).join("-") === date;
  });
  return result;
}

/**
 * Calculate the number of business days between two dates
 * @param d1 - The start date.
 * @returns The number of business days between the start and end dates.
 */
export function calcBusinessDays(d1) {
  // This makes no effort to account for holidays
  // Counts end day, does not count start day
  // make copies we can normalize without changing passed in objects
  var start = new Date(d1);
  var month = d1.split("-")[1];
  var year = d1.split("-")[0];
  var newdateParams = year + "-" + Number(month);
  var d = new Date(year, month, 0);
  //nombre des jours de mois en params
  var n = d.getDate();
  //format 2020-01
  var dateObj = new Date();
  var currentDayMonth = dateObj.getUTCMonth() + 1;
  var year = dateObj.getUTCFullYear();
  var newdate = year + "-" + currentDayMonth;
  //
  var end = null;
  //error
  if (newdateParams === newdate) {
    //date aujourdhui
    end = new Date();
  } else {
    //date dernier jour du mois en params
    end = new Date(year + "-" + month + "-" + n);
  }
  // normalize both start and end to beginning of the day
  start.setHours(0, 0, 0, 0);
  end.setHours(0, 0, 0, 0);
  var current = new Date();
  current.setDate(current.getDate() + 1);
  var day;
  // initial total
  var totalBusinessDays = 0;
  // loop through each day, checking
  while (start <= end) {
    day = start.getDay();
    if (day >= 1 && day <= 5) {
      ++totalBusinessDays;
    }
    start.setDate(start.getDate() + 1);
  }
  return totalBusinessDays;
}

/**
 * Convert seconds to time format
 * @param s - The number of seconds to convert.
 * @returns a string.
 */
export function convertSecondToTime2(s) {
  var totalNumberOfSeconds = s;
  var hours = parseInt(totalNumberOfSeconds / 3600);
  var minutes = parseInt((totalNumberOfSeconds - hours * 3600) / 60);
  var seconds = Math.floor(totalNumberOfSeconds - (hours * 3600 + minutes * 60));
  var result = (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
  return result;
}

/**
 * Calculate the absent houre of a employee
 * @param normalDay - The number of working days in a week.
 * @param cumulHoureNow - The cumulative hours of the current day.
 * @param workhour - the working hour of the day
 * @returns the absent houre.
 */
export function calculateAbsentHoure(normalDay, cumulHoureNow, workhour) {
  var time = normalDay * cumulHoureNow + ":00:00";
  var secondtime = convertTimeToSecond(time);
  var secondworkinghoure = convertTimeToSecond(workhour);
  var diff = secondtime - secondworkinghoure;
  return convertSecondToTime2(diff);
}
