import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUserById } from "../../Services/Pointeuse/UsersApi";
import { t } from "i18next";

function Sidebar() {
  const [data, setdata] = useState({});
  useEffect(() => {
    // Toggle Sidebar
    $(document).on("click", '[data-toggle="sidebar"]', function (event) {
      event.preventDefault();
      $(".app").toggleClass("sidenav-toggled");
    });

    $(".app-sidebar").hover(
      function () {
        if ($(".app").hasClass("sidenav-toggled")) {
          $(".app").addClass("sidenav-toggled1");
        }
      },
      function () {
        if ($(".app").hasClass("sidenav-toggled")) {
          $(".app").removeClass("sidenav-toggled1");
        }
      }
    );

    // Activate sidebar slide toggle
    $("[data-toggle='slide']").on("click", function (e) {
      var $this = $(this);
      var checkElement = $this.next();
      var animationSpeed = 300,
        slideMenuSelector = ".slide-menu";
      if (checkElement.is(slideMenuSelector) && checkElement.is(":visible")) {
        checkElement.slideUp(animationSpeed, function () {
          checkElement.removeClass("open");
        });
        checkElement.parent("li").removeClass("is-expanded");
      } else if (checkElement.is(slideMenuSelector) && !checkElement.is(":visible")) {
        var parent = $this.parents("ul").first();
        var ul = parent.find("ul:visible").slideUp(animationSpeed);
        ul.removeClass("open");
        var parent_li = $this.parent("li");
        checkElement.slideDown(animationSpeed, function () {
          checkElement.addClass("open");
          parent.find("li.is-expanded").removeClass("is-expanded");
          parent_li.addClass("is-expanded");
        });
      }
      if (checkElement.is(slideMenuSelector)) {
        e.preventDefault();
      }
    });

    // Activate sidebar slide toggle
    $("[data-toggle='sub-slide']").on("click", function (e) {
      var $this = $(this);
      var checkElement = $this.next();
      var animationSpeed = 300,
        slideMenuSelector = ".sub-slide-menu";
      if (checkElement.is(slideMenuSelector) && checkElement.is(":visible")) {
        checkElement.slideUp(animationSpeed, function () {
          checkElement.removeClass("open");
        });
        checkElement.parent("li").removeClass("is-expanded");
      } else if (checkElement.is(slideMenuSelector) && !checkElement.is(":visible")) {
        var parent = $this.parents("ul").first();
        var ul = parent.find("ul:visible").slideUp(animationSpeed);
        ul.removeClass("open");
        var parent_li = $this.parent("li");
        checkElement.slideDown(animationSpeed, function () {
          checkElement.addClass("open");
          parent.find("li.is-expanded").removeClass("is-expanded");
          parent_li.addClass("is-expanded");
        });
      }
      if (checkElement.is(slideMenuSelector)) {
        e.preventDefault();
      }
    });

    // Activate sidebar slide toggle
    $("[data-toggle='sub-slide2']").on("click", function (e) {
      var $this = $(this);
      var checkElement = $this.next();
      var animationSpeed = 300,
        slideMenuSelector = ".sub-slide-menu2";
      if (checkElement.is(slideMenuSelector) && checkElement.is(":visible")) {
        checkElement.slideUp(animationSpeed, function () {
          checkElement.removeClass("open");
        });
        checkElement.parent("li").removeClass("is-expanded");
      } else if (checkElement.is(slideMenuSelector) && !checkElement.is(":visible")) {
        var parent = $this.parents("ul").first();
        var ul = parent.find("ul:visible").slideUp(animationSpeed);
        ul.removeClass("open");
        var parent_li = $this.parent("li");
        checkElement.slideDown(animationSpeed, function () {
          checkElement.addClass("open");
          parent.find("li.is-expanded").removeClass("is-expanded");
          parent_li.addClass("is-expanded");
        });
      }
      if (checkElement.is(slideMenuSelector)) {
        e.preventDefault();
      }
    });

    //Activate bootstrip tooltips

    // ______________Active Class
    $(document).ready(function () {
      $(".app-sidebar li a").each(function () {
        var pageUrl = window.location.href.split(/[?#]/)[0];
        if (this.href == pageUrl) {
          $(this).addClass("active");
          $(this).parent().addClass("is-expanded");
          $(this).parent().parent().prev().addClass("active");
          $(this).parent().parent().addClass("open");
          $(this).parent().parent().prev().addClass("is-expanded");
          $(this).parent().parent().parent().addClass("is-expanded");
          $(this).parent().parent().parent().parent().addClass("open");
          $(this).parent().parent().parent().parent().prev().addClass("active");
          $(this).parent().parent().parent().parent().parent().addClass("is-expanded");
          $(this).parent().parent().parent().parent().parent().parent().prev().addClass("active");
          $(this).parent().parent().parent().parent().parent().parent().parent().addClass("is-expanded");
        }
      });
    });
  }, []);
  useEffect(() => {
    // document.body.classList.add("sidebar-collapse");
    getUserById(localStorage.getItem("id")).then((res) => {
      setdata(res.data);
    });
  }, []);
  return (
    <aside className="app-sidebar">
      <div className="app-sidebar__logo">
        <Link to="/" className="header-brand">
          {/* <img src="assets/images/brand/logo2.png" className="header-brand-img desktop-lgo" alt="Cleges" /> */}
          {/* <p className="header-brand-img dark-logo" style={{ color: "white", fontSize: 20 }}>
            Pointeuse
          </p> */}
          <img src="assets/images/brand/logo2.png" className="header-brand-img dark-logo" style={{ width: "125px" }} alt="Cleges" />
          <img src="assets/images/brand/logo1.png" className="header-brand-img mobile-logo" alt="Cleges" />
          {/* <img src="assets/images/brand/logo2.png" className="header-brand-img darkmobile-logo" alt="Cleges" /> */}
        </Link>
      </div>
      <div className="app-sidebar3">
        <div className="app-sidebar__user">
          <div className="dropdown user-pro-body text-center">
            <div className="user-pic">
              <img
                src={data.img == null || data.img == "" ? "assets/images/users/avatar.png" : localStorage.getItem("baseUrl") + data.img}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "assets/images/users/avatar.png";
                }}
                alt="user-img"
                className="avatar-xxl rounded-circle mb-1"
              />
            </div>
            <div className="user-info">
              <h5 className=" mb-2">
                {data.nom} {data.prenom}
              </h5>
              <span className="text-muted app-sidebar__user-name text-sm">{data.fonction}</span>
            </div>
          </div>
        </div>
        <ul className="side-menu">
          {/* <li className="side-item side-item-category mt-4">{t("Dashboard")}</li> */}
          <li className="side-item side-item-category">{t("Gestion RH")}</li>

          {/* pointage */}
          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="fa fa-check sidemenu_icon" />
              <span className="side-menu__label">{t("Pointage")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/jourpointage" className="slide-item">
                  {t("Pointage Aujourd'hui")}
                </Link>
              </li>
              <li>
                <Link to="/admin/moispointage" className="slide-item">
                  {t("Pointage du mois")}
                </Link>
              </li>
              <li>
                <Link to="/admin/pointagemanquant" className="slide-item">
                  {t("Pointage manquant")}
                </Link>
              </li>
              <li>
                <Link to="/admin/retard" className="slide-item">
                  {t("Retard")}
                </Link>
              </li>
              <li>
                <Link to="/admin/questionnaire" className="slide-item">
                  {t("Questionnaires")}
                </Link>
              </li>
            </ul>
          </li>
          {/* conges */}
          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="fa fa-calendar sidemenu_icon" />
              <span className="side-menu__label">{t("Congés")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/holiday" className="slide-item">
                  {t("Jours fériés")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/calendarconge" className="slide-item">
                  {t("Calendrier")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/demandeconge" className="slide-item">
                  {t("Demandes Congés")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/congevalide" className="slide-item">
                  {t("Congés validés")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/congenonvalide" className="slide-item">
                  {t("Congés non validés")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/decharge" className="slide-item">
                  {t("Décharge congé")}
                </Link>
              </li>
            </ul>
          </li>

          {/* collaborateurs */}
          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="feather feather-users sidemenu_icon" />
              <span className="side-menu__label">{t("Collaborateur")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/users" className="slide-item">
                  {t("Collaborateurs")}
                </Link>
              </li>
              <li>
                <Link to="/admin/global" className="slide-item">
                  {t("Statistique Collaborateurs")}
                </Link>
              </li>

              <Link to="/admin/vaccin" className="slide-item">
                {t("Vaccin")}
              </Link>
              <Link to="/admin/departements" className="slide-item">
                {t("Départements")}
              </Link>
            </ul>
          </li>

          {/* demande */}
          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              {/* <i className="feather feather-home sidemenu_icon" /> */}
              <i className="fa fa-shield sidemenu_icon"></i>

              <span className="side-menu__label">{t("Demande")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/autorisation" className="slide-item">
                  {t("Autorisation de sortie")}
                </Link>
              </li>
              <li>
                <Link to="/admin/teletravail" className="slide-item">
                  {t("Télétravail")}
                </Link>
              </li>
              <li>
                <Link to="/admin/acomptesalaire" className="slide-item">
                  {t("Acomptes sur salaire")}
                </Link>
              </li>
              <li>
                <Link to="/admin/materiel" className="slide-item">
                  {t("Matériel")}
                </Link>
              </li>
              <li>
                <Link to="/admin/maintenance" className="slide-item">
                  {t("Maintenance")}
                </Link>
              </li>
            </ul>
          </li>

          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="fa fa-hospital-o sidemenu_icon" />
              <span className="side-menu__label">{t("Entreprise")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/configuration" className="slide-item">
                  {t("Configurations")}
                </Link>
              </li>
              <li>
                <Link to="/admin/fileconge" className="slide-item">
                  {t("Document")}
                </Link>
              </li>
              <li>
                <Link to="/admin/entreprise" className="slide-item">
                  {t("Société")}
                </Link>
              </li>
              <li>
                <Link to="/admin/baremes" className="slide-item">
                  {t("Paramètres de paie")}
                </Link>
              </li>

              <li>
                <Link to="/admin/salaire" className="slide-item">
                  {t("Paie")}
                </Link>
              </li>
              <li>
                <Link to="/admin/tax" className="slide-item">
                  {t("Taxe")}
                </Link>
              </li>
              <li>
                <Link to="/admin/prefix" className="slide-item">
                  {t("Préfix")}
                </Link>
              </li>
              <li>
                <Link to="/admin/cheque" className="slide-item">
                  {t("Chèques tiroirs")}
                </Link>
              </li>
              <li>
                <Link to="/admin/caisse" className="slide-item">
                  {t("Caisse")}
                </Link>
              </li>
              <li>
                <Link to="/admin/banque" className="slide-item">
                  {t("Banque")}
                </Link>
              </li>
            </ul>
          </li>

          {/* <li className="slide">
            <Link to="/admin/support" className="side-menu__item" data-toggle="slide">
              <i className="feather feather-headphones sidemenu_icon" />
              <span className="side-menu__label">{t("Support")}</span>
            </Link>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/support" className="slide-item">
                  {t("support")}
                </Link>
              </li>
            </ul>
          </li> */}

          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="fa fa-newspaper-o sidemenu_icon" />
              <span className="side-menu__label">{t("Note et Blog")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/blog" className="slide-item">
                  {t("Blog")}
                </Link>
              </li>
              <li>
                <Link to="/admin/annonce" className="slide-item">
                  {t("Annonces")}
                </Link>
              </li>
            </ul>
          </li>

          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="fa fa-line-chart sidemenu_icon" />
              <span className="side-menu__label">{t("Objectif")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/objectif" className="slide-item">
                  {t("Objectif")}
                </Link>
              </li>
            </ul>
          </li>

          <li className="side-item side-item-category">{t("Gestion projet")}</li>

          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="feather feather-clipboard sidemenu_icon" />
              <span className="side-menu__label">{t("Tâches et projets")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/task" className="slide-item">
                  {t("Tâches")}
                </Link>
              </li>
              <li>
                <Link to="/admin/kanban" className="slide-item">
                  {t("Kanban")}
                </Link>
              </li>
              <li>
                <Link to="/admin/gantt" className="slide-item">
                  {t("Tableau des tâches")}
                </Link>
              </li>
              <li>
                <Link to="/admin/projects" className="slide-item">
                  {t("Projets")}
                </Link>
              </li>
              <li>
                <Link to="/admin/todo" className="slide-item">
                  {t("TODO")}
                </Link>
              </li>
            </ul>
          </li>

          <li className="side-item side-item-category">{t("Gestion ticket")}</li>

          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="feather feather-clipboard sidemenu_icon" />
              <span className="side-menu__label">{t("Ticket")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/ticket" className="slide-item">
                  {t("Tickets")}
                </Link>
              </li>
            </ul>
          </li>

          <li className="side-item side-item-category">{t("Facturation")}</li>

          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="fa fa-cube sidemenu_icon" />
              <span className="side-menu__label">{t("Produits")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/produit" className="slide-item">
                  {t("Produits")}
                </Link>
              </li>
              <li>
                <Link to="/admin/productparams" className="slide-item">
                  {t("Paramétrage produit")}
                </Link>
              </li>
            </ul>
          </li>

          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="feather feather-users sidemenu_icon" />
              <span className="side-menu__label">{t("Client et fournisseur")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/client" className="slide-item">
                  {t("Clients")}
                </Link>
              </li>
              <li>
                <Link to="/admin/fournisseur" className="slide-item">
                  {t("Fournisseurs")}
                </Link>
              </li>
            </ul>
          </li>

          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="fa fa-usd sidemenu_icon" />
              <span className="side-menu__label">{t("Encaissement")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/encaissement" className="slide-item">
                  {t("Encaissements")}
                </Link>
              </li>
            </ul>
          </li>
          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="fa fa-usd sidemenu_icon" />
              <span className="side-menu__label">{t("Paiement")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/paiement" className="slide-item">
                  {t("Paiements")}
                </Link>
              </li>
              <li>
                <Link to="/admin/cheque-emis" className="slide-item">
                  {t("chèque émis")}
                </Link>
              </li>
            </ul>
          </li>

          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="fa fa-copy sidemenu_icon" />
              <span className="side-menu__label">{t("Document de vente")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/facturevente" className="slide-item">
                  {t("Facture")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/blvente" className="slide-item">
                  {t("Bon de livraison")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/devisvente" className="slide-item">
                  {t("Devis")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/commandevente" className="slide-item">
                  {t("Commande")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/listabonnement" className="slide-item">
                  {t("abonnement")}
                </Link>
              </li>
            </ul>
          </li>

          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="fa fa-copy sidemenu_icon" />
              <span className="side-menu__label">{t("Document d'achat")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/factureachat" className="slide-item">
                  {t("Facture")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/blachat" className="slide-item">
                  {t("Bon de livraison")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/devisachat" className="slide-item">
                  {t("Devis")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/commandeachat" className="slide-item">
                  {t("commande")}
                </Link>
              </li>
            </ul>
          </li>
        </ul>

        {/* khkjklj */}
        <div className="Annoucement_card">
          <div className="text-center">
            <div>
              <h5 className="title mt-0 mb-1 ml-2 font-weight-bold tx-12">{t("Note de service")}</h5>
              <div className="bg-layer py-4">
                <img src="assets/images/photos/announcement-1.png" className="py-3 text-center mx-auto" alt="img" />
              </div>
              <p className="subtext mt-0 mb-0 ml-2 fs-13 text-center my-2">{t("Faire une annonce pour votre collaborateurs")}</p>
            </div>
          </div>
          <Link to="/admin/annonce">
            <button className="btn btn-block btn-primary my-4 fs-12">{t("Ajouter annonce")}</button>
          </Link>
          <Link to="/admin/support">
            <button className="btn btn-block btn-outline fs-12">{t("Support")}</button>
          </Link>
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
