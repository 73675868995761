import React, { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { margePercentage, sumNumber } from "../../../Utils/NumberUtils";
import { getAllTva } from "../../../Services/Facturation/FacturationTvaApi";
import { getAllFamille } from "../../../Services/Facturation/FacturationFamille";
import { ajouterSousFamille } from "../../../Services/Facturation/FacturationSousFamilleApi";
import Select from "react-select";

function AddSousFamille({ getData }) {
  const refHideModal = useRef();
  const [color, setColor] = useState("");
  const [libelle, setLibelle] = useState("");
  const [order, setOrder] = useState("");
  const [marge1, setMarge1] = useState(0);
  const [marge2, setMarge2] = useState(0);
  const [marge3, setMarge3] = useState(0);
  const [marge4, setMarge4] = useState(0);
  const [marge5, setMarge5] = useState(0);
  const [actionMarge1, setActionMarge1] = useState("1");
  const [actionMarge2, setActionMarge2] = useState("1");
  const [actionMarge3, setActionMarge3] = useState("1");
  const [actionMarge4, setActionMarge4] = useState("1");
  const [actionMarge5, setActionMarge5] = useState("1");
  const [prixHt1, setprixHt1] = useState("");
  const [prixHt2, setprixHt2] = useState("");
  const [prixHt3, setprixHt3] = useState("");
  const [prixHt4, setprixHt4] = useState("");
  const [prixHt5, setprixHt5] = useState("");
  const [prixttc1, setprixttc1] = useState("");
  const [prixttc2, setprixttc2] = useState("");
  const [prixttc3, setprixttc3] = useState("");
  const [prixttc4, setprixttc4] = useState("");
  const [prixttc5, setprixttc5] = useState("");
  const [prixVentePublic, setPrixVentePublic] = useState("");
  const [prixAchatHt, setPrixAchatHt] = useState("");
  const [prixAchattc, setPrixAchattc] = useState("");
  const [data, setData] = useState([]);
  const [familleCode, setFamilleCode] = useState("");
  const [familleLibelle, setFamilleLibelle] = useState("");
  const [dataTva, setDataTva] = useState("");
  const [tauxTva, setTauxTva] = useState("");

  useEffect(() => {
    getAllFamille().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.code,
        label: d.libelle,
      }));
      setData(options);
    });
    getAllTva().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.taux,
        label: d.taux,
      }));
      setDataTva(options);
    });
  }, []);

  /**
   * It sets the familleCode and familleLibelle variables to the value and label of the selected option.
   * @param event - The event object that contains the value of the selected option.
   */
  const handleChange = (event) => {
    console.log(event.value);
    setFamilleCode(event.value);
    setFamilleLibelle(event.label);
  };

  /**
   * It sets the taux tva, the prix achat TTC, the prix TTC 1, the prix TTC 2, the prix TTC 3, the prix
   * TTC 4, the prix TTC 5, the prix HT 1, the prix HT 2, the prix HT 3, the prix HT 4, the prix HT 5,
   * the marge percentage, the marge HT 1, the marge HT 2, the marge HT 3, the marge HT 4, the marge HT
   * 5, the marge TTC 1, the marge TTC 2, the marge TTC 3, the marge TTC 4, the marge TTC 5, the marge HT
   * 1, the marge HT 2, the marge HT 3, the marge HT 4, the marge HT 5, the marge TTC 1, the marge TTC 2,
   * the marge TTC 3, the marge TTC 4, the marge TTC 5,
   * @param event - The event that triggered the callback.
   */
  const handleChangeTva = (event) => {
    setTauxTva(event.value);
    setPrixAchattc(margePercentage(prixAchatHt, event.value));
    setprixttc1(margePercentage(prixHt1, event.value));
    setprixttc2(margePercentage(prixHt2, event.value));
    setprixttc3(margePercentage(prixHt3, event.value));
    setprixttc4(margePercentage(prixHt4, event.value));
    setprixttc5(margePercentage(prixHt5, event.value));
  };

  /**
   * It sets the value of the marge1 variable to the value of the input field.
   * @param e - The event object that contains the value of the input.
   */
  const handleChangeMarge1 = (e) => {
    setMarge1(e.target.value);
    if (actionMarge1 === "1") {
      setprixHt1(margePercentage(prixAchatHt, e.target.value));
    } else {
      setprixHt1(sumNumber(prixAchatHt, marge1));
    }
  };
  /**
   * It sets the value of the marge2 variable and then calculates the prixHt2 variable.
   * @param e - The event object that contains the value of the input.
   */
  const handleChangeMarge2 = (e) => {
    setMarge2(e.target.value);
    if (actionMarge2 === "1") {
      setprixHt2(margePercentage(prixAchatHt, e.target.value));
    } else {
      setprixHt2(sumNumber(prixAchatHt, marge2));
    }
  };
  /**
   * It sets the value of the marge3 variable to the value of the input field.
   * @param e - The event object that contains the value of the input.
   */
  const handleChangeMarge3 = (e) => {
    setMarge3(e.target.value);
    if (actionMarge3 === "1") {
      setprixHt3(margePercentage(prixAchatHt, e.target.value));
    } else {
      setprixHt3(sumNumber(prixAchatHt, marge3));
    }
  };
  /**
   * It sets the value of the marge4 variable to the value of the input field.
   * @param e - The event object that contains the target.
   */
  const handleChangeMarge4 = (e) => {
    setMarge4(e.target.value);
    if (actionMarge4 === "1") {
      setprixHt4(margePercentage(prixAchatHt, e.target.value));
    } else {
      setprixHt4(sumNumber(prixAchatHt, marge4));
    }
  };
  /**
   * It sets the value of the marge5 variable to the value of the input field.
   * @param e - The event object that contains the target.
   */
  const handleChangeMarge5 = (e) => {
    setMarge5(e.target.value);
    if (actionMarge5 === "1") {
      setprixHt5(margePercentage(prixAchatHt, e.target.value));
    } else {
      setprixHt5(sumNumber(prixAchatHt, marge5));
    }
  };

  /**
   * It sets the value of the marge1 variable to the value of the input field.
   * @param e - The event object that contains the value of the input.
   */
  const handleChangeActionMargin1 = (e) => {
    setActionMarge1(e.target.value);
    if (e.target.value === "1") {
      setprixHt1(margePercentage(prixAchatHt, marge1));
    } else {
      setprixHt1(sumNumber(prixAchatHt, marge1));
    }
  };

  /**
   * It sets the value of the marge2 variable to the value of the input field.
   * @param e - The event object that contains the value of the input.
   */
  const handleChangeActionMargin2 = (e) => {
    setActionMarge2(e.target.value);
    if (e.target.value === "1") {
      setprixHt2(margePercentage(prixAchatHt, marge2));
    } else {
      setprixHt2(sumNumber(prixAchatHt, marge2));
    }
  };

  /**
   * It sets the value of the marge3 variable to the value of the input field.
   * @param e - The event object that contains the value of the input.
   */
  const handleChangeActionMargin3 = (e) => {
    setActionMarge3(e.target.value);
    if (e.target.value === "1") {
      setprixHt3(margePercentage(prixAchatHt, marge3));
    } else {
      setprixHt3(sumNumber(prixAchatHt, marge3));
    }
  };

  /**
   * It sets the value of the marge4 variable to the value of the input field.
   * @param e - The event object that contains the value of the input.
   */
  const handleChangeActionMargin4 = (e) => {
    setActionMarge4(e.target.value);
    if (e.target.value === "1") {
      setprixHt4(margePercentage(prixAchatHt, marge4));
    } else {
      setprixHt4(sumNumber(prixAchatHt, marge4));
    }
  };

  /**
   * It sets the value of the marge5 variable to the value of the input field.
   * @param e - The event object that contains the value of the input.
   */
  const handleChangeActionMargin5 = (e) => {
    setActionMarge5(e.target.value);
    if (e.target.value === "1") {
      setprixHt5(margePercentage(prixAchatHt, marge5));
    } else {
      setprixHt5(sumNumber(prixAchatHt, marge5));
    }
  };

  /**
   * It calls the API to add a new sous famille.
   */
  const handleSubmit = () => {
    if (libelle === "") {
      toast.error("nom vide");
    } else if (order === "") {
      toast.error("order vide");
    } else if (order !== "" && isNaN(order)) {
      toast.error("order doit être un nombre");
    } else if (prixHt1 === "") {
      toast.error("Prix vente 1 vide");
    } else if (prixHt1 !== "" && isNaN(prixHt1)) {
      toast.error("Prix vente 1 doit être un nombre");
    } else if (prixHt2 === "") {
      toast.error("Prix vente 2 vide");
    } else if (prixHt2 !== "" && isNaN(prixHt2)) {
      toast.error("Prix vente 2 doit être un nombre");
    } else if (prixHt3 === "") {
      toast.error("Prix vente 3 vide");
    } else if (prixHt3 !== "" && isNaN(prixHt3)) {
      toast.error("Prix vente 3 doit être un nombre");
    } else if (prixHt4 === "") {
      toast.error("Prix vente 4 vide");
    } else if (prixHt4 !== "" && isNaN(prixHt4)) {
      toast.error("Prix vente 4 doit être un nombre");
    } else if (prixHt5 === "") {
      toast.error("Prix vente 5 vide");
    } else if (prixHt5 !== "" && isNaN(prixHt5)) {
      toast.error("Prix vente 5 doit être un nombre");
    } else {
      ajouterSousFamille(
        familleCode,
        libelle,
        order,
        color,
        prixHt1,
        prixHt2,
        prixHt3,
        prixHt4,
        prixHt5,
        prixttc1,
        prixttc2,
        prixttc3,
        prixttc4,
        prixttc5,
        marge1,
        marge2,
        marge3,
        marge4,
        marge5,
        prixAchatHt,
        prixAchattc,
        prixVentePublic
      ).then((res) => {
        //history.push("/facturation/sousfamille");
        getData();
        refHideModal.current.click();
      });
    }
  };

  /**
   * It sets the color variable to the value of the color picker.
   * @param event - The event that triggered the callback.
   */
  const handleChangeComplete = (event) => {
    setColor(event.target.value);
  };

  return (
    <div
      className="modal fade"
      id="sousfamilleModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="sousfamilleModalTitle"
      aria-hidden="true"
    >
      <ToastContainer></ToastContainer>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Ajouter sous famille
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Nom </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="..."
                  onChange={(e) => setLibelle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Order</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="..."
                  onChange={(e) => setOrder(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Prix vente public</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="..."
                  onChange={(e) => setPrixVentePublic(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Prix achat HT</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="..."
                  onChange={(e) => setPrixAchatHt(e.target.value)}
                />
              </div>
              <div className="form-group">
                <div className="m-2">
                  <div className="row mb-2 mt-2">
                    <div className="col-5">
                      <div className="row mt-2">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <select defaultValue="1" onChange={handleChangeActionMargin1} className="form-control">
                              <option value="1">Pourcent</option>
                              <option value="2">Montant</option>
                            </select>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Marge 1"
                            onChange={handleChangeMarge1}
                            aria-label="Text input with dropdown button"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-4 mt-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Prix vente HT 1"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={prixHt1}
                        onChange={(e) => setprixHt1(e.target.value)}
                      />
                    </div>
                    <div className="col-3 mt-2">
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        placeholder="Prix vente TTC 1"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={prixttc1}
                        onChange={(e) => setprixttc1(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5">
                      <div className="row mt-2">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <select defaultValue="1" onChange={handleChangeActionMargin2} className="form-control">
                              <option value="1">Pourcent</option>
                              <option value="2">Montant</option>
                            </select>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChangeMarge2}
                            placeholder="Marge 2"
                            aria-label="Text input with dropdown button"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-4 mt-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Prix vente HT 2"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={prixHt2}
                        onChange={(e) => setprixHt2(e.target.value)}
                      />
                    </div>
                    <div className="col-3 mt-2">
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        placeholder="Prix vente TTC 2"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={prixttc2}
                        onChange={(e) => setprixttc2(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5">
                      <div className="row mt-2">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <select defaultValue="1" onChange={handleChangeActionMargin3} className="form-control">
                              <option value="1">Pourcent</option>
                              <option value="2">Montant</option>
                            </select>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Marge 3"
                            onChange={handleChangeMarge3}
                            aria-label="Text input with dropdown button"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-4 mt-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Prix vente HT 3"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={prixHt3}
                        onChange={(e) => setprixHt3(e.target.value)}
                      />
                    </div>
                    <div className="col-3 mt-2">
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        placeholder="Prix vente TTC 3"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={prixttc3}
                        onChange={(e) => setprixttc3(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5">
                      <div className="row mt-2">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <select defaultValue="1" onChange={handleChangeActionMargin4} className="form-control">
                              <option value="1">Pourcent</option>
                              <option value="2">Montant</option>
                            </select>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Marge 4"
                            onChange={handleChangeMarge4}
                            aria-label="Text input with dropdown button"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-4 mt-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Prix vente HT 4"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={prixHt4}
                        onChange={(e) => setprixHt4(e.target.value)}
                      />
                    </div>
                    <div className="col-3 mt-2">
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        placeholder="Prix vente TTC 4"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={prixttc4}
                        onChange={(e) => setprixttc4(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5">
                      <div className="row mt-2">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <select defaultValue="1" onChange={handleChangeActionMargin5} className="form-control">
                              <option value="1">Pourcent</option>
                              <option value="2">Montant</option>
                            </select>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Marge 5"
                            onChange={handleChangeMarge5}
                            aria-label="Text input with dropdown button"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-4 mt-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Prix vente HT 5"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={prixHt5}
                        onChange={(e) => setprixHt5(e.target.value)}
                      />
                    </div>
                    <div className="col-3 mt-2">
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        placeholder="Prix vente TTC 5"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={prixttc5}
                        onChange={(e) => setprixttc5(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Taux Tva : {tauxTva}</label>
                <Select onChange={handleChangeTva} options={dataTva} />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Prix achat TTC</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="..."
                  onChange={(e) => setPrixAchattc(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Choisir Famille : {familleLibelle}</label>
                <Select onChange={handleChange} options={data} />
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Coleur</label>
                <input type="color" className="form-control" onChange={handleChangeComplete} />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={refHideModal}>
              Annuler
            </button>
            <button onClick={handleSubmit} className="btn btn-primary">
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSousFamille;
