import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  deleteDechargeById,
  deleteEnteteByCode,
  editEnteteAfterDelete,
  getEnteteCommercialVente,
} from "../../../../../Services/Facturation/FacturationEnteteApi";
import { filterFactureByWord } from "../../../../../Utils/SortObject";
import { convertToDigit, soustractMontant, soustractSolde } from "../../../../../Utils/NumberUtils";
import { sumEncaissement, sumMontant, sumMontantHT, sumMontantTTC, sumMontantTVA, sumSolde } from "../../../../../Utils/ObjectOperation";
import { toast, ToastContainer } from "react-toastify";
import { compareDate } from "../../../../../Utils/DateUtils";
import moment from "moment";
import { deleteLigneByCodeEntete } from "../../../../../Services/Facturation/FacturationLigneApi";
import { getSequenceByCode } from "../../../../../Services/Facturation/FacturationSequenceApi";
import { getEncaissementByCode } from "../../../../../Services/Facturation/FacturationEncaissementApi";
import { checkMontanPaiement, groupByNumeroPiece, groupByNumPiece } from "../../../../../Utils/FactureUtils";
import CustomSyncLoader from "../../../../Others/CustomSyncLoader";
import ErrorCustum from "../../../../Others/ErrorCustum";
import Pagination from "../../../../Others/Pagination";
import ButtonHeaderComponent from "../../../../Styles/ButtonHeaderComponent";
import "./ListFactureVente.css";
import ExportCsv from "../../../../Exports/ExportCsv";
import ExportPdf from "../../../../Exports/ExportPdf";
import CopieContent from "../../../../Exports/CopieContent";
import swal from "sweetalert";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function StaticExample(props) {
  return (
    <Modal.Dialog>
      <Modal.Header closeButton>
        <Modal.Title>Modal title</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Modal body text goes here.</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary">Close</Button>
        <Button variant="primary">Save changes</Button>
      </Modal.Footer>
    </Modal.Dialog>
  );
}

function ListFactureVente() {
  let history = useHistory();
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [actionId, setActionId] = useState(false);
  const [factureCode, setfactureCode] = useState(null);
  const myRefDelete = useRef(null);
  const [size, setsize] = useState(40);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [allEncaiss, setallEncaiss] = useState(null);
  const [resultEnc, setresultEnc] = useState(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  useEffect(() => {
    getData(new Date().getFullYear());
  }, []);

  const getData = (d) => {
    setLoading(true);
    getEnteteCommercialVente()
      .then((res) => {
        const currentYearArray = res.data.filter((el) => {
          return el.date.split("-")[0] == d;
        });

        getEncaissementByCode().then((resEncaissement) => {
          const goupedEncaissement = groupByNumeroPiece(resEncaissement.data);
          setallEncaiss(resEncaissement.data);
          console.log(resEncaissement);
          // merge entete et encaissement
          const mergeById = (a1, a2) =>
            a1.map((itm) => ({
              ...a2.find((item) => item.numero_piece === itm.code && item),
              ...itm,
            }));

          const mergedList = mergeById(currentYearArray, goupedEncaissement);

          setdata(mergedList);
          setallInitialData(mergedList);
        });
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    const dataSearch = filterFactureByWord(allInitialData, e.target.value);
    setdata(dataSearch);
  };

  const filterByDate = (value) => {
    const filterd = allInitialData.filter((el) => {
      if (el.date) {
        return el.date.split(" ")[0] == value;
      }
    });

    if (filterd.length === 0) {
      setdata(allInitialData);
    } else {
      setdata(filterd);
    }
  };

  const handleSelectStaus = (e) => {
    switch (e.target.value) {
      case "1":
        setdata(allInitialData.filter((el) => !checkMontanPaiement(el?.montant_encaissement)));
        break;
      case "2":
        setdata(allInitialData.filter((el) => checkMontanPaiement(el?.montant_encaissement) == el.net_a_payer));
        break;

      case "3":
        setdata(
          allInitialData.filter(
            (el) => checkMontanPaiement(el?.montant_encaissement) < el.net_a_payer && checkMontanPaiement(el?.montant_encaissement) > 0
          )
        );
        break;
      case "4":
        setdata(allInitialData.filter((el) => checkMontanPaiement(el?.montant_encaissement) == 0 && compareDate(el.date_prevu_livraison)));
        break;
      default:
        setdata(allInitialData);
    }
  };
  const handleDeletefacture = (code, factureCode) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        getEnteteCommercialVente().then((result) => {
          var lastCodeFacture = result.data[0].facture_code.split("_")[1];
          var lastListFacture = factureCode.split("_")[1];

          if (parseInt(lastCodeFacture) === parseInt(lastListFacture)) {
            deleteLigneByCodeEntete(code).then((res) => {
              deleteEnteteByCode(code).then((res) => {
                getData(new Date().getFullYear());
              });
            });
          } else {
            deleteLigneByCodeEntete(code).then((res) => {
              editEnteteAfterDelete(code).then((res) => {
                swal("Opération effectuée avec succès!", {
                  icon: "success",
                });
                getData(new Date().getFullYear());
              });
            });
          }
        });
      }
    });
  };

  const handleActionId = (id, codefacture) => {
    setActionId(id);
    setfactureCode(codefacture);
  };
  const handleGoEncaissement = (el) => {
    localStorage.setItem("facture", JSON.stringify(el));
    history.push("/admin/addEncaissement/" + el.id, el);
  };

  const handleLastYear = (e) => {
    getData(new Date().getFullYear() - 1);
  };
  const handleCurrentYear = (e) => {
    getData(new Date().getFullYear());
  };

  const handleSelectDate = (e) => {
    const expr = e.target.value;
    if (expr == 1) {
      getData(new Date().getFullYear());
    }
    if (expr == 2) {
      getData(new Date().getFullYear() - 1);
    }
  };

  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };

  const hadleShowEncaissement = (value) => {
    console.log(value);
    console.log(allEncaiss);
    const resultEncaissement = allEncaiss.filter((el) => el.num_facture == value);
    console.log(resultEncaissement);
    setresultEnc(allEncaiss.filter((el) => el.num_facture == value));
    setShow(true);
  };

  const handleRemoveDecharge = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Voulez vous vraiment supprimé décharge!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Opération effectuée avec succès!", {
          icon: "success",
        });

        deleteDechargeById(id).then((res) => {
          getData(new Date().getFullYear());
        });
      }
    });
  };

  return (
    <>
      {resultEnc && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Encaissement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {/* <ClientEncaissement encaissement={encaissements}></ClientEncaissement> */}
              <div className="table-responsive">
                {/* <Link to="#" className="btn btn-primary btn-tableview">
                        Add Invoice
                      </Link> */}
                <table className="table  table-vcenter text-nowrap table-bordered border-bottom border-top" id="invoice-tables">
                  <thead>
                    <tr>
                      <th>N° Facture</th>
                      <th>Ref Facture</th>
                      <th>
                        Montant HT <br></br>
                        {/* <span className="badge badge-pill badge-danger">{sumMontant(resultEnc)}</span> */}
                      </th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultEnc?.map((el, idx) => (
                      <tr key={idx}>
                        <td>
                          <Link to={"/admin/facturation/editencaissement/" + el?.id}>{el.num_facture}</Link>
                        </td>
                        <td>
                          {el.type === "facture" && <Link to={"/admin/editerfacturevente/" + el.code_facture}>{el.code_facture}</Link>}
                          {el.type === "devis" && <Link to={"/admin/editerdevisvente/" + el.code_facture}>{el.code_facture}</Link>}
                          {el.type === "bl" && <Link to={"/admin/editerblvente/" + el.code_facture}>{el.code_facture}</Link>}
                          {el.type === "commande" && <Link to={"/admin/editercommandevente/" + el.code_facture}>{el.code_facture}</Link>}
                        </td>
                        <td>{convertToDigit(el.montant)}</td>
                        <td>{el.created_at}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Facture vente</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/ajoutfacturevente" className="btn btn-primary mr-3">
                Ajouter facture
              </Link>
              <Link to="/admin/addproduct" className="btn btn-warning mr-3">
                Ajouter produit
              </Link>
              <Link to="/admin/addclient" className="btn btn-warning mr-3">
                Ajouter client
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>

      <div className="flexrow">
        <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant HT</span>
                    <h3 className="mb-0 mt-1 text-success">{sumMontantHT(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant TVA</span>
                    <h3 className="mb-0 mt-1 text-primary">{sumMontantTVA(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-primary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant TTC</span>
                    <h3 className="mb-0 mt-1 text-secondary">{sumMontantTTC(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-secondary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Encaissement</span>
                    <h3 className="mb-0 mt-1 text-danger">{sumEncaissement(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-danger-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Solde</span>
                    <h3 className="mb-0 mt-1 text-danger">{sumSolde(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-danger-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-8 col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Date:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date" onChange={(e) => filterByDate(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Choisir année:</label>
                        <select className="form-control custom-select select2" onChange={handleSelectDate}>
                          <option value={0}>Année</option>
                          <option value={1}>{new Date().getFullYear()}</option>
                          <option value={2}>{new Date().getFullYear() - 1}</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Choisir Statut:</label>

                        <select className="form-control custom-select select2" onChange={handleSelectStaus}>
                          <option value={0}>Tous</option>
                          <option value={1}>En cours</option>
                          <option value={2}>Payée</option>
                          <option value={3}>Partiel</option>
                          <option value={4}>échu</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-2 col-md-12">
                  <div className="form-group mt-5">
                    <Link to="#" className="btn btn-primary btn-block">
                      Recherche
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">List factures vente</h4>

              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">Note:</label>
                </div>
                <div>
                  <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> payé
                  </span>
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> échu
                  </span>
                  <span className="badge badge-warning-light mr-2">
                    <i className="fa fa-star text-warning" /> partial
                  </span>
                  {/* <span className="badge badge-orange-light mr-2">
                    <i className="fa fa-adjust text-orange" /> ---&gt; Half Day
                  </span> */}
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            Afficher{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            entrées
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            {/* <select className="custom-select m-2 p-2" onChange={handleSelectStaus}>
                              <option value={0}>Tous</option>
                              <option value={1}>En cours</option>
                              <option value={2}>Payée</option>
                              <option value={3}>Partiel</option>
                            </select>

                            <select className="custom-select" onChange={handleSelectDate}>
                              <option value={0}>Année</option>
                              <option value={1}>{new Date().getFullYear()}</option>
                              <option value={2}>{new Date().getFullYear() - 1}</option>
                            </select> */}

                            <input
                              type="search"
                              className="form-control"
                              placeholder="Recherche..."
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                            {/* <input
                              className="form-control floating"
                              type="date"
                              onChange={(e) => filterByDate(e.target.value)}
                            /> */}
                          </label>

                          <ExportCsv data={data} name={"List_factures_ventes"} />
                          <ExportPdf
                            data={data}
                            name={"List_factures_ventes"}
                            columns={[
                              "facture_code",
                              "client_nom",
                              "montant_total_ht",
                              "montant_total_tva",
                              "montant_total_ttc",
                              "montant_encaissement",
                              "net_a_payer",
                            ]}
                          />
                          <CopieContent />

                          {/* <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0">N° Facture</th>
                              <th className="border-bottom-0">Client</th>
                              <th className="border-bottom-0">Date</th>
                              <th className="border-bottom-0">Montant HT</th>
                              <th className="border-bottom-0">Montant TVA</th>
                              <th className="border-bottom-0">Montant TTC</th>
                              <th className="border-bottom-0">Encaissement</th>
                              <th className="border-bottom-0">Solde</th>
                              <th className="border-bottom-0" style={{ width: 60 }}>
                                Statut
                              </th>
                              <th className="border-bottom-0">Date d'échéance</th>
                              <th className="border-bottom-0"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* <tr>
                              <td className="align-middle w-5">
                                <label className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name="example-checkbox2"
                                    defaultValue="option2"
                                  />
                                  <span className="custom-control-label" />
                                </label>
                              </td>
                              <td className="align-middle">
                                <div className="d-flex">
                                  <img src="assets/images/files/file.png" alt="img" className="w-5 h-5 mr-2" />
                                  <div className="mt-1">
                                    <a className="btn-link" href="#">
                                      INVOICE #23543
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="text-nowrap align-middle">
                                <span className="font-weight-semibold">$230</span>
                              </td>
                              <td className="text-nowrap align-middle">
                                <span>10 Jan 2020</span>
                              </td>
                              <td className="text-nowrap align-middle">25 Jan 2020</td>
                              <td className="text-nowrap align-middle">Daneil Robert</td>
                              <td>
                                <div className="btn-group">
                                  <a
                                    href="#"
                                    className="btn btn-light btn-sm"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    Options <i className="fa fa-angle-down" />
                                  </a>
                                  <div className="dropdown-menu">
                                    <a className="dropdown-item" href="#">
                                      <i className="fe fe-eye mr-2" /> View
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      <i className="fe fe-share mr-2" /> Send
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      <i className="fe fe-edit mr-2" /> Edit
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      <i className="fe fe-trash mr-2" /> Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr> */}
                            {currentData.map((el, idx) => (
                              <tr key={el.id}>
                                <td>
                                  <div className="d-flex">
                                    <img src="assets/images/files/file.png" alt="img" className="w-5 h-6" />
                                    <div className="mt-1 mr-4">
                                      <Link to={"/admin/editerfacturevente/" + el.code}>{el.facture_code}</Link>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <Link to={"/admin/clientactivity/" + el.client_code}>{el.client_nom}</Link>
                                </td>

                                <td>{el.date.split(" ")[0] === "2030-01-01" ? "" : el.date.split(" ")[0]}</td>

                                <td style={{ textAlign: "right" }}>{el.montant_total_ttc == "0" ? "" : convertToDigit(el.montant_total_ht)}</td>
                                <td style={{ textAlign: "right" }}>{el.montant_total_ttc == "0" ? "" : convertToDigit(el.montant_total_tva)}</td>
                                <td style={{ textAlign: "right" }}>{el.montant_total_ttc == "0" ? "" : convertToDigit(el.montant_total_ttc)}</td>

                                <td
                                  style={{ textAlign: "right" }}
                                  onClick={() => hadleShowEncaissement(el.facture_code)}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={"Clicker pour voir l'encaissement"}
                                >
                                  {convertToDigit(checkMontanPaiement(el?.montant_encaissement))}
                                </td>

                                {/* <td>dd</td> */}
                                <td style={{ textAlign: "right" }}>
                                  {el.montant_total_ttc == "0"
                                    ? ""
                                    : soustractSolde(el.montant_total_ttc, checkMontanPaiement(el?.montant_encaissement))}
                                  {/* soustractSolde(el.montant_total_ttc, checkMontanPaiement(el?.montant_encaissement)) */}
                                </td>

                                <td>
                                  {checkMontanPaiement(el?.montant_encaissement) == el.net_a_payer && el.net_a_payer != 0 && (
                                    <span className="badge badge-success">payée</span>
                                  )}
                                  {checkMontanPaiement(el?.montant_encaissement) > el.net_a_payer && (
                                    <span className="badge badge-success">payée</span>
                                  )}
                                  {checkMontanPaiement(el?.montant_encaissement) < el.net_a_payer &&
                                    checkMontanPaiement(el?.montant_encaissement) > 0 && (
                                      <>
                                        <span className="badge badge-warning">partiel</span>
                                        {compareDate(el.date_prevu_livraison) && <span className="badge badge-danger ml-1">échu</span>}
                                      </>
                                    )}

                                  {checkMontanPaiement(el?.montant_encaissement) == 0 && (
                                    <>{compareDate(el.date_prevu_livraison) && <span className="badge badge-danger ml-1">échu</span>}</>
                                  )}
                                  {checkMontanPaiement(el?.montant_encaissement) == 0 && el.montant_total_ttc == 0 && (
                                    <span className="badge badge-primary ml-1">vide</span>
                                  )}
                                </td>

                                <td>{el.date_prevu_livraison.split(" ")[0] === "2030-01-01" ? "" : el.date_prevu_livraison.split(" ")[0]}</td>
                                <td>
                                  {true && (
                                    <div className="btn-group">
                                      <button
                                        style={{ textAlign: "center", padding: 2, width: 40 }}
                                        type="button"
                                        className="btn btn-secondary dropdown-toggle dropdown-icon"
                                        data-toggle="dropdown"
                                      ></button>
                                      <div className="dropdown-menu" role="menu">
                                        <Link to="#" onClick={() => handleGoEncaissement(el)} className="dropdown-item">
                                          Ajouter encaissement
                                        </Link>

                                        {/* <div className="dropdown-divider" /> */}
                                        <Link to={"/admin/editerfacturevente/" + el.code} className="dropdown-item">
                                          Editer document
                                        </Link>

                                        {el.image ? (
                                          <>
                                            <Link to={"/admin/facturation/uploaddecharge/" + el.code} className="dropdown-item">
                                              Voir décharge
                                            </Link>
                                            <Link to="#" className="dropdown-item" onClick={() => handleRemoveDecharge(el.id)}>
                                              Supprimer décharge
                                            </Link>
                                          </>
                                        ) : (
                                          <Link to={"/admin/facturation/uploaddecharge/" + el.code} className="dropdown-item">
                                            Ajouter décharge
                                          </Link>
                                        )}

                                        <div className="dropdown-divider" />
                                        {checkMontanPaiement(el?.montant_encaissement) == 0 && (
                                          <Link to="#" onClick={() => handleDeletefacture(el.code, el.facture_code)} className="dropdown-item">
                                            Supprimer facture
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th className="border-bottom-0">N° Facture</th>
                              <th className="border-bottom-0">Client</th>
                              <th className="border-bottom-0">Date</th>
                              <th className="border-bottom-0">Montant HT</th>
                              <th className="border-bottom-0">Montant TVA</th>
                              <th className="border-bottom-0">Montant TTC</th>
                              <th className="border-bottom-0">Encaissement</th>
                              <th className="border-bottom-0">Solde</th>
                              <th className="border-bottom-0" style={{ width: 60 }}>
                                Statut
                              </th>
                              <th className="border-bottom-0">Date d'échéance</th>
                              <th className="border-bottom-0"></th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          Affichage de 1 à {size} sur {data.length} entrées
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListFactureVente;
