import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";
import Pagination from "../../Others/Pagination";
import { deletePaiementById, getPaiementByCode } from "../../../Services/Facturation/FacturationPaiementApi";
import { convertToDigit } from "../../../Utils/NumberUtils";
import { sumMontant } from "../../../Utils/ObjectOperation";
import { filterEncaissement } from "../../../Utils/SortObject";
import ButtonHeaderComponent from "../../Styles/ButtonHeaderComponent";
import swal from "sweetalert";
import ExportCsv from "../../Exports/ExportCsv";
import ExportPdf from "../../Exports/ExportPdf";
import CopieContent from "../../Exports/CopieContent";

function ListPaiement() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [idAction, setidAction] = useState(null);
  const myRefDelete = useRef(null);
  const [size, setsize] = useState(20);
  const [isCollapsed, setisCollapsed] = useState(false);

  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getPaiementByCode()
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
        console.log(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    const dataSearch = filterEncaissement(allInitialData, e.target.value);
    setdata(dataSearch);
  };

  const handleDelete = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deletePaiementById(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  const addIdToAction = (id) => {
    setidAction(id);
  };
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Paiement</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/addpaiement" className="btn btn-primary mr-3">
                Ajouter paiement
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
      <div className="col-xl-3 col-lg-6 col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-7">
                <div className="mt-0 text-left">
                  <span className="font-weight-semibold">Total collaborateur</span>
                  <h3 className="mb-0 mt-1 text-success">{data.length}</h3>
                </div>
              </div>
              <div className="col-5">
                <div className="icon1 bg-success-transparent my-auto  float-right">
                  <i className="las la-users" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-lg-6 col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-7">
                <div className="mt-0 text-left">
                  <span className="font-weight-semibold">Total Homme</span>
                  <h3 className="mb-0 mt-1 text-primary">{males?.length}</h3>
                </div>
              </div>
              <div className="col-5">
                <div className="icon1 bg-primary-transparent my-auto  float-right">
                  <i className="las la-male" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-lg-6 col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-7">
                <div className="mt-0 text-left">
                  <span className="font-weight-semibold">Totale Femme</span>
                  <h3 className="mb-0 mt-1 text-secondary">{femelle?.length}</h3>
                </div>
              </div>
              <div className="col-5">
                <div className="icon1 bg-secondary-transparent my-auto  float-right">
                  <i className="las la-female" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-lg-6 col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-7">
                <div className="mt-0 text-left">
                  <span className="font-weight-semibold">Total inactifs</span>
                  <h3 className="mb-0 mt-1 text-danger">{inactif?.length}</h3>
                </div>
              </div>
              <div className="col-5">
                <div className="icon1 bg-danger-transparent my-auto  float-right">
                  <i className="las la-user-friends" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}

      {/* <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-0 pb-0">
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-primary-transparent">31</span>
                  <h5 className="mb-0 mt-3">Total Working Days</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5 ">
                  <span className="avatar avatar-md bradius fs-20 bg-success-transparent">24</span>
                  <h5 className="mb-0 mt-3">Présent Days</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-danger-transparent">2</span>
                  <h5 className="mb-0 mt-3">Absent Days</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-warning-transparent">0</span>
                  <h5 className="mb-0 mt-3">Half Days</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5 ">
                  <span className="avatar avatar-md bradius fs-20 bg-orange-transparent">2</span>
                  <h5 className="mb-0 mt-3">Late Days</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-pink-transparent">5</span>
                  <h5 className="mb-0 mt-3">Holidays</h5>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-5 col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">From:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control fc-datepicker" placeholder="DD-MM-YYYY" type="date" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">To:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control fc-datepicker" placeholder="DD-MM-YYYY" type="date" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Select Job Type:</label>
                        <select
                          name="attendance"
                          className="form-control custom-select select2"
                          data-placeholder="Select Job Type"
                        >
                          <option label="Select Job Type" />
                          <option value={1}>Full-Time</option>
                          <option value={2}>Part-Time</option>
                          <option value={3}>Freelancer</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Select Status:</label>
                        <select
                          name="attendance"
                          className="form-control custom-select select2"
                          data-placeholder="Select Status"
                        >
                          <option label="Select Status" />
                          <option value={1}>Active</option>
                          <option value={2}>InActive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-md-12">
                  <div className="form-group mt-5">
                    <Link to="#" className="btn btn-primary btn-block">
                      Recherche
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">Liste des paiements</h4>
              <div className="card-options">
                <Link
                  to="#"
                  className="card-options-fullscreen mr-2"
                  data-toggle="card-fullscreen"
                  onClick={handleCollapse}
                >
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">Note:</label>
                </div>
                <div>
                  {/* <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> activé
                  </span> */}
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> Total
                  </span>
                  {/* <span className="badge badge-warning-light mr-2">
                  <i className="fa fa-star text-warning" /> ---&gt; Holiday
                </span>
                <span className="badge badge-orange-light mr-2">
                  <i className="fa fa-adjust text-orange" /> ---&gt; Half Day
                </span> */}
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            Afficher{" "}
                            <select
                              value={size}
                              className="form-control"
                              id="exampleFormControlSelect1"
                              onChange={handleChangeSize}
                            >
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            entrées
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Recherche..."
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv data={data} name={"List_paiements"} />
                          <ExportPdf
                            data={data}
                            name={"List_paiements"}
                            columns={["num_piece", "idClient", "nom", "prenom", "montant", "date_echeance"]}
                          />
                          <CopieContent />
                          {/* <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0 w-5">Facture</th>
                              <th className="border-bottom-0">Ref Facture</th>
                              <th className="border-bottom-0">Nom du client</th>
                              <th className="border-bottom-0">Référence</th>
                              {/* <th>Méthode</th> */}
                              <th className="border-bottom-0">Source</th>
                              <th>
                                <span className="badge badge-pill badge-danger">{sumMontant(data)}</span>
                                <br></br>
                                Montant
                              </th>
                              {/* <th className="text-center">Status</th> */}
                              <th className="text-right">Date d'échéance</th>
                              <th className="text-right">Date de réception</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el, idx) => (
                              <tr key={idx}>
                                <td>
                                  {el.type === "facture" && (
                                    <Link to={"/admin/editerfacturevente/" + el.code_facture}>{el.code_facture}</Link>
                                  )}
                                  {el.type === "devis" && (
                                    <Link to={"/admin/editerdevisvente/" + el.code_facture}>{el.code_facture}</Link>
                                  )}
                                  {el.type === "bl" && (
                                    <Link to={"/admin/editerblvente/" + el.code_facture}>{el.code_facture}</Link>
                                  )}
                                  {el.type === "commande" && (
                                    <Link to={"/admin/editercommandevente/" + el.code_facture}>{el.code_facture}</Link>
                                  )}
                                </td>

                                <td>{el.num_piece}</td>
                                <td>
                                  <Link to={"/admin/client/" + el.idClient}>
                                    {el.nom}
                                    {el.prenom}
                                  </Link>
                                </td>
                                <td>{el.reference_banque}</td>
                                {/* <td>{el.mode_paiement}</td> */}
                                <td>{el.banque}</td>
                                <td>{convertToDigit(el.montant)}</td>
                                {/* <td className="text-center">
                            {el.status === "payer" && <span className="badge badge-success">Payé</span>}
                            {el.status === "partiel" && <span className="badge badge-warning">Partiel</span>}
                            {el.status === "" && <span className="badge badge-danger"></span>}
                          </td> */}
                                <td className="text-right">{el.date_echeance}</td>
                                <td className="text-right">{el.date_reception}</td>
                                <td className="text-left">
                                  <Link
                                    to={"/admin/facturation/editpaiement/" + el.id}
                                    className="action-btns"
                                    data-tip="editer"
                                  >
                                    <i className="feather feather-edit text-info" />
                                  </Link>

                                  <Link
                                    to="#"
                                    className="action-btns"
                                    data-tip="supprimer"
                                    onClick={() => handleDelete(el.id)}
                                  >
                                    <i className="feather feather-x text-danger" />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          Affichage de 1 à {size} sur {data.length} entrées
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* modal enc */}
      <div
        className="modal fade"
        id="modalDeleteEncaissement"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="modalDeleteEncaissementTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Suppression
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">Voulez vous vraiment supprimé ce encaissement ?</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={myRefDelete}>
                Annuler
              </button>
              <button type="button" className="btn btn-primary" onClick={() => handleDelete(idAction)}>
                Supprimé
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListPaiement;
