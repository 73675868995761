import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#fff";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#142328",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 40,
    fontStyle: "bold",
  },
  description: {
    width: "70%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 0,
    fontSize: 10,
  },

  pu: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 1,
    fontSize: 10,
  },
  qte: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 1,
    fontSize: 10,
  },
  remise: {
    width: "15%",
    textAlign: "center",
    paddingRight: 1,
    fontSize: 10,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingRight: 1,
    fontSize: 10,
  },
});

const InvoiceTableRow = ({ items, isremise }) => {
  console.log(isremise);
  const rows = items.map((item, idx) => (
    <View key={idx} style={styles.row}>
      <Text style={styles.description}>{item.libelleProduit}</Text>
      <Text style={styles.pu}>{item.prixHt}</Text>
      <Text style={styles.qte}>{item.qte}</Text>
      {item.remise != "0" ? (
        <Text style={styles.remise}>
          {item.remise}
          {item.actionRemise === "2" ? " TND" : " %"}
        </Text>
      ) : //egal to 0
      // <Text style={styles.remise}></Text>
      isremise == true ? (
        <Text style={styles.remise}></Text>
      ) : (
        <></>
      )}
      <Text style={styles.total}>{item.totalHt} TND</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
