import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteProjetById, getProjetByCode } from "../../Services/Pointeuse/ProjetApi";
import { reduceObject } from "../../Utils/ObjectOperation";
import { filterProjectByWord } from "../../Utils/SortObject";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import swal from "sweetalert";
import AddProject from "./AddProject.js";
import MembersPic from "./MembersPic";
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import CopieContent from "../Exports/CopieContent";

function ListProjects() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(15);
  const [etats, setetats] = useState(null);

  /**
   * It sets the size of the data to be retrieved from the API.
   * @param e - The event object that contains the data we want to use.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  /**
   * It gets the data from the API and sets it to the data variable.
   */
  const getData = () => {
    setLoading(true);
    getProjetByCode()
      .then((res) => {
        setetats(reduceObject(res.data));
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  /**
   * *This function is called when the user clicks on the page numbers.*
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data based on the search term.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    if (e.target.value) {
      const dataSearch = filterProjectByWord(allInitialData, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  const handleDelete = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteProjetById(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  const handlePriority = (value) => setdata(allInitialData.filter((el) => el.priority == value));
  const handleStatus = (value) => setdata(allInitialData.filter((el) => el.etat == value));
  const handleStart = (value) => setdata(allInitialData.filter((el) => el.date_debut.split(" ")[0] == value || el.date_fin.split(" ")[0] == value));

  return (
    <div>
      <AddProject />
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Liste des projets</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="#" className="btn btn-primary" data-toggle="modal" data-target="#newprojectmodal">
                <i className="feather feather-plus fs-15 my-auto mr-2" />
                Ajouter Projet
              </Link>
              <Link to="#" className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                {" "}
                <i className="feather feather-mail" />{" "}
              </Link>
              <Link to="#" className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                {" "}
                <i className="feather feather-phone-call" />{" "}
              </Link>
              <Link to="#" className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                {" "}
                <i className="feather feather-info" />{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <Link to="#">
              <div className="card-body text-center">
                <span className="avatar avatar-lg bradius fs-20 bg-secondary-transparent">{data && data.length}</span>
                <h5 className="mb-0 mt-3">Total Projets</h5>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <Link to="#">
              <div className="card-body text-center">
                <span className="avatar avatar-lg bradius fs-20 bg-primary-transparent">{etats && etats.afaire}</span>
                <h5 className="mb-0 mt-3">Projets à faire</h5>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <Link to="#">
              <div className="card-body text-center">
                <span className="avatar avatar-lg bradius fs-20 bg-warning-transparent">{etats && etats.encours}</span>
                <h5 className="mb-0 mt-3">Projets en cours</h5>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <Link to="#">
              <div className="card-body text-center">
                <span className="avatar avatar-lg bradius fs-20 bg-success-transparent">{etats && etats.terminer}</span>
                <h5 className="mb-0 mt-3">Projets terminées</h5>
              </div>
            </Link>
          </div>
        </div>
      </div>
      {/* End Row*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-header  border-0">
              <h4 className="card-title">Résumé du projet récent</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 col-xl-7">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Date:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input
                            className="form-control fc-datepicker"
                            placeholder="DD-MM-YYYY"
                            type="date"
                            onChange={(e) => handleStart(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Priorité:</label>
                        <select
                          name="attendance"
                          className="form-control custom-select select2"
                          data-placeholder="Choisir priorité"
                          onChange={(e) => handlePriority(e.target.value)}
                        >
                          <option label="Choisir priorité" />
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xl-3">
                  <div className="form-group">
                    <label className="form-label">Status:</label>
                    <select
                      name="attendance"
                      className="form-control custom-select select2"
                      data-placeholder="Choisir status"
                      onChange={(e) => handleStatus(e.target.value)}
                    >
                      <option label="Choisir status" />
                      <option value="afaire">à faire</option>
                      <option value="encours">en cours</option>
                      <option value="terminer">terminé</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12 col-xl-2">
                  <div className="form-group mt-5">
                    <Link to="#" className="btn btn-primary btn-block" onClick={() => getData()}>
                      Réinitialiser
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="card-body">
                <div className="d-flex mb-6 mt-5">
                  <div className="mr-3">
                    <label className="form-label">Note:</label>
                  </div>
                  <div>
                    <span className="badge badge-primary-light mr-2">
                      <i className="fa fa-adjust text-primary" /> à faire
                    </span>
                    <span className="badge badge-warning-light mr-2">
                      <i className="fa fa-star text-warning" /> en cours
                    </span>
                    <span className="badge badge-success-light mr-2">
                      <i className="fa fa-adjust text-success" /> terminé
                    </span>
                  </div>
                </div>

                {loading && <CustomSyncLoader></CustomSyncLoader>}
                {!loading && error && <ErrorCustum></ErrorCustum>}
                {!loading && !error && data && (
                  <div className="table-responsive">
                    <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div className="dataTables_length" id="hr-table_length">
                            <label>
                              Afficher{" "}
                              <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                                <option value={8}>8</option>
                                <option value={20}>20</option>
                                <option value={40}>40</option>
                                <option value={60}>60</option>
                                <option value={100}>100</option>
                              </select>{" "}
                              entrées
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div id="hr-table_filter" className="dataTables_filter">
                            <label className="mr-2">
                              <input
                                type="search"
                                className="form-control"
                                placeholder="Recherche..."
                                aria-controls="hr-table"
                                onChange={handleSearch}
                              />
                            </label>
                            <ExportCsv data={data} name={"List_projets"} />
                            <ExportPdf data={data} name={"List_projets"} columns={["nom", "priority", "etat", "date_fin", "budget"]} />
                            <CopieContent />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                            id="hr-table"
                            role="grid"
                            aria-describedby="hr-table_info"
                          >
                            <thead>
                              <tr>
                                <th className="border-bottom-0 w-5">#ID</th>
                                <th className="border-bottom-0">Nom</th>
                                <th className="border-bottom-0">Collaborateurs</th>
                                <th className="border-bottom-0">Priorité</th>
                                <th className="border-bottom-0">Date début</th>
                                <th className="border-bottom-0">Date fin</th>
                                <th className="border-bottom-0">Progression</th>
                                <th className="border-bottom-0">Statut</th>
                                <th className="border-bottom-0">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentData.map((el, idx) => (
                                <tr key={idx}>
                                  <td># {el.id}</td>
                                  <td>
                                    <Link to="#" className="d-flex sidebarmodal-collpase">
                                      {el.etat == "afaire" && <span className="bg-primary dot-label1 mr-2 mt-1" />}
                                      {el.etat == "encours" && <span className="bg-warning dot-label1 mr-2 mt-1" />}
                                      {el.etat == "terminer" && <span className="bg-success dot-label1 mr-2 mt-1" />}
                                      <Link to={"/admin/projects/" + el.id}>
                                        <span>{el.nom}</span>
                                      </Link>
                                    </Link>
                                  </td>
                                  <td>
                                    <MembersPic projectId={el.id} />
                                  </td>
                                  <td>
                                    {el.priority == "0" && <span className="badge badge-danger-light">0</span>}
                                    {el.priority == "1" && <span className="badge badge-secondary-light">1</span>}
                                    {el.priority == "2" && <span className="badge badge-warning-light">2</span>}
                                    {el.priority == "3" && <span className="badge badge-success-light">3</span>}
                                  </td>
                                  <td>{el.date_debut && el.date_debut.split(" ")[0]}</td>
                                  <td>{el.date_fin && el.date_fin.split(" ")[0]}</td>
                                  <td>
                                    <div className="d-flex align-items-end justify-content-between">
                                      <h6 className="fs-12">Status</h6>
                                      <h6 className="fs-12">100%</h6>
                                    </div>
                                    <div className="progress h-1">
                                      <div className="progress-bar bg-warning w-100" />
                                    </div>
                                  </td>
                                  <td>
                                    {el.etat == "afaire" && <span className="badge badge-primary">En cours</span>}
                                    {el.etat == "encours" && <span className="badge badge-warning">En cours</span>}
                                    {el.etat == "terminer" && <span className="badge badge-success">En cours</span>}
                                  </td>
                                  <td>
                                    <div className="d-flex">
                                      <Link
                                        to={"/admin/projects/" + el.id}
                                        className="action-btns1"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Voir projet"
                                      >
                                        <i className="feather feather-eye text-primary" />
                                      </Link>
                                      <Link to={"/admin/projects/" + el.id} className="action-btns1">
                                        <i
                                          className="feather feather-edit-2  text-success"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit task"
                                        />
                                      </Link>
                                      <Link
                                        to="#"
                                        className="action-btns1"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="supprimer projet"
                                        onClick={() => handleDelete(el.id)}
                                      >
                                        <i className="feather feather-trash-2 text-danger" />
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                            Affichage de 1 à {size} sur {data.length} entrées
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListProjects;
