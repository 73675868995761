import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { getAllDepartementMembers } from "../../../Services/Pointeuse/DepartementMemberApi";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";
import { getAllDepartements } from "../../../Services/Pointeuse/DepartementApi";
import { getAllAutorisation } from "../../../Services/Pointeuse/AutorisationApi";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartJsAutorisationStatus = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [options] = useState({
    responsive: true,
    legend: false,
    maintainAspectRatio: false,
  });

  function random_rgba(value) {
    let backColor = [];
    var o = Math.round,
      r = Math.random,
      s = 255;
    for (var i = 0; i < value; i++) {
      backColor.push("rgba(" + o(r() * s) + "," + o(r() * s) + "," + o(r() * s) + "," + r().toFixed(1) + ")");
    }
    return backColor;
  }

  useEffect(() => {
    setLoading(true);
    let acceptes = [];
    let refuses = [];
    var demandes = [];
    var colorsList = null;

    getAllAutorisation()
      .then((res) => {
        res.data.forEach((element) => {
          colorsList = random_rgba(res.data.length);
          if (element.type == "autorisation" && element.etat == "1") {
            acceptes.push(element.nb_heure);
          } else if (element.type == "autorisation" && element.etat == "0") {
            refuses.push(element.nb_heure);
          } else {
            demandes.push("ss");
          }
        });

        console.log(demandes);
        const data = {
          labels: ["Acceptés", "refusés", "Demandes"],
          datasets: [
            {
              label: "# of Votes",
              data: [acceptes.length, refuses.length, demandes.length],
              backgroundColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)", "rgba(255, 206, 86, 1)"],
              // borderColor: [
              //   "rgba(255, 99, 132, 1)",
              //   "rgba(54, 162, 235, 1)",
              //   "rgba(255, 206, 86, 1)",
              //   "rgba(75, 192, 192, 1)",
              //   "rgba(153, 102, 255, 1)",
              //   "rgba(255, 159, 64, 1)",
              // ],
              borderWidth: 1,
            },
          ],
        };

        setData(data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  //console.log(random_rgba(6));

  return (
    <>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && <Pie data={data} height={300} options={options} />}
    </>
  );
};

export default PieChartJsAutorisationStatus;
