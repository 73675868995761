import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get Pointage by code
 */
export async function getPersonnelByCode() {
  try {
    const response = await axios.get(api_url + "personnel/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get Pointage by id
 */
export async function getPersonnelById(id) {
  try {
    const response = await axios.get(api_url + "personnel/personnelid/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * edit personnel detail
 * @param cin
 * @param delivre_le
 * @param cnss
 * @param type_contrat
 * @param date_embauche
 * @param date_fincontrat
 * @param famille
 * @param enfant
 * @param adresse
 * @param salaire_base
 */
export async function editPersonnel(
  user_code_id,
  code_generated,
  cin,
  delivre_le,
  cnss,
  type_contrat,
  date_embauche,
  date_fincontrat,
  famille,
  enfant,
  adresse,
  latitude,
  longitude,
  salaire_base,
  rib,
  bank,
  agence,
  contact
) {
  const credentiel = {
    user_code_id: user_code_id,
    code_generated: code_generated,
    cin: cin,
    delivre_le: delivre_le,
    cnss: cnss,
    type_contrat: type_contrat,
    date_embauche: date_embauche,
    date_fincontrat: date_fincontrat,
    famille: famille,
    enfant: enfant,
    adresse: adresse,
    latitude: latitude,
    longitude: longitude,
    salaire_base: salaire_base,
    rib: rib,
    bank: bank,
    agence: agence,
    contact_urgence: contact,
  };

  try {
    const response = await axios.post(api_url + "personnel", credentiel, config);
    console.log(response);
    toast.success("mise à jour");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * update contratrecto
 * @param contrat_recto
 * @param user_code_id
 */
export async function updateContratRecto(contrat_recto, user_code_id) {
  const frmData = new FormData();
  frmData.append("user_code_id", user_code_id);
  frmData.append("contrat_recto", contrat_recto);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.put(api_url + "personnel/contratrecto", frmData, options);
    toast.success("mise à jour");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * update contrat_verso
 * @param contrat_verso
 * @param user_code_id
 */
export async function updateContratVerso(contrat_verso, user_code_id) {
  const frmData = new FormData();
  frmData.append("user_code_id", user_code_id);
  frmData.append("contrat_verso", contrat_verso);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.put(api_url + "personnel/contratverso", frmData, options);
    toast.success("mise à jour");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * update cinrecto
 * @param cin_recto
 * @param user_code_id
 */
export async function updateCinRecto(cin_recto, user_code_id) {
  const frmData = new FormData();
  frmData.append("user_code_id", user_code_id);
  frmData.append("cin_recto", cin_recto);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.put(api_url + "personnel/cinrecto", frmData, options);
    toast.success("mise à jour");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * update cinverso
 * @param cin_verso
 * @param user_code_id
 */
export async function updateCinVerso(cin_verso, user_code_id) {
  const frmData = new FormData();
  frmData.append("user_code_id", user_code_id);
  frmData.append("cin_verso", cin_verso);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.put(api_url + "personnel/cinverso", frmData, options);
    toast.success("mise à jour");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
