import React from "react";
import BaremeIrpp from "../BaremeIrpp/BaremeIrpp";
import LignePaie from "../LignePaie/LignePaie";

function Baremes() {
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Barèmes</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              {/* <a href="#" className="btn btn-primary" data-toggle="modal" data-target="#addclientmodal">
                <i className="feather feather-plus fs-15 my-auto mr-2" />
                Add New Client
              </a> */}
              <a href="#" className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                {" "}
                <i className="feather feather-mail" />{" "}
              </a>
              <a href="#" className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                {" "}
                <i className="feather feather-phone-call" />{" "}
              </a>
              <a href="#" className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                {" "}
                <i className="feather feather-info" />{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="tab-menu-heading hremp-tabs p-0 ">
            <div className="tabs-menu1">
              {/* Tabs */}
              <ul className="nav panel-tabs">
                <li className="ml-4">
                  <a href="#tab5" className="active" data-toggle="tab">
                    Barème IRP
                  </a>
                </li>
                <li>
                  <a href="#tab6" data-toggle="tab">
                    Ligne de paie
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="panel-body tabs-menu-body hremp-tabs1 p-0">
            <div className="tab-content">
              <div className="tab-pane active" id="tab5">
                <div className="card-body">
                  <BaremeIrpp></BaremeIrpp>
                </div>
              </div>
              <div className="tab-pane" id="tab6">
                <div className="card-body">
                  <LignePaie></LignePaie>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Baremes;
