import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getSalaireByUserId } from "../../Services/Pointeuse/salaireApi";
import { sumObjectif } from "../../Utils/ObjectifUtils";
import CopieContent from "../Exports/CopieContent";
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";

function ListVirementUser({ userId }) {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(100);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [currentYear, setcurrentYear] = useState(new Date().getFullYear());
  const [thisMonth, setthisMonth] = useState(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);

  useEffect(() => {
    getData();
  }, [userId]);

  const getData = () => {
    getSalaireByUserId(userId)
      .then((res) => {
        setallInitialData(res.data);
        setdata(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };

  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  /**
   * It gets all the pointages for a given date.
   * @param event - The event object that contains the target.
   */
  const handleChangeDate = (value) => {
    setcurrentYear(value);
    const newFilteredData = allInitialData.filter((el) => {
      return el.annee == value;
    });
    setdata(newFilteredData);
  };

  /**
   * The function takes in a page of items and sets the currentData state to that page of items
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    setcurrentData(pageOfItems);
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Pointage du mois")}</h4>
              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")} : </label>
                </div>
                <div>
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-check-circle text-danger" /> {t("Total salaire")}
                  </span>
                </div>
              </div>

              <div className="table-responsive">
                <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <div className="dataTables_length" id="hr-table_length">
                        <label>
                          {t("Afficher")}{" "}
                          <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                            <option value={8}>8</option>
                            <option value={20}>20</option>
                            <option value={40}>40</option>
                            <option value={60}>60</option>
                            <option value={100}>100</option>
                          </select>{" "}
                          {t("entrées")}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div id="hr-table_filter" className="dataTables_filter">
                        <label className="mr-2">
                          <input
                            type="number"
                            min="1900"
                            max="2099"
                            step="1"
                            value={currentYear}
                            onChange={(event) => handleChangeDate(event.target.value)}
                            className="form-control"
                            placeholder=""
                            aria-controls="hr-table"
                          />
                        </label>
                        {/* {settings && user && (
                          <ExportCsv
                            data={exportDataPointage(data, settings?.breakTime, user)}
                            name={`Pointage du mois ${thisMonth}_${user?.nom} ${user?.prenom}`}
                          />
                        )}
                        <ExportPdf
                          data={exportDataPointage(data, settings?.breakTime)}
                          name={`Pointage du mois ${thisMonth}_${user?.nom} ${user?.prenom}`}
                          columns={["date", "pointage d'entrée", "pointage de sortie", "heure de travail", "heure de travail avec pause"]}
                        /> */}
                        <CopieContent />
                      </div>
                    </div>
                  </div>

                  {loading && <CustomSyncLoader></CustomSyncLoader>}
                  {!loading && error && <ErrorCustum></ErrorCustum>}
                  {!loading && !error && data && (
                    <>
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                            id="hr-table"
                            role="grid"
                            aria-describedby="hr-table_info"
                          >
                            <thead>
                              <tr>
                                <th>{t("code")}</th>
                                <th>
                                  {t("salaire")} <span className="badge badge-pill badge-danger">{sumObjectif(data, "salaire")}</span>
                                </th>
                                <th>{t("cin")}</th>
                                <th>{t("rib")}</th>
                                <th>{t("banque")}</th>
                                <th>{t("mois")} </th>
                                <th>{t("annee")} </th>
                                <th>{t("valide")} </th>
                                <th>{t("num_banque")} </th>
                                <th>{t("créé à")} </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((el) => (
                                <tr key={el.id}>
                                  <td>{el.code}</td>
                                  <td>{el.salaire}</td>
                                  <td>{el.cin}</td>
                                  <td>{el.rib}</td>
                                  <td>{el.banque}</td>
                                  <td>{el.mois}</td>
                                  <td>{el.annee}</td>
                                  <td>{el.valide}</td>
                                  <td>{el.num_banque}</td>
                                  <td>{el.created_at}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                            {t("Affichage de 1 à")} {size} {t("sur")} {data.length} {t("entrées")}
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* leflet */}
      </div>
    </>
  );
}

export default ListVirementUser;
