import React, { useEffect, useState } from "react";
import { getClientById, getFacturationClientByCode } from "../../../Services/Facturation/FacturationClientApi";
import { editerEncaissementById, getEncaissementById } from "../../../Services/Facturation/FacturationEncaissementApi";
import { editSoldeEnteteByCode, getEnteteByClientCode } from "../../../Services/Facturation/FacturationEnteteApi";
import Select from "react-select";
import { ToastContainer } from "react-toastify";

function EditEncaissement(props) {
  const [dataFournisseur, setDataFournisseur] = useState([]);
  const [fournisseurFullName, setFournisseurFullName] = useState("");
  const [fournisseurCode, setFournisseurCode] = useState("");
  const [dataFacturation, setDataFacturation] = useState([]);
  const [factureLabel, setfactureLabel] = useState("");
  const [factureCode, setfactureCode] = useState("");
  const [factureNum, setfactureNum] = useState("");
  const [banque, setbanque] = useState("");
  const [modePaiement, setmodePaiement] = useState("");
  const [numPaiement, setnumPaiement] = useState("");
  const [referenceBanque, setreferenceBanque] = useState("");
  const [montant, setmontant] = useState("");
  const [dateReception, setdateReception] = useState("");
  const [dateVersement, setdateVersement] = useState("");
  const [dateEcheance, setdateEcheance] = useState("");
  const [exercice, setexercice] = useState("");
  const [commentaire, setcommentaire] = useState("");
  const [ttcFacture, setttcFacture] = useState("");
  const [validation, setvalidation] = useState("");

  useEffect(() => {
    getEncaissementById(props.match.params.id).then((res) => {
      setbanque(res.data.banque);
      setnumPaiement(res.data.numero_piece);
      setmontant(res.data.montant);
      setdateEcheance(res.data.date_echeance);
      setdateReception(res.data.date_reception);
      setdateVersement(res.data.date_versement);
      setcommentaire(res.data.commentaire);
      setreferenceBanque(res.data.reference_banque);
      setmodePaiement(res.data.mode_paiement);
      setFournisseurFullName(res.data.nom + " " + res.data.prenom);
      setfactureLabel(res.data.exercice + "_" + res.data.num_facture + "_" + res.data.montant_total_ttc);
      setvalidation(res.data.validation);
      setexercice(res.data.exercice);
      setfactureCode(res.data.code_facture);
      setfactureNum(res.data.num_facture);
      setFournisseurCode(res.data.client_code);
    });
  }, []);
  useEffect(() => {
    getFacturationClientByCode().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.code,
        label: d.nom + " " + d.prenom,
        secondValue: d.id,
      }));

      setDataFournisseur(options);
    });
  }, []);
  const handleChangeFournisseur = (event) => {
    setFournisseurCode(event.value);
    setFournisseurFullName(event.label);
    // if client selected get
    // get client by id
    getClientById(event.secondValue).then((res) => {
      getEnteteByClientCode(event.value).then((res) => {
        console.log(res);
        const result = res.data;
        console.log(result);
        const options = result.map((d) => ({
          value: d.code,
          label: d.date.split("-")[0] + "_" + d.facture_code + "_" + d.montant_total_ttc,
          dateValue: d.date.split("-")[0],
          ttcValue: d.montant_total_ttc,
          numfactureValue: d.facture_code,
        }));

        setDataFacturation(options);
      });
    });
  };

  const handleChangeFacture = (event) => {
    setfactureLabel(event.label);
    setfactureCode(event.value);
    console.log(event.numfactureValue);
    setfactureNum(event.numfactureValue);
    setexercice(event.dateValue);
    setttcFacture(event.ttcValue);
  };
  const handleSubmitEncaissement = (e) => {
    e.preventDefault();

    editerEncaissementById(
      banque,
      modePaiement,
      numPaiement,
      referenceBanque,
      montant,
      dateReception,
      dateVersement,
      dateEcheance,
      fournisseurCode,
      factureNum,
      factureCode,
      exercice,
      commentaire,
      "",
      props.match.params.id
    ).then(() => {
      //change if solde changed
      //editSoldeEnteteByCode(montant, factureCode).then(() => {});
    });
  };
  return (
    <div className="col-xl-12 col-md-12 col-lg-12 mt-4">
      <div className="card">
        <div className="card-header  border-0">
          <h4 className="card-title">Editer encaissement</h4>
        </div>
        <div className="card-body">
          <div className="container">
            <form onSubmit={handleSubmitEncaissement}>
              <div className="row">
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group">
                    <label>Banque</label>
                    <input disabled type="text" className="form-control" value={banque} onChange={(e) => setbanque(e.target.value)} />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group">
                    <label>Client : {fournisseurFullName}</label>
                    <Select onChange={handleChangeFournisseur} options={dataFournisseur} />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group">
                    <label>Facture : {factureLabel}</label>
                    <Select onChange={handleChangeFacture} options={dataFacturation} />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group">
                    <label>Reference</label>
                    <input type="text" className="form-control" value={referenceBanque} onChange={(e) => setreferenceBanque(e.target.value)} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group">
                    <label>Mode de paiement</label>
                    <select className="form-control" value={modePaiement} onChange={(e) => setmodePaiement(e.target.value)}>
                      <option value="cheque">Chèque</option>
                      <option value="virement">Virement</option>
                      <option value="traite">Traite</option>
                      <option value="espece">Espèce</option>
                      <option value="rs">RS</option>
                      <option value="autre">Autre</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Numéro paiement</label>
                    <input type="text" className="form-control" value={numPaiement} onChange={(e) => setnumPaiement(e.target.value)} />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Montant</label>
                    <input type="text" className="form-control" value={montant} onChange={(e) => setmontant(e.target.value)} />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Note</label>
                    <input type="text" className="form-control" value={commentaire} onChange={(e) => setcommentaire(e.target.value)} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Date versement</label>
                    <input type="date" className="form-control" value={dateVersement} onChange={(e) => setdateVersement(e.target.value)} />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Date d'échéance</label>
                    <input type="date" className="form-control" value={dateEcheance} onChange={(e) => setdateEcheance(e.target.value)} />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Date de réception</label>
                    <input type="date" className="form-control" value={dateReception} onChange={(e) => setdateReception(e.target.value)} />
                  </div>
                </div>
              </div>
              {validation == 0 && (
                <button type="submit" className="btn btn-primary">
                  Editer
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditEncaissement;
