import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import { deleteSalaireByCode, getAllSalaires, updateNumBanque } from "../../Services/Pointeuse/salaireApi";
import { dateBetweenTwoDates, getFullMonthName } from "../../Utils/DateUtils";
import { groupByCodeSalaire } from "../../Utils/GroupeUtils";
import { sumSalaire, sumVirement } from "../../Utils/ObjectOperation";
import { filterCongeByWord, filterVirementByCode } from "../../Utils/SortObject";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import swal from "sweetalert";
import { Button, Modal } from "react-bootstrap";

function ListSalaire() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [dateStart, setdateStart] = useState("");
  const [total, setTotal] = useState(null);
  const [size, setsize] = useState(8);
  const [show, setShow] = useState(false);
  const [salaire, setsalaire] = useState(null);
  const [numBanque, setnumBanque] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (vir) => {
    setsalaire(vir);
    setShow(true);
    setnumBanque(vir.num_banque);
  };

  useEffect(() => {
    getData();
  }, []);

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data based on the search input.
   * @param e - The event object that contains the search value.
   */
  const handleSearch = (e) => {
    if (e) {
      const dataSearch = filterVirementByCode(allInitialData, e);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  /**
   * It filters the data by the month and year selected.
   * @param value - The value of the input.
   */
  const handleSearchBydate = (value) => {
    // const filtredDate = dateBetweenTwoDates(dateStart, dateEnd, allInitialData);
    // setdata(filtredDate);
    const newArray = allInitialData.filter((el, idx) => {
      return el.mois == value.split("-")[1] && el.annee == value.split("-")[0];
    });
    setdata(newArray);
  };

  /**
   * It gets all the salaires from the API and then groups them by code salaire.
   */
  const getData = () => {
    setLoading(true);
    getAllSalaires()
      .then((res) => {
        console.log(res.data);
        setTotal(sumVirement(res.data));
        const groupByList = groupByCodeSalaire(res.data);
        console.log(groupByList);
        setdata(groupByList);
        setallInitialData(groupByList);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  /**
   * It deletes a record from the database.
   * @param id - The id of the record to delete.
   */
  const handleDelete = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteSalaireByCode(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          const updatedData = data.filter((item) => item.code != id);
          setdata(updatedData);
        });
      }
    });
  };

  /**
   * The function sets the size of the data to be retrieved from the API.
   * @param e - The event object that contains the data we want to use.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  const hancleUpdateVirement = () => {
    if (salaire.valide == "1") {
      updateNumBanque("", "0", salaire.code).then(() => {
        setShow(false);
        getData();
      });
    } else {
      if (numBanque) {
        updateNumBanque(numBanque, "1", salaire.code).then(() => {
          setShow(false);
          getData();
        });
      } else {
        alert("ajouter numéro de banque");
      }
    }
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Virement salaire</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to={"/admin/virementsalaire"} className="btn btn-primary" data-toggle="modal" data-target="#addclientmodal">
                <i className="feather feather-plus fs-15 my-auto mr-2" />
                Ajouter virement
              </Link>
              <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                {" "}
                <i className="feather feather-mail" />{" "}
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                {" "}
                <i className="feather feather-phone-call" />{" "}
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                {" "}
                <i className="feather feather-info" />{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-header  border-0">
              <h4 className="card-title">List virements</h4>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">Note:</label>
                </div>
                <div>
                  {/* <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> ---&gt; Présent
                  </span> */}
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> total
                  </span>
                  {/* <span className="badge badge-warning-light mr-2">
                    <i className="fa fa-star text-warning" /> ---&gt; Holiday
                  </span>
                  <span className="badge badge-orange-light mr-2">
                    <i className="fa fa-adjust text-orange" /> ---&gt; Half Day
                  </span> */}
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            Afficher{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            entrées
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Recherche..."
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <Link to="#" className="action-btns" data-tip="télécharger">
                            <i className="feather feather-download  text-secondary" />
                          </Link>

                          <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0 w-5">Code</th>
                              <th className="border-bottom-0">Mois</th>
                              <th className="border-bottom-0">Année</th>
                              <th className="border-bottom-0">Numéro banque</th>
                              <th className="border-bottom-0">
                                Total
                                <span className="badge badge-pill badge-danger ml-2">{total}</span>
                              </th>
                              <th className="text-right"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el, idx) => (
                              <tr key={el.id}>
                                <td>
                                  <Link to="#"> {el.code}</Link>
                                </td>
                                {/* <td>{currentData[idx]?.virements[idx]?.mois}</td> */}

                                <td>{getFullMonthName(el.mois)}</td>
                                <td>{el.annee}</td>
                                <td>{el.num_banque}</td>
                                <td>{sumSalaire(currentData[idx]?.virements)}</td>
                                <td className="text-left">
                                  {el.valide == "1" ? (
                                    <i
                                      className="feather feather-x text-secondry m-4"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Réactiver virement"
                                      onClick={() => handleShow(el)}
                                    ></i>
                                  ) : (
                                    <i
                                      className="feather feather-check text-success m-4"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Confirmer virement"
                                      onClick={() => handleShow(el)}
                                    ></i>
                                  )}
                                  {el?.valide == "0" && (
                                    <Link to={"/admin/salaire/" + el.code} className="action-btns" data-tip="editer">
                                      <i
                                        className="feather feather-edit text-info"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Editer virement"
                                      />
                                    </Link>
                                  )}

                                  <Link to="#" className="action-btns" data-tip="supprimer" onClick={() => handleDelete(el.code)}>
                                    <i
                                      className="feather feather-x text-danger"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Supprimer virement"
                                    />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          Affichage de 1 à {size} sur {data.length} entrées
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Virement : {`${getFullMonthName(salaire?.mois)} ${salaire?.annee}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="form-label">Numéro de banque</label>
            <input
              required
              disabled={salaire?.num_banque ? true : false}
              value={numBanque}
              type="text"
              className="form-control"
              onChange={(e) => setnumBanque(e.target.value)}
            />
          </div>
          {salaire?.num_banque && <small className="text-danger">si vous réactiver le virement, le numéro de banque sera écrasé . </small>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={() => hancleUpdateVirement()}>
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ListSalaire;
