import React, { useEffect, useState } from "react";
import { editUserProfile, getUserById } from "../../Services/Pointeuse/UsersApi";
import ImageComponent from "../Styles/ImageComponent";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import CardPerso from "./CardPerso";
import { getRetardByUserId } from "../../Services/Pointeuse/RetardApi";
import { getPointageByUserId } from "../../Services/Pointeuse/PointageApi";
import { getAutorisationByUserId } from "../../Services/Pointeuse/AutorisationApi";
import { getCongeByUserCodeId, getCountCongeByUserCodeId } from "../../Services/Pointeuse/CongeApi";
import { filterTodayPointage, filterYearAutorisation, filterYearDeplacement, filterYearQuestionnaire } from "../../Utils/userDetaiUtils";
import { getQuestionnaireByUserId } from "../../Services/Pointeuse/QuestionnaireApi";
import { getDepalacementByUserId } from "../../Services/Pointeuse/DeplacementApi";
import RetardChart from "../Charts/CustumRecharts/AreaChartRecharts";
import RetardRechartsChart from "../Charts/CustumRecharts/AreaChartRecharts";
import A from "../Charts/CustumApex/BarChartApex";
import CongeRechartChart from "../Charts/CustumRecharts/BarChartRecharts";
import CardDocument from "./CardDocument";
import BarChartApex from "../Charts/CustumApex/BarChartApex";
import AreaChartApex from "../Charts/CustumApex/AreaChartApex";
import ApexBar2 from "../Charts/apexcharts/Bar2";
import AreaApexConge from "../Charts/CustumApex/AreaApexConge";
import AddContrat from "../Contrat/AddContrat";
import CardOtherDocument from "./CardOtherDocument";
import AddNewUserTime from "../UserTime/AddNewUserTime";
import PointageTableMonth from "../Pointages/PointageTableMonth";
import ListVirementUser from "../Virement/ListVirementUser";
import { Button, Modal } from "react-bootstrap";
import { addNumberToDate } from "../../Utils/DateUtils";

function EditUser(props) {
  const [birthDate, setbirthDate] = useState("");
  const [codeGenerated, setcodeGenerated] = useState("");
  const [email, setemail] = useState("");
  const [fonction, setfonction] = useState("");
  const [id, setid] = useState(null);
  const [isactif, setisactif] = useState("");
  const [mobile, setmobile] = useState("");
  const [nom, setnom] = useState("");
  const [prenom, setprenom] = useState("");
  const [roleCode, setroleCode] = useState("");
  const [soldeConge, setsoldeConge] = useState("");
  const [img, setimg] = useState("");

  const [retards, setretards] = useState(null);
  const [pointages, setpointages] = useState(null);
  const [conges, setConges] = useState(null);
  const [deplacements, setdeplacements] = useState(null);
  const [autorisations, setautorisation] = useState(null);
  const [questionnaire, setquestionnaire] = useState(null);
  const [contConge, setcontConge] = useState("0");
  const [totalAutorisation, settotalAutorisation] = useState(null);
  const [totalQuestionnaire, settotalQuestionnaire] = useState(null);
  const [totalConge, settotalConge] = useState(null);
  const [totalDeplacement, settotalDeplacement] = useState(null);
  const [coeffConge, setcoeffConge] = useState(null);
  const [user, setuser] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getUserById(props.match.params.id).then((res) => {
      setimg(res.data.img);

      setbirthDate(res.data.birth_date);
      setcodeGenerated(res.data.code_generated);
      setemail(res.data.email);
      setfonction(res.data.fonction);
      setid(res.data.id);
      setisactif(res.data.isactif);
      setmobile(res.data.mobile);
      setnom(res.data.nom);
      setprenom(res.data.prenom);
      setroleCode(res.data.role_code);
      setsoldeConge(res.data.solde_conge);
      setcoeffConge(res.data.coefficient_conge);
      setuser(res.data);
    });
  }, []);

  useEffect(() => {
    getRetardByUserId(id).then((res) => {
      setretards(res.data);
    });
  }, [id]);
  useEffect(() => {
    getPointageByUserId(id).then((res) => {
      var todayPointage = filterTodayPointage(res.data);
      setpointages(todayPointage);
    });
  }, [id]);

  useEffect(() => {
    getCongeByUserCodeId(id).then((res) => {
      settotalConge(res.data);
      var yearAutorisation = filterYearAutorisation(res.data);
      console.log(yearAutorisation);
      setConges(yearAutorisation);
    });
  }, [id]);

  useEffect(() => {
    getAutorisationByUserId(id).then((res) => {
      settotalAutorisation(res.data);
      var yearAutorisation = filterYearAutorisation(res.data);
      setautorisation(yearAutorisation);
    });
  }, [id]);
  useEffect(() => {
    getDepalacementByUserId(id).then((res) => {
      settotalDeplacement(res.data);
      var yearDeplacement = filterYearDeplacement(res.data);
      setdeplacements(yearDeplacement);
    });
  }, [id]);

  useEffect(() => {
    getQuestionnaireByUserId(id).then((res) => {
      settotalQuestionnaire(res.data);
      var yearQuestionnaire = filterYearQuestionnaire(res.data);
      setquestionnaire(yearQuestionnaire);
    });
  }, [id]);
  useEffect(() => {
    getCountCongeByUserCodeId(id).then((res) => {
      console.log(res);
      var key = Object.entries(res.data[0]);
      key[0][1] && setcontConge(key[0][1]);
    });
  }, [id]);

  /**
   * It modifies the user profile.
   */
  const handleSubmitFom = () => {
    swal({
      title: "Modification",
      text: "Voulez vous vraiment modifier cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        editUserProfile(props.match.params.id, nom, prenom, fonction, mobile, coeffConge).then((res) => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          //getData();
        });
      }
    });
  };
  return (
    <div>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Profile</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <a href="#" className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                {" "}
                <i className="feather feather-mail" />{" "}
              </a>
              <a href="#" className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                {" "}
                <i className="feather feather-phone-call" />{" "}
              </a>
              <a href="#" className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                {" "}
                <i className="feather feather-info" />{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-3 col-md-12 col-lg-12">
          <div className="card user-pro-list overflow-hidden">
            <div className="card-body">
              <div className="user-pic text-center">
                <ImageComponent atr={"avatar avatar-xxl brround"} picture={img}></ImageComponent>
                {/* <span className="avatar-Statut bg-green" /> */}

                <div className="pro-user mt-3">
                  <h5 className="pro-user-username text-dark mb-1 fs-16">
                    {nom} {prenom}
                  </h5>
                  <h6 className="pro-user-desc text-muted fs-12">{email}</h6>
                  {/* <div className="star-ratings start-ratings-main mb-3 clearfix">
                    <div className="stars stars-example-fontawesome star-sm">
                      <select id="example-fontawesome" name="rating">
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4} selected>
                          4
                        </option>
                        <option value={5}>5</option>
                      </select>
                    </div>
                  </div> */}
                  <div className="btn-list">
                    {/* <a href="#" className="btn btn-light">
                      {" "}
                      <i className="feather feather-edit" />{" "}
                    </a> */}
                    <a href="#" className="btn btn-light">
                      {" "}
                      <i className="feather feather-mail" />{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer p-0">
              <div className="row">
                <Link to="/admin/questionnaire" className="col-4 text-center py-5 border-right">
                  <h5 className="fs-12 font-weight-semibold mb-3">Cette année</h5>
                  <h5 className="mb-2">
                    <span className="fs-18 text-success">{questionnaire?.length}</span>
                  </h5>
                  <h5 className="fs-12 mb-0"> Questionnaires</h5>
                </Link>
                <Link to="#" className="col-4  py-5 text-center border-right">
                  <h5 className="fs-12 font-weight-semibold mb-3">Cette année</h5>
                  <h5 className="mb-2">
                    <span className="fs-18 text-orange">{deplacements?.length}</span>
                  </h5>
                  <h5 className="fs-12 mb-0"> Déplacement</h5>
                </Link>
                <Link to="/admin/autorisation" className="col-4 text-center py-5">
                  <h5 className="fs-12 font-weight-semibold mb-3">Cette année</h5>
                  <h5 className="mb-2">
                    <span className="fs-18 text-primary">{autorisations?.length}</span>
                  </h5>
                  <h5 className="fs-12 mb-0"> Autorisation</h5>
                </Link>
              </div>
            </div>
          </div>
          <div className="card">
            {/* <div className="card-header  border-0">
              <div className="card-title">Basic Info</div>
            </div> */}
            <div className="card-body pt-0">
              <div className="table-responsive">
                <table className="table mb-0">
                  <tbody>
                    <tr>
                      <td className="py-2 px-0">
                        <span className="w-50">Collaborateur ID</span>
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        <span>#{id}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-0">
                        <span className="w-50">Désignation</span>
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        <span>{fonction}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-0">
                        <span className="w-50">Département</span>
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        <span>-</span>
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="py-2 px-0">
                        <span className="w-50">Join Date</span>
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        <span >{}</span>
                      </td>
                    </tr> */}
                    <tr>
                      <td className="py-2 px-0">
                        <span className="w-50">N° de téléphone</span>
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        <span>{mobile}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-0">
                        <span className="w-50">Statut</span>
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        {isactif == "1" ? (
                          <span className="badge badge-success">Active</span>
                        ) : (
                          <span className="badge badge-danger">Non active</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header  border-0">
              <div className="card-title">Congé {new Date().getFullYear() - 1}</div>
            </div>
            <div className="card-body">
              <AreaApexConge id={id}></AreaApexConge>
              {/* <div className="d-flex align-items-end justify-content-between mg-b-5">
                <h6>Tasks</h6>
                <h6 className="font-weight-bold mb-1">45%</h6>
              </div>
              <div className="progress progress-sm mb-5">
                <div className="progress-bar bg-danger w-10" />
              </div>
              <div className="d-flex align-items-end justify-content-between mg-b-5">
                <h6>Projects</h6>
                <h6 className="font-weight-bold mb-1">56%</h6>
              </div>
              <div className="progress progress-sm mb-5">
                <div className="progress-bar bg-info w-30" />
              </div>
              <div className="d-flex align-items-end justify-content-between mg-b-5">
                <h6>Over all Performance</h6>
                <h6 className="font-weight-bold mb-1">72%</h6>
              </div>
              <div className="progress progress-sm mb-0">
                <div className="progress-bar bg-warning w-50" />
              </div> */}
            </div>
          </div>

          {/* <div className="card">
            <div className="card-header  border-0">
              <div className="card-title">Statistics-2021</div>
            </div>
            <div className="card-body">
              <div className="row mb-7">
                <div className="col-4 text-center">
                  <div
                    className="chart-circle chart-circle-sm"
                    data-value="0.89"
                    data-thickness={5}
                    data-color="#3366ff"
                  >
                    <div className="chart-circle-value text-primary">89</div>
                  </div>
                  <h6 className="fs-14 font-weight-semibold mt-3">Attendance</h6>
                </div>
                <div className="col-4 text-center">
                  <div
                    className="chart-circle chart-circle-sm"
                    data-value="0.23"
                    data-thickness={5}
                    data-color="#fe7f00"
                  >
                    <div className="chart-circle-value text-secondary">23</div>
                  </div>
                  <h6 className="fs-14 font-weight-semibold mt-3">Projects</h6>
                </div>
                <div className="col-4 text-center">
                  <div
                    className="chart-circle chart-circle-sm"
                    data-value="0.67"
                    data-thickness={5}
                    data-color="#0dcd94"
                  >
                    <div className="chart-circle-value text-success">67%</div>
                  </div>
                  <h6 className="fs-14 font-weight-semibold mt-3">Performance</h6>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mg-b-5">
                <h6>This Week</h6>
                <h6 className="font-weight-bold mb-1">01</h6>
              </div>
              <div className="progress progress-sm mb-5">
                <div className="progress-bar bg-danger w-10" />
              </div>
              <div className="d-flex align-items-end justify-content-between mg-b-5">
                <h6>This Month</h6>
                <h6 className="font-weight-bold mb-1">05</h6>
              </div>
              <div className="progress progress-sm mb-5">
                <div className="progress-bar bg-info w-30" />
              </div>
              <div className="d-flex align-items-end justify-content-between mg-b-5">
                <h6>Cette année</h6>
                <h6 className="font-weight-bold mb-1">22</h6>
              </div>
              <div className="progress progress-sm mb-5">
                <div className="progress-bar bg-warning w-50" />
              </div>
            </div>
          </div> */}
        </div>
        <div className="col-xl-9 col-md-12 col-lg-12">
          <div className="row">
            <Link to="#" onClick={handleShow} className="col-xl-3 col-lg-6 col-md-12">
              <div className="card">
                <a href="#">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-8">
                        <div className="mt-0 text-left">
                          <span className="fs-16 font-weight-semibold">Total Congés</span>
                          <h3 className="mb-0 mt-1 text-danger fs-25">{totalConge?.length}</h3>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="icon1 bg-danger-transparent my-auto  float-right">
                          {" "}
                          <i className="feather feather-briefcase" />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Link>
            <Link to="/admin/autorisation" className="col-xl-3 col-lg-6 col-md-12">
              <div className="card">
                <a href="#">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-8">
                        <div className="mt-0 text-left">
                          <span className="fs-16 font-weight-semibold">Total Autorisations</span>
                          <h3 className="mb-0 mt-1 text-primary  fs-25">{totalAutorisation?.length}</h3>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="icon1 bg-primary-transparent my-auto  float-right">
                          {" "}
                          <i className="feather feather-clipboard" />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Link>
            <Link to="#" className="col-xl-3 col-lg-6 col-md-12">
              <div className="card">
                <a href="#">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-8">
                        <div className="mt-0 text-left">
                          <span className="fs-16 font-weight-semibold">Total Déplacement</span>
                          <h3 className="mb-0 mt-1 text-secondary fs-25">{totalDeplacement?.length}</h3>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="icon1 bg-secondary-transparent my-auto  float-right">
                          {" "}
                          <i className="feather feather-info" />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Link>
            <Link to="/admin/questionnaire" className="col-xl-3 col-lg-6 col-md-12">
              <div className="card">
                <a href="#">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-8">
                        <div className="mt-0 text-left">
                          <span className="fs-16 font-weight-semibold">Total Questionnaire</span>
                          <h3 className="mb-0 mt-1 text-success fs-25">{totalQuestionnaire?.length}</h3>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="icon1 bg-success-transparent my-auto  float-right">
                          {" "}
                          <i className="feather feather-check" />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Link>
          </div>

          <div className="row">
            <div className="col-xl-12 col-md-12 col-lg-12">
              <div className="tab-menu-heading hremp-tabs p-0 ">
                <div className="tabs-menu1">
                  {/* Tabs */}
                  <ul className="nav panel-tabs">
                    <li className="ml-4">
                      <a href="#tab5" className="active" data-toggle="tab">
                        Détails personnels
                      </a>
                    </li>

                    <li>
                      <a href="#tab7" data-toggle="tab">
                        Détails Collaborateur
                      </a>
                    </li>
                    <li>
                      <a href="#tab8" data-toggle="tab">
                        Documents
                      </a>
                    </li>
                    <li>
                      <a href="#tab9" data-toggle="tab">
                        Contrat
                      </a>
                    </li>
                    <li>
                      <a href="#tab6" data-toggle="tab">
                        Autre document
                      </a>
                    </li>
                    <li>
                      <a href="#tab1" data-toggle="tab">
                        Heure de travail
                      </a>
                    </li>
                    <li>
                      <a href="#tab13" data-toggle="tab">
                        Fiche du pointage
                      </a>
                    </li>
                    <li>
                      <a href="#tab14" data-toggle="tab">
                        Historique Salaire
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="panel-body tabs-menu-body hremp-tabs1 p-0">
                <div className="tab-content">
                  <div className="tab-pane active" id="tab5">
                    <CardPerso userId={props.match.params.id}></CardPerso>
                  </div>
                  <div className="tab-pane" id="tab6">
                    <div className="card-body">
                      <CardOtherDocument userId={props.match.params.id} />
                    </div>
                  </div>
                  <div className="tab-pane" id="tab7">
                    <div className="card-body">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">Nom</label>
                          </div>
                          <div className="col-md-9">
                            <input value={nom} onChange={(e) => setnom(e.target.value)} type="text" className="form-control" placeholder="Nom" />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">Prénom</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={prenom}
                              onChange={(e) => setprenom(e.target.value)}
                              type="text"
                              className="form-control"
                              placeholder="Prénom"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">Email</label>
                          </div>
                          <div className="col-md-9">
                            <input disabled value={email} type="email" className="form-control" placeholder="Email" />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">Fonction</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={fonction}
                              onChange={(e) => setfonction(e.target.value)}
                              type="text"
                              className="form-control"
                              placeholder="Fonction"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">Date de naissance</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={birthDate}
                              disabled
                              onChange={(e) => setbirthDate(e.target.value)}
                              type="text"
                              className="form-control"
                              placeholder="date de naissance"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">
                              Mobile
                              {/* <span
                                className="form-help"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Bank Identify Number in your Country"
                              >
                                ?
                              </span> */}
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={mobile}
                              onChange={(e) => setmobile(e.target.value)}
                              type="text"
                              className="form-control"
                              placeholder="Mobile"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">
                              Coefficient de congé
                              {/* <span
                                className="form-help"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Bank Identify Number in your Country"
                              >
                                ?
                              </span> */}
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={coeffConge}
                              onChange={(e) => setcoeffConge(e.target.value)}
                              type="text"
                              className="form-control"
                              placeholder="1.1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer text-right">
                        <Link to="#" className="btn btn-primary mr-2" onClick={handleSubmitFom}>
                          Enregistrer
                        </Link>
                        {/* <a href="#" className="btn btn-danger">
                          Cancle
                        </a> */}
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="tab8">
                    <CardDocument idUser={props.match.params.id}></CardDocument>
                  </div>
                  <div className="tab-pane" id="tab9">
                    <AddContrat idUser={props.match.params.id} />
                  </div>
                  <div className="tab-pane" id="tab1">
                    <AddNewUserTime idUser={props.match.params.id}></AddNewUserTime>
                  </div>
                  <div className="tab-pane" id="tab13">
                    {user && <PointageTableMonth user={user}></PointageTableMonth>}
                  </div>
                  <div className="tab-pane" id="tab14">
                    <ListVirementUser userId={props.match.params.id}></ListVirementUser>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Congé {new Date().getFullYear()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <table className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer">
              <thead>
                <tr>
                  <th className="border-bottom-0 w-5">motif congé</th>
                  <th className="border-bottom-0 w-5">De</th>
                  <th className="border-bottom-0 w-5">à</th>
                  <th className="border-bottom-0 w-4">Nb-J</th>
                </tr>
              </thead>
              <tbody>
                {conges &&
                  conges.map((el) => (
                    <tr key={el.id}>
                      <td>{el.motif}</td>

                      <td>{el.date.split(" ")[0]}</td>
                      <td>{addNumberToDate(el.nbr_jour, el.date)}</td>
                      <td>{el.nbr_jour}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditUser;
