import React, { useEffect, useRef, useState } from "react";
import { ajouterConge } from "../../Services/Pointeuse/CongeApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";

function AddConge(props) {
  const [libelle, setlibelle] = useState("");
  const [date, setdate] = useState("");
  const [nbjour, setnbjour] = useState("");
  const [type, settype] = useState("CP");
  const myRefDelete = useRef(null);
  const [users, setusers] = useState(null);
  const [userId, setuserId] = useState(null);
  const [motif, setmotif] = useState("CONGÉ PAYÉ");

  useEffect(() => {
    getUsersByCode().then((res) => {
      setusers(res.data);
    });
  }, []);

  /**
   * It adds a new conge to the database.
   */
  const handelSave = () => {
    ajouterConge(userId, nbjour, date, motif).then((res) => {
      console.log(res);
      myRefDelete.current.click();
      props.getData();
    });
  };
  return (
    <div
      className="modal fade"
      id="addCongeModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="addCongeModalolidayModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Ajouter Congé
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>
                  Collaborateur<span className="text-danger">*</span>
                </label>

                <select onChange={(e) => setuserId(e.target.value)} className="selectpicker form-control">
                  <option value="0">Choisir un collaborateur</option>
                  {users &&
                    users.map((el, idx) => (
                      <option key={idx} value={el.id}>
                        {el.nom} {el.prenom}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail4">Date</label>
                <input type="date" className="form-control" onChange={(e) => setdate(e.target.value)} />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputPassword4">Nombre des jours</label>
                <input type="number" className="form-control" onChange={(e) => setnbjour(e.target.value)} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>
                  Motif<span className="text-danger">*</span>
                </label>

                <select onChange={(e) => setmotif(e.target.value)} className="selectpicker form-control">
                  <option value={"CONGÉ PAYÉ"}>CONGÉ PAYÉ</option>
                  <option value={"CONGÉ SANS SOLDE"}>CONGÉ SANS SOLDE</option>
                  <option value={"CONGÉ ANNUEL"}>CONGÉ ANNUEL</option>
                  <option value={"CONGÉ D'EXAMEN"}>CONGÉ D'EXAMEN</option>
                  <option value={"CONGÉ INDIVIDUEL DE FORMATION"}>CONGÉ INDIVIDUEL DE FORMATION</option>
                  <option value={"CONGÉ FORMATION ÉCONOMIQUE, SOCIALE ET SYNDICALE"}>
                    CONGÉ FORMATION ÉCONOMIQUE, SOCIALE ET SYNDICALE
                  </option>
                  <option value={"CONGÉ D’ENSEIGNEMENT ET DE RECHERCHE"}>CONGÉ D’ENSEIGNEMENT ET DE RECHERCHE</option>
                  <option value={"CONGÉ MALADIE"}>CONGÉ MALADIE</option>
                  <option value={"CONGÉ MATERNITÉ"}>CONGÉ MATERNITÉ</option>
                  <option value={"CONGÉ PATERNITÉ"}>CONGÉ PATERNITÉ</option>
                  <option value={"CONGÉ POUR CATASTROPHE NATURELLE"}>CONGÉ POUR CATASTROPHE NATURELLE</option>
                  <option value={"CONGÉ SABBATIQUE"}>CONGÉ SABBATIQUE</option>
                  <option value={"CONGÉ POUR RAISON FAMILIALE"}>CONGÉ POUR RAISON FAMILIALE</option>
                  <option value={"Autre"}>Autre</option>
                </select>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={myRefDelete}>
              Annuler
            </button>
            <button type="button" className="btn btn-primary" onClick={handelSave}>
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddConge;
