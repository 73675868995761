import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getDocumentTypeByCode } from "../../Services/Pointeuse/DocumentTypeApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import {
  addDocumentUser,
  getDocumentUserByUserId,
  deleteDocumentUserById,
} from "../../Services/Pointeuse/DocumentUserApi";
import { useAlert } from "react-alert";
import * as FileSaver from "file-saver";
import swal from "sweetalert";

function CardOtherDocument({ userId }) {
  const alert = useAlert();
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setimageName] = useState("Choisir un document ...");
  const [documents, setdocuments] = useState(null);
  const [documentId, setdocumentId] = useState(null);
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    getDocumentTypeByCode().then((res) => {
      const result = res.data;
      const sortActifUser = result.sort((a, b) => b.isactif - a.isactif);
      setdocuments(sortActifUser);
    });
  }, []);

  /**
   * It gets the data from the API and sets the data to the state.
   */
  const getData = () => {
    setLoading(true);
    getDocumentUserByUserId(userId)
      .then((res) => {
        console.log(res);
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  /**
   * It sets the imageUrl to the image selected by the user.
   * @param event - The event that triggered the function.
   */
  const handleUploadFiles = (event) => {
    //setFilesToUpload(URL.createObjectURL(event.target.files[0]));
    setImageUrl(event.target.files[0]);
    setimageName(event.target.files[0].name);
  };
  /**
   * It adds a document to the user.
   */

  const handleSave = () => {
    if (documentId) {
      console.log(userId, documentId, imageUrl);
      addDocumentUser(userId, documentId, imageUrl).then((res) => {
        getData();
        alert.success("ajouter avec succes");
      });
    } else {
      alert.error("vérifié choisir un type document");
    }
  };

  /**
   * It saves the file to the local machine.
   * @param file - The file to download.
   */
  const saveFile = (file) => {
    FileSaver.saveAs(process.env.REACT_APP_API + file, file.split("Z")[file.length - 1]);
  };

  /**
   * It deletes a document from the database.
   * @param id - The id of the document you want to delete.
   */
  const handleDelete = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteDocumentUserById(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  return (
    <div className="table-responsive">
      {/* <Link to="#"className="btn btn-primary btn-tableview">
        Upload Files
      </Link> */}

      <div class="">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-lg-12">
            <div className="card">
              <div className="row">
                <div class="col-xl-4 col-md-12 col-lg-12">
                  <div className="card-header  border-0">
                    <h4 className="card-title">Ajouter document</h4>
                  </div>
                  <div className="card-body">
                    <div className="leave-types">
                      <div className="form-group">
                        {/* <label className="form-label">Type Documents</label> */}
                        <select onChange={(e) => setdocumentId(e.target.value)} className="selectpicker form-control">
                          <option value="0">Choisir Type documents</option>
                          {documents &&
                            documents.map((el, idx) => (
                              <option key={idx} value={el.id}>
                                {el.nom}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="leave-content active" id="single">
                        <div className="form-group">
                          <label className="form-label">Choisir un fichier :</label>
                          <div className="input-group file-browser">
                            <input
                              type="text"
                              className="form-control browse-file"
                              placeholder={imageName || "choisir un fichier ..."}
                            />
                            <label className="input-group-append">
                              <span className="btn btn-primary">
                                choisir fichier{" "}
                                <input type="file" style={{ display: "none" }} onChange={handleUploadFiles} />
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex">
                      {/* <div>
                    <label className="mb-0 font-weight-semibold text-dark">Selected Days:</label>
                    <span className="badge badge-danger badge-pill ml-2">2</span>
                  </div> */}
                      <div className="ml-auto">
                        <Link to="/admin/configuration" className="btn btn-outline-primary mr-2">
                          ajouter un type
                        </Link>
                        <Link to="#" className="btn btn-primary" onClick={handleSave}>
                          Ajouter
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-8 col-md-12 col-lg-12">
                  {loading && <CustomSyncLoader></CustomSyncLoader>}
                  {!loading && error && <ErrorCustum></ErrorCustum>}
                  {!loading && !error && data && (
                    <table
                      className="table  table-vcenter text-nowrap table-bordered border-bottom border-top"
                      id="files-tables"
                    >
                      <thead>
                        <tr>
                          <th className="border-bottom-0 text-center w-5">#</th>
                          <th className="border-bottom-0">Document</th>
                          <th className="border-bottom-0">Categorie</th>
                          <th className="border-bottom-0">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((el, idx) => (
                          <tr key={idx}>
                            <td className="text-center">{el.id}</td>
                            <td>
                              <small className="text-muted">{el.nom}</small>
                              <div className="clearfix" />
                              <Link to="#" className="font-weight-semibold fs-14 mt-5">
                                {el.file.split("Z")[1]}
                              </Link>
                              <div className="clearfix" />
                              <span className="text-muted ml-2">({el.createdAt})</span>
                            </td>
                            <td>
                              {el.categorie === "recrutement" && <span>Supports Processus de recrutement</span>}
                              {el.categorie === "civil" && <span>Supports Etat Civil</span>}
                              {el.categorie === "carriere" && <span>Supports Carrière /Développement</span>}
                              {el.categorie === "mouvement" && <span>Supports Mouvements du personnel</span>}
                              {el.categorie === "medical" && <span>Supports Dossier Médical</span>}
                            </td>
                            <td>
                              <div className="d-flex">
                                {/* <Link
                                  to="#"
                                  className="action-btns1"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="View"
                                >
                                  <i className="feather feather-eye  text-primary" />
                                </Link> */}
                                <Link
                                  to="#"
                                  className="action-btns1"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Télécharcher"
                                  onClick={() => saveFile(el.file)}
                                >
                                  <i className="feather feather-download   text-success" />
                                </Link>
                                <Link
                                  to="#"
                                  className="action-btns1"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Supprimer"
                                  onClick={() => handleDelete(el.id)}
                                >
                                  <i className="feather feather-trash-2 text-danger" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="form-row">
        <div className="form-group col-md-12">
          <label>
            Collaborateur<span className="text-danger">*</span>
          </label>

          <select onChange={(e) => setdocumentId(e.target.value)} className="selectpicker form-control">
            <option value="0">Choisir un collaborateur</option>
            {documents &&
              documents.map((el, idx) => (
                <option key={idx} value={el.id}>
                  {el.nom}
                </option>
              ))}
          </select>
        </div>
      </div> */}
      {/* <div className="form-row">
        <div className="input-group file-browser">
          <input type="text" className="form-control browse-file" placeholder={imageName || "choisir un fichier ..."} />
          <label className="input-group-append">
            <span className="btn btn-primary">
              choisir fichier <input type="file" style={{ display: "none" }} onChange={handleUploadFiles} />
            </span>
          </label>
        </div>
        <small className="form-text text-muted  mb-5">
          Documents autorisés: jpg, jpeg, png, pdf. Maximum 1 fichier.
        </small>
      </div> */}
    </div>
  );
}

export default CardOtherDocument;
