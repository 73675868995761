import React, { useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ajouterMarque } from "../../../Services/Facturation/FacturationMarqueApi";

function AddMarque({ getData }) {
  const refHideModal = useRef();
  const [plafond, setPlafond] = useState("");
  const [libelle, setlibelle] = useState("");

  /**
   * It adds a new marque to the database.
   */
  const handleSubmit = () => {
    if (libelle == "") {
      toast.error("nom vide");
    } else if (plafond == "") {
      toast.error("plafond vide");
    } else if (plafond !== "" && isNaN(plafond)) {
      toast.error("plafond doit etre un nombre");
    } else {
      ajouterMarque(libelle, plafond).then((res) => {
        getData();
        refHideModal.current.click();
        // history.push("/facturation/marque")
      });
    }
  };

  return (
    <div
      className="modal fade"
      id="marqueModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="marqueModalTitle"
      aria-hidden="true"
    >
      <ToastContainer></ToastContainer>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Ajouter marque
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Nom </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="..."
                  onChange={(e) => setlibelle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Plafond crédit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="..."
                  onChange={(e) => setPlafond(e.target.value)}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={refHideModal}>
              Annuler
            </button>
            <button onClick={handleSubmit} className="btn btn-primary">
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMarque;
