import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all the deplacement for a user
 * @param userid - The user's ID.
 * @returns The response is an array of objects.
 */
export async function getDepalacementByUserId(userid) {
  try {
    const response = await axios.get(api_url + "deplacement/usercode/" + userid, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getAllDeplacementsByCode() {
  try {
    const response = await axios.get(api_url + "deplacement/codeimg/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
