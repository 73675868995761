import { Howl } from "howler";
import { getUserById } from "../../Services/Pointeuse/UsersApi";
import { getSession, isLogin } from "../../Utils/SessionUtils";
import ImageComponent from "../Styles/ImageComponent";
import socket from "../../Connection/ws";
import toast from "react-hot-toast";

/* Used to play a sound when a new message is received. */
var msgSound = new Howl({
  src: ["./assets/sounds/msg.mp3"],
  loop: false,
  preload: true,
});

/**
 * The function is used to listen to the event "chat" and display a toast notification when a new
 * message is received
 */
export function ChatNotification() {
  socket.on("chat", (result) => {
    console.log(result);
    if (isLogin() && result.code_generated == getSession("code_generated") && result.id_receiver == getSession("id")) {
      //alert("sound");
      msgSound.play();
      //playAudio();
      getUserById(result.id_sender).then((res) => {
        res.data.id &&
          toast.custom(
            (t) => (
              <div className="" style={{ width: 500, borderRadius: "60px", backgroundColor: "#333" }}>
                <div className="p-3">
                  <div className="d-flex align-items-center mt-auto" style={{ float: "left" }}>
                    <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={res.data.img}></ImageComponent>
                    <div>
                      <a href="#" className="font-weight-semibold">
                        {res.data.nom} {res.data.prenom}
                      </a>
                      <small className="d-block text-muted fa-12">{result.msg}</small>
                    </div>
                  </div>
                  <div className="border-l border-gray-200 mb-4" style={{ float: "right" }}>
                    <a href="#" onClick={() => toast.dismiss(t.id)} class="btn btn-pill btn-light">
                      voir
                    </a>
                  </div>
                </div>
              </div>
            ),
            { duration: 5000 }
          );
      });
    }
  });
}
