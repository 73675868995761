import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken, getSession } from "./../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It gets all the objectifs for the current user.
 * @returns The response is an array of objectifs.
 */
export async function getAllObjectif() {
  try {
    const response = await axios.get(api_url + "objectif/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add an objectif to the database
 * @param objectif - the objectif to add
 * @param realise - the number of the objectif that has been realized
 * @param mois - the month of the objectif
 * @param annee - the year of the objectif
 * @returns The response from the server.
 */
export async function addObjectif(objectif, realise, mois, annee) {
  const credentiel = {
    code_societe: localStorage.getItem("code_generated"),
    objectif: objectif,
    realise: realise,
    mois: mois,
    annee: annee,
  };
  try {
    const response = await axios.post(api_url + "objectif", credentiel, config);
    console.log(response);
    toast.success("Objectif ajouté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It deletes an objectif by year.
 * @param annee - The year of the objectif to delete.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "status": 200,
 *   "data": {
 *     "objectif": {
 *       "annee": "2019",
 *       "objectif": "100",
 *       "objectif_client": "50",
 *       "objectif_magasin": "50",
 *       "
 */
export async function deleteObjectifByYear(annee) {
  try {
    const response = await axios.delete(api_url + "objectif/" + annee, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
