import axios from "axios";
import { type } from "os";
import { getCurrentDateTime } from "../../Utils/DateUtils";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It gets all the tasks that have the same code as the one generated by the previous function.
 * @returns The response is an array of objects.
 */
export async function getTasksCommentByTaskId(task_id) {
  try {
    const response = await axios.get(api_url + "taskcomment/" + task_id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function addTaskComment(task_id, text) {
  const credentiel = { code_generated: getSession("code_generated"), task_id: task_id, user_id: getSession("id"), comment: text || "" };
  console.log(credentiel);

  try {
    const response = await axios.post(api_url + "taskcomment", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function deleteTaskCommentById(id) {
  try {
    const response = await axios.delete(api_url + "taskcomment/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function senFcmTask(text, receiver) {
  const notification = {
    data: {
      title: "Nouvelle Tâche",
      message: text,
    },
    to: "/topics/" + localStorage.getItem("code_generated") + "user" + receiver,
  };
  const fcm_server_key =
    "AAAAyFwzY3I:APA91bGIPThuQusFacH13cip7zdnDNY81mhV1xJV5pQXjqoHaIUwcibidgFahbxnjAxWODcIR0pew1a3tYlVUQNPLpx7nIanisZieGr0ZaMcwSqDvmcvyr9YjJK3yfoEbwu82sWDhL7E";

  const g = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `key=${fcm_server_key}`,
    },
  };
  try {
    const response = await axios.post("https://fcm.googleapis.com/fcm/send", notification, g);
    console.log(response);

    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js

      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function updateTaskCommentById(text, id) {
  const credentiel = {
    comment: text,
    id: id,
  };
  try {
    const response = await axios.patch(api_url + "taskcomment", credentiel, config);
    // console.log(response);
    // toast.success("Congé accepté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
