import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getUsersByCode } from "../../../Services/Pointeuse/UsersApi";
import { getAge } from "../../../Utils/DateUtils";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";

function BarApexAgeGender() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const arrayFilterMale = [];
    const arrayFilterFemelle = [];

    getUsersByCode()
      .then((res) => {
        res.data.map((item) => {
          if (item.gender === "male") {
            arrayFilterMale.push(getAge(item.birth_date));
          } else {
            arrayFilterFemelle.push(getAge(item.birth_date));
          }
        });

        const average = (array) => array.reduce((a, b) => a + b) / array.length;

        //const obj = arrayFilter.reduce((prev, curr) => ((prev[curr] = ++prev[curr] || 1), prev), {});
        let chartOptions = {
          series: [
            {
              name: "Homme",
              data: [parseInt(average(arrayFilterMale))],
            },
            {
              name: "Femme",
              data: [parseInt(average(arrayFilterFemelle))],
            },
          ],
          options: {
            chart: {
              type: "bar",
              height: 350,
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
              },
            },
            dataLabels: {
              enabled: false,
            },

            legend: {
              show: true,
              fontSize: "12px",
              fontWeight: 300,

              labels: {
                colors: "black",
              },
              position: "bottom",
              horizontalAlign: "center",
              markers: {
                width: 19,
                height: 19,
                strokeWidth: 0,
                radius: 19,
                strokeColor: "#fff",
                fillColors: ["#22BBFF", "#E0148D"],
                offsetX: 0,
                offsetY: 0,
              },
            },
            yaxis: {
              labels: {
                style: {
                  colors: "#3e4954",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: 100,
                },
              },
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },
            xaxis: {
              categories: ["Age"],
            },
            fill: {
              colors: ["#22BBFF", "#E0148D"],
              opacity: 1,
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val + " ans";
                },
              },
            },
          },
        };
        setData(chartOptions);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);
  return (
    <>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="bar"
          height={251}
          // width={300}
        />
      )}
    </>
  );
}

export default BarApexAgeGender;
