import React, { useRef, useState } from "react";
import { useAlert } from "react-alert";
import {
  getAllUsers,
  getUsersByCode,
  getUsersPersonnelByCode,
  registerNewUser,
} from "../../Services/Pointeuse/UsersApi";
import { getSession } from "../../Utils/SessionUtils";

function AddUser(props) {
  const myRefDelete = useRef(null);
  const alert = useAlert();
  const [gender, setgender] = useState("male");
  const [nom, setnom] = useState(null);
  const [prenom, setprenom] = useState(null);
  const [email, setemail] = useState(null);
  const [mobile, setmobile] = useState(null);
  const [birth, setbirth] = useState(null);
  const [password, setpassword] = useState(null);
  const [users, setusers] = useState(null);

  /**
   * It registers a new user in the database.
   */
  const handelSave = (e) => {
    e.preventDefault();
    //get all user
    getAllUsers().then((res) => {
      console.log(res.data);
      const result = res.data.data;
      const Listadmins = result.filter((el) => {
        return el.role_code == "admin" && el.code_generated == getSession("code_generated");
      });
      const nbUses = Listadmins[0].nb_users;
      const listUsers = result.filter((el) => {
        return el.role_code == "user" && el.code_generated == getSession("code_generated");
      });
      const nbUsersExiste = listUsers.length;
      if (parseInt(nbUsersExiste) < parseInt(nbUses)) {
        registerUser();
      } else {
        alert.error("nombres des utilisateurs limités");
      }
    });
  };

  /**
   * It registers a new user in the database.
   */
  const registerUser = () => {
    registerNewUser(email, nom, prenom, password, birth, mobile).then((res) => {
      myRefDelete.current.click();
      props.getData();
    });
  };

  return (
    <div className="modal fade" id="newusermodal">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Ajouter collaborateur</h5>
            <button className="close" data-dismiss="modal" aria-label="Close" ref={myRefDelete}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form onSubmit={handelSave}>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Nom<span className="text-danger"> *</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder="..."
                      required
                      onChange={(e) => setnom(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Prénom<span className="text-danger"> *</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder="..."
                      type="text"
                      required
                      onChange={(e) => setprenom(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>
                        Genre<span className="text-danger"> *</span>
                      </label>

                      <select
                        onChange={(e) => setgender(e.target.value)}
                        className="selectpicker form-control"
                        value={gender}
                      >
                        <option value={"male"}>Male</option>
                        <option value={"femelle"}>Femelle</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Mot de passe :<span className="text-danger"> *</span>
                    </label>
                    <div className="input-group">
                      <input
                        className="form-control fc-datepicker"
                        placeholder="..."
                        type="password"
                        required
                        onChange={(e) => setpassword(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Date de naissance:<span className="text-danger"> *</span>
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="feather feather-calendar" />
                        </div>
                      </div>
                      <input
                        className="form-control fc-datepicker"
                        placeholder="DD-MM-YYY"
                        type="date"
                        required
                        onChange={(e) => setbirth(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Mobile:<span className="text-danger"> *</span>
                    </label>
                    <div className="input-group">
                      <input
                        className="form-control fc-datepicker"
                        placeholder="..."
                        type="text"
                        onChange={(e) => setmobile(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">
                  Email<span className="text-danger"> *</span>
                </label>
                <input className="form-control" type="email" required onChange={(e) => setemail(e.target.value)} />
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-outline-primary" data-dismiss="modal">
                Fermer
              </button>
              <button className="btn btn-success" type="submit">
                Ajouter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
