import React, { useState } from "react";
import { addFacturationFournisseur } from "../../../Services/Facturation/FacturationFournisseurApi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddFournisseur() {
  const [stat, setStat] = React.useState("Client");
  const [tarif, setTarif] = React.useState("tarif");
  const [selectedDate, setSelectedDate] = React.useState();

  const [code, setCode] = useState("");
  const [codeBare, setCodeBare] = useState("");
  const [codeActivity, setCodeActivity] = useState("");
  const [magasin, setMagasin] = useState("");
  const [tva, setTva] = useState("");
  const [potentiel, setPotentiel] = useState("");
  const [potentielSoc, setPotentielSoc] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [cin, setCin] = useState("");
  const [email, setEmail] = useState("");
  const [fixe, setFixe] = useState("");
  const [Mobile, setMobile] = useState("");
  const [description, setDescription] = useState("");
  const [rib, setRib] = useState("");
  const [soldeReleve, setSoldeReleve] = useState("");
  const [codeErp, setCodeErp] = useState("");
  const [tax, setTax] = useState("");
  const [adrFacturation, setAdrFacturation] = useState("");

  const [localite, setLocalite] = useState("");
  const [region, setRegion] = useState("");
  const [gouvernorat, setGouvernorat] = useState("");
  const [secteur, setSecteur] = useState("");
  const [zone, setZone] = useState("");
  const [categorie, setCategorie] = useState("");
  const [codeCommercialCategorie, setCodeCommercialCategorie] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [regimeFiscal, setRegimeFiscal] = useState("");
  const [matriculeFiscal, setMatriculeFiscal] = useState("");
  const [commentaire, setCommentaire] = useState("");
  const [image, setImage] = useState("/dist/img/user/user.jpg");
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setimageName] = useState("");

  const [remise, setRemise] = useState("0");
  const [delaiPaiement, setDelaiPaiement] = useState("");
  const [plafondCredit, setPlafondCredit] = useState("0");
  const [rc, setRc] = useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChangeStatus = (event) => {
    setStat(event.target.value);
  };

  const handleChangeTarification = (event) => {
    setTarif(event.target.value);
  };

  const handleImgClient = (event) => {
    console.log(event.target.files[0]);
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageUrl(event.target.files[0]);
    setimageName(event.target.files[0].name);
  };

  const handleSubmit = () => {
    if (code === "") {
      toast.error("code vide");
    } else if (nom === "") {
      toast.error("nom vide");
    } else {
      addFacturationFournisseur(
        code,
        codeActivity,
        tarif,
        magasin,
        nom,
        prenom,
        email,
        selectedDate,
        fixe,
        Mobile,
        description,
        rib,
        codeErp,
        adrFacturation,
        localite,
        region,
        gouvernorat,
        secteur,
        zone,
        categorie,
        speciality,
        regimeFiscal,
        matriculeFiscal,
        commentaire,
        imageUrl,
        remise,
        delaiPaiement,
        plafondCredit,
        rc
      ).then((res) => {
        console.log(res);
      });
    }
  };

  return (
    <>
      <div className="row mt-4">
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Détail Fournisseur</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>
                  Code <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setCode(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>Code Activité</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCodeActivity(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Tarification</label>
                <select className="form-control" id="exampleFormControlSelect1" onChange={handleChangeTarification}>
                  <option value={0}>tarif</option>
                  <option value={1}>tarif1</option>
                  <option value={2}>tarif2</option>
                </select>
              </div>
              <div className="form-group">
                <label>Magasin</label>
                <input className="form-control" type="text" onChange={(e) => setMagasin(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>Code ERP</label>
                <input className="form-control" type="text" onChange={(e) => setCodeErp(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>
                  Adresse de facturation
                  <small> (maximum 40 caractères)</small>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setAdrFacturation(e.target.value)}
                  required
                  maxLength="40"
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
              <a href="https://select2.github.io/">Select2 documentation</a> for
              more examples and information about the plugin. */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Information de contact</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>Nom</label>
                <span className="text-danger">*</span>
                <input className="form-control" type="text" onChange={(e) => setNom(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>Prénom</label>
                <input className="form-control" type="text" onChange={(e) => setPrenom(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input className="form-control" type="email" onChange={(e) => setEmail(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>Anniversaire</label>
                <input
                  className="form-control"
                  type="date"
                  onChange={(e) => setSelectedDate(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Fixe</label>
                <input className="form-control" type="number" onChange={(e) => setFixe(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>Mobile</label>
                <input className="form-control" type="number" onChange={(e) => setMobile(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>Description</label>
                <input className="form-control" type="text" onChange={(e) => setDescription(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>Commantaire</label>
                <input className="form-control" type="text" onChange={(e) => setCommentaire(e.target.value)} required />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
              <a href="https://select2.github.io/">Select2 documentation</a> for
              more examples and information about the plugin. */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Information complémentaire</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>Catégorie</label>
                <input className="form-control" type="text" onChange={(e) => setCategorie(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>Spécialité</label>
                <input className="form-control" type="text" onChange={(e) => setSpeciality(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>Régime fiscal</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setRegimeFiscal(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Matricule fiscal</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setMatriculeFiscal(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Remise client</label>
                <input className="form-control" type="text" onChange={(e) => setRemise(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>Delai de paiement</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setDelaiPaiement(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Plafond crédit</label>
                <input
                  className="form-control"
                  type="number"
                  onChange={(e) => setPlafondCredit(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Registre de commerce</label>
                <input className="form-control" type="text" onChange={(e) => setRc(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>RIB</label>
                <input className="form-control" type="text" onChange={(e) => setRib(e.target.value)} required />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
              <a href="https://select2.github.io/">Select2 documentation</a> for
              more examples and information about the plugin. */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Adresse</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>Localité</label>
                <input
                  className="form-control"
                  type="text"
                  value={localite}
                  onChange={(e) => setLocalite(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Région</label>
                <input
                  className="form-control"
                  type="text"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label>Gouvernorat</label>
                <input
                  className="form-control"
                  type="text"
                  value={gouvernorat}
                  onChange={(e) => setGouvernorat(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Secteur</label>
                <input
                  className="form-control"
                  type="text"
                  value={secteur}
                  onChange={(e) => setSecteur(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Zone</label>
                <input
                  className="form-control"
                  type="text"
                  value={zone}
                  onChange={(e) => setZone(e.target.value)}
                  required
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
              <a href="https://select2.github.io/">Select2 documentation</a> for
              more examples and information about the plugin. */}
            </div>
          </div>
          <div>
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Image fournisseur</h3>
                <div className="card-tools">
                  {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                    <i className="fas fa-minus" />
                  </button>
                  <button type="button" className="btn btn-tool" data-card-widget="remove">
                    <i className="fas fa-times" />
                  </button> */}
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="form-group">
                  <div className="d-flex justify-content-center">
                    <img alt="image produit" src={image} className="avatar" />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        className="custom-file-input"
                        accept="image/*"
                        type="file"
                        id="exampleInputFile"
                        onChange={handleImgClient}
                      />
                      <label className="custom-file-label" htmlFor="exampleInputFile">
                        {imageName}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                {/* Visit{" "}
              <a href="https://select2.github.io/">Select2 documentation</a> for
              more examples and information about the plugin. */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn-danger text-center mr-2">
            <i className="fas fa-trash-alt" /> Annuler
          </button>
          <button type="button" className="btn btn-primary text-center" onClick={handleSubmit}>
            <i className="far fa-credit-card" /> Enregistrer
          </button>
        </div>
      </div>
    </>
  );
}

export default AddFournisseur;
