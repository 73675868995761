import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { ToastContainer } from "react-toastify";
import { editTaxById, getTaxById } from "../../../Services/Facturation/FacturationTvaApi";

function EditTax(props) {
  const history = useHistory();
  const [libelle, setlibelle] = useState("");
  const [taux, settaux] = useState("");
  const myRefHide = useRef(null);

  useEffect(() => {
    getData();
    props.setisEditing(false);
  }, [props.isEditing]);

  const getData = () => {
    getTaxById(props.idTax).then((res) => {
      setlibelle(res.data.libelle);
      settaux(res.data.taux);
    });
  };

  const handleSubmitTax = () => {
    editTaxById(libelle, taux, props.match.params.id).then(() => {
      props.getData();
      myRefHide.current.click();
    });
  };
  return (
    <div
      className="modal fade"
      id="taxModalEdit"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="taxModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Editer tax
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container">
              <form>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Libelle<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="..."
                    required
                    value={libelle || ""}
                    onChange={(e) => setlibelle(e.target.value)}
                  />
                  {/* <small id="emailHelp" className="form-text text-muted">
          We'll never share your email with anyone else.
        </small> */}
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">
                    Taux (%)<span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="..."
                    required
                    value={taux || ""}
                    onChange={(e) => settaux(e.target.value)}
                  />
                </div>
                <div className="form-check"></div>
                {/* <button type="submit" className="btn btn-primary">
              Modifier
            </button> */}
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={myRefHide}>
              Annuler
            </button>
            <button type="button" className="btn btn-primary" onClick={handleSubmitTax}>
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditTax;
