import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { addTaskMediaComment } from "../../Services/Pointeuse/TaskCommentMediaApi";

import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import ImageComponent from "../Styles/ImageComponent";
import swal from "sweetalert";
import { getSession } from "../../Utils/SessionUtils";
import { isCode } from "../../Utils/InputUtils";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {
  addTicketComment,
  deleteTicketCommentById,
  getTicketCommentByTicketId,
  updateTicketCommentById,
} from "../../Services/Pointeuse/TicketCommentApi";
import {
  addTicketMediaComment,
  addTicketMediaCommentWithouFilePont,
} from "../../Services/Pointeuse/TicketCommentMediaApi";
import CommentMediaTicket from "./CommentMediaTicket";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function CommentTicket({ ticketId }) {
  const myRefFile = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [imageName, setimageName] = useState("Choisir un document ...");
  const [text, setText] = useState(null);
  const [filesProject, setfilesProject] = useState(null);
  const [commentId, setcommentId] = useState(null);
  const [files, setFiles] = useState(null);
  const [filesComment, setfilesComment] = useState(null);
  const [inputList, setInputList] = useState([
    {
      code_generated: "",
      comment: "",
      created_at: "",
      id: "",
      img: "",
      nom: "",
      prenom: "",
      task_id: "",
      userId: "",
    },
  ]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    console.log(list);

    setInputList(list);
  };

  const handleClickChange = (e, index) => {
    console.log(index);
    const list = [...inputList];
    console.log();
    if (list[index]["userId"] == getSession("id")) {
      list[index]["update"] = true;
      console.log(list);
      setInputList(list);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleUploadFiles = (event) => {
    // const files = event.target.files;
    // // if (files) {
    // for (let i = 0; i < files.length; i++) {
    //   addProjectMediaComment(projetId, files[i], commentId).then(() => getData());
    // }
    // if (event.target.files) {
    //   setfiles(event.target.files);
    //   //setimageName(event.target.files.split(""));
    //   var list = [];
    //   for (let i = 0; i < files.length; i++) {
    //     list.push(files[i].name);
    //   }
    //   setimageName(list);
    // }
  };

  const handleUploadFileComment = (event) => {
    const listFiles = event.target.files;
    console.log(listFiles);
    if (listFiles) {
      addTicketMediaCommentWithouFilePont(ticketId, listFiles, commentId).then((res) => {
        getData();
        //setfilesComment(null);
      });
    }
  };

  const handleSave = () => {
    addTicketComment(ticketId, text).then((res) => {
      setText("");
      if (filesComment) {
        addTicketMediaComment(ticketId, filesComment, res.data.id).then((res) => {
          console.log(res);
          getData();
          setfilesComment(null);
        });
      }
    });
  };

  const getData = () => {
    setLoading(true);
    getTicketCommentByTicketId(ticketId)
      .then((res) => {
        console.log(res);
        const result = res.data;
        const newList = result.map((el) => {
          return { ...el, update: false };
        });
        console.log(newList);
        setdata(res.data);
        console.log(newList);
        setInputList(newList);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteTicketCommentById(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  const handleUpdate = (id, comment, index) => {
    console.log(id);
    console.log(comment);
    updateTicketCommentById(comment, id).then((res) => {
      const list = [...inputList];
      list[index]["update"] = false;
      console.log(list);
      setInputList(list);
    });
  };

  const handleTrigerRef = (id) => {
    setcommentId(id);
    myRefFile.current.click();
  };

  return (
    <div className="card support-converbody">
      <div className="card-header border-0">
        <h4 className="card-title">Conversation</h4>
      </div>

      <div className="card-body">
        <div className="pt-4 pb-4 text-right">
          {/* <Link to="#" className="btn btn-primary">
    Add Comment
  </Link> */}
        </div>
        {loading && <CustomSyncLoader></CustomSyncLoader>}
        {!loading && error && <ErrorCustum></ErrorCustum>}
        {!loading && !error && data && (
          <>
            {inputList.map((el, i) => (
              <div className="card shadow-none border">
                <div className="d-sm-flex p-5">
                  <div className="d-flex mr-3">
                    <Link to="#">
                      <ImageComponent atr={"media-object brround avatar-lg"} picture={el.img}></ImageComponent>
                    </Link>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-1 mb-1 font-weight-semibold">
                      {el.nom} {el.prenom}
                    </h5>
                    <small className="text-muted">
                      <i className="fa fa-calendar" /> {el.createdAt} <i className=" ml-3 fa fa-clock-o" />
                    </small>
                    <div onClick={(e) => handleClickChange(e, i)}>
                      {el.update ? (
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            placeholder="votre commentaire"
                            rows={4}
                            name="comment"
                            value={el.comment}
                            onChange={(e) => handleInputChange(e, i)}
                            // onClick={(e) => handleClickChange(e, i)}

                            // value={isChange ? text : el.comment}
                            // onChange={(e) => setText(e.target.value)}
                            // onClick={() => handleOnClick(el.id)}
                          />
                        </div>
                      ) : (
                        <>
                          <br></br>
                          {isCode(el.comment) && (
                            <code
                              className="fs-13 mb-2 mt-1"
                              name="comment"
                              value={el.comment}
                              // onClick={(e) => handleClickChange(e, i)}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Taper pour modifier"
                            >
                              {el.comment}
                            </code>
                          )}
                          {!isCode(el.comment) && (
                            <p
                              className="fs-13 mb-2 mt-1"
                              name="comment"
                              value={el.comment}
                              onClick={(e) => handleClickChange(e, i)}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Taper pour modifier"
                            >
                              {el.comment}
                            </p>
                          )}
                        </>
                      )}
                    </div>

                    <div style={{ display: "none" }} className="input-group file-browser">
                      <input type="text" className="form-control browse-file" />
                      <label className="input-group-append">
                        <span className="btn btn-primary">
                          choisir fichier{" "}
                          <input
                            ref={myRefFile}
                            type="file"
                            multiple
                            style={{ display: "none" }}
                            onChange={(e) => handleUploadFileComment(e)}
                          />
                        </span>
                      </label>
                    </div>

                    <div className="row mt-2 mb-4">
                      <CommentMediaTicket commentId={el.id} />
                    </div>

                    <Link to="#" className="mr-2">
                      <span className="badge badge-danger-light" onClick={() => handleDelete(el.id)}>
                        <i className="fe fe-trash" /> supprimer
                      </span>
                      {el.userId == getSession("id") && (
                        <span className="badge badge-light ml-2" onClick={() => handleTrigerRef(el.id)}>
                          <i className="fe fe-paperclip" /> ajouter
                        </span>
                      )}

                      {el.update && (
                        <span
                          className="badge badge-warning-light ml-2"
                          onClick={() => handleUpdate(el.id, el.comment, i)}
                        >
                          <i className="fa fa-pencil" /> modifier
                        </span>
                      )}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        <div className="card mb-md-0">
          <div className="card-header border-0">
            <h4 className="card-title">Commentaire</h4>
          </div>
          <div className="card-body">
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="votre commentaire"
                rows={6}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
            {/* <div className="input-group file-browser">
      <input type="text" className="form-control browse-file" placeholder={imageName || "choisir un fichier ..."} />
      <label className="input-group-append">
        <span className="btn btn-primary">
          choisir fichier <input type="file" multiple style={{ display: "none" }} onChange={handleUploadFiles} />
        </span>
      </label>
    </div> */}

            <FilePond
              files={filesComment}
              onupdatefiles={setfilesComment}
              allowMultiple={true}
              maxFiles={10}
              name="files"
              labelIdle='Faites glisser et déposez vos fichiers ou <span class="filepond--label-action">Parcourir</span>'
            />
            <Link to="#" className="btn btn-primary" onClick={handleSave}>
              Ajouter
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommentTicket;
