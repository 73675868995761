import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllAutorisation } from "../../Services/Pointeuse/AutorisationApi";
import { getAllDemandeCongeByCode } from "../../Services/Pointeuse/CongeApi";
import { getAllPointages } from "../../Services/Pointeuse/PointageApi";
import { getQuestionnaireByCode } from "../../Services/Pointeuse/QuestionnaireApi";
import { getRetardByCode } from "../../Services/Pointeuse/RetardApi";
import { filterTodayDate } from "../../Utils/ActivityUtils";
import { isLogin } from "../../Utils/SessionUtils";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import ImageComponent from "../Styles/ImageComponent";

function Activity() {
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [retards, setretards] = useState(null);
  const [pointage, setpointage] = useState(null);
  const [conges, setconges] = useState(null);
  const [autorisations, setautorisations] = useState(null);
  const [questionnaires, setquestionnaires] = useState(null);

  useEffect(() => {
    /* Fetching the data from the API and then filtering the data to get only the data of today. */
    getRetardByCode().then((res) => {
      const todayResult = filterTodayDate(res.data, "createdAt");
      const options = todayResult?.map((d) => ({
        sujet: "retard",
        nom: d.nom + " " + d.prenom,
        img: d.img,
        date: d.createdAt.split(" ")[1],
        time: d.time,
      }));

      setretards(options);
    });
  }, []);

  useEffect(() => {
    if (isLogin()) {
      var todayDate = new Date().toISOString().slice(0, 10);
      getAllPointages(todayDate).then((res) => {
        const options = res?.data?.map((d) => ({
          sujet: "pointage",
          nom: d.nom + " " + d.prenom,
          date: d.pointage,
        }));
        setpointage(options);
      });
    }

    //setdata([...data, ...options]);
  }, []);

  useEffect(() => {
    getAllDemandeCongeByCode()
      .then((res) => {
        const todayResult = filterTodayDate(res.data, "date");
        const options = todayResult?.map((d) => ({
          sujet: "conge",
          nom: d.nom + " " + d.prenom,
          date: d.date,
          jour: d.nbr_jour,
        }));
        setconges(options);
        //setdata([...data, ...options]);
      })
      .catch((error) => {
        setconges([]);
      });
  }, []);

  useEffect(() => {
    getAllAutorisation().then((res) => {
      const todayResult = filterTodayDate(res.data, "date");

      const demandesConges =
        todayResult &&
        todayResult.filter((el) => {
          return el.type == "demande";
        });
      const options = demandesConges?.map((d) => ({
        sujet: "autorisation",
        nom: d.nom + " " + d.prenom,
        date: d.date,
        houre: d.nb_heure,
      }));
      setautorisations(options);
      //setdata([...data, ...options]);
    });
  }, []);
  //   useEffect(() => {
  //     getDepalacementByUserId(user.id).then((res) => {
  //       setdeplacements(filterDeplacementByMonth(res.data, currentDate));
  //     });
  //   }, [user, currentDate, totalConge, cp, breakTime]);

  useEffect(() => {
    getQuestionnaireByCode().then((res) => {
      const todayResult = filterTodayDate(res.data, "createdAt");
      const options = todayResult?.map((d) => ({
        sujet: "questionnaire",
        nom: d.nom + " " + d.prenom,
        date: d.createdAt.split(" ")[1],
        motif: d.motif,
        time: d.retardTime,
      }));
      setquestionnaires(options);
      //setdata([...data, ...options]);
    });
  }, []);

  useEffect(() => {
    if (autorisations && autorisations && conges && retards && pointage && questionnaires) {
      setdata([...autorisations, ...retards, ...questionnaires, ...conges, ...pointage]);
    }
    //setdata(pointage);
    //setdata([...autorisations, ...conges, ...retards, ...pointage, ...questionnaires]);
  }, [autorisations, conges, retards, pointage, questionnaires]);

  return (
    <div className="sidebar sidebar-right sidebar-animate">
      <div className="card-header border-bottom pb-5">
        <h4 className="card-title">{t("Activités du jour")} </h4>
        <div className="card-options">
          <Link to="#" className="btn btn-sm btn-icon btn-light  text-primary" data-toggle="sidebar-right" data-target=".sidebar-right">
            <i className="feather feather-x" />
          </Link>
        </div>
      </div>

      {/* {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>} */}
      {data && (
        <div>
          {/* <div className="list-group-item  align-items-center  border-bottom">
        <div className="d-flex">
          <span className="avatar avatar-lg brround mr-3 bg-pink-transparent">
            <span className="feather feather-shopping-cart" />
          </span>
          <div className="mt-1">
            <Link to="#" className="font-weight-semibold fs-16">
              James<span className="text-muted font-weight-normal"> Order Placed</span>
            </Link>
            <span className="clearfix" />
            <span className="text-muted fs-13 ml-auto">
              <i className="mdi mdi-clock text-muted mr-1" />1 day ago
            </span>
          </div>
          <div className="ml-auto">
            <Link
              to="#"
              className="mr-0 option-dots"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="feather feather-more-horizontal" />
            </Link>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li>
                <Link to="#">
                  <i className="feather feather-eye mr-2" />
                  View
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="feather feather-plus-circle mr-2" />
                  Add
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="feather feather-trash-2 mr-2" />
                  Remove
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="feather feather-settings mr-2" />
                  More
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div> */}

          {data.map((el, idx) => (
            <div key={idx} className="list-group-item  align-items-center  border-bottom">
              <div className="d-flex">
                <ImageComponent atr={"avatar avatar-lg brround mr-3"} picture={el.img}></ImageComponent>
                {/* {el.img ? (
                  <span style={{ minWidth: 35 }} className="avatar avatar-lg brround mr-3 bg-primary-transparent">
                    {el.nom[0]}
                  </span>
                ) : (
                  <img src={el.img} alt=""></img>
                )} */}
                {/* <span
                className="avatar avatar-lg brround mr-3"
                style={{ backgroundImage: "url(assets/images/users/9.jpg)" }}
              >
                <span className="avatar-status bg-green" />
              </span> */}
                <div className="mt-1 ml-4">
                  <Link to="#" className="font-weight-semibold fs-16">
                    {el.nom}
                    {el.sujet == "retard" && (
                      <span className="text-muted font-weight-normal">
                        {" "}
                        {t("fait un retard de")} {el.time} {t("min")}
                      </span>
                    )}
                    {el.sujet == "autorisation" && (
                      <span className="text-muted font-weight-normal">
                        {" "}
                        {t("demandé une autorisation de")} {el.houre} {t("heures")}
                      </span>
                    )}
                    {el.sujet == "questionnaire" && (
                      <span className="text-muted font-weight-normal">
                        {" "}
                        {t("questionnaire de retard")} {el.time} {t("min pour")} {el.motif}
                      </span>
                    )}
                    {el.sujet == "conge" && (
                      <span className="text-muted font-weight-normal">
                        {" "}
                        {t("demandé un congé de")} {el.jour} {t("jours")}
                      </span>
                    )}
                    {el.sujet == "pointage" && <span className="text-muted font-weight-normal"> {t("pointage d'entrée")}</span>}
                  </Link>
                  <span className="clearfix" />
                  <span className="text-muted fs-13 ml-auto">
                    <i className="mdi mdi-clock text-muted mr-1" />
                    {el.date}
                  </span>
                </div>
                <div className="ml-auto">
                  <Link to="#" className="mr-0 option-dots" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                    <span className="feather feather-more-horizontal" />
                  </Link>
                  <ul className="dropdown-menu dropdown-menu-right" role="menu">
                    <li>
                      <Link to="#">
                        <i className="feather feather-eye mr-2" />
                        View
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="feather feather-plus-circle mr-2" />
                        Add
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="feather feather-trash-2 mr-2" />
                        Remove
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="feather feather-settings mr-2" />
                        More
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}

          {/* <div className="list-group-item  align-items-center  border-bottom">
            <div className="d-flex">
              <span
                className="avatar avatar-lg brround mr-3"
                style={{ backgroundImage: "url(assets/images/users/5.jpg)" }}
              >
                <span className="avatar-status bg-muted" />
              </span>
              <div className="mt-1">
                <Link to="#" className="font-weight-semibold fs-16">
                  Vinny<span className="text-muted font-weight-normal"> shared your post</span>
                </Link>
                <span className="clearfix" />
                <span className="text-muted fs-13 ml-auto">
                  <i className="mdi mdi-clock text-muted mr-1" />2 days ago
                </span>
              </div>
              <div className="ml-auto">
                <Link
                  to="#"
                  className="mr-0 option-dots"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="feather feather-more-horizontal" />
                </Link>
                <ul className="dropdown-menu dropdown-menu-right" role="menu">
                  <li>
                    <Link to="#">
                      <i className="feather feather-eye mr-2" />
                      View
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="feather feather-plus-circle mr-2" />
                      Add
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="feather feather-trash-2 mr-2" />
                      Remove
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="feather feather-settings mr-2" />
                      More
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="list-group-item  align-items-center  border-bottom">
            <div className="d-flex">
              <span className="avatar avatar-lg brround mr-3 bg-primary-transparent">M</span>
              <div className="mt-1">
                <Link to="#" className="font-weight-semibold fs-16">
                  Mack<span className="text-muted font-weight-normal"> your admin lanuched</span>
                </Link>
                <span className="clearfix" />
                <span className="text-muted fs-13 ml-auto">
                  <i className="mdi mdi-clock text-muted mr-1" />1 week ago
                </span>
              </div>
              <div className="ml-auto">
                <a
                  href="#"
                  className="mr-0 option-dots"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="feather feather-more-horizontal" />
                </a>
                <ul className="dropdown-menu dropdown-menu-right" role="menu">
                  <li>
                    <Link to="#">
                      <i className="feather feather-eye mr-2" />
                      View
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="feather feather-plus-circle mr-2" />
                      Add
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="feather feather-trash-2 mr-2" />
                      Remove
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="feather feather-settings mr-2" />
                      More
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
}

export default Activity;
