import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get Pointage by code (Today pointage)
 */
export async function getPointageByCode() {
  try {
    const response = await axios.get(api_url + "pointage/imagepointeuse/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get Pointage by code and date
 */
export async function getAllPointages(date) {
  try {
    const response = await axios.get(api_url + "pointage/getallpointages/" + getSession("code_generated") + "/" + date, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get Pointage by code
 */
export async function getPointageByUserId(id) {
  try {
    const response = await axios.get(api_url + "pointage/historymonth/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets all the pointage history for the current month.
 * @returns The response is an array of objects.
 */
export async function getAllPointageHistory() {
  try {
    const response = await axios.get(api_url + "pointage/historymonthcode/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It adds a pointage by user id.
 * @param userId - The ID of the user.
 * @param date - Date of the pointage
 * @param latitude - latitude of the pointage
 * @param longitude - The longitude of the point of arrival.
 * @param device - The device that the user is using to record the pointage.
 * @param network - The network type of the device.
 * @param createdAt - Date.now()
 * @returns The response is an object that contains the status code, the response data, the response
 * headers, and the request.
 */
export async function addPointageByUserId(userId, date, latitude, longitude, device, network, createdAt) {
  const credentiel = {
    code_generated: getSession("code_generated"),
    user_code_id: userId,
    date: date,
    longitude_arrive: latitude,
    latitude_arrive: longitude,
    device: device,
    network: network,
    web: "1",
    created_at: createdAt,
  };
  try {
    const response = await axios.post(api_url + "pointage/pointeuse", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function sendFcmPointage(receiver) {
  const notification = {
    data: {
      title: "Pointage",
      message: "vous êtes invité à effectuer le pointage",
    },
    to: "/topics/" + localStorage.getItem("code_generated") + "user" + receiver,
  };
  const fcm_server_key =
    "AAAAyFwzY3I:APA91bGIPThuQusFacH13cip7zdnDNY81mhV1xJV5pQXjqoHaIUwcibidgFahbxnjAxWODcIR0pew1a3tYlVUQNPLpx7nIanisZieGr0ZaMcwSqDvmcvyr9YjJK3yfoEbwu82sWDhL7E";

  const g = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `key=${fcm_server_key}`,
    },
  };
  try {
    const response = await axios.post("https://fcm.googleapis.com/fcm/send", notification, g);
    console.log(response);

    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js

      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
