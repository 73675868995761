import React, { useEffect, useState } from "react";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";
import Pagination from "../../Others/Pagination";
import { Link } from "react-router-dom";
import { filterTaxByWord } from "../../../Utils/SortObject";
import { deleteTaxById, editTaxById, getAllTva, getTaxById } from "../../../Services/Facturation/FacturationTvaApi";
import AddTax from "./AddTax.js";
import ButtonHeaderComponent from "../../Styles/ButtonHeaderComponent";
import swal from "sweetalert";
import ImageComponent from "../../Styles/ImageComponent";
import EditTax from "./EditTax";
import ExportCsv from "../../Exports/ExportCsv";
import ExportPdf from "../../Exports/ExportPdf";
import CopieContent from "../../Exports/CopieContent";

function Tax() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(8);
  const [idTax, setidTax] = useState(null);
  const [isEditing, setisEditing] = useState(false);
  const [libelle, setlibelle] = useState("");
  const [taux, settaux] = useState("");
  const [isCollapsed, setisCollapsed] = useState(false);
  // const myRefHide = useRef(null);

  useEffect(() => {
    getTaxById(idTax).then((res) => {
      setlibelle(res.data.libelle);
      settaux(res.data.taux);
    });
  }, [idTax]);

  const handleSubmitTax = () => {
    editTaxById(libelle, taux, idTax).then(() => {
      // props.getData();
      // myRefHide.current.click();
    });
  };

  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  const getData = () => {
    setLoading(true);
    getAllTva()
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
        console.log(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    if (e.target.value != "") {
      const dataSearch = filterTaxByWord(allInitialData, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  const handleDelete = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteTaxById(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  const handleCloseModal = () => {
    //setisEditing(false);
  };
  const handleOpenModal = (id) => {
    //    console.log(id);
    setidTax(id);
    setisEditing(true);
  };

  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Tax</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="#" className="btn btn-primary" data-toggle="modal" data-target="#taxModal">
                <i className="feather feather-plus fs-15 my-auto mr-2" />
                Ajouter Tax
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-9 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">List Taxes</h4>
              <div className="card-options">
                <Link
                  to="#"
                  className="card-options-fullscreen mr-2"
                  data-toggle="card-fullscreen"
                  onClick={handleCollapse}
                >
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>
            {/* <div className="card-body">
              <div className="row">
                <div className="col-xl-7 col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">From:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control fc-datepicker" placeholder="DD-MM-YYYY" type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">To:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control fc-datepicker" placeholder="DD-MM-YYYY" type="text" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-12">
                  <div className="form-group">
                    <label className="form-label">Select Status:</label>
                    <select
                      name="attendance"
                      className="form-control custom-select select2"
                      data-placeholder="Select Status"
                    >
                      <option label="Select Status" />
                      <option value={1}>Active</option>
                      <option value={2}>InActive</option>
                    </select>
                  </div>
                </div>
                <div className="col-xl-2 col-md-12">
                  <div className="form-group mt-5">
                    <Link to="#" className="btn btn-primary btn-block">
                      Search
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="card-body">
              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            Afficher{" "}
                            <select
                              value={size}
                              className="form-control"
                              id="exampleFormControlSelect1"
                              onChange={handleChangeSize}
                            >
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            entrées
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Recherche..."
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv data={data} name={"List_tax"} />
                          <ExportPdf data={data} name={"List_tax"} columns={["id", "libelle", "taux", "created_at"]} />
                          <CopieContent />
                          {/* 
                          <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0 w-5"># ID</th>
                              <th className="border-bottom-0">Libelle</th>
                              <th className="border-bottom-0">Taux</th>
                              <th className="border-bottom-0">Date de création</th>
                              <th className="border-bottom-0">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el, idx) => (
                              <tr key={el.id}>
                                <td>{el.id}</td>
                                <td>{el.libelle}</td>
                                <td>{el.taux}</td>
                                <td>{el.created_at}</td>
                                <td>
                                  <div className="d-flex">
                                    {/* <a
                                      href="client-view.html"
                                      className="action-btns1"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="View"
                                    >
                                      <i className="feather feather-eye text-primary" />
                                    </a> */}
                                    <Link
                                      to="#"
                                      className="action-btns1"
                                      data-toggle="modal"
                                      data-target="#taxModalEdit"
                                      onClick={() => handleOpenModal(el.id)}
                                    >
                                      <i
                                        className="feather feather-edit-2  text-success"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit"
                                      />
                                    </Link>
                                    <Link
                                      to="#"
                                      className="action-btns1"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                      onClick={() => handleDelete(el.id)}
                                    >
                                      <i className="feather feather-trash-2 text-danger" />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          Affichage de 1 à {size} sur {data.length} entrées
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-header  border-0">
              <h4 className="card-title">Aperçu Tax</h4>
            </div>
            <div className="card-body">
              <div id="leavesoverview" className="mx-auto pt-2" />
              <div className="row pt-7 pb-5  mx-auto text-center">
                <div className="col-md-12 mx-auto d-block">
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <div className="d-flex font-weight-semibold">ttt</div>
                    </div>

                    {/* <div className="col-md-12 mt-3">
                      <div className="d-flex font-weight-semibold">
                        <span className="dot-label badge-success mr-2 my-auto" />
                        Vacciné
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="d-flex font-weight-semibold">
                        <span className="dot-label bg-danger mr-2 my-auto" />
                        Non vacciné
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddTax getData={getData}></AddTax>
      {/* edittax */}
      {isEditing && (
        <div
          className="modal fade"
          id="taxModalEdit"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="taxModalTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Editer tax
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <form>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        Libelle<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="..."
                        required
                        value={libelle || ""}
                        onChange={(e) => setlibelle(e.target.value)}
                      />
                      {/* <small id="emailHelp" className="form-text text-muted">
          We'll never share your email with anyone else.
        </small> */}
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Taux (%)<span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        required
                        value={taux || ""}
                        onChange={(e) => settaux(e.target.value)}
                      />
                    </div>
                    <div className="form-check"></div>
                    {/* <button type="submit" className="btn btn-primary">
              Modifier
            </button> */}
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleCloseModal}>
                  Annuler
                </button>
                <button type="button" className="btn btn-primary" onClick={handleSubmitTax}>
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Tax;
