import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import { getAllMaintenance } from "../../Services/Pointeuse/MaintenanceApi";
import { filterTeletravailByWord } from "../../Utils/SortObject";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import ImageComponent from "../Styles/ImageComponent";

function ListDemandeMaintenance() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllMaintenance()
      .then((res) => {
        console.log(res.data);
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  /**
   * The onChangePage function is called when the user clicks on the page numbers.
   * It takes the page of items returned by the paginate function as a parameter and
   * uses that parameter to update the currentData state
   * @param pageOfItems - an array of items on the current page
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the word that is typed in the search bar.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      const dataSearch = filterTeletravailByWord(data, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  return (
    <div>
      <div className="page-header d-lg-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Demande Maintenance</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className=" btn-list">
            <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
              {" "}
              <i className="feather feather-mail" />{" "}
            </button>
            <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
              {" "}
              <i className="feather feather-phone-call" />{" "}
            </button>
            <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
              {" "}
              <i className="feather feather-info" />{" "}
            </button>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row flex-lg-nowrap">
        <div className="col-12">
          <div className="row flex-lg-nowrap">
            <div className="col-12 mb-3">
              <div className="e-panel card">
                <div className="card-body pb-2">
                  <div className="row">
                    {/* <div className="col mb-4">
                      <Link to="#" className="btn btn-primary">
                        <i className="fe fe-plus" /> Add New User
                      </Link>
                    </div> */}
                    <div className="col col-auto mb-4">
                      <div className="form-group w-100">
                        <div className="input-icon">
                          <span className="input-icon-addon">
                            <i className="fe fe-search" />
                          </span>
                          <input
                            onChange={handleSearch}
                            type="text"
                            className="form-control"
                            placeholder="Recherche ..."
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {loading && <CustomSyncLoader></CustomSyncLoader>}
                  {!loading && error && <ErrorCustum></ErrorCustum>}
                  {!loading && !error && data && (
                    <div className="row">
                      {data.map((el, idx) => (
                        <div key={idx} className="col-lg-6 col-xl-4">
                          <div className="card">
                            <div className="card-body d-flex flex-column">
                              <h4>
                                <Link to="#">{el.titre}</Link>
                              </h4>
                              <div className="text-muted">{el.description}</div>
                              <div className="d-flex align-items-center pt-5 mt-auto">
                                <ImageComponent
                                  atr={"avatar avatar-md brround mr-3"}
                                  picture={el.imguser}
                                ></ImageComponent>
                                <div>
                                  <a href="profile.html" className="font-weight-semibold">
                                    {el.nom} {el.prenom}
                                  </a>
                                  <small className="d-block text-muted">{el.created_at}</small>
                                </div>
                                {/* <div className="ml-auto">
                                  <a href="javascript:void(0)" className="d-none d-md-inline-block ml-3">
                                    <span className="feather feather-heart text-muted" />
                                  </a>
                                  <a href="javascript:void(0)" className="d-none d-md-inline-block ml-3">
                                    <span className="feather feather-thumbs-up text-muted" />
                                  </a>
                                </div> */}
                              </div>
                            </div>
                            <Link to="#">
                              <SRLWrapper>
                                <img
                                  className="card-img-top br-bl-7 br-br-7"
                                  src={
                                    el.image
                                      ? localStorage.getItem("baseUrl") + el.image
                                      : "assets/images/users/avatar.png"
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "assets/images/users/avatar.png";
                                  }}
                                  loading="lazy"
                                  alt="How do you know she is a witch?"
                                />
                              </SRLWrapper>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListDemandeMaintenance;
