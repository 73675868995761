import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { convertFloatToDigit } from "../../../Utils/NumberUtils";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    // borderBottomColor: "#142328",
    // borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "65%",
    textAlign: "right",

    paddingRight: 8,
  },
  total: {
    width: "35%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const InvoiceTableFooter = ({ invoice }) => {
  return (
    <>
      {invoice.remise != "0" && (
        <View style={styles.row}>
          <Text style={styles.description}>Sous total HT</Text>
          <Text style={styles.total}>{invoice.sous_total_ht} TND</Text>
        </View>
      )}

      {invoice.remise != "0" && (
        <View style={styles.row}>
          <Text style={styles.description}>Remise</Text>
          <Text style={styles.total}>
            {invoice.remise}
            {invoice.actionRemiseEntete === "2" ? " TND" : " %"}
          </Text>
        </View>
      )}

      <View style={styles.row}>
        <Text style={styles.description}>Total HT</Text>
        <Text style={styles.total}>{invoice.totalHt == "0" ? "0" : invoice.totalHt + " TND"}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>TVA</Text>
        <Text style={styles.total}>{invoice.tva == "0" ? "0" : invoice.tva + " TND"}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>Timbre fiscal</Text>
        <Text style={styles.total}>1.000 TND</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>Total TTC</Text>
        <Text style={styles.total}>{invoice.totalTTC == "0" ? "0" : invoice.totalTTC + " TND"}</Text>
      </View>
    </>
  );
};

export default InvoiceTableFooter;
