import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import { getAllSettings } from "../../Services/Pointeuse/SettingsApi";
import { getUserTimeByUserId, postUserTime, updateUserTime } from "../../Services/Pointeuse/UserTimeApi";
import { validateHoure, validateTime } from "../../Utils/ConfigUtils";

function AddNewUserTime({ idUser }) {
  const alert = useAlert();
  const [debut, setdebut] = useState(null);
  const [fin, setfin] = useState(null);
  const [heures, setheures] = useState(null);
  const [retard, setretard] = useState(null);
  const [debutD, setdebutD] = useState(null);
  const [finD, setfinD] = useState(null);
  const [heuresD, setheuresD] = useState(null);
  const [retardD, setretardD] = useState(null);
  const [isExist, setisExist] = useState(false);
  const [id, setid] = useState(null);

  useEffect(() => {
    getAllSettings().then((res) => {
      setdebutD(res.data.startHoure);
      setfinD(res.data.endHoure);
      setheuresD(res.data.workHoure);
      setretardD(res.data.dayRetard);
    });
  }, []);

  useEffect(() => {
    getUserTimeByUserId(idUser).then((res) => {
      console.log(res);
      if (res.data.debut) {
        setisExist(true);
      }
      setdebut(res.data.debut);
      setfin(res.data.fin);
      setheures(res.data.heures);
      setretard(res.data.daily_retard);
      setid(res.data.id);
    });
  }, []);

  const handleSubmitFom = () => {
    if (isExist) {
      if (validateTime(debut) && validateTime(fin) && validateTime(retard)) {
        updateUserTime(id, debut, fin, heures, retard).then((res) => {
          alert.success("configuration modifié");
        });
      } else {
        alert.error("vérifié les champs incorrects");
      }
    } else {
      if (validateTime(debut) && validateTime(fin) && validateTime(retard)) {
        postUserTime(idUser, debut, fin, heures, retard).then((res) => {
          alert.success("configuration ajouté");
          setid(res.data.id);
          setisExist(true);
        });
      } else {
        alert.error("vérifié les champs incorrects");
      }
    }
  };

  return (
    <div className="card-body">
      <div className="form-group">
        <div className="row">
          <div className="col-md-3">
            <label className="form-label mb-0 mt-2">
              {t("Heure de début")}
              {debutD && (
                <small className="form-text text-muted  mb-5">
                  {t("Par défaut")} : {debutD}
                </small>
              )}
            </label>
          </div>
          <div className="col-md-9">
            <input
              value={debut}
              onChange={(e) => setdebut(e.target.value)}
              type="text"
              className="form-control"
              placeholder="08:00:00 (hh:mm:ss)"
              required
              style={validateTime(debut) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col-md-3">
            <label className="form-label mb-0 mt-2">
              {t("Heure de fin")}

              {finD && (
                <small className="form-text text-muted  mb-5">
                  {t("Par défaut")} : {finD}
                </small>
              )}
            </label>
          </div>
          <div className="col-md-9">
            <input
              value={fin}
              onChange={(e) => setfin(e.target.value)}
              type="text"
              className="form-control"
              placeholder="17:00:00 (hh:mm:ss)"
              style={validateTime(fin) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }}
              required
            />
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col-md-3">
            <label className="form-label mb-0 mt-2">
              {t("Heures")}
              {heuresD && (
                <small className="form-text text-muted  mb-5">
                  {t("Par défaut")} : {heuresD}
                </small>
              )}
            </label>
          </div>
          <div className="col-md-9">
            <input
              value={heures}
              onChange={(e) => setheures(e.target.value)}
              type="text"
              className="form-control"
              placeholder="ex : 8"
              required
              style={validateHoure(heures) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col-md-3">
            <label className="form-label mb-0 mt-2">
              {t("Retard de jour")}
              {retardD && (
                <small className="form-text text-muted  mb-5">
                  {t("Par défaut")} : {retardD}
                </small>
              )}
            </label>
          </div>
          <div className="col-md-9">
            <input
              value={retard}
              onChange={(e) => setretard(e.target.value)}
              type="text"
              className="form-control"
              placeholder="00:15:00 (hh:mm:ss)"
              required
              style={validateTime(retard) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }}
            />
          </div>
        </div>
      </div>

      <div className="card-footer text-right">
        <Link to="#" className="btn btn-primary mr-2" onClick={handleSubmitFom}>
          {t("Enregistrer")}
        </Link>
        {/* <a href="#" className="btn btn-danger">
      Cancle
    </a> */}
      </div>
    </div>
  );
}

export default AddNewUserTime;
