import React, { useEffect, useState } from "react";
import { getCongeById } from "../../Services/Pointeuse/CongeApi";
import { getEntrepriseByCode } from "../../Services/Pointeuse/EntrepriseApi";
import { getUserById } from "../../Services/Pointeuse/UsersApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import "./PrintConge.css";

function PrintConge(props) {
  const [data, setdata] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [entreprise, setentreprise] = useState(null);
  const [responsable, setresponsable] = useState(null);

  useEffect(() => {
    getUserById(localStorage.getItem("id")).then((res) => {
      setresponsable(res.data);
    });

    getEntrepriseByCode().then((res) => {
      console.log(res.data);
      setentreprise(res.data);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    getCongeById(props.match.params.id)
      .then((res) => {
        setdata(res.data);
        console.log(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  /**
   * Prints the current page
   */
  const handlePrint = () => {
    window.print();
  };
  return (
    <div>
      <div className="page-header d-lg-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Titre congé</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className=" btn-list">
            <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
              <i className="feather feather-mail" />
            </button>
            <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
              <i className="feather feather-phone-call" />
            </button>
            <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
              <i className="feather feather-info" />
            </button>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row*/}

      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <div className="row">
          <div className="col-md-12">
            <div className="card overflow-hidden">
              <div className="card-body">
                <h2 className="text-muted font-weight-bold">Titre congé</h2>
                <div>
                  <img src={localStorage.getItem("baseUrl") + entreprise?.logo} alt=""></img>
                </div>
                <div>
                  Vous devez soumettre vos demandes de congé (à l'exception des congés maladie)
                  <strong>deux jours avant</strong> leur date effective.
                  <br></br>
                  <br></br>
                  <br></br>
                  <h5>
                    Nom de l'employé :
                    <strong>
                      {data.nom} {data.prenom}
                    </strong>
                  </h5>
                </div>
                <div className="card-body pl-0 pr-0">
                  <div className="row">
                    <div className="col-sm-6">
                      <span>Matricule</span>
                      <br />
                      <strong># {data.user_code_id}</strong>
                    </div>
                    <div className="col-sm-6 text-right">
                      <span>Date</span>
                      <br />
                      <strong>{data.created_at}</strong>
                    </div>
                  </div>
                </div>
                <div className="dropdown-divider" />
                <div className="row pt-4">
                  <div className="col-lg-6 ">
                    <p className="h5 font-weight-bold">Responsable</p>
                    <address>
                      {responsable?.nom}
                      <br />
                      {responsable?.prenom}
                      <br />
                      {responsable?.fonction}
                      <br />
                      {responsable?.email}
                    </address>
                  </div>
                  <div className="col-lg-6 text-right">
                    <p className="h5 font-weight-bold">Type congé</p>
                    <address>
                      {data.motif}
                      <br />
                    </address>
                  </div>
                </div>
                <div className="table-responsive push">
                  <table className="table table-bordered table-hover text-nowrap">
                    <tbody>
                      <tr className=" ">
                        <th className="text-center " />
                        <th></th>
                        {/* <th className="text-center" style={{ width: "1%" }}>
                          Qnty
                        </th>
                        <th className="text-right" style={{ width: "1%" }}>
                          Unit Price
                        </th>
                        <th className="text-right" style={{ width: "1%" }}>
                          Amount
                        </th> */}
                      </tr>
                      <tr>
                        <td className="text-center">Date début</td>
                        <td>
                          <p className="font-weight-semibold mb-1">{data.date.split(" ")[0]}</p>
                          {/* <div className="text-muted">Logo and business cards design</div> */}
                        </td>
                        {/* <td className="text-center">2</td>
                        <td className="text-right">$60.00</td>
                        <td className="text-right">$120.00</td> */}
                      </tr>
                      <tr>
                        <td className="text-center">Nombre des jours</td>
                        <td>
                          <p className="font-weight-semibold mb-1">{data.nbr_jour}</p>
                          {/* <div className="text-muted">Logo and business cards design</div> */}
                        </td>
                        {/* <td className="text-center">2</td>
                        <td className="text-right">$60.00</td>
                        <td className="text-right">$120.00</td> */}
                      </tr>

                      {/* <tr>
                        <td colSpan={4} className="font-weight-semibold text-right">
                          Subtotal
                        </td>
                        <td className="text-right">$400.00</td>
                      </tr>
                      <tr>
                        <td colSpan={4} className="font-weight-semibold text-right">
                          Vat Rate
                        </td>
                        <td className="text-right">20%</td>
                      </tr> */}
                      {/* <tr>
                        <td colSpan={4} className="font-weight-semibold text-right">
                          Vat Due
                        </td>
                        <td className="text-right">$50.00</td>
                      </tr> */}
                      <tr>
                        <td colSpan={4} className="font-weight-bold text-uppercase text-right h4 mb-0">
                          Décision
                        </td>
                        <td className="font-weight-bold text-right h4 mb-0">accepté</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="card-body pl-0 pr-0">
                  <div className="row mr-4 ml-4" style={{ marginBottom: 100 }}>
                    <div className="col-sm-6">
                      <span>Signature de l'employé</span>
                      <br />
                    </div>
                    <div className="col-sm-6 text-right">
                      <span>Signature du responsable</span>
                      <br />
                    </div>
                  </div>
                </div>

                <div className="card-body pl-0 pr-0">
                  <div className="row">
                    <div className="col-sm-6">
                      <br />
                    </div>
                    <div className="col-sm-6 text-right">
                      <button className="btn btn-primary" onClick={handlePrint}>
                        <i className="si si-printer" /> Imprimer
                      </button>
                      <br />
                    </div>
                  </div>
                </div>
                <p className="text-muted text-center">
                  NB : Nombre de jours de congé annuel est de 30 jours calendaire
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PrintConge;
