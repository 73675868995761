import React, { useEffect, useState } from "react";
import { editPersonnel, getPersonnelById } from "../../Services/Pointeuse/PersonnelApi";
import { getPersonnelDocByUserId } from "../../Services/Pointeuse/PersonnelDocApi";
import { getUserById } from "../../Services/Pointeuse/UsersApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import swal from "sweetalert";

function CardPerso({ userId }) {
  const [delivre_le, setdelivre_le] = useState("");
  const [cin, setcin] = useState("");

  const [cnss, setcnss] = useState("");
  const [type_contrat, settype_contrat] = useState("");
  const [data, setdata] = useState([]);
  const [date_embauche, setdate_embauche] = useState("");
  const [date_fincontrat, setdate_fincontrat] = useState("");
  const [famille, setfamille] = useState("");
  const [enfant, setenfant] = useState("");
  const [adresse, setadresse] = useState("");
  const [salaire_base, setsalaire_base] = useState("");
  const [base, setbase] = useState("");
  const [rib, setrib] = useState("");
  const [bank, setbank] = useState("");
  const [agence, setagence] = useState("");
  const [jour_semaine, setjour_semaine] = useState("");
  const [heure_semaine, setheure_semaine] = useState("");
  const [startDate, setstartDate] = useState("");
  const [documentData, setDocumentData] = useState([]);
  const [latitude, setlatitude] = useState(null);
  const [longitude, setlongitude] = useState(null);
  const [contact, setcontact] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [user, setuser] = useState(null);
  const [img, setimg] = useState("");

  useEffect(() => {
    setLoading(true);
    getPersonnelById(userId).then((res) => {
      setdata(res.data);
      setcin(res.data.cin);
      setdelivre_le(res.data.delivre_le);
      setcnss(res.data.cnss);
      settype_contrat(res.data.type_contrat || "sivp");
      setdate_embauche(res.data.date_embauche);
      setdate_fincontrat(res.data.date_fincontrat);
      setfamille(res.data.famille || "non");
      setenfant(res.data.enfant);
      setadresse(res.data.adresse);
      setsalaire_base(res.data.salaire_base);
      setbase(res.data.base);
      setrib(res.data.rib);
      setbank(res.data.bank);
      setagence(res.data.agence);
      setjour_semaine(res.data.jour_semaine);
      setheure_semaine(res.data.heure_semaine);
      setlatitude(res.data.latitude);
      setlongitude(res.data.longitude);
      setcontact(res.data.contact_urgence);

      getPersonnelDocByUserId(userId)
        .then((resDocument) => {
          setDocumentData(resDocument.data);
        })
        .finally(() => setLoading(false));
    });
  }, []);

  /**
   * * This function is used to update personnel data in the database
   */
  const handleSubmitPersonnel = () => {
    if (
      cin === null ||
      delivre_le === null ||
      type_contrat === null ||
      date_embauche === null ||
      date_fincontrat === null ||
      famille === null ||
      enfant === null ||
      salaire_base === null ||
      adresse === null
    ) {
      //toast.error("vérifié les champs vide");
      alert("vérifié les champs vides");
    } else {
      swal({
        title: "Modification",
        text: "Voulez vous vraiment modifier cet enregistrement!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          editPersonnel(
            userId,
            localStorage.getItem("code_generated"),
            cin,
            delivre_le,
            cnss,
            type_contrat,
            date_embauche,
            date_fincontrat,
            famille,
            enfant,
            adresse,
            latitude,
            longitude,
            salaire_base,
            rib,
            bank,
            agence,
            contact
          ).then(() => {
            swal("Opération effectuée avec succès!", {
              icon: "success",
            });
          });
        }
      });
    }
  };
  // const handleSubmitPersonnel = () => {
  //   console.log(famille);
  // };

  useEffect(() => {
    getUserById(userId).then((res) => {
      if (res.data.img) {
        setimg(localStorage.getItem("baseUrl") + res.data.img);
      } else {
        setimg("/assets/images/ic_man.png");
      }

      setuser(res.data);
    });
  }, []);

  return (
    <>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && (
        <div className="card-body">
          {/* <h4 className="mb-4 font-weight-bold">Basic</h4> */}
          <div className="row gutters">
            {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <h6 className="mb-2 text-primary">Détails personnels</h6>
            </div> */}
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label htmlFor="fullName">
                  Cin<span className="text-danger">*</span>
                </label>
                <input type="text" className="form-control" value={cin} onChange={(e) => setcin(e.target.value)} />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label>
                  Délivré le<span className="text-danger">*</span>
                </label>
                <input className="form-control" type="date" value={delivre_le} onChange={(e) => setdelivre_le(e.target.value)} />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label>cnss</label>
                <input className="form-control" type="text" value={cnss} onChange={(e) => setcnss(e.target.value)} />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label>
                  Type contrat<span className="text-danger">*</span>
                </label>
                <select value={type_contrat} onChange={(e) => settype_contrat(e.target.value)} className="selectpicker form-control">
                  <option value="sivp">SIVP</option>
                  <option value="cdi">CDI</option>
                  <option value="cdd">CDD</option>
                  <option value="karama">Karama</option>
                  <option value="stage">Stage</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row gutters">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{/* <h6 className="mt-3 mb-2 text-primary">Address</h6> */}</div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label>
                  Date d'embauche<span className="text-danger">*</span>
                </label>
                <input className="form-control" type="date" value={date_embauche} onChange={(e) => setdate_embauche(e.target.value)} />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label>
                  Date fin contrat<span className="text-danger">*</span>
                </label>
                <input className="form-control" type="date" value={date_fincontrat} onChange={(e) => setdate_fincontrat(e.target.value)} />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label>
                  Famille<span className="text-danger">*</span>
                </label>

                <select value={famille} onChange={(e) => setfamille(e.target.value)} className="selectpicker form-control">
                  <option value="non">Non</option>
                  <option value="oui">Oui</option>
                </select>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label>
                  Enfant<span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" value={enfant} placeholder="ex: 0" onChange={(e) => setenfant(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="row gutters">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{/* <h6 className="mt-3 mb-2 text-primary">Address</h6> */}</div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label>
                  Salaire de base<span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" value={salaire_base} onChange={(e) => setsalaire_base(e.target.value)} />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label>Rib</label>
                <input className="form-control" type="text" value={rib} onChange={(e) => setrib(e.target.value)} />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label>Bank</label>
                <input className="form-control" type="text" value={bank} onChange={(e) => setbank(e.target.value)} />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label>Agence</label>
                <input className="form-control" type="text" value={agence} onChange={(e) => setagence(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="row gutters">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{/* <h6 className="mt-3 mb-2 text-primary">Address</h6> */}</div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label>
                  Adresse<span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" value={adresse} onChange={(e) => setadresse(e.target.value)} />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label>latitude</label>
                <input className="form-control" type="text" value={latitude} placeholder="32.32" onChange={(e) => setlatitude(e.target.value)} />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label>Longitude</label>
                <input className="form-control" type="text" placeholder="10.10" value={longitude} onChange={(e) => setlongitude(e.target.value)} />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label>Contact d'urgence</label>
                <input className="form-control" type="text" placeholder="" value={contact} onChange={(e) => setcontact(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="row gutters">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="text-right">
                {/* <button type="button" id="submit" name="submit" className="btn btn-secondary">
                      Cancel
                    </button> */}
                <button type="button" id="submit" name="submit" className="btn btn-primary" onClick={handleSubmitPersonnel}>
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
          {/* <div className="row gutters">
              <div>
                {loading && (
                  <div className="container mt-5">
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <CustomSyncLoader></CustomSyncLoader>
                    </div>
                  </div>
                )}
                {!loading && error && (
                  <div>
                    <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
                  </div>
                )}
                {!loading && !error && documentData && (
                  <PersonnelDoc idUser={userId}></PersonnelDoc>
                )}
              </div>
            </div> */}
        </div>
      )}
    </>
  );
}

export default CardPerso;
