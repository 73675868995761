import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteDepartementByCode, deleteDepartementById } from "../../Services/Pointeuse/DepartementApi";
import { getAllDepartementMembers } from "../../Services/Pointeuse/DepartementMemberApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import ImageComponent from "../Styles/ImageComponent";
import swal from "sweetalert";

function TableDepartement({ dep, fetchAllDepartement }) {
  const [data, setdata] = useState([]);
  const [nom, setnom] = useState(null);
  const [users, setusers] = useState([]);

  useEffect(() => {
    getAllDepartementMembers(dep.code).then((res) => {
      setdata(res.data);
    });
  }, []);
  // const handleDelete = () => {
  //   deleteDepartementByCode(dep.code).then(() => {
  //     fetchAllDepartement();
  //   });
  // };

  /**
   * It deletes a departement by its code.
   * @param id - The id of the record to delete.
   */
  const handleDelete = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteDepartementByCode(dep.code).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          fetchAllDepartement();
        });
      }
    });
  };

  useEffect(() => {
    getUsersByCode().then((res) => {
      setusers(res.data);
    });
  }, []);
  return (
    <>
      <tr key={dep.id}>
        <td>{dep.code}</td>
        <td>{dep.nom}</td>
        <td>{dep.created_at.split(" ")[0]} </td>
        <td>
          <ul className="list-inline mt-2">
            {data.map((el, idx) => (
              <li key={idx} className="list-inline-item">
                <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={el.imguser}></ImageComponent>
                {/* <img
                  data-toggle="tooltip"
                  data-placement="top"
                  title={el.nom + " " + el.prenom}
                  alt="Avatar"
                  className="avatar"
                  src={
                    el.imguser == null || el.imguser == ""
                      ? "dist/img/user/avatar.png"
                      : localStorage.getItem("baseUrl") + el.imguser
                  }
                /> */}
              </li>
            ))}
          </ul>
        </td>

        <td>
          <p className="mb-0">
            contribution
            <span className="float-right">
              {data.length}/{users.length}
            </span>
          </p>
          <div className="progress mt-2" style={{ height: 5 }}>
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: `${data.length * 1 * users.length}%` }}
            />
          </div>
        </td>

        <td className="text-left">
          {/* <Link
            to="#"
            className="btn btn-default btn-icon btn-sm sidebarmodal-collpase"
            data-toggle="tooltip"
            data-placement="top"
            title="View"
          >
            <i className="feather feather-eye" />
          </Link> */}

          <Link
            className="btn btn-primary btn-icon btn-sm"
            to={"/admin/editerdepartement/" + dep.code}
            onClick={() => setnom(dep.nom)}
          >
            <i
              className="feather feather-edit"
              to={"/admin/editerdepartement/" + dep.code}
              data-toggle="tooltip"
              data-original-title="Edit"
            />
          </Link>
          <Link to="#" className="btn btn-danger btn-icon btn-sm" onClick={handleDelete}>
            <i className="feather feather-trash-2" />
          </Link>
        </td>
      </tr>
    </>
  );
}

export default TableDepartement;
