import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get all tva by code
 */
/**
 * It gets all the tva from the database.
 * @returns The response is an array of objects.
 */
export async function getAllTva() {
  try {
    const response = await axios.get(api_url + "facturation/tva/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add a tax to the database
 * @param libelle - the name of the tax
 * @param taux - the tax rate
 * @returns The response is an object that contains the data, a status code, and a status message.
 */
export async function addTax(libelle, taux) {
  const credentiel = {
    soussociete_code: localStorage.getItem("code_generated"),
    libelle: libelle,
    taux: taux,
  };

  try {
    const response = await axios.post(api_url + "facturation/tva/", credentiel, config);
    console.log(response);
    toast.success("Tax ajouté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It edits a tax by its id.
 * @param libelle - the name of the tax
 * @param taux - the tax rate
 * @param id - id of the tax to edit
 * @returns The response is an object that contains the data, the status code, the headers and the
 * request.
 */
export async function editTaxById(libelle, taux, id) {
  const credentiel = {
    libelle: libelle,
    taux: taux,
    id: id,
  };

  try {
    const response = await axios.put(api_url + "facturation/tva/", credentiel, config);
    console.log(response);
    toast.success("Tax modifié");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It deletes a tax from the database.
 * @param id - The id of the tax to delete.
 * @returns The response is an object with a status code and a body. The body is an object with a
 * message and a code.
 */
export async function deleteTaxById(id) {
  try {
    const response = await axios.delete(api_url + "facturation/tva/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets a tax by id.
 * @param id - The id of the tax you want to retrieve.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "id": 1,
 *   "name": "TVA",
 *   "value": 20,
 *   "createdAt": "2020-04-01T09:00:00.000Z",
 *   "updatedAt": "2020-04-01T09:00:00.
 */
export async function getTaxById(id) {
  try {
    const response = await axios.get(api_url + "facturation/tva/id/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
