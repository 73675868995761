import React from "react";
import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <div className="page error-bg">
      <div className="page-content m-0">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <img src="assets/images/png/error.png" alt="img" className="mt-7" />
            </div>
            <div className="col-md-7 mt-6">
              <div className="display-1 text-primary  mb-2 font-weight-semibold"> Oops!</div>
              <h1 className="h3  mb-3 font-weight-semibold">
                Nous n'avons pas pu trouver la page que vous recherchiez.
              </h1>
              <p className="h5 font-weight-normal mb-7 leading-normal">
                vous pouvez revenir au tableau de bord ou essayer d'utiliser le formulaire de recherche.
              </p>
              <Link className="btn btn-primary" to={"/"}>
                <i className="fe fe-arrow-left-circle mr-1" />
                Tableau de bord
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
