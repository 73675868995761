import React, { useRef, useState } from "react";
import { ajouterAnnonce, senFcmAnnonce } from "../../Services/Pointeuse/AnnonceApi";
import { senFcmConge } from "../../Services/Pointeuse/CongeApi";
import firebase from "../../firebase";

function AddAnnonce(props) {
  const myRefClose = useRef(null);
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setimageName] = useState("Choisir un document ...");
  const [type, settype] = useState("identite");
  const [title, settitle] = useState(null);
  const [dateDebut, setdateDebut] = useState(null);
  const [dateFin, setdateFin] = useState(null);
  const [description, setdescription] = useState(null);

  /**
   * It sets the imageUrl to the image file that is uploaded.
   * @param event - The event that triggered the function.
   */
  const handleUploadFiles = (event) => {
    //setFilesToUpload(URL.createObjectURL(event.target.files[0]));
    setImageUrl(event.target.files[0]);
    setimageName(event.target.files[0].name);
  };

  /**
   * It adds a new annonce to the database.
   */
  const submitAnnonce = () => {
    ajouterAnnonce(title, description, dateDebut, dateFin, imageUrl).then(() => {
      myRefClose.current.click();
      props.getData();
      sendFirebaseNotification(title, description);
    });
  };

  /**
   * It sends a notification to the user.
   * @param title - The title of the notification.
   * @param description - The description of the notification.
   */
  const sendFirebaseNotification = (title, description) => {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then(() => {
        senFcmAnnonce(title, description);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="modal fade" id="newAnnoncemodal">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Ajouter note de service</h5>
            <button className="close" data-dismiss="modal" aria-label="Close" ref={myRefClose}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-label">Titre</label>
                  <input
                    className="form-control"
                    placeholder="..."
                    type="text"
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Date début:</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="feather feather-calendar" />
                      </div>
                    </div>
                    <input
                      className="form-control fc-datepicker"
                      placeholder="DD-MM-YYY"
                      type="date"
                      onChange={(e) => setdateDebut(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Date fin:</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="feather feather-calendar" />
                      </div>
                    </div>
                    <input
                      className="form-control fc-datepicker"
                      placeholder="DD-MM-YYY"
                      type="date"
                      onChange={(e) => setdateFin(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">Description:</label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={3}
                onChange={(e) => setdescription(e.target.value)}
              />
            </div>
            <div className="input-group file-browser">
              <input
                type="text"
                className="form-control browse-file"
                placeholder={imageName || "choisir un fichier ..."}
              />
              <label className="input-group-append">
                <span className="btn btn-primary">
                  choisir fichier <input type="file" style={{ display: "none" }} onChange={handleUploadFiles} />
                </span>
              </label>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-outline-primary" data-dismiss="modal">
              Fermer
            </button>
            <button className="btn btn-success" onClick={submitAnnonce}>
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAnnonce;
