import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get entete achat by code
 */
export async function getEnteteCommercialAchat() {
  try {
    const response = await axios.get(api_url + "facturation/entete/achat/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get entete vente by code
 */
export async function getEnteteCommercialVente() {
  try {
    const response = await axios.get(api_url + "facturation/entete/vente/" + getSession("code_generated"), config);

    response.data.sort((a, b) => {
      return parseFloat(b.facture_code.split("_")[1]) - parseFloat(a.facture_code.split("_")[1]);
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get entete achat devis by code
 */
export async function getEnteteCommercialAchatDevis() {
  try {
    const response = await axios.get(api_url + "facturation/entete/devis/achat/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get entete vente devis by code
 */
export async function getEnteteCommercialVenteDevis() {
  try {
    const response = await axios.get(api_url + "facturation/entete/devis/vente/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get entete achat bl by code
 */
export async function getEnteteCommercialAchatBl() {
  try {
    const response = await axios.get(api_url + "facturation/entete/bl/achat/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get entete vente bl by code
 */
export async function getEnteteCommercialVenteBl() {
  try {
    const response = await axios.get(api_url + "facturation/entete/bl/vente/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get entete achat commande by code
 */
export async function getEnteteCommercialAchatCommande() {
  try {
    const response = await axios.get(api_url + "facturation/entete/commande/achat/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get entete vente commande by code
 */
export async function getEnteteCommercialVenteCommande() {
  try {
    const response = await axios.get(api_url + "facturation/entete/commande/vente/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets the entete of a facturation by its code.
 * @param code - The code of the invoice to retrieve.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "code": "",
 *   "date": "",
 *   "description": "",
 *   "entete": "",
 *   "montant": 0,
 *   "numero": "",
 *   "type": ""
 * }
 * ```
 */
export async function getEnteteByCode(code) {
  try {
    const response = await axios.get(api_url + "facturation/entete/code/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets the entete by client code.
 * @param code - The client code of the client you want to get the entete for.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "entete": {
 *       "id": "string",
 *       "code": "string",
 *       "date": "string",
 *       "client": {
 *         "id": "string",
 *         "code": "string",
 *         "name":
 */
export async function getEnteteByClientCode(code) {
  try {
    const response = await axios.get(api_url + "facturation/entete/entetebycode/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function addEntete(
  code,
  code_commande,
  facture_code,
  document,
  dossier,
  type,
  client_code,
  client_nom,
  date,
  taux_tva_1,
  tva_1,
  taux_tva_2,
  tva_2,
  taux_tva_3,
  tva_3,
  timbre,
  montant_total_ht,
  montant_total_ttc,
  montant_total_tva,
  net_a_payer,
  remise_total,
  remise_commande,
  remise_action,
  remise_valeur,
  adresse,
  date_prevu_livraison,
  commentaire,
  createdAtFacture
) {
  const credentiel = {
    code: code,
    code_commande: code_commande,
    code_a_barre: "",
    facture_code: facture_code,
    visite_code: "",
    document: document,
    dossier: dossier,
    type: type,
    envoi_cmd: "0",
    societe_code: getSession("code_generated"),
    soussociete_code: getSession("code_generated"),
    commercial_code: getSession("id"),
    user_code: getSession("id"),
    depot_code: getSession("id"),
    client_code: client_code,
    caisse_code: "",
    client_nom: client_nom,
    date: date,
    tax: "0",
    taux_tva_1: taux_tva_1,
    tva_1: tva_1,
    taux_tva_2: taux_tva_2,
    tva_2: tva_2,
    taux_tva_3: taux_tva_3,
    tva_3: tva_3,
    taux_tva: "0",
    timbre: timbre,
    mode_paiement: "",
    montant_retenue: "0",
    montant_total_ht: montant_total_ht,
    montant_total_ttc: montant_total_ttc,
    montant_total_tva: montant_total_tva,
    retenue_source: "0",
    net_a_payer: net_a_payer,
    net_a_payer_ht: "0",
    remise_ligne_ht: "0",
    impot_forfaitaire: "0",
    remise_total: remise_total,
    remiseEspece: "0",
    remise_pied: "0",
    remise_commande: remise_commande,
    remise_action: remise_action,
    remise_valeur: remise_valeur,
    longitude: "",
    latitude: "",
    adresse: adresse,
    wavesoft: "",
    demande_facture: "",
    cloture: "",
    cloture_bl: "0",
    date_prevu_livraison: date_prevu_livraison,
    annule: "",
    solde: "0",
    marque: "0",
    hors_client: "0",
    cause_non_vente: "",
    commentaire: commentaire,
    produit_annonce_non_vendu: "",
    code_abonnement: "",
    is_deleted: "0",
    deleted_at: "",
    created_at: createdAtFacture,
    updated_a: "",
  };

  try {
    const response = await axios.post(api_url + "facturation/entete/", credentiel, config);
    console.log(response);
    toast.success("entete ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
/**
 * delete entete by code
 */
export async function deleteEnteteByCode(code) {
  try {
    const response = await axios.delete(api_url + "facturation/entete/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Edit an entete
 * @param code_commande - The code of the commande.
 * @param facture_code - The code of the invoice.
 * @param document - the document type (invoice, order, etc.)
 * @param type - type of document (invoice, order, ...)
 * @param client_code - client_code,
 * @param client_nom - client_nom,
 * @param date - Date of the invoice
 * @param taux_tva_1 - taux_tva_1,
 * @param tva_1 - taux_tva_1,
 * @param taux_tva_2 - taux_tva_2,
 * @param tva_2 - taux_tva_2,
 * @param taux_tva_3 - taux_tva_3,
 * @param tva_3 - taux_tva_3,
 * @param timbre - the amount of the timbre
 * @param montant_total_ht - montant_total_ht,
 * @param montant_total_ttc - Total TTC
 * @param montant_total_tva - The total amount of VAT.
 * @param net_a_payer - net_a_payer,
 * @param remise_total - remise_total,
 * @param remise_commande - remise_commande,
 * @param remise_action - The amount of the discount.
 * @param adresse - adresse,
 * @param date_prevu_livraison - date_prevu_livraison,
 * @param commentaire - commentaire,
 * @param code - the code of the entete
 * @returns The response is an object that contains the data that was returned by the API.
 */
export async function editEntete(
  code_commande,
  facture_code,
  document,
  type,
  client_code,
  client_nom,
  date,
  taux_tva_1,
  tva_1,
  taux_tva_2,
  tva_2,
  taux_tva_3,
  tva_3,
  timbre,
  montant_total_ht,
  montant_total_ttc,
  montant_total_tva,
  net_a_payer,
  remise_total,
  remise_commande,
  remise_action,
  adresse,
  date_prevu_livraison,
  commentaire,
  code
) {
  const credentiel = {
    code_commande: code_commande,
    facture_code: facture_code,
    visite_code: "",
    document: document,
    dossier: "",
    type: type,
    envoi_cmd: "0",
    societe_code: getSession("code_generated"),
    soussociete_code: getSession("code_generated"),
    commercial_code: getSession("id"),
    user_code: getSession("id"),
    depot_code: getSession("id"),
    client_code: client_code,
    caisse_code: "",
    client_nom: client_nom,
    date: date,
    tax: "0",
    taux_tva_1: taux_tva_1,
    tva_1: tva_1,
    taux_tva_2: taux_tva_2,
    tva_2: tva_2,
    taux_tva_3: taux_tva_3,
    tva_3: tva_3,
    taux_tva: "0",
    timbre: timbre,
    mode_paiement: "",
    montant_retenue: "0",
    montant_total_ht: montant_total_ht,
    montant_total_ttc: montant_total_ttc,
    montant_total_tva: montant_total_tva,
    retenue_source: "0",
    net_a_payer: net_a_payer,
    net_a_payer_ht: "0",
    remise_ligne_ht: "0",
    impot_forfaitaire: "0",
    remise_total: remise_total,
    remiseEspece: "0",
    remise_pied: "0",
    remise_commande: remise_commande,
    remise_action: remise_action,
    longitude: "",
    latitude: "",
    adresse: adresse,
    wavesoft: "",
    demande_facture: "",
    cloture: "",
    cloture_bl: "0",
    date_prevu_livraison: date_prevu_livraison,
    annule: "",
    solde: "0",
    marque: "0",
    hors_client: "0",
    cause_non_vente: "",
    commentaire: commentaire,
    produit_annonce_non_vendu: "",
    is_deleted: "0",
    updated_at: "",
    code: code,
  };

  try {
    const response = await axios.patch(api_url + "facturation/entete/", credentiel, config);
    console.log(response);
    toast.success("entete modifié");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Edit the solde of the entete by code
 * @param solde - the new solde
 * @param code - the code of the solde to edit
 * @returns The response is an object that contains the data, status, and other information returned by
 * the server.
 */
export async function editSoldeEnteteByCode(solde, code) {
  const credentiel = {
    solde: solde,
    code: code,
  };
  try {
    const response = await axios.patch(api_url + "facturation/entete/solde", credentiel, config);
    console.log(response);
    toast.success("solde modifié");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Edit the send email facture
 * @param code - the code of the facture
 * @returns The response is an object that contains the data that we want.
 */
export async function editSendEmailFacture(code) {
  const credentiel = {
    code: code,
  };
  try {
    const response = await axios.patch(api_url + "facturation/entete/updatesendedemail", credentiel, config);
    console.log(response);
    //toast.success("solde modifié");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Edit the solde of the entete by code facture
 * @param solde - the new solde value
 * @param code - The code of the facture
 * @returns The response is an object that contains the data, status, and other information returned by
 * the server.
 */
export async function editSoldeEnteteByCodeFacture(solde, code) {
  const credentiel = {
    solde: solde,
    facture_code: code,
  };
  try {
    const response = await axios.patch(api_url + "facturation/entete/soldeachat", credentiel, config);
    console.log(response);
    toast.success("solde modifié");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * update cinrecto
 * @param cin_recto
 * @param user_code_id
 */
export async function uploadDechargeFacuteByCode(image, code) {
  const frmData = new FormData();
  frmData.append("image", image);
  frmData.append("code", code);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.patch(api_url + "facturation/entete/decharge", frmData, options);
    toast.success("mise à jour");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Edit the entete after delete
 * @param code - the code of the entete to update
 * @returns The response is an object that contains the data that was returned by the server.
 */
export async function editEnteteAfterDelete(code) {
  const credentiel = {
    montant_total_ht: "0",
    montant_total_ttc: "0",
    montant_total_tva: "0",
    net_a_payer: "0",
    net_a_payer_ht: "0",
    remise_ligne_ht: "0",
    remise_total: "0",
    remiseEspece: "0",
    remise_pied: "0",
    remise_commande: "0",
    remise_action: "0",
    solde: "0",
    commentaire: "",
    code: code,
  };

  try {
    const response = await axios.put(api_url + "facturation/entete/update", credentiel, config);
    console.log(response);
    toast.success("entete modifié");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It gets the entete of a subscription.
 * @param code - The code of the subscription.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "code": "A00001",
 *   "date": "2020-01-01",
 *   "montant": "100.00",
 *   "client": {
 *     "code": "C00001",
 *     "nom": "Dupont",
 *     "pren
 */
export async function getEnteteByCodeAbonnement(code) {
  try {
    const response = await axios.get(api_url + "facturation/entete/abonnement/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteDechargeById(id) {
  try {
    const response = await axios.patch(api_url + "facturation/entete/decharge/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
