import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { addDepartement } from "../../Services/Pointeuse/DepartementApi";
import { addDepartementMembre } from "../../Services/Pointeuse/DepartementMemberApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { filterUserByWord } from "../../Utils/SortObject";
import { useHistory } from "react-router-dom";
import ErrorCustum from "../Others/ErrorCustum";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import Pagination from "../Others/Pagination";
import ImageComponent from "../Styles/ImageComponent";

function AddDepartement(props) {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [nom, setnom] = useState(null);
  const history = useHistory();

  /**
   * It fetches all the users from the API and sets the data to the state.
   */
  const fetchAllUser = () => {
    setLoading(true);
    getUsersByCode()
      .then((res) => {
        const newArr = res.data.map((v) => ({ ...v, isSelected: false }));
        setdata(newArr);
        setallInitialData(newArr);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchAllUser();
  }, []);

  /**
   * *This function is called when the user clicks on the page numbers in the pagination component.*
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the search term.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    if (e.target.value) {
      const dataSearch = filterUserByWord(data, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  /**
   * *This function takes in an id and a boolean value. It then finds the index of the object in the data
   * array that has the same id as the one passed in. It then sets the isSelected property of the object
   * at that index to the opposite of the boolean value passed in.*
   * @param id - The id of the row that was clicked.
   * @param isSelected - This is the current state of the checkbox.
   */
  const handleSelect = (id, isSelected) => {
    const objIndex = data.findIndex((obj) => obj.id == id);
    const list = [...data];
    list[objIndex]["isSelected"] = !isSelected;
    setdata(list);
    //setdata((data[objIndex].isSelected = !isSelected));
  };

  /**
   * It adds a new departement and assign it to the selected users.
   */
  const handleAdd = () => {
    let code = parseInt(Date.now() * Math.random());
    const userSelected = data.filter((el) => {
      return el.isSelected == true;
    });

    addDepartement(code, nom).then((res) => {
      userSelected.forEach((element) => {
        addDepartementMembre(element.id, code);
      });

      //history.push("/admin/departements");
      props.updateData();
    });
  };
  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Nom département</label>
            <input
              onChange={(e) => setnom(e.target.value)}
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Entre nom"
            />
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title mr-4">Collaborateurs</h3>
                  <div className="row"></div>
                  <div className="card-tools">
                    <div className="input-group input-group-sm" style={{ width: 150 }}>
                      <input
                        type="text"
                        name="table_search"
                        className="form-control float-right ml-4"
                        placeholder="Rechercher..."
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body table-responsive p-0">
                  {loading && <CustomSyncLoader></CustomSyncLoader>}
                  {!loading && error && <ErrorCustum></ErrorCustum>}
                  {!loading && !error && data && (
                    <div className=" mt-5">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="text-center"></th>
                              <th></th>
                              <th>Nom et prénonm</th>

                              <th>Role</th>
                              <th>Statut</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el) => (
                              <tr key={el.id}>
                                <td className="text-center">
                                  <input
                                    type="checkbox"
                                    onChange={() => {
                                      handleSelect(el.id, el.isSelected);
                                    }}
                                    checked={el.isSelected}
                                  />
                                </td>
                                <td>
                                  <ImageComponent
                                    atr={"avatar avatar-md brround mr-3"}
                                    picture={el.img}
                                  ></ImageComponent>
                                  {/* <img
                                    src={
                                      el.img == null || el.img == ""
                                        ? "dist/img/user/avatar.png"
                                        : localStorage.getItem("baseUrl") + el.img
                                    }
                                    alt="Avatar"
                                    className="avatar"
                                  ></img> */}
                                </td>
                                <td>
                                  <Link to={"/admin/users/" + el.id}>
                                    {el.nom} <span>{el.prenom}</span>
                                  </Link>
                                </td>

                                <td>{el.fonction}</td>
                                <td>
                                  {el.isactif == 0 ? (
                                    <span className="badge badge-warning">Inactif</span>
                                  ) : (
                                    <span className="badge badge-success">actif</span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
                {/* /.card-body */}
                <div className="card-footer clearfix">
                  <Pagination items={data} onChangePage={onChangePage} pageSize={5} />
                </div>
              </div>
              {/* /.card */}
            </div>
            <div className="d-flex justify-content-center  align-items-center">
              <button type="button" className="btn btn-primary text-center ml-4" onClick={handleAdd}>
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDepartement;
