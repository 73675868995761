import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get Famille by code
 */
export async function getAllFamille() {
  try {
    const response = await axios.get(api_url + "facturation/famille/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets a famille by id.
 * @param id - The id of the famille you want to get.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "id": 1,
 *     "nom": "Famille",
 *     "description": "Famille de produits",
 *     "date_creation": "2020-01-01T00:00:00.000Z",
 *     "date_
 */
export async function getFamilleById(id) {
  try {
    const response = await axios.get(api_url + "facturation/famille/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add a new famille to the database
 * @param libelle - The name of the family
 * @param gamme_code - The code of the gamme
 * @param color - The color of the box.
 * @param hors_box - Boolean
 * @param image - The image file.
 * @returns The response is an object with the following structure:
 */
export async function addFacturationFamille(libelle, gamme_code, color, hors_box, image) {
  const frmData = new FormData();

  frmData.append("soussociete_code", getSession("code_generated"));
  frmData.append("libelle", libelle);
  frmData.append("gamme_code", gamme_code);
  frmData.append("color", color);
  frmData.append("hors_box", hors_box);
  frmData.append("image", image);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.post(api_url + "facturation/famille/", frmData, options);
    console.log(response);
    toast.success("Famille ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It deletes a famille by id.
 * @param id - The id of the famille you want to delete.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "id": "1",
 *     "nom": "Famille 1",
 *     "description": "Famille 1",
 *     "createdAt": "2019-11-26T14:05:00.000Z",
 *     "updatedAt":
 */
export async function deleteFamilleById(id) {
  try {
    const response = await axios.delete(api_url + "facturation/famille/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Update the image of a famille
 * @param image - The image file that you want to upload.
 * @param id - The id of the image to update.
 * @returns The response is an object with the following properties:
 */
export async function updateImageFamille(image, id) {
  const frmData = new FormData();
  frmData.append("image", image);
  frmData.append("id", id);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.put(api_url + "facturation/famille/image", frmData, options);
    toast.success("mise à jour");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It updates a famille.
 * @param libelle - the name of the famille
 * @param gamme_code - The code of the gamme
 * @param color - the color of the box
 * @param hors_box - boolean
 * @param id - The id of the famille to update.
 * @returns The response is an object that contains the data, a status code, and a status message.
 */
export async function updateFamille(libelle, gamme_code, color, hors_box, id) {
  const credentiel = {
    libelle: libelle,
    gamme_code: gamme_code,
    color: color,
    hors_box: hors_box,
    id: id,
  };

  try {
    const response = await axios.patch(api_url + "facturation/famille/", credentiel, config);
    console.log(response);
    toast.success("Famille modifié");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
