import React from "react";
import Activity from "../Components/Activity/Activity";
import Footer from "../Components/shared/Footer";
import Header from "../Components/shared/Header";
import Preloader from "../Components/shared/Preloader";
import Sidebar from "../Components/shared/Sidebar";

function DashboardLayout(props) {
  return (
    <div>
      {/* Switcher */}
      {/* <div className="switcher-wrapper">
        <div className="demo_changer">
          <div className="demo-icon bg_dark">
            <i className="fa fa-cog fa-spin  text_primary" />
          </div>
          <div className="form_holder sidebar-right1">
            <div className="row">
              <div className="predefined_styles">
                <div className="swichermainleft text-center">
                  <div className="p-3">
                    <a href="index.html" className="btn ripple btn-primary btn-block mt-0">
                      View Demo
                    </a>
                    <a
                      href="https://themeforest.net/user/spruko/portfolio"
                      className="btn ripple btn-secondary btn-block"
                    >
                      Buy Now
                    </a>
                    <a href="https://themeforest.net/user/spruko/portfolio" className="btn ripple btn-red btn-block">
                      Our Portfolio
                    </a>
                  </div>
                </div>
                <div className="swichermainleft mb-4">
                  <h4>Navigation Style</h4>
                  <div className="pl-3 pr-3 pt-3">
                    <a className="btn ripple btn-success btn-block" href="verticalmenu.html">
                      {" "}
                      Leftmenu
                    </a>
                    <a className="btn ripple btn-danger btn-block" href="horizontal.html">
                      {" "}
                      Horizontal{" "}
                    </a>
                  </div>
                </div>
                <div className="swichermainleft">
                  <h4>Theme Layout</h4>
                  <div className="switch_section d-flex my-4">
                    <ul className="switch-buttons row">
                      <li className="col-md-6 mb-0">
                        <button type="button" id="background-left1" className="bg-left1 wscolorcode1 button-image" />
                        <span className="d-block">Light Theme</span>
                      </li>
                      <li className="col-md-6 mb-0">
                        <button type="button" id="background-left2" className="bg-left2 wscolorcode1 button-image" />
                        <span className="d-block">Dark Theme</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="swichermainleft">
                  <h4>Header Styles Mode</h4>
                  <div className="switch_section d-flex my-4">
                    <ul className="switch-buttons row">
                      <li className="col-md-6 light-bg">
                        <button type="button" id="background1" className="bg1 wscolorcode1 button-image" />
                        <span className="d-block">Light Header</span>
                      </li>
                      <li className="col-md-6">
                        <button type="button" id="background2" className="bg2 wscolorcode1 button-image" />
                        <span className="d-block">Color Header</span>
                      </li>
                      <li className="col-md-6 d-block mx-auto dark-bg">
                        <button type="button" id="background3" className="bg3 wscolorcode1 button-image" />
                        <span className="d-block">Dark Header</span>
                      </li>
                      <li className="col-md-6 d-block mx-auto">
                        <button type="button" id="background11" className="bg8 wscolorcode1 button-image" />
                        <span className="d-block">Gradient Header</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="swichermainleft">
                  <h4>Leftmenu Styles Mode</h4>
                  <div className="switch_section d-flex my-4">
                    <ul className="switch-buttons row">
                      <li className="col-md-6 mb-4">
                        <button type="button" id="background4" className="bg4 wscolorcode1 button-image" />
                        <span className="d-block">Light Menu</span>
                      </li>
                      <li className="col-md-6 mb-4">
                        <button type="button" id="background5" className="bg5 wscolorcode1 button-image" />
                        <span className="d-block">Color Menu</span>
                      </li>
                      <li className="col-md-6 mb-0 d-block mx-auto dark-bgmenu">
                        <button type="button" id="background6" className="bg6 wscolorcode1 button-image" />
                        <span className="d-block">Dark Menu</span>
                      </li>
                      <li className="col-md-6 mb-0 d-block mx-auto">
                        <button type="button" id="background10" className="bg7 wscolorcode1 button-image" />
                        <span className="d-block">Gradient Menu</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* End Switcher */}
      {/*-Global-loader*/}
      {/* <Preloader></Preloader> */}
      <div className="page">
        <div className="page-main">
          {/*aside open*/}
          <Sidebar></Sidebar>
          {/*aside closed*/}
          <div className="app-content main-content">
            <div className="side-app">
              {/*app header*/}
              <Header></Header>
              {/*/app header*/}
              {/*Page header*/}
              {/* <div className="m-2"> */}
              <React.Fragment>{props.children}</React.Fragment>
              {/* </div> */}
            </div>
          </div>
          {/* end app-content*/}
        </div>
        {/*Footer*/}
        <Footer></Footer>
        {/* End Footer*/}
        {/*Sidebar-right*/}
        <Activity />
        {/*/Sidebar-right*/}
        {/*Clock-IN Modal */}
        <div className="modal fade" id="clockinmodal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  <span className="feather feather-clock  mr-1" />
                  Clock In
                </h5>
                <button className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="countdowntimer">
                  <span id="clocktimer" className="border-0" />
                </div>
                <div className="form-group">
                  <label className="form-label">Note:</label>
                  <textarea className="form-control" rows={3} defaultValue={"Some text here..."} />
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-outline-primary" data-dismiss="modal">
                  Close
                </button>
                <button className="btn btn-primary">Clock In</button>
              </div>
            </div>
          </div>
        </div>
        {/* End Clock-IN Modal  */}
        {/*Change password Modal */}
        <div className="modal fade" id="changepasswordnmodal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Change Password</h5>
                <button className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-label">New Password</label>
                  <input type="password" className="form-control" placeholder="password" defaultValue />
                </div>
                <div className="form-group">
                  <label className="form-label">Confirm New Password</label>
                  <input type="password" className="form-control" placeholder="password" defaultValue />
                </div>
              </div>
              <div className="modal-footer">
                <a href="#" className="btn btn-outline-primary" data-dismiss="modal">
                  Close
                </a>
                <a href="#" className="btn btn-primary">
                  Confirm
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* End Change password Modal  */}
      </div>
    </div>
  );
}

export default DashboardLayout;
