import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import { ToastContainer } from "react-toastify";
import { addTax } from "../../../Services/Facturation/FacturationTvaApi";

function AddTax(props) {
  const history = useHistory();
  const [libelle, setlibelle] = useState("");
  const [taux, settaux] = useState("");
  const myRefHide = useRef(null);

  const handleSaveTax = () => {
    addTax(libelle, taux).then(() => {
      props.getData();
      myRefHide.current.click();
      // history.push("/tax");
      // history.go(0);
    });
  };
  return (
    <div
      className="modal fade"
      id="taxModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="taxModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Ajouter tax
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="control-label">
                Libelle <span className="text-danger">*</span>
              </label>
              <input className="form-control" type="text" onChange={(e) => setlibelle(e.target.value)} />
            </div>
            <div className="form-group">
              <label className="control-label">
                Taux (%) <span className="text-danger">*</span>
              </label>
              <input className="form-control" type="text" onChange={(e) => settaux(e.target.value)} />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={myRefHide}>
              Annuler
            </button>
            <button type="button" className="btn btn-primary" onClick={handleSaveTax}>
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddTax;
