import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all the retards for a user
 * @param id - The id of the user you want to get the retards for.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": [
 *     {
 *       "id": 1,
 *       "userId": 1,
 *       "date": "2020-04-01T00:00:00.000Z",
 *       "comment": "I was late today",
 *       "createdAt": "2020
 */
export async function getRetardByUserId(id) {
  try {
    const response = await axios.get(api_url + "retard/userid/" + id, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get the retard by code
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "code": "",
 *   "message": "",
 *   "data": {
 *     "id": "",
 *     "code": "",
 *     "date": "",
 *     "time": "",
 *     "type": "",
 *     "user": {
 *       "
 */
export async function getRetardByCode() {
  try {
    const response = await axios.get(api_url + "retard/code/" + getSession("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It updates a questionnaire by id.
 * @param questionnaire - the questionnaire to update
 * @param id - the id of the questionnaire to update
 * @returns The response is an object with the following properties:
 */
export async function updateRetardById(questionnaire, id) {
  const credentiel = {
    questionnaire: questionnaire,
    id: id,
  };
  console.log(credentiel);

  try {
    const response = await axios.put(api_url + "retard/updatequestionnaire", credentiel, config);
    console.log(response);
    //toast.success("mise à jour");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Send a notification to a specific user
 * @param text - The text of the notification.
 * @param receiver - The receiver of the notification.
 * @returns The response from the server.
 */
export async function sendFcmRetardWargn(text, receiver) {
  const notification = {
    data: {
      title: "Avertissement",
      message: `Vous avez ${text} retard consécutif ce mois`,
    },
    to: "/topics/" + localStorage.getItem("code_generated") + "user" + receiver,
  };
  const fcm_server_key =
    "AAAAyFwzY3I:APA91bGIPThuQusFacH13cip7zdnDNY81mhV1xJV5pQXjqoHaIUwcibidgFahbxnjAxWODcIR0pew1a3tYlVUQNPLpx7nIanisZieGr0ZaMcwSqDvmcvyr9YjJK3yfoEbwu82sWDhL7E";

  const g = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `key=${fcm_server_key}`,
    },
  };
  try {
    const response = await axios.post("https://fcm.googleapis.com/fcm/send", notification, g);
    console.log(response);

    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js

      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
