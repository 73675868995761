import userEvent from "@testing-library/user-event";
import moment from "moment";
import { parseMillisecondsIntoReadableTime } from "./GlobalTableUtils";
import { MultiplicateNumber } from "./NumberUtils";

/**
 * compare two time
 * @param {*} d1
 * @param {*} d2
 */
export function compareTwoTime(t1, t2) {
  if (t1 > t2) {
    return true;
  } else {
    return false;
  }
}

/**
 * check if date is greater than other date
 * @param {} d1
 * @param {*} d2
 */
export function compareTwoDate(d1, d2) {
  if (d1 > d2) {
    return true;
  } else {
    return false;
  }
}

/**
 * soustraire date from now date
 */
export function soustractDateFromToday(d1) {
  const date1 = new Date(d1);
  const date2 = Date.now();
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return Math.round(diffDays / 365);
}

/**
 * get date name
 */
export function getMonthName(month) {
  const monthNames = ["Jan", "Fév", "Mar", "Apr", "May", "Juin", "Juil", "août", "Sept", "Oct", "Nov", "Déc"];
  return monthNames[month - 1];
}

export function addNumberToDate(number, date) {
  var someDate = new Date(date);
  someDate.setDate(someDate.getDate() + number);
  var dd = someDate.getDate();
  var mm = someDate.getMonth() + 1;
  var y = someDate.getFullYear();
  var someFormattedDate = y + "-" + mm + "-" + dd;
  return someFormattedDate;
}

export function addNumberToCongeDate(number, date, days) {
  var someDate = new Date(date);
  var newNumber = number;
  if (number > days) {
    newNumber = number + 2;
  }
  someDate.setDate(someDate.getDate() + newNumber);
  var dd = someDate.getDate();
  var mm = someDate.getMonth() + 1;
  var y = someDate.getFullYear();
  var someFormattedDate = y + "-" + mm + "-" + dd;
  return someFormattedDate;
}

/**
 * Subtract a number of days from a date and return the result as a string
 * @param number - The number of days to subtract from the date.
 * @param date - The date to subtract from.
 * @returns The date of the day before the date that was passed in.
 */
export function soustractNumberToDate(number, date) {
  var someDate = new Date(date);
  someDate.setDate(someDate.getDate() - number);
  var dd = someDate.getDate();
  var mm = someDate.getMonth() + 1;
  var y = someDate.getFullYear();
  var someFormattedDate = y + "-" + mm + "-" + dd;
  return someFormattedDate;
}

/**
 * Given a start date and end date, return an array of objects that fall between the two dates
 * @param start - The start date.
 * @param end - The end date of the range.
 * @param arrayObject - the array of objects that you want to filter
 * @returns An array of objects.
 */
export function dateBetweenTwoDates(start, end, arrayObject) {
  var from = new Date(start);
  var to = new Date(end);
  const newList = arrayObject.filter((el) => new Date(el.date) > from && new Date(el.date) < to);
  return newList;
}

/**
 * Check if the date is between two dates
 * @param dateEcheance - The date of the invoice.
 * @returns a boolean value.
 */
export function checkDateBetweenTwoDates(dateEcheance) {
  if (dateEcheance) {
    let dateWithPeriod = new Date(new Date(dateEcheance).getTime() - 15 * 24 * 60 * 60 * 1000);

    //3
    var currentDate = new Date().toJSON().slice(0, 10);
    var periodFormattedDate = dateWithPeriod.toJSON().slice(0, 10);
    var d1 = dateEcheance.split("-");
    var c = currentDate.split("-");
    var d2 = periodFormattedDate.split("-");
    var from = new Date(d2);
    var to = new Date(d1);
    var check = new Date(c);
    if (to.getTime() > check.getTime() && from.getTime() < check.getTime()) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
  //2
}

/**
 * get current date
 * @returns
 */
export function getCurrentDate() {
  var today = new Date();
  return today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
}

/**
 * check if date is greater than other date
 * @param {} d1
 * @param {*} d2
 */
export function compareDate(date) {
  //date today
  var d1 = new Date();
  //formated date
  var d2 = new Date(date);
  if (d1.getTime() > d2.getTime()) {
    return true;
  } else {
    return false;
  }
}

/**
 * Given a year and a month, return the number of days in that month
 * @param year - The year of the date to get the number of days for.
 * @param month - The month number to get the days for.
 * @returns The number of days in the month.
 */
export function daysInMonth(year, month) {
  return new Date(year, month + 1, 0).getDate();
}

/**
 * Given a birth date, return the age in years
 * @param birthDate - The date of birth of the person whose age you want to find.
 */
export const getAge = (birthDate) => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

/**
 * Add a number of months to a date
 * @param date - The date to add months to.
 * @param months - The number of months to add to the date.
 * @param cof - The number of months to add to the date.
 * @returns The date of the first day of the month after the month that was passed in.
 */
export function addMonthToDate(date, months, cof) {
  const result = months * cof;
  var dd = new Date(date);
  dd.setMonth(dd.getMonth() + result);
  const finalDate = dd.toJSON().slice(0, 10);
  // console.log("**********");
  // console.log(finalDate);
  // console.log("**********");
  return finalDate;
}

/**
 * Add a number of months to a date
 * @param date - The date to add the months to.
 * @param months - The number of months to add to the date.
 * @param multip - The number of months to add to the date.
 * @returns a date that is the number of months from the date passed in.
 */
export function addMonthToDateEcheance(date, months, multip) {
  const result = months * multip;
  var d1 = new Date(date);
  var target_month = d1.getMonth() + (parseInt(result) + 1);
  var year = d1.getFullYear() + parseInt(target_month / 12);
  var month = target_month % 12;
  var day = d1.getDate();
  var last_day = daysInMonth(year, month);
  if (day > last_day) {
    day = last_day;
  }
  var someFormattedDate = year + "-" + month + "-" + day;
  return someFormattedDate;
}

/**
 * Get the full month name from a month number
 * @param month - The month number (1-12).
 * @returns The full name of the month.
 */
export function getFullMonthName(month) {
  const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "août", "Septembre", "Octobre", "Novembre", "Décembre"];
  return monthNames[month - 1];
}

/**
 * Get the current date and time
 * @returns The current date and time.
 */
export function getCurrentDateTime() {
  var today = new Date();
  var date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  return date + " " + time;
}

/**
 * Given a date, return the number of milliseconds between the date and the current date
 * @param date - The date to calculate the distance to.
 * @returns The number of milliseconds between the current date and the birthday.
 */
export function distanceToBirthday(date) {
  let currDate = new Date();
  currDate.setHours(0, 0, 0, 0);
  let currYear = currDate.getFullYear();

  let offset = new Date();
  offset.setHours(0, 0, 0, 0);
  offset.setFullYear(currYear + 1);

  date = new Date(date + " 00:00");
  date.setFullYear(currYear);

  let diff = date - currDate;
  return diff < 0 ? diff + offset.getTime() : diff;
}

/**
 * Get a list of upcoming birthdays
 */
export function getUpcomingBirthdays() {
  const birthdays = [
    ["Firstname Lastname", "1979-01-03", "40"],
    ["Firstname Lastname", "1996-01-23", "23"],
    ["Firstname Lastname", "1977-01-28", "41"],
    ["Firstname Lastname", "1983-03-11", "35"],
    ["Firstname Lastname", "1977-03-30", "41"],
    ["Firstname Lastname", "1975-05-08", "43"],
  ];
}

/**
 * Get the current date and time in a format that can be used as a file name
 * @returns a string that is the current date and time.
 */
export function getDateTime() {
  var now = new Date();
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  var hour = now.getHours();
  var minute = now.getMinutes();
  var second = now.getSeconds();
  if (month.toString().length == 1) {
    month = "0" + month;
  }
  if (day.toString().length == 1) {
    day = "0" + day;
  }
  if (hour.toString().length == 1) {
    hour = "0" + hour;
  }
  if (minute.toString().length == 1) {
    minute = "0" + minute;
  }
  if (second.toString().length == 1) {
    second = "0" + second;
  }
  var dateTime = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
  return dateTime;
}

/**
 * Check if the current date is between two dates
 * @param start - The start date of the time period you want to compare against.
 * @param end - The date to compare against.
 * @returns a boolean value.
 */
export function getCompareTodayBetweenTwoDate(start, end) {
  var check = new Date();
  var from = new Date(start);
  var to = new Date(end);
  if (check.getTime() <= to.getTime() && check.getTime() >= from.getTime()) {
    return true;
  } else {
    return false;
  }
}

/**
 * This function compares two times and returns true if the first time is before the second time.
 * @param start - The start time of the job.
 * @param end - The end time of the job.
 * @returns a boolean value.
 */
export function getCompareTwoTime(start, end) {
  var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
  if (parseInt(end.replace(regExp, "$1$2$3")) > parseInt(start.replace(regExp, "$1$2$3"))) {
    return false;
  } else {
    return true;
  }
}

export function differenceBetweenTwoTime(start, end) {
  if (end.length > 2) {
    var time_start = new Date();
    var time_end = new Date();
    var value_start = start.split(":");
    var value_end = end.split(":");
    time_start.setHours(value_start[0], value_start[1], value_start[2], 0);
    time_end.setHours(value_end[0], value_end[1], value_end[2], 0);
    const mili = time_end - time_start;
    return parseMillisecondsIntoReadableTime(mili); // millisecond
  } else {
    return "00:00:00";
  }
}

const timeToMilliseconds = (timeBreak) => {
  var a = timeBreak.split(":");
  const second = Number(a[2]);
  const minutes = Number(a[1]);
  const houres = Number(a[0]);
  return (houres * 60 * 60 + minutes * 60 + second) * 1000;
};

export function differenceBetweenTwoTimeWithBreak(start, end, timeBreak) {
  if (end.length > 2) {
    var time_start = new Date();
    var time_end = new Date();
    var value_start = start.split(":");
    var value_end = end.split(":");
    time_start.setHours(value_start[0], value_start[1], value_start[2], 0);
    time_end.setHours(value_end[0], value_end[1], value_end[2], 0);
    const mili = time_end - time_start;
    const calculateBreakMili = timeToMilliseconds(timeBreak);
    const miliWithoutBreak = Number(mili) - Number(calculateBreakMili);
    return parseMillisecondsIntoReadableTime(miliWithoutBreak); // millisecond
  } else {
    return "00:00:00";
  }
}

const getTimeCheckin = (word) => {
  let timeCheckin = word.split(",");
  return timeCheckin[0].split(" ")[1];
};

const getTimeCheckout = (word) => {
  let timeCheckout = word.split(",");
  if (timeCheckout.length === 1) {
    return "-";
  } else {
    return timeCheckout[timeCheckout.length - 1].split(" ")[1];
  }
};

export function sumTime(arrayList) {
  const me = arrayList.map((el) => {
    const pe = getTimeCheckin(el.pointage);
    const ps = getTimeCheckout(el.pointage);
    const diff = differenceBetweenTwoTime(pe, ps);
    return { pe: pe, ps: ps, diff: diff };
  });
  const sum = me.reduce((acc, time) => acc.add(moment.duration(time.diff)), moment.duration());
  const total = [Math.floor(sum.asHours()), sum.minutes(), sum.seconds()].join(":");
  return total;
}

export function sumTimeWithBreak(arrayList, breakTime) {
  const me = arrayList.map((el) => {
    const pe = getTimeCheckin(el.pointage);
    const ps = getTimeCheckout(el.pointage);
    const diff = differenceBetweenTwoTimeWithBreak(pe, ps, breakTime);
    return { pe: pe, ps: ps, diff: diff };
  });
  const sum = me.reduce((acc, time) => acc.add(moment.duration(time.diff)), moment.duration());
  const total = [Math.floor(sum.asHours()), sum.minutes(), sum.seconds()].join(":");
  return total;
}

export function exportDataPointage(arrayList, breakTime, user) {
  const dataToExport = arrayList.map((el) => {
    const pe = getTimeCheckin(el.pointage);
    const ps = getTimeCheckout(el.pointage);
    const diff = differenceBetweenTwoTime(pe, ps);
    const diffBreak = differenceBetweenTwoTimeWithBreak(pe, ps, breakTime);
    const d = el.pointage.split(" ")[0];
    return { date: d, "pointage d'entrée": pe, "pointage de sortie": ps, "heure de travail": diff, "heure de travail avec pause": diffBreak };
  });

  const result = [
    ...dataToExport,
    { "total heure": sumTime(arrayList), "total heure avec pause": sumTimeWithBreak(arrayList, breakTime), nom: `${user?.nom} ${user?.prenom}` },
  ];
  return result;
}
