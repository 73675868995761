import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken, getSession } from "../../Utils/SessionUtils";
import moment from "moment";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It gets all the session that the user has created.
 * @returns The response is an array of objects. Each object contains the session id, the session name,
 * the session description, the session start date, the session end date, the session status, the
 * session type, the session location, the session instructor, the session instructor email, the
 * session instructor phone, the session instructor picture, the session instructor bio, the session
 * instructor website, the session instructor company, the
 */
export async function getAllSession() {
  try {
    const response = await axios.get(api_url + "session/" + localStorage.getItem("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getSessionByUserId() {
  try {
    return await axios.get(`${api_url}sal`);
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get a session by its code
 * @returns The response is an object with the following properties:
 */
export async function getSessionByCode() {
  try {
    const response = await axios.get(api_url + "session/code/" + getSession("codeCnx"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It adds a session to the database.
 * @param obj - the object that contains the information about the user
 * @param code - The code generated by the user
 * @param id - the user id
 * @param codecnx - the code of the connection
 * @returns The response from the server.
 */
export async function ajouterSession(obj, code, id, codecnx) {
  const credentiel = {
    code_generated: code,
    user_id: id,
    code: codecnx,
    date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    continent: obj.continent_name,
    country: obj.country_name,
    ip: obj.ip,
    latitude: obj.latitude,
    longitude: obj.longitude,
    currency: obj.currency.name,
    browser: obj.browser,
    os: obj.os,
  };
  try {
    const response = await axios.post(api_url + "session", credentiel, config);
    console.log(response);
    // toast.success("Congé accepté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * `deleteSessionById` deletes a session by id
 * @param id - The id of the session to delete.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "id": "string",
 *   "name": "string",
 *   "description": "string",
 *   "created": "string",
 *   "updated": "string",
 *   "status": "string",
 *   "questions": [
 *     {
 *       "id": "
 */
export async function deleteSessionById(id) {
  try {
    const response = await axios.delete(api_url + "session/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Send an email to the user with a link to the session
 * @param obj - the object that contains the information about the user
 * @param email - the email address of the user
 * @returns The response from the server.
 */
export async function senEmailSession(obj, email) {
  const credentiel = {
    email: email,
    date: moment(new Date()).format("YYYY-MM-DD , hh:mm:ss"),
    continent: obj.continent_name,
    country: obj.country_name,
    ip: obj.ip,
    latitude: obj.latitude,
    longitude: obj.longitude,
    currency: obj.currency.name,
    browser: obj.browser,
    os: obj.os,
  };
  try {
    const response = await axios.post(api_url + "loginemail", credentiel, config);
    console.log(response);
    // toast.success("Congé accepté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
