import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getChequeById, updateChequeById } from "../../Services/Pointeuse/ChequeApi";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function UpdateCheque(props) {
  const history = useHistory();
  const alert = useAlert();

  const [nom, setnom] = useState(null);
  const [montant, setmontant] = useState(null);
  const [numero, setnumero] = useState(null);
  const [date, setdate] = useState(null);
  const [image, setimage] = useState(null);
  const [files, setFiles] = useState(null);

  useEffect(() => {
    getChequeById(props.match.params.id).then((res) => {
      setnom(res.data.nom);
      setmontant(res.data.montant);
      setdate(res.data.date);
      setnumero(res.data.numero);
      setimage(res.data.image);
    });
  }, []);

  const handleSubmitEncaissement = (e) => {
    e.preventDefault();
    if (files) {
      updateChequeById(props.match.params.id, date, nom, montant, numero, files[0].file).then((res) => {
        history.push("/admin/cheque");
      });
    } else {
      updateChequeById(props.match.params.id, date, nom, montant, numero, null).then((res) => {
        history.push("/admin/cheque");
      });
    }
  };

  return (
    <div className="col-xl-12 col-md-12 col-lg-12 mt-4">
      <div className="card">
        <div className="card-header  border-0">
          <h4 className="card-title">Modifié Chèque # {props.match.params.id}</h4>
        </div>
        <div className="card-body">
          <div className="container-fluid">
            <div class="row">
              {/* <div class="col-sm-12 col-lg-6">
            <img style={{ marginTop: 130 }} src="/dist/img/encaissement/encaissement.svg"></img>
          </div> */}
              <div class="col-sm-12 col-lg-12">
                <form style={{ marginTop: 50 }} onSubmit={handleSubmitEncaissement}>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label>Nom</label>
                        <input type="text" className="form-control" value={nom} required onChange={(e) => setnom(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Montant</label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          placeholder="ex : 100.100"
                          value={montant}
                          onChange={(e) => setmontant(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Numéro</label>
                        <input type="text" value={numero} className="form-control" required onChange={(e) => setnumero(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Date</label>
                        <input type="date" value={date} className="form-control" required onChange={(e) => setdate(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  {image && <img src={localStorage.getItem("baseUrl") + image} alt=""></img>}
                  <FilePond
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple={true}
                    maxFiles={10}
                    name="files"
                    labelIdle='Faites glisser et déposez vos fichiers ou <span class="filepond--label-action">Parcourir</span>'
                  />
                  <button type="submit" className="btn btn-primary">
                    Enregistrer
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateCheque;
