import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getEnteteCommercialAchat } from "../../../../../Services/Facturation/FacturationEnteteApi";
import { filterFactureByWord } from "../../../../../Utils/SortObject";
import { convertToDigit, soustractSolde } from "../../../../../Utils/NumberUtils";
import {
  sumMontantHT,
  sumMontantTTC,
  sumMontantTVA,
  sumPaiement,
  sumSolde,
  sumSoldePaiement,
} from "../../../../../Utils/ObjectOperation";
import { compareDate } from "../../../../../Utils/DateUtils";
import { getPaiementByCode } from "../../../../../Services/Facturation/FacturationPaiementApi";
import { checkMontanPaiement, groupByNumPiece } from "../../../../../Utils/FactureUtils";
import CustomSyncLoader from "../../../../Others/CustomSyncLoader";
import ErrorCustum from "../../../../Others/ErrorCustum";
import Pagination from "../../../../Others/Pagination";
import ButtonHeaderComponent from "../../../../Styles/ButtonHeaderComponent";

function ListFactureAchat() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    setLoading(true);
    getEnteteCommercialAchat()
      .then((res) => {
        getPaiementByCode().then((resPaiement) => {
          const goupedPaiement = groupByNumPiece(resPaiement.data);
          const mergeById = (a1, a2) =>
            a1.map((itm) => ({
              ...a2.find((item) => item.num_piece === itm.facture_code && item),
              ...itm,
            }));
          const mergedList = mergeById(res.data, goupedPaiement);
          setdata(mergedList);
          setallInitialData(mergedList);
        });
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    if (e.target.value.length != "") {
      const dataSearch = filterFactureByWord(allInitialData, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Facture vente</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/addfournisseur" className="btn btn-primary mr-3">
                Ajouter fournisseur
              </Link>
              <Link to="/admin/addproduct" className="btn btn-primary mr-3">
                Ajouter produit
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant HT</span>
                    <h3 className="mb-0 mt-1 text-success">{sumMontantHT(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant TVA</span>
                    <h3 className="mb-0 mt-1 text-primary">{sumMontantTVA(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-primary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant TTC</span>
                    <h3 className="mb-0 mt-1 text-secondary">{sumMontantTTC(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-secondary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Encaissement</span>
                    <h3 className="mb-0 mt-1 text-danger">{sumPaiement(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-danger-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Solde</span>
                    <h3 className="mb-0 mt-1 text-danger">{sumSolde(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-danger-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                Facture d'achat{" "}
                <span title="3 New Messages" className="badge badge-warning">
                  {data.length}
                </span>
              </h3>
              <div className="card-tools">
                <div className="row">
                  <div className="input-group input-group-sm" style={{ width: 130, margin: 4 }}>
                    <input
                      type="text"
                      name="table_search"
                      className="form-control floating"
                      placeholder="Rechercher..."
                      onChange={handleSearch}
                    />
                  </div>

                  <div className="input-group input-group-sm" style={{ width: 130, margin: 4 }}>
                    <Link to="ajoutfactureachat" className="btn btn-success btn-block" style={{ padding: 2 }}>
                      Ajouter
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body table-responsive p-0">
              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive-sm">
                  <table className="table table-striped custom-table m-b-0">
                    <thead>
                      <tr>
                        <th>N° Facture</th>
                        <th>Client </th>
                        <th>
                          Montant HT <br></br>
                          <span className="badge badge-pill badge-danger">{sumMontantHT(data)}</span>
                        </th>
                        <th>
                          Montant TVA <br></br>
                          <span className="badge badge-pill badge-danger">{sumMontantTVA(data)}</span>
                        </th>
                        <th>
                          Montant TTC <br></br>
                          <span className="badge badge-pill badge-danger">{sumMontantTTC(data)}</span>
                        </th>
                        <th>
                          Paiement<br></br>
                          <span className="badge badge-pill badge-danger">{sumPaiement(data)}</span>
                        </th>
                        <th>
                          Solde<br></br>
                          <span className="badge badge-pill badge-danger">{sumSoldePaiement(data)}</span>
                        </th>
                        <th>Statut</th>
                        <th>Date</th>
                        <th className="text-right"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((el, i) => (
                        <tr key={el.id}>
                          <td>
                            <Link to={"/admin/editerfactureachat/" + el.code}>{el.facture_code}</Link>
                          </td>
                          <td>{el.client_nom}</td>
                          <td>{convertToDigit(el.montant_total_ht)}</td>
                          <td>{convertToDigit(el.montant_total_tva)}</td>
                          <td>{convertToDigit(el.montant_total_ttc)}</td>
                          <td>{convertToDigit(checkMontanPaiement(el.montant_paiement))}</td>
                          <td>
                            {el.montant_total_ttc == "0"
                              ? ""
                              : soustractSolde(el.montant_total_ttc, el.montant_paiement || 0)}
                          </td>
                          <td>
                            {checkMontanPaiement(el?.montant_paiement) == el.net_a_payer && el.net_a_payer != 0 && (
                              <span className="badge badge-success">payée</span>
                            )}
                            {checkMontanPaiement(el?.montant_paiement) > el.net_a_payer && (
                              <span className="badge badge-success">payée</span>
                            )}
                            {checkMontanPaiement(el?.montant_paiement) < el.net_a_payer &&
                              checkMontanPaiement(el?.montant_paiement) > 0 && (
                                <>
                                  <span className="badge badge-warning">partiel</span>
                                  {compareDate(el.date_prevu_livraison) && (
                                    <span className="badge badge-danger ml-1">échu</span>
                                  )}
                                </>
                              )}
                            {checkMontanPaiement(el?.montant_paiement) == 0 && (
                              <>
                                {compareDate(el.date_prevu_livraison) && (
                                  <span className="badge badge-danger ml-1">échu</span>
                                )}
                              </>
                            )}
                            {checkMontanPaiement(el?.montant_paiement) == 0 && el.montant_total_ttc == 0 && (
                              <span className="badge badge-primary ml-1">vide</span>
                            )}
                          </td>
                          <td>{el.date.split(" ")[0]}</td>

                          <td className="project-actions text-right">
                            {/* <Link to={"/admin/editerfactureachat/" + el.code} className="btn btn-default btn-xs" href="#">
                            <i className="fas fa-pencil-alt fa-xs"></i>
                          </Link> */}

                            <div className="btn-group">
                              <button
                                style={{ textAlign: "center", padding: 2, width: 40 }}
                                type="button"
                                className="btn btn-secondary dropdown-toggle dropdown-icon"
                                data-toggle="dropdown"
                              ></button>
                              <div className="dropdown-menu" role="menu">
                                <Link
                                  // to={{
                                  //   pathname: "/admin/addpaiement",
                                  //   state: { message: "hello, im a passed message!" },
                                  // }}

                                  to={{ pathname: `/admin/addpaiement/`, state: { el } }}
                                  key={i}
                                  className="dropdown-item"
                                >
                                  Ajouter paiement
                                </Link>

                                {/* <div className="dropdown-divider" /> */}
                                <Link to={"/admin/editerfactureachat/" + el.code} className="dropdown-item">
                                  Editer document
                                </Link>

                                {/* <Link to="#" className="dropdown-item">
                                Supprimer facture
                              </Link> */}

                                <div className="dropdown-divider" />

                                {/* <Link
                                    to="#"
                                    onClick={() => handleActionId(el.code, el.facture_code)}
                                    className="dropdown-item"
                                  >
                                    Supprimer facture
                                  </Link> */}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {/* /.card-body */}
            <div className="card-footer clearfix">
              <Pagination items={data} onChangePage={onChangePage} pageSize={40} />
            </div>
          </div>
          {/* /.card */}
        </div>
      </div>
    </>
  );
}

export default ListFactureAchat;
