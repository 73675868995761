import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { deleteTicketById, getTicketByUserId, updateTicketByStatus } from "../../Services/Pointeuse/TicketApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import swal from "sweetalert";
function ListTickets() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  /**
   * It gets the data from the API and then sorts it by the isactif column.
   */
  const getData = () => {
    setLoading(true);
    getTicketByUserId()
      .then((res) => {
        console.log(res.data);
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getData();
  }, []);

  /**
   * The onChangePage function is called when the user clicks on the page numbers.
   * It takes the page of items returned by the paginate function as a parameter and
   * uses that to update the currentData state
   * @param pageOfItems - an array of items on the current page
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };

  const handleDelete = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteTicketById(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          //getData();
          setdata(data.filter((item) => item.id !== id));
        });
      }
    });
  };

  const handleUpdateStatus = (id) => {
    const objectSelect = data.filter((item) => item.id == id);
    console.log();
    const statusData = objectSelect[0].status;
    if (statusData == "open") {
      updateTicketByStatus("close", id).then((el) => {
        getData();
      });
    } else {
      updateTicketByStatus("open", id).then((el) => {
        getData();
      });
    }

    // updateTicketByStatus(status,id).then((el)=>{
    //   getData()
    // })
  };

  const handlePriority = (value) => setdata(allInitialData.filter((el) => el.priority == value));
  const handleStatus = (value) => setdata(allInitialData.filter((el) => el.etat == value));
  const handleCategory = (value) => setdata(allInitialData.filter((el) => el.category == value));
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Ajouter ticket</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/addticket" className="btn btn-primary mr-3">
                ajouter ticket
              </Link>
              <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                <i className="feather feather-mail" />
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                <i className="feather feather-phone-call" />
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                <i className="feather feather-info" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-12">
        <div className="card mb-0">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label mb-0 mt-2"></label>
                  <select
                    className="form-control custom-select select2"
                    data-placeholder="Select Priority"
                    onChange={(e) => handlePriority(e.target.value)}
                  >
                    <option label="Choisir Priorité" />
                    <option value="high">Urgente</option>
                    <option value="meduim">Moyenne</option>
                    <option value="low">Facultatif</option>
                  </select>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label mb-0 mt-2"></label>
                      <select
                        className="form-control custom-select select2"
                        data-placeholder="Select Priority"
                        onChange={(e) => handleCategory(e.target.value)}
                      >
                        <option label="Choisir Catégorie" />
                        <option value="supports">Support</option>
                        <option value="services">Services</option>
                        <option value="techniques">Techniques</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label mb-0 mt-2"></label>
                      <select
                        className="form-control custom-select select2"
                        data-placeholder="Select Priority"
                        onChange={(e) => handleStatus(e.target.value)}
                      >
                        <option label="Choisir Status" />
                        <option value="open">Ouverte</option>
                        <option value="close">Fermé</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group mt-2">
                  <Link to="#" className="btn btn-primary btn-block" onClick={() => getData()}>
                    réinitialiser
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {loading && <CustomSyncLoader></CustomSyncLoader>}
          {!loading && error && <ErrorCustum></ErrorCustum>}
          {!loading && !error && data && (
            <div className="card-body pl-0 pr-0 pt-0">
              <div className="table-responsive">
                <table className="table table-vcenter text-nowrap table-hover border-bottom supportticket-active">
                  <thead>
                    <tr className="bg-light">
                      <th className="border-bottom-0">#ID</th>
                      <th className="border-bottom-0">sujet</th>
                      <th className="border-bottom-0">Priorité</th>
                      <th className="border-bottom-0  w-5">Categorie</th>
                      <th className="border-bottom-0">Statut</th>
                      <th className="border-bottom-0">Modification</th>
                      <th className="border-bottom-0 w-5">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((el, idx) => (
                      <tr key={idx}>
                        <td>#{el.id}</td>
                        <td>
                          <div>
                            <Link to={"/admin/ticket/" + el.id} className="h5">
                              {el.sujet}
                            </Link>
                          </div>
                          <small className="fs-12 text-muted">
                            <i className="fa fa-clock-o mr-1 text-muted" />
                            ouverte le : <span className="font-weight-normal1">{el.createdAt}</span>
                          </small>
                        </td>
                        <td>
                          {el.priority == "high" && <span className="badge badge-danger-light">Urgente</span>}
                          {el.priority == "meduim" && <span className="badge badge-warning-light">Moyenne</span>}
                          {el.priority == "low" && <span className="badge badge-success-light">Facultatif</span>}
                        </td>
                        <td>Services</td>
                        <td>
                          {el.status == "open" && <span className="badge badge-primary">Ouverte</span>}
                          {el.status == "close" && <span className="badge badge-dark">Fermé</span>}
                        </td>
                        <td>
                          <span className="fs-13 text-muted">
                            <i className="feather feather-clock mr-2" />
                            {el.updatedAt}
                          </span>
                        </td>
                        <td>
                          <div className="d-flex">
                            <a
                              href="#"
                              className="action-btns1"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="feather feather-more-vertical text-primary" />
                            </a>
                            <ul className="dropdown-menu dropdown-menu-right" role="menu">
                              {/* <li>
                                <Link to="#">
                                  <i className="feather feather-send mr-2" />
                                  Reply Ticket
                                </Link>
                              </li> */}
                              <li>
                                <Link to={"/admin/ticket/" + el.id}>
                                  <i className="feather feather-eye mr-2" />
                                  Voir plus
                                </Link>
                              </li>
                              <li>
                                <Link to="#" onClick={() => handleDelete(el.id)}>
                                  <i className="feather feather-trash-2 mr-2" />
                                  supprimer
                                </Link>
                              </li>
                              <li>
                                <Link to="#" onClick={() => handleUpdateStatus(el.id)}>
                                  <i className="feather feather-settings mr-2" />
                                  modifié statut
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination items={data} onChangePage={onChangePage} pageSize={20} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ListTickets;
