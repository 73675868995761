/**
 * `soustractAdressFirstLine` is a function that takes a string and returns a string
 * @param string - The string to be processed.
 * @returns The first 34 characters of the string.
 */
export const soustractAdressFirstLine = (string) => {
  return string.substring(0, 34);
};

/**
 * Substring the second line of a string
 * @param string - The string to be parsed.
 * @returns The substring of the address string from the 34th character to the second to last
 * character.
 */
export const soustractAdressSecondLine = (string) => {
  return string.substring(34, string.length - 1);
};

/**
 * Substring the first 63 characters of a string
 * @param string - The string to be truncated.
 * @returns The first 63 characters of the string.
 */
export const soustractProductFirstLine = (string) => {
  return string.substring(0, 63);
};

/**
 * Substring the string from the 63rd character to the end of the string
 * @param string - The string to be parsed.
 * @returns The substring of the string that is being passed in.
 */
export const soustractProductSecondLine = (string) => {
  return string.substring(63, string.length - 1);
};
