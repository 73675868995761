import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import { getAllMateriel } from "../../Services/Pointeuse/MaterielApi";
import { convertToDigit } from "../../Utils/NumberUtils";
import { sumPrix } from "../../Utils/ObjectOperation";
import { filterTeletravailByWord } from "../../Utils/SortObject";
import ImageComponent from "../Styles/ImageComponent";
import DetailsMateriel from "./DetailsMateriel";
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import CopieContent from "../Exports/CopieContent";
function ListDemandeMateriel() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [achetes, setachetes] = useState([]);
  const [encours, setencours] = useState([]);
  const [anullers, setanullers] = useState([]);
  const [size, setsize] = useState(8);
  const [isOpened, setIsOpened] = useState(false);
  const [id, setId] = useState(null);
  const [isCollapsed, setisCollapsed] = useState(false);

  /**
   * It gets all the materiel from the database and sets the data, allInitialData, achetes, encours,
   * anullers variables.
   */
  const getData = () => {
    setLoading(true);
    getAllMateriel()
      .then((res) => {
        console.log(res.data);
        setdata(res.data);
        setallInitialData(res.data);
        setachetes(
          res.data.filter((el) => {
            return el.type == "accepter";
          })
        );
        setencours(
          res.data.filter((el) => {
            return el.type == "demande";
          })
        );
        setanullers(
          res.data.filter((el) => {
            return el.type == "refuser";
          })
        );
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  /**
   * The function takes in a page of items and sets the currentData state to that page of items
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the word that is typed in the search bar.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      const dataSearch = filterTeletravailByWord(data, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  /**
   * The function sets the size of the data to be retrieved from the API.
   * @param e - The event object that contains the data we want to use.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  /**
   * * When the user clicks on a sidebar link, the modal is opened
   * @param id - The id of the modal you want to open.
   */
  const handleOpenModal = (id) => {
    setIsOpened(true);
    setId(id);
    $(".sidebar-modal").addClass("active");
    $("body").addClass("overlay-open");
    $("body").append('<div class="overlay"></div>');
  };

  /**
   * * When the user clicks on the "X" button, the sidebar is hidden
   */
  const handleDismiss = () => {
    $(".sidebar-modal").removeClass("active");
    $("body").removeClass("overlay-open");
    // $("body").append('<div class="overlay"></div>');
    setIsOpened(false);
  };
  /**
   * *This function is used to toggle the collapse state of the sidebar.*
   */
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Demande matériels</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              {/* <a href="hr-addemployee.html" className="btn btn-primary mr-3">
                Add New Employee
              </a> */}
              <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                <i className="feather feather-mail" />
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                <i className="feather feather-phone-call" />
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                <i className="feather feather-info" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row*/}
      <div className="row">
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total</span>
                    <h3 className="mb-0 mt-1 text-success">{sumPrix(data)} DT</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total achetés</span>
                    <h3 className="mb-0 mt-1 text-primary">{sumPrix(achetes)} DT</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-primary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total en cours</span>
                    <h3 className="mb-0 mt-1 text-secondary">{sumPrix(encours)} DT</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-secondary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total annulés</span>
                    <h3 className="mb-0 mt-1 text-danger">{sumPrix(anullers)} DT</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-danger-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Row */}
      {/* Row */}
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">Liste des matériaux</h4>
              <div className="card-options">
                <Link
                  to="#"
                  className="card-options-fullscreen mr-2"
                  data-toggle="card-fullscreen"
                  onClick={handleCollapse}
                >
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">Note:</label>
                </div>
                <div>
                  <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> acheté
                  </span>
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> annulé
                  </span>
                  <span className="badge badge-warning-light mr-2">
                    <i className="fa fa-star text-warning" /> en cours
                  </span>
                  {/* <span className="badge badge-orange-light mr-2">
                    <i className="fa fa-adjust text-orange" /> ---&gt; Half Day
                  </span> */}
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            Afficher{" "}
                            <select
                              value={size}
                              className="form-control"
                              id="exampleFormControlSelect1"
                              onChange={handleChangeSize}
                            >
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            entrées
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Recherche..."
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv data={data} name={"List_demandes_materiels"} />
                          <ExportPdf
                            data={data}
                            name={"List_demandes_materiels"}
                            columns={["nom", "prenom", "titre", "prix", "type"]}
                          />
                          <CopieContent />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0 w-5">#ID</th>
                              <th className="border-bottom-0">Nom/Prénom</th>
                              <th className="border-bottom-0">Produit</th>
                              <th className="border-bottom-0">prix</th>
                              <th className="border-bottom-0">Statut</th>
                              <th className="border-bottom-0">Date</th>
                              <th className="border-bottom-0">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el, idx) => (
                              <tr key={idx} role="row" className="odd">
                                <td># {el.id}</td>
                                <td>
                                  <div className="d-flex">
                                    <ImageComponent
                                      atr={"avatar avatar-md brround mr-3"}
                                      picture={el.imguser}
                                    ></ImageComponent>
                                    <div className="mr-8 mt-0 mt-sm-1 d-block">
                                      <h6 className="mb-1 fs-14">
                                        {el.nom} {el.prenom}
                                      </h6>
                                      <p className="text-muted mb-0 fs-12">{el.email}</p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {/* <Link to={"/admin/materiel/" + el.id}> */}
                                  <Link
                                    to="#"
                                    className="d-flex sidebarmodal-collpase"
                                    onClick={() => handleOpenModal(el.id)}
                                  >
                                    <span className="font-weight-bold">{el.titre}</span>
                                  </Link>
                                </td>
                                <td>{convertToDigit(el.prix)}</td>
                                <td>
                                  {el.type == "demande" ? (
                                    <span className="badge badge-warning">{el.type}</span>
                                  ) : (
                                    <span className="badge badge-danger">Non Active</span>
                                  )}
                                </td>
                                <td>{el.created_at}</td>
                                {/* <td>{el.birth_date}</td> */}

                                <td className="text-left">
                                  {el.type == "demande" ? (
                                    <>
                                      <Link to="#" className="action-btns" data-tip="editer">
                                        {/* <i className="feather feather-edit text-info" /> */}
                                        <i className="fa fa-check-circle text-success"></i>
                                      </Link>
                                      <Link to="#" className="action-btns" data-tip="editer">
                                        {/* <i className="feather feather-edit text-info" /> */}
                                        <i className="fa fa-ban text-danger"></i>
                                      </Link>
                                    </>
                                  ) : (
                                    <p></p>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          Affichage de 1 à {size} sur {data.length} entrées
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*View Sub-Task Modal */}
      <div className="task-fade sidebar-modal">
        <div className="sidebar-dialog task-modal">
          <div className="sidebar-content">
            <div className="sidebar-header">
              <div>
                <h4>
                  <span className="font-weight-normal text-muted mr-2">Demande</span> Matériel
                </h4>
                <label className="mr-2">Statut:</label>
                <span className="badge badge-warning-light">Demande</span>
              </div>
              <div className="ml-auto mt-3">
                {/* <Link to="#" className="btn btn-success mr-2">
                  Mark as Complete
                </Link> */}
                <Link to="#" className="action-btns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="feather feather-more-horizontal" />
                </Link>
                <ul className="dropdown-menu dropdown-menu-right" role="menu">
                  <li>
                    <Link to="#">
                      <i className="feather feather-eye mr-2" />
                      View
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="feather feather-plus-circle mr-2" />
                      Add
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="feather feather-send mr-2" />
                      Send
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="feather feather-edit-2 mr-2" />
                      Edit
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="feather feather-trash-2 mr-2" />
                      Remove
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="feather feather-settings mr-2" />
                      More
                    </Link>
                  </li>
                </ul>
                <Link to="#" className="action-btns dismiss" onClick={handleDismiss}>
                  <i className="feather feather-x" />
                </Link>
              </div>
            </div>
            {isOpened && <DetailsMateriel id={id}></DetailsMateriel>}
          </div>
        </div>
      </div>
    </>
  );
}

export default ListDemandeMateriel;
