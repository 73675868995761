/**
 * This function filters an array of objects by a given value.
 * @param arrayObject - The array of objects that you want to filter.
 * @param value - The name of the column that you want to filter by.
 * @returns An array of objects.
 */
export function filterTodayDate(arrayObject, value) {
  if (arrayObject.success != "0") {
    var todayDate = new Date().toISOString().slice(0, 10);
    return arrayObject.filter((el) => {
      return el[value].split(" ")[0] == todayDate;
    });
  }
}
