import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  invoiceNoContainer: {
    flexDirection: "row",

    justifyContent: "flex-start",
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  invoiceDate: {
    fontSize: 11,
    fontStyle: "bold",
    marginLeft: 30,
  },

  label: {
    width: 120,
  },
  ToStyle: {
    marginLeft: 30,
    justifyContent: "center",
    alignItems: "center",
  },
});

const InvoiceNo = ({ invoice }) => (
  <Fragment>
    <View style={styles.invoiceNoContainer}>
      <Text style={styles.label}>Numéro de facture:</Text>
      <Text style={styles.invoiceDate}>{invoice.invoice_no}</Text>
      {/* <Text style={styles.ToStyle}>
        {invoice.receiver_name} {invoice.receiver_prenom}
      </Text> */}
    </View>
    <View style={styles.invoiceNoContainer}>
      <Text style={styles.label}>N° de Bon de Commande:</Text>
      <Text style={styles.invoiceDate}>{invoice.commande_no}</Text>
      {/* <Text style={styles.ToStyle}>{invoice.receiver_adr}</Text> */}
    </View>
    <View style={styles.invoiceDateContainer}>
      <Text style={styles.label}>Date Facture: </Text>
      <Text style={styles.invoiceDate}>{invoice.trans_date}</Text>
      {/* <Text style={styles.ToStyle}>{invoice.receiver_mobile}</Text> */}
    </View>
    <View style={styles.invoiceDateContainer}>
      <Text style={styles.label}>Date d'echéance: </Text>
      <Text style={styles.invoiceDate}>{invoice.due_date}</Text>
      {/* <Text style={styles.ToStyle}>{invoice.receiver_email}</Text> */}
    </View>
  </Fragment>
);

export default InvoiceNo;
