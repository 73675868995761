import React, { useEffect } from "react";
import { Page, Text, View, Document, Image, StyleSheet } from "@react-pdf/renderer";
import InvoiceNo from "./InvoiceNo";
import InvoiceItemsTable from "./InvoiceItemsTable";
import InvoiceThankYouMsg from "./InvoiceThankYouMsg";
import BillFrom from "./BillFrom";
import InvoiceLettrePrice from "./InvoiceLettrePrice";
import { getAllSettings } from "../../../Services/Pointeuse/SettingsApi";
import BillTo from "./BillTo";
// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 10,
    paddingTop: 20,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo2: {
    width: 93,
    height: 93,
    marginTop: -20,
  },
  logo: {
    width: 113,
    height: 113,
    marginTop: -20,
  },
  entete: {
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-between",
  },
  entete1: {
    flexDirection: "row",
  },
  enteteRow: {
    width: 100,
  },
  enteteRow2: {
    marginTop: 30,
    width: 100,
  },
  enteteRow3: {
    marginTop: 26,
    width: 100,
  },
  certImage: { height: 80, width: 250 },
  invoiceTitle: {
    color: "#000",
    marginTop: 5,
    fontSize: 15,
    fontStyle: "bold",
  },
});

function MyDocument({ invoice }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.entete}>
          <View style={styles.enteteRow}>
            <Image style={styles.logo} src={localStorage.getItem("baseUrl") + invoice.sender_logo} />

            <Image style={styles.logo2} src={invoice.facutre_media_entete} />
          </View>
          <BillFrom style={styles.enteteRow2} invoice={invoice} />
          <View style={{ width: 100, marginTop: 20 }}>
            <Text styles={styles.enteteRow3}>{invoice.sender_adr}</Text>
          </View>
        </View>
        <Text style={styles.invoiceTitle}>{invoice.title}</Text>
        <View style={styles.entete}>
          <View>
            <InvoiceNo invoice={invoice} />
          </View>
          <View>
            <BillTo invoice={invoice} />
          </View>
        </View>
        <InvoiceItemsTable invoice={invoice} />
        <InvoiceLettrePrice invoice={invoice} />
        <InvoiceThankYouMsg invoice={invoice} />
        <view>
          <Text style={{ fontSize: 8 }}>{invoice.ligne_facture_1}</Text>
          <Text style={{ fontSize: 8 }}>{invoice.ligne_facture_2}</Text>
          <Text style={{ fontSize: 8 }}>{invoice.ligne_facture_3}</Text>
          <Text style={{ fontSize: 8 }}>{invoice.ligne_facture_4}</Text>
        </view>

        <Image style={styles.certImage} src={invoice.facutre_media_pied}></Image>
      </Page>
    </Document>
  );
}

export default MyDocument;
