import React, { useState, useEffect } from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ajouterProduit } from "../../../Services/Facturation/FacturationProduitApi";
import { getAllMarques } from "../../../Services/Facturation/FacturationMarqueApi";
import Select from "react-select";
import { getAllSousFamille, getSousFamilleById } from "../../../Services/Facturation/FacturationSousFamilleApi";
import { getFacturationFournisseurByCode } from "../../../Services/Facturation/FacturationFournisseurApi";
import { margePercentage, sumNumber } from "../../../Utils/NumberUtils";
import { getAllTva } from "../../../Services/Facturation/FacturationTvaApi";
import { getAllFamille } from "../../../Services/Facturation/FacturationFamille";
import { addStock } from "../../../Services/Facturation/FacturationStockApi";
import ButtonHeaderComponent from "../../Styles/ButtonHeaderComponent";
import { Link } from "react-router-dom";

function AddProduct() {
  const [codeBare, setcodeBare] = useState("");
  const [libelle, setLibelle] = useState("");
  const [unite, setUnite] = useState("");
  const [qteMin, setQteMin] = useState("");
  const [prixPromotion, setPrixPromotion] = useState("");
  const [codePromotion, setCodePromotion] = useState("");
  const [fiche, setFiche] = useState("");
  const [hauteur, setHauteur] = useState("");
  const [largeur, setLargeur] = useState("");
  const [profondeur, setProfondeur] = useState("");
  const [marge1, setMarge1] = useState(0);
  const [marge2, setMarge2] = useState(0);
  const [marge3, setMarge3] = useState(0);
  const [marge4, setMarge4] = useState(0);
  const [marge5, setMarge5] = useState(0);
  const [actionMarge1, setActionMarge1] = useState("1");
  const [actionMarge2, setActionMarge2] = useState("1");
  const [actionMarge3, setActionMarge3] = useState("1");
  const [actionMarge4, setActionMarge4] = useState("1");
  const [actionMarge5, setActionMarge5] = useState("1");
  const [poids, setPoids] = useState("");
  const [prixVentePublic, setPrixVentePublic] = useState("");
  const [prixAchatHt, setPrixAchatHt] = useState("");
  const [prixAchattc, setPrixAchattc] = useState("");
  const [prixHt1, setprixHt1] = useState("");
  const [prixHt2, setprixHt2] = useState("");
  const [prixHt3, setprixHt3] = useState("");
  const [prixHt4, setprixHt4] = useState("");
  const [prixHt5, setprixHt5] = useState("");
  const [prixttc1, setprixttc1] = useState("");
  const [prixttc2, setprixttc2] = useState("");
  const [prixttc3, setprixttc3] = useState("");
  const [prixttc4, setprixttc4] = useState("");
  const [prixttc5, setprixttc5] = useState("");
  const [image, setImage] = useState("assets/images/products/7.jpg");
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setimageName] = useState("");
  const [dataMarque, setDataMarque] = useState([]);
  const [marqueCode, setMarqueCode] = useState("");
  const [marqueLibelle, setMarqueLibelle] = useState("");
  const [dataSousFamille, setDataSousFamille] = useState([]);
  const [sousFamilleCode, setSousFamilleCode] = useState("");
  const [sousFamilleLibelle, setSousFamilleLibelle] = useState("");
  const [sousFamilleID, setSousFamilleID] = useState("");
  const [familleCode, setFamilleCode] = useState("");
  const [dataFournisseur, setDataFournisseur] = useState("");
  const [fournisseurCode, setFournisseurCode] = useState("");
  const [fournisseurFullName, setFournisseurFullName] = useState("");
  const [dataTva, setDataTva] = useState("");
  const [tauxTva, setTauxTva] = useState("");
  const [gammeCode, setGammeCode] = useState("");
  const [suivie, setsuivie] = useState("0");
  const [produitCode, setproduitCode] = useState("");
  const [abonnement, setabonnement] = useState("0");
  const [achat, setachat] = useState("0");

  useEffect(() => {
    //get all marque
    getAllMarques().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.code,
        label: d.libelle,
      }));
      setDataMarque(options);
    });

    //get all sous famille
    getAllSousFamille().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.code,
        label: d.libelle,
        secondValue: d.famille_code,
        thirdValue: d.id,
      }));
      setDataSousFamille(options);
    });

    //format facutraion by label and value
    //to be inserted in Select
    getFacturationFournisseurByCode().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.code,
        label: d.nom + " " + d.prenom,
      }));
      setDataFournisseur(options);
    });

    //get tva
    //format tva by value and label
    getAllTva().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.taux,
        label: d.taux,
      }));
      setDataTva(options);
    });
  }, []);

  //select marque
  const handleChangeMarque = (event) => {
    setMarqueCode(event.value);
    setMarqueLibelle(event.label);
  };

  //select fournisseur
  const handleChangeFournisseur = (event) => {
    setFournisseurCode(event.value);
    setFournisseurFullName(event.label);
  };

  //select sous famille
  const handleChangeSousFamille = (event) => {
    setSousFamilleCode(event.value);
    setSousFamilleLibelle(event.label);
    setFamilleCode(event.secondValue);
    setSousFamilleID(event.thirdValue);
    //get sous famille by id
    getSousFamilleById(event.thirdValue).then((res) => {
      //set all sous famille value to product field
      setPrixAchatHt(res.data.prix_achat_ht);
      setPrixAchattc(res.data.prix_achat_ttc);
      setPrixVentePublic(res.data.prix_vente_public);

      setMarge1(res.data.marge_1);
      setMarge2(res.data.marge_2);
      setMarge3(res.data.marge_3);
      setMarge4(res.data.marge_4);
      setMarge5(res.data.marge_5);

      setprixHt1(res.data.prix_vente_ht_1);
      setprixHt2(res.data.prix_vente_ht_2);
      setprixHt3(res.data.prix_vente_ht_3);
      setprixHt4(res.data.prix_vente_ht_4);
      setprixHt5(res.data.prix_vente_ht_5);

      setprixttc1(res.data.prix_vente_ttc_1);
      setprixttc2(res.data.prix_vente_ttc_2);
      setprixttc3(res.data.prix_vente_ttc_3);
      setprixttc4(res.data.prix_vente_ttc_4);
      setprixttc5(res.data.prix_vente_ttc_5);
    });
    //get gamme code
    getAllFamille().then((res) => {
      console.log(res);
      var newArray = res.data.filter((el) => {
        return el.code === event.secondValue;
      });
      setGammeCode(newArray[0].gamme_code);
    });
  };

  //change tva and ttc
  const handleChangeTva = (event) => {
    setTauxTva(event.value);
    setPrixAchattc(margePercentage(prixAchatHt, event.value));
    setprixttc1(margePercentage(prixHt1, event.value));
    setprixttc2(margePercentage(prixHt2, event.value));
    setprixttc3(margePercentage(prixHt3, event.value));
    setprixttc4(margePercentage(prixHt4, event.value));
    setprixttc5(margePercentage(prixHt5, event.value));
  };

  //get img path
  const handleImgClient = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageUrl(event.target.files[0]);
    setimageName(event.target.files[0].name);
    console.log(event.target.files);
  };

  const handleChangeMarge1 = (e) => {
    setMarge1(e.target.value);
    if (actionMarge1 === "1") {
      setprixHt1(margePercentage(prixAchatHt, e.target.value));
    } else {
      setprixHt1(sumNumber(prixAchatHt, marge1));
    }
  };
  const handleChangeMarge2 = (e) => {
    setMarge2(e.target.value);
    if (actionMarge2 === "1") {
      setprixHt2(margePercentage(prixAchatHt, e.target.value));
    } else {
      setprixHt2(sumNumber(prixAchatHt, marge2));
    }
  };
  const handleChangeMarge3 = (e) => {
    setMarge3(e.target.value);
    if (actionMarge3 === "1") {
      setprixHt3(margePercentage(prixAchatHt, e.target.value));
    } else {
      setprixHt3(sumNumber(prixAchatHt, marge3));
    }
  };
  const handleChangeMarge4 = (e) => {
    setMarge4(e.target.value);
    if (actionMarge4 === "1") {
      setprixHt4(margePercentage(prixAchatHt, e.target.value));
    } else {
      setprixHt4(sumNumber(prixAchatHt, marge4));
    }
  };
  const handleChangeMarge5 = (e) => {
    setMarge5(e.target.value);
    if (actionMarge5 === "1") {
      setprixHt5(margePercentage(prixAchatHt, e.target.value));
    } else {
      setprixHt5(sumNumber(prixAchatHt, marge5));
    }
  };

  const handleChangeActionMargin1 = (e) => {
    setActionMarge1(e.target.value);
    if (e.target.value === "1") {
      setprixHt1(margePercentage(prixAchatHt, marge1));
    } else {
      setprixHt1(sumNumber(prixAchatHt, marge1));
    }
  };

  const handleChangeActionMargin2 = (e) => {
    setActionMarge2(e.target.value);
    if (e.target.value === "1") {
      setprixHt2(margePercentage(prixAchatHt, marge2));
    } else {
      setprixHt2(sumNumber(prixAchatHt, marge2));
    }
  };

  const handleChangeActionMargin3 = (e) => {
    setActionMarge3(e.target.value);
    if (e.target.value === "1") {
      setprixHt3(margePercentage(prixAchatHt, marge3));
    } else {
      setprixHt3(sumNumber(prixAchatHt, marge3));
    }
  };

  const handleChangeActionMargin4 = (e) => {
    setActionMarge4(e.target.value);
    if (e.target.value === "1") {
      setprixHt4(margePercentage(prixAchatHt, marge4));
    } else {
      setprixHt4(sumNumber(prixAchatHt, marge4));
    }
  };

  const handleChangeActionMargin5 = (e) => {
    setActionMarge5(e.target.value);
    if (e.target.value === "1") {
      setprixHt5(margePercentage(prixAchatHt, marge5));
    } else {
      setprixHt5(sumNumber(prixAchatHt, marge5));
    }
  };

  const handleChangeSuivie = (event) => {
    setsuivie(event.target.value);
    console.log(event.target.value);
  };

  const handleChangeAbonnement = (event) => {
    setabonnement(event.target.value);
    console.log(event.target.value);
  };

  const handleChangeAchat = (event) => {
    setachat(event.target.value);
  };

  //post product
  const handleSubmit = () => {
    let codeProduit = parseInt(Date.now() * Math.random());
    setproduitCode(codeProduit);

    ajouterProduit(
      codeProduit,
      codeBare,
      libelle,
      sousFamilleCode,
      marqueCode,
      gammeCode,
      familleCode,
      fournisseurCode,
      unite,
      tauxTva,
      qteMin,
      imageUrl,
      fiche,
      hauteur,
      largeur,
      profondeur,
      prixAchatHt,
      prixAchattc,
      prixHt1,
      prixHt2,
      prixHt3,
      prixHt4,
      prixHt5,
      prixttc1,
      prixttc2,
      prixttc3,
      prixttc4,
      prixttc5,
      marge1,
      marge2,
      marge3,
      marge4,
      marge5,
      poids,
      prixVentePublic,
      prixPromotion,
      codePromotion,
      suivie,
      abonnement,
      achat
    ).then((res) => {
      if (suivie == "1") {
        addStock(codeProduit, 0).then((res) => {});
      } else {
        console.log("not added to stock");
      }
    });
  };
  return (
    <>
      <div className="row mt-4">
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Détail produit</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>
                  Nom produit <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setLibelle(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>
                  Code-barres <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setcodeBare(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>
                  Unité <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setUnite(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>
                  Quantité min <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setQteMin(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>
                  Prix promotion <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setPrixPromotion(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  Code promotion <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCodePromotion(e.target.value)}
                  required
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
              <a href="https://select2.github.io/">Select2 documentation</a> for
              more examples and information about the plugin. */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Information produit</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>
                  Sous Famille : {sousFamilleLibelle} <span className="text-danger">*</span>
                </label>
                {/* <p>Famille : {familleCode}</p> */}
                <Select onChange={handleChangeSousFamille} options={dataSousFamille} />
              </div>
              <div className="form-group">
                <label>
                  Choisir Marque : {marqueLibelle} <span className="text-danger">*</span>
                </label>
                <Select onChange={handleChangeMarque} options={dataMarque} />
              </div>
              <div className="form-group">
                <label>
                  Choisir Fournisseur : {fournisseurFullName}
                  <span className="text-danger">*</span>
                </label>
                <Select onChange={handleChangeFournisseur} options={dataFournisseur} />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Suivi Stock produit</label>
                <select
                  onChange={handleChangeSuivie}
                  value={suivie}
                  className="form-control"
                  id="exampleFormControlSelect1"
                >
                  <option value={0}>Non</option>
                  <option value={1}>Oui</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">abonnement</label>
                <select
                  value={abonnement}
                  onChange={handleChangeAbonnement}
                  className="form-control"
                  id="exampleFormControlSelect1"
                >
                  <option value={0}>Non</option>
                  <option value={1}>Oui</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Achat</label>
                <select
                  value={achat}
                  onChange={handleChangeAchat}
                  className="form-control"
                  id="exampleFormControlSelect1"
                >
                  <option value={0}>Non</option>
                  <option value={1}>Oui</option>
                </select>
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
              <a href="https://select2.github.io/">Select2 documentation</a> for
              more examples and information about the plugin. */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Information complémentaire</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>
                  Fiche <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setFiche(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>
                  Hauteur <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setHauteur(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>
                  Largeur <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setLargeur(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>
                  Profondeur <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setProfondeur(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>
                  Poids <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setPoids(e.target.value)} required />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
              <a href="https://select2.github.io/">Select2 documentation</a> for
              more examples and information about the plugin. */}
            </div>
          </div>

          <div>
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Image produit</h3>
                <div className="card-tools">
                  {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                    <i className="fas fa-minus" />
                  </button>
                  <button type="button" className="btn btn-tool" data-card-widget="remove">
                    <i className="fas fa-times" />
                  </button> */}
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="form-group">
                  <div className="d-flex justify-content-center">
                    <img alt="image produit" src={image} className="avatar" />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        className="custom-file-input"
                        accept="image/*"
                        type="file"
                        id="exampleInputFile"
                        onChange={handleImgClient}
                      />
                      <label className="custom-file-label" htmlFor="exampleInputFile">
                        {imageName}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                {/* Visit{" "}
              <a href="https://select2.github.io/">Select2 documentation</a> for
              more examples and information about the plugin. */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Prix produits</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>
                  Prix vente public<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={prixVentePublic}
                  onChange={(e) => setPrixVentePublic(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  Prix achat HT <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={prixAchatHt}
                  onChange={(e) => setPrixAchatHt(e.target.value)}
                  required
                />
              </div>
              <div>
                <div className="row mb-2 mt-2">
                  <div className="col-5">
                    <div className="row ml-2 mt-2">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <select defaultValue="1" onChange={handleChangeActionMargin1} className="form-control">
                            <option value="1">Pourcent</option>
                            <option value="2">Montant</option>
                          </select>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Marge 1"
                          value={marge1}
                          onChange={handleChangeMarge1}
                          aria-label="Text input with dropdown button"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-4 mt-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Prix vente HT 1"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={prixHt1}
                      onChange={(e) => setprixHt1(e.target.value)}
                    />
                  </div>
                  <div className="col-3 mt-2">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      placeholder="Prix vente TTC 1"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={prixttc1}
                      onChange={(e) => setprixttc1(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-5">
                    <div className="row ml-2 mt-2">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <select defaultValue="1" onChange={handleChangeActionMargin2} className="form-control">
                            <option value="1">Pourcent</option>
                            <option value="2">Montant</option>
                          </select>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChangeMarge2}
                          placeholder="Marge 2"
                          value={marge2}
                          aria-label="Text input with dropdown button"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-4 mt-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Prix vente HT 2"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={prixHt2}
                      onChange={(e) => setprixHt2(e.target.value)}
                    />
                  </div>
                  <div className="col-3 mt-2">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      placeholder="Prix vente TTC 2"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={prixttc2}
                      onChange={(e) => setprixttc2(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-5">
                    <div className="row ml-2 mt-2">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <select defaultValue="1" onChange={handleChangeActionMargin3} className="form-control">
                            <option value="1">Pourcent</option>
                            <option value="2">Montant</option>
                          </select>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Marge 3"
                          value={marge3}
                          onChange={handleChangeMarge3}
                          aria-label="Text input with dropdown button"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-4 mt-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Prix vente HT 3"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={prixHt3}
                      onChange={(e) => setprixHt3(e.target.value)}
                    />
                  </div>
                  <div className="col-3 mt-2">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      placeholder="Prix vente TTC 3"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={prixttc3}
                      onChange={(e) => setprixttc3(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-5">
                    <div className="row ml-2 mt-2">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <select defaultValue="1" onChange={handleChangeActionMargin4} className="form-control">
                            <option value="1">Pourcent</option>
                            <option value="2">Montant</option>
                          </select>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Marge 4"
                          value={marge4}
                          onChange={handleChangeMarge4}
                          aria-label="Text input with dropdown button"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-4 mt-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Prix vente HT 4"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={prixHt4}
                      onChange={(e) => setprixHt4(e.target.value)}
                    />
                  </div>
                  <div className="col-3 mt-2">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      placeholder="Prix vente TTC 4"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={prixttc4}
                      onChange={(e) => setprixttc4(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-5">
                    <div className="row ml-2 mt-2">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <select defaultValue="1" onChange={handleChangeActionMargin5} className="form-control">
                            <option value="1">Pourcent</option>
                            <option value="2">Montant</option>
                          </select>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Marge 5"
                          value={marge5}
                          onChange={handleChangeMarge5}
                          aria-label="Text input with dropdown button"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-4 mt-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Prix vente HT 5"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={prixHt5}
                      onChange={(e) => setprixHt5(e.target.value)}
                    />
                  </div>
                  <div className="col-3 mt-2">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      placeholder="Prix vente TTC 5"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={prixttc5}
                      onChange={(e) => setprixttc5(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>
                  Taux Tva : {tauxTva} <span className="text-danger">*</span>
                </label>

                <Select onChange={handleChangeTva} options={dataTva} />
              </div>
              <div className="form-group">
                <label>
                  Prix achat ttc <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={prixAchattc}
                  onChange={(e) => setPrixAchattc(e.target.value)}
                  required
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
              <a href="https://select2.github.io/">Select2 documentation</a> for
              more examples and information about the plugin. */}
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn-danger text-center mr-2">
            <i className="fas fa-trash-alt" /> Annuler
          </button>
          <button type="button" className="btn btn-primary text-center" onClick={handleSubmit}>
            {/* <i className="far fa-credit-card" /> */}
            Enregistrer
          </button>
        </div>
      </div>
    </>
  );
}

export default AddProduct;
