import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It gets all the sous famille from the database.
 * @returns The response is an array of objects.
 */
export async function getAllSousFamille() {
  try {
    const response = await axios.get(api_url + "facturation/sousfamille/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets the sous-famille with the given id.
 * @param id - The id of the sous-famille you want to get.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "id": 1,
 *   "nom": "Sous-famille 1",
 *   "famille": {
 *     "id": 1,
 *     "nom": "Famille 1"
 *   }
 * }
 * ```
 */
export async function getSousFamilleById(id) {
  try {
    const response = await axios.get(api_url + "facturation/sousfamille/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get a sous famille by its code id
 * @param id - The id of the sous-famille you want to get.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "code": "SOUS-FAMILLE-1",
 *   "libelle": "Sous-famille 1",
 *   "famille": {
 *     "code": "FAMILLE-1",
 *     "libelle": "Famille 1"
 *   }
 */
export async function getSousFamilleByCodeId(id) {
  try {
    const response = await axios.get(api_url + "facturation/sousfamille/codeid/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * * Add a new sous famille to the database
 * @param famille_code - The code of the famille to which the sous famille belongs.
 * @param libelle - The name of the sous famille
 * @param ordre - the order of the sous famille in the famille
 * @param color - the color of the product
 * @param prixHt1 - prix de vente HT pour la 1ère tranche
 * @param prixHt2 - prixHt2,
 * @param prixHt3 - prix_vente_ht_3
 * @param prixHt4 - prix_vente_ht_4
 * @param prixHt5 - prix de vente HT au 5eme niveau de prix
 * @param prixttc1 - prix de vente TTC 1
 * @param prixttc2 - prix de vente TTC 2
 * @param prixttc3 - prix de vente TTC 3eme niveau
 * @param prixttc4 - prix de vente TTC 4eme niveau
 * @param prixttc5 - prix de vente TTC 5eme prix
 * @param marge1 - marge 1
 * @param marge2 - marge2,
 * @param marge3 - marge3,
 * @param marge4 - marge_4
 * @param marge5 - Marge 5
 * @param prixAchatHt - prixAchatHt,
 * @param prixAchattc - prixAchatHt
 * @param prixVentePublic - The price of the product in the public market.
 * @returns The response is an object that contains the data that was returned by the server.
 */
export async function ajouterSousFamille(
  famille_code,
  libelle,
  ordre,
  color,
  prixHt1,
  prixHt2,
  prixHt3,
  prixHt4,
  prixHt5,
  prixttc1,
  prixttc2,
  prixttc3,
  prixttc4,
  prixttc5,
  marge1,
  marge2,
  marge3,
  marge4,
  marge5,
  prixAchatHt,
  prixAchattc,
  prixVentePublic
) {
  const credentiel = {
    famille_code: famille_code,
    soussociete_code: getSession("code_generated"),
    libelle: libelle,
    ordre: ordre,
    color: color,
    prix_vente_ht_1: prixHt1,
    prix_vente_ht_2: prixHt2,
    prix_vente_ht_3: prixHt3,
    prix_vente_ht_4: prixHt4,
    prix_vente_ht_5: prixHt5,
    prix_vente_ttc_1: prixttc1,
    prix_vente_ttc_2: prixttc2,
    prix_vente_ttc_3: prixttc3,
    prix_vente_ttc_4: prixttc4,
    prix_vente_ttc_5: prixttc5,
    marge_1: marge1,
    marge_2: marge2,
    marge_3: marge3,
    marge_4: marge4,
    marge_5: marge5,
    prix_achat_ht: prixAchatHt,
    prix_achat_ttc: prixAchattc,
    prix_vente_public: prixVentePublic,
  };

  try {
    const response = await axios.post(api_url + "facturation/sousfamille/", credentiel, config);
    console.log(response);
    toast.success("Sous famille ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Delete a sous famille by its id
 * @param id - The id of the sous-famille to delete.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "id": "1",
 *     "nom": "Sous-famille 1",
 *     "famille": {
 *       "id": "1",
 *       "nom": "Famille 1"
 *     }
 *   }
 * }
 * ```
 */
export async function deleteSousFamilleById(id) {
  try {
    const response = await axios.delete(api_url + "facturation/sousfamille/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It updates a sous famille.
 * @param famille_code - The code of the famille
 * @param libelle - the name of the sous famille
 * @param ordre - the order of the sous famille in the famille
 * @param color - hexadecimal color code
 * @param prixHt1 - prix_vente_ht_1
 * @param prixHt2 - prixHt2,
 * @param prixHt3 - prixHt3,
 * @param prixHt4 - prixHt4,
 * @param prixHt5 - prix de vente HT au 5eme niveau de prix
 * @param prixttc1 - prix vente ttc 1
 * @param prixttc2 - prix de vente TTC 2
 * @param prixttc3 - prix de vente TTC 3eme prix
 * @param prixttc4 - prix de vente TTC 4
 * @param prixttc5 - prix de vente TTC 5eme prix
 * @param marge1 - marge_1,
 * @param marge2 - marge2,
 * @param marge3 - marge3,
 * @param marge4 - marge4,
 * @param marge5 - marge5,
 * @param prixAchatHt - prixAchatHt,
 * @param prixAchattc - prixAchatHt,
 * @param prixVentePublic - prix de vente public
 * @param id - id of the sous famille to update
 * @returns The response is an object that contains the data from the server.
 */
export async function updateSousFamille(
  famille_code,
  libelle,
  ordre,
  color,
  prixHt1,
  prixHt2,
  prixHt3,
  prixHt4,
  prixHt5,
  prixttc1,
  prixttc2,
  prixttc3,
  prixttc4,
  prixttc5,
  marge1,
  marge2,
  marge3,
  marge4,
  marge5,
  prixAchatHt,
  prixAchattc,
  prixVentePublic,
  id
) {
  const credentiel = {
    famille_code: famille_code,
    libelle: libelle,
    ordre: ordre,
    color: color,
    prix_vente_ht_1: prixHt1,
    prix_vente_ht_2: prixHt2,
    prix_vente_ht_3: prixHt3,
    prix_vente_ht_4: prixHt4,
    prix_vente_ht_5: prixHt5,
    prix_vente_ttc_1: prixttc1,
    prix_vente_ttc_2: prixttc2,
    prix_vente_ttc_3: prixttc3,
    prix_vente_ttc_4: prixttc4,
    prix_vente_ttc_5: prixttc5,
    marge_1: marge1,
    marge_2: marge2,
    marge_3: marge3,
    marge_4: marge4,
    marge_5: marge5,
    prix_achat_ht: prixAchatHt,
    prix_achat_ttc: prixAchattc,
    prix_vente_public: prixVentePublic,
    id: id,
  };

  try {
    const response = await axios.patch(api_url + "facturation/sousfamille/", credentiel, config);
    console.log(response);
    toast.success("Sous Famille modifié");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
