import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { getFacturationFournisseurByCode } from "../../../Services/Facturation/FacturationClientApi";
import { getEnteteByClientCode, getEnteteCommercialAchat } from "../../../Services/Facturation/FacturationEnteteApi";
import { editerPaiementById, getPaiementById } from "../../../Services/Facturation/FacturationPaiementApi";
import { getPercent, soustractPercent } from "../../../Utils/PaiementUtils";
import { getSession } from "../../../Utils/SessionUtils";

function EditerPaiement(props) {
  const [isChecked, setisChecked] = useState(false);
  const [dataFournisseur, setDataFournisseur] = useState([]);
  const [dataFacturation, setDataFacturation] = useState([]);

  const [state, setState] = useState({
    societe_code: getSession("code_generated"),
    banque: "",
    mode_paiement: "",
    numero_paiement: "",
    reference_banque: "",
    montant: "",
    rs: "",
    date_reception: "",
    date_echeance: "",
    fournisseur: "",
    num_piece: "",
    exercice: "",
    commentaire: "",
    emplacement: "",
    status: "",
    compte: "",
    sous_compte: "",
  });

  useEffect(() => {
    getPaiementById(props.match.params.id).then((res) => {
      const result = res.data;
      console.log(state);
      console.log(result);
      let merged = { ...state, ...result };

      //console.log(merged);
      setState(merged);
      if (merged.rs) {
        //alert(merged.rs);
        setisChecked(true);
      }
    });
  }, []);

  useEffect(() => {
    getFacturationFournisseurByCode().then((res) => {
      //console.log(res.data);
      setDataFournisseur(res.data);
    });
  }, []);

  useEffect(() => {
    getEnteteCommercialAchat().then((res) => {
      const result = res.data.filter((el) => {
        return el.type == "facture" && el.solde < el.net_a_payer;
      });
      const options = result.map((d) => ({
        value: d.facture_code,
        label: d.date.split("-")[0] + "_" + d.facture_code + "_" + d.montant_total_ttc,
        dateValue: d.date.split("-")[0],
        ttcValue: d.montant_total_ttc,
        numfactureValue: d.facture_code,
      }));
      setDataFacturation(options);
    });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const list = { ...state };
    list[name] = value;
    setState(list);

    if (list.fournisseur) {
      getEnteteByClientCode(list.fournisseur).then((res) => {
        const result = res.data.filter((el) => {
          return el.type == "facture" && el.solde < el.net_a_payer;
        });
        const options = result.map((d) => ({
          value: d.facture_code,
          label: d.date.split("-")[0] + "_" + d.facture_code + "_" + d.montant_total_ttc,
          dateValue: d.date.split("-")[0],
          ttcValue: d.montant_total_ttc,
          numfactureValue: d.facture_code,
        }));
        setDataFacturation(options);
      });
    }

    // setState((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));
  };

  const handleSubmitPaiement = () => {
    alert("kkkkkkkkkkkk");
    // const list = { ...state };
    // list["montant"] = soustractPercent(list.montant);
    // list["rs"] = getPercent(list.rs);
    // console.log(list);
    editerPaiementById(state).then((el) => {
      console.log(el);
    });
    console.log(state);
  };

  const handleCheck = () => {
    if (isChecked === true) {
      const list = { ...state };
      // const oldRs = list.rs;
      // console.log(oldRs);
      list["montant"] = Number(list["rs"]) + Number(list["montant"]);
      list["rs"] = 0;
      setState(list);
    } else {
      const list = { ...state };
      list["rs"] = getPercent(list.montant);
      list["montant"] = soustractPercent(list.montant);
      setState(list);
    }
    setisChecked(!isChecked);
  };
  return (
    <div className="container-fluid mt-4">
      <div class="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Editer paiement</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <div className="form-group">
                    <label>Fournisseur</label>

                    {dataFournisseur && (
                      <select
                        required
                        name="fournisseur"
                        className="form-control"
                        id="exampleFormControlSelect0"
                        onChange={handleInputChange}
                        value={state.fournisseur}
                      >
                        <option>Choisir ...</option>
                        {dataFournisseur.map((option) => (
                          <option value={option.code}>
                            {option.nom} {option.prenom}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                  <div className="form-group">
                    <label>Numéro pièce</label>
                    {dataFacturation && (
                      <select
                        required
                        value={state.num_piece}
                        name="num_piece"
                        className="form-control"
                        id="exampleFormControlSelect1"
                        onChange={handleInputChange}
                      >
                        <option value={"default"}>Choisir Facture</option>
                        {dataFacturation.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <div className="form-group">
                    <label>Banque</label>
                    <input
                      value={state.banque}
                      type="text"
                      className="form-control"
                      required
                      name="banque"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                  <div className="form-group">
                    <label>Mode de paiement</label>
                    <select
                      value={state.mode_paiement}
                      className="form-control"
                      name="mode_paiement"
                      required
                      onChange={handleInputChange}
                    >
                      <option>Choisir ...</option>
                      <option value="cheque">Chèque</option>
                      <option value="virement">Virement</option>
                      <option value="traite">Traite</option>
                      <option value="espece">Espèce</option>
                      <option value="rs">RS</option>
                      <option value="autre">Autre</option>
                    </select>
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                  <div className="form-group">
                    <label>Numéro de paiement</label>
                    <input
                      value={state.numero_paiement}
                      type="text"
                      className="form-control"
                      required
                      name="numero_paiement"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label>Référence banque</label>
                      <input
                        value={state.reference_banque}
                        type="text"
                        className="form-control"
                        required
                        name="reference_banque"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div> */}

                <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Montant</label>
                    <input
                      value={state.montant}
                      type="number"
                      className="form-control"
                      required
                      name="montant"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                  <div className="form-group mt-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        value={isChecked}
                        defaultChecked={isChecked}
                        onChange={handleCheck}
                        checked={isChecked}
                      />

                      {/* <input
                          onClick={handleCheck}
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                        /> */}

                      <label className="form-check-label" htmlFor="flexCheckDefault">
                        RS
                      </label>
                    </div>
                    <input
                      disabled
                      type="number"
                      className="form-control"
                      required
                      name="rs"
                      onChange={handleInputChange}
                      value={state.rs}
                    />
                  </div>
                </div>

                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">RS</label>
                      <input
                        type="number"
                        className="form-control"
                        required
                        name="rs"
                        onChange={handleInputChange}
                        value={state.rs}
                      />
                    </div>
                  </div> */}
                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Exercice</label>
                      <input
                        value={state.exercice}
                        type="text"
                        className="form-control"
                        required
                        name="exercice"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div> */}

                <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Date</label>
                    <input
                      value={state.date_reception}
                      type="date"
                      className="form-control"
                      required
                      name="date_reception"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Date versement</label>
                      <input
                        value={state.date_versement}
                        type="date"
                        className="form-control"
                        required
                        name="date_versement"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div> */}
                <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Date_écheance</label>
                    <input
                      value={state.date_echeance}
                      type="date"
                      className="form-control"
                      required
                      name="date_echeance"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Emplacement</label>
                      <input
                        value={state.emplacement}
                        type="text"
                        className="form-control"
                        required
                        name="emplacement"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div> */}
                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Status</label>
                      <input
                        value={state.status}
                        type="text"
                        className="form-control"
                        required
                        name="status"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div> */}
                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Validation</label>
                      <input
                        value={state.validation}
                        type="text"
                        className="form-control"
                        required
                        name="validation"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div> */}
                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Compte</label>
                      <input
                        value={state.compte}
                        type="text"
                        className="form-control"
                        required
                        name="compte"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div> */}

                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Sous compte</label>
                      <input
                        value={state.sous_compte}
                        type="text"
                        className="form-control"
                        required
                        name="sous_compte"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div> */}
                <div className="col-12 col-sm-6 col-md-6 col-lg-9 col-xl-9">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Commentaire</label>
                    <textarea
                      value={state.commentaire}
                      required
                      name="commentaire"
                      onChange={handleInputChange}
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary" onClick={handleSubmitPaiement}>
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditerPaiement;
