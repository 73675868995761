import React, { Component, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getEntrepriseByCode } from "../../Services/Pointeuse/EntrepriseApi";
import { getPersonnelByCode } from "../../Services/Pointeuse/PersonnelApi";
import { addSalaire, deleteSalaireByCode, getAllSalairesVirement } from "../../Services/Pointeuse/salaireApi";
import { convertAmount } from "../../Utils/ConvertToCurrency_third";
import { sumMontantVirement } from "../../Utils/ObjectOperation";
import "./Virement.css";

function EditerVirement(props) {
  const history = useHistory();
  const [date, setdate] = useState(null);
  const [data, setData] = useState([]);
  const [inputList, setInputList] = useState([{ code: "", cin: "", nom: "", rib: "", banque: "", montant: "", userId: "" }]);
  const [entrepriseData, setEntrepriseData] = useState({
    adresse: "",
    banque: "",
    code_generated: "",
    email: "",
    fax: "",
    id: "",
    logo: "",
    matricule_fiscal: "",
    mobile: "",
    nb_employe: "",
    nom: "",
    numero_cnss: "",
    rib: "",
    secteur_activite: "",
    start_year: "",
    website_url: "",
    reference: "",
    nom: "",
    prenom: "",
  });

  useEffect(() => {
    getEntrepriseByCode().then((res) => {
      Object.keys(entrepriseData).map(function (key, index) {
        entrepriseData[key] = res.data[key];
      });
    });
  }, []);
  useEffect(() => {
    getAllSalairesVirement(props.match.params.id).then((res) => {
      const options = res.data.map((d) => ({
        code: d.code,
        cin: d.cin,
        nom: d.nom,
        rib: d.rib,
        banque: d.banque,
        montant: d.salaire,
        userId: d.user_id,
      }));

      setInputList(options);
      const monthLength = String(res.data[0].mois);
      if (monthLength.length === 1) {
        setdate(res.data[0].annee + "-0" + res.data[0].mois);
      } else {
        setdate(res.data[0].annee + "-" + res.data[0].mois);
      }
    });
  }, []);

  /**
   * It takes in an event and an index and sets the name and value of the input at that index in the
   * inputList to the values of the event's target.
   * @param e - The event object that contains the data of the event that triggered the function.
   * @param index - The index of the input in the list.
   */
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  /**
   * *Remove the item at the given index from the input list.*
   * @param index - The index of the item to remove.
   */
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  /**
   * Add a new input line to the list of inputs
   */
  const handleAddClick = () => {
    setInputList([...inputList, { code: "", cin: "", nom: "", rib: "", banque: "", montant: "", userId: "" }]);
  };

  /**
   * Add the salaire of each employee to the database
   */
  const handleSaveSalaire = () => {
    const sequence = parseInt(Date.now() * Math.random());
    inputList.forEach((element) => {
      addSalaire(element, date, sequence);
    });
    //addSalaire(inputList[0]);
  };

  /**
   * It deletes the salaire by its code and then adds the new salaire to the database.
   */
  const handleDelete = () => {
    deleteSalaireByCode(props.match.params.id).then((res) => {
      inputList.forEach((element) => {
        addSalaire(element, date, props.match.params.id);
      });
    });

    setTimeout(() => {
      toast.success("virement ajouter");
      history.push("/admin/salaire");
    }, 1500);
  };

  /**
   * Prints the current page
   */
  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="card p-4 mt-4">
      <h4 className="page-title mb-4">Virement : {props.match.params.id}</h4>
      <div className="container-fluid">
        <>
          <div className="row">
            <div className="image-fluid border">
              <img
                src={entrepriseData.logo == null || entrepriseData.logo == "" ? "" : localStorage.getItem("baseUrl") + entrepriseData.logo}
                alt=""
              ></img>
            </div>
            <div className="ml-4 border">
              <p>SOCIETE {entrepriseData.nom}</p>
              <p>
                ADRESSE : {entrepriseData.adresse}
                -Ariana
              </p>
              <p>MF: {entrepriseData.matricule_fiscal}</p>
              <p>TEL: {entrepriseData.mobile}</p>
              <p>Mail :{entrepriseData.email}</p>
            </div>
            <div className="ml-4"></div>
          </div>

          <div className="text-right">
            <p>Tunis le {new Date().toISOString().slice(0, 10)}</p>
            <p>Notre référence : {props.match.params.id}</p>
            <div className="noprint" style={{ width: 300, float: "right" }}>
              <input value={date} className="form-control" type="month" onChange={(e) => setdate(e.target.value)} />
            </div>
          </div>

          <div>
            <p>Messieurs, Madame {entrepriseData.banque}</p>
            <p>Par le débit de notre compte n° {entrepriseData.rib} ouvert dans nos livres.</p>
            <p>Veuillez procéder au(x) virement(s) suivant(s) pour le(s) salaire(s) du mois de choisir {date}</p>
          </div>
        </>

        <div>
          <table className="table">
            <thead className="thead">
              <tr>
                <th scope="col">Code</th>
                <th scope="col">C.I.N</th>
                <th scope="col">Nom & Prénom</th>
                <th scope="col">RIB</th>
                <th scope="col">BANQUE</th>
                <th scope="col">Montant</th>
              </tr>
            </thead>
            <tbody>
              {inputList.map((x, i) => (
                <tr key={i}>
                  <td style={{ minWidth: 140 }}>
                    <input
                      className="form-control"
                      type="text"
                      name="code"
                      placeholder="..."
                      value={x.code}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </td>
                  <td style={{ minWidth: 100 }}>
                    <input
                      className="form-control"
                      type="text"
                      name="cin"
                      placeholder="..."
                      value={x.cin}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </td>
                  <td style={{ minWidth: 200 }}>
                    <input
                      className="form-control"
                      type="text"
                      name="nom"
                      placeholder="..."
                      value={x.nom}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </td>
                  <td style={{ minWidth: 250 }}>
                    <input
                      className="form-control"
                      type="text"
                      name="rib"
                      placeholder="..."
                      value={x.rib}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </td>
                  <td style={{ minWidth: 150 }}>
                    <input
                      className="form-control"
                      type="text"
                      name="banque"
                      placeholder="..."
                      value={x.banque}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </td>
                  <td style={{ minWidth: 100 }}>
                    <input
                      className="form-control"
                      type="text"
                      name="montant"
                      placeholder="..."
                      value={x.montant}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </td>

                  <td className="noprint">
                    {inputList.length - 1 === i && (
                      <Link to="#" className="text-success font-18 mr-2" title="Add" onClick={handleAddClick}>
                        <i className="fa fa-plus" />
                      </Link>
                    )}
                    {inputList.length !== 1 && (
                      <Link to="#" className="text-danger font-18" title="remove" onClick={() => handleRemoveClick(i)}>
                        <i className="fa fa-minus" />
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div>
          <p>
            Soit un montant total de : {sumMontantVirement(inputList)} {convertAmount(sumMontantVirement(inputList))}
            Millimes.
          </p>
        </div>
        <div className="noprint text-right">
          {/* <p>Signature</p> */}

          <button type="button" className="btn btn-default float-right mr-1" onClick={handleDelete}>
            <i className="feather feather-plus-circle text-default"> Modifier</i>
          </button>
          <button type="button" className="btn btn-default float-right mr-1">
            <i className="feather feather-printer text-success" onClick={handlePrint}>
              Imprimer
            </i>
          </button>
        </div>
        <div className="text-center">
          <strong>Avec nos remerciements !</strong>
        </div>
        <div className="mt-4 text-center">
          <p>www.clediss.com</p>
        </div>
      </div>
    </div>
  );
}

export default EditerVirement;
