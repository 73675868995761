import React, { useEffect, useState } from "react";
import { deleteTaskCommentMediaById, getTaskMediaCommentByCommentId } from "../../Services/Pointeuse/TaskCommentMediaApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import * as FileSaver from "file-saver";
import ImageComponent from "../Styles/ImageComponent";

function CommentMedia({ commentId }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {}, []);

  const getData = () => {
    setLoading(true);
    getTaskMediaCommentByCommentId(commentId)
      .then((res) => {
        setdata(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDeleteFile = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteTaskCommentMediaById(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  /**
   * It saves the file to the local machine.
   * @param file - The file to download.
   */
  const saveFile = (file) => {
    FileSaver.saveAs(process.env.REACT_APP_API + file, file.split("Z")[file.length - 1]);
  };

  return (
    <>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <>
          {data.map((el) => (
            <div className="col-lg-2 col-md-3">
              <Link to="#" className="attach-supportfiles">
                <span className="img-options-remove" onClick={() => handleDeleteFile(el.id)}>
                  <i className="fe fe-x" />
                </span>

                {["png", "jpg", "jpeg"].some((element) => el.file.split(".").pop().includes(element)) && (
                  <ImageComponent atr={"avatar bg-transparent avatar-xl mr-2"} picture={el.file}></ImageComponent>
                )}
                {el.file.split(".").pop() === "pdf" && (
                  <img src="assets/images/files/attachments/pdf.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                )}
                {el.file.split(".").pop() === "css" && (
                  <img src="assets/images/files/attachments/css.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                )}
                {el.file.split(".").pop() === "html" && (
                  <img src="assets/images/files/attachments/html.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                )}
                {el.file.split(".").pop() === "zip" && (
                  <img src="assets/images/files/attachments/zip.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                )}
                {el.file.split(".").pop() === "docx" && (
                  <img src="assets/images/files/attachments/docx.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                )}
                {el.file.split(".").pop() === "txt" && (
                  <img src="assets/images/files/attachments/txt.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                )}

                {el.file.split(".").pop() === "js" && (
                  <img src="assets/images/files/attachments/js.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                )}
                {el.file.split(".").pop() === "apk" && (
                  <img src="assets/images/files/attachments/apk.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                )}
                {el.file.split(".").pop() === "svg" && (
                  <img src="assets/images/files/attachments/svg.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                )}
                {el.file.split(".").pop() === "pptx" && (
                  <img src="assets/images/files/attachments/pptx.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                )}
                {el.file.split(".").pop() === "xlsx" && (
                  <img src="assets/images/files/attachments/xlsx.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                )}
                {el.file.split(".").pop() === "rar" && (
                  <img src="assets/images/files/attachments/rar.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                )}
                {el.file.split(".").pop() === "sql" && (
                  <img src="assets/images/files/attachments/sql.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                )}

                <div className="attach-title" onClick={() => saveFile(el.file)}>
                  {el.file.split("Z")[1].split(".")[0].substring(0, 3)}.{el.file.split("Z")[1].split(".")[1]}
                </div>
              </Link>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default CommentMedia;
