import React, { useEffect, useState } from "react";
import {
  getClientFournisseurByid,
  updateClientById,
  updateImageClientById,
} from "../../../Services/Facturation/FacturationClientApi";
import moment from "moment";
import { ToastContainer } from "react-toastify";

function EditerFournisseur(props) {
  const [stat, setStat] = React.useState("Client");
  const [tarif, setTarif] = React.useState("tarif");
  const [selectedDate, setSelectedDate] = React.useState();
  const [code, setCode] = useState("");
  const [codeBare, setCodeBare] = useState("");
  const [codeActivity, setCodeActivity] = useState("");
  const [magasin, setMagasin] = useState("");
  const [tva, setTva] = useState("");
  const [potentiel, setPotentiel] = useState("");
  const [potentielSoc, setPotentielSoc] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [cin, setCin] = useState("");
  const [email, setEmail] = useState("");
  const [fixe, setFixe] = useState("");
  const [mobile, setMobile] = useState("");
  const [description, setDescription] = useState("");
  const [rib, setRib] = useState("");
  const [soldeReleve, setSoldeReleve] = useState("");
  const [codeErp, setCodeErp] = useState("");
  const [tax, setTax] = useState("");
  const [adrFacturation, setAdrFacturation] = useState("");
  const [localite, setLocalite] = useState("");
  const [region, setRegion] = useState("");
  const [gouvernorat, setGouvernorat] = useState("");
  const [secteur, setSecteur] = useState("");
  const [zone, setZone] = useState("");
  const [categorie, setCategorie] = useState("");
  const [codeCommercialCategorie, setCodeCommercialCategorie] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [regimeFiscal, setRegimeFiscal] = useState("");
  const [matriculeFiscal, setMatriculeFiscal] = useState("");
  const [commentaire, setCommentaire] = useState("");
  const [image, setImage] = useState("/dist/img/user/user.jpg");
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setimageName] = useState("");
  const [remise, setRemise] = useState("");
  const [delaiPaiement, setDelaiPaiement] = useState("");
  const [plafondCredit, setPlafondCredit] = useState("");
  const [rc, setRc] = useState("");

  useEffect(() => {
    getClientFournisseurByid(props.match.params.id).then((res) => {
      setStat(res.data.statut);
      setTarif(res.data.tarification);
      setSelectedDate(moment(res.data.anniversaire).format("YYYY-MM-DD"));
      setCode(res.data.code);
      setCodeActivity(res.data.activite_code);
      setMagasin(res.data.magasin);
      setNom(res.data.nom);
      setPrenom(res.data.prenom);
      setEmail(res.data.email);
      setFixe(res.data.fixe);
      setMobile(res.data.mobile);
      setDescription(res.data.description);
      setRib(res.data.rib);
      setCodeErp(res.data.code_erp);
      setAdrFacturation(res.data.adresse_facturation);
      setLocalite(res.data.localite);
      setRegion(res.data.region);
      setGouvernorat(res.data.gouvernorat);
      setSecteur(res.data.secteur);
      setZone(res.data.zone);
      setCategorie(res.data.categorie);
      setSpeciality(res.data.specialite);
      setRegimeFiscal(res.data.regime_fiscale);
      setMatriculeFiscal(res.data.matricule_fiscale);
      setCommentaire(res.data.commentaire);
      setRemise(res.data.remise_client);
      setDelaiPaiement(res.data.delai_paiement);
      setPlafondCredit(res.data.plafond_credit);
      setRc(res.data.rc);
      if (res.data.image) {
        setImage(localStorage.getItem("baseUrl") + res.data.image);
      }
    });
  }, []);

  const handleChangeTarification = (event) => {
    setTarif(event.target.value);
  };

  const handleImgClient = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageUrl(event.target.files[0]);
    setimageName(event.target.files[0].name);
    updateImageClientById(event.target.files[0], props.match.params.id);
  };

  const handleSubmit = () => {
    updateClientById(
      code,
      codeActivity,
      "Fournisseur",
      tarif,
      magasin,
      tva,
      potentiel,
      potentielSoc,
      nom,
      prenom,
      cin,
      email,
      selectedDate,
      fixe,
      mobile,
      description,
      rib,
      soldeReleve,
      codeErp,
      tax,
      adrFacturation,
      localite,
      region,
      gouvernorat,
      secteur,
      zone,
      categorie,
      codeCommercialCategorie,
      speciality,
      regimeFiscal,
      matriculeFiscal,
      commentaire,
      remise,
      delaiPaiement,
      plafondCredit,
      rc,
      props.match.params.id
    ).then((res) => {
      console.log(res);
    });
  };

  return (
    <>
      <div className="row mt-4">
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Détail client</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>
                  Code <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCode(e.target.value)}
                  required
                  value={code || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  Code Activité <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCodeActivity(e.target.value)}
                  required
                  value={codeActivity || ""}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Tarification</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  onChange={handleChangeTarification}
                  value={tarif || ""}
                >
                  <option value="tarif">tarif</option>
                  <option value="tarif1">tarif1</option>
                  <option value="tarif2">tarif2</option>
                </select>
              </div>
              <div className="form-group">
                <label>
                  Magasin <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setMagasin(e.target.value)}
                  required
                  value={magasin || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  Code ERP <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCodeErp(e.target.value)}
                  required
                  value={codeErp || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  Adresse de facturation <span className="text-danger">*</span>
                  <small> (maximum 40 caractères)</small>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setAdrFacturation(e.target.value)}
                  required
                  value={adrFacturation || ""}
                  maxLength="40"
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
                <a href="https://select2.github.io/">Select2 documentation</a> for
                more examples and information about the plugin. */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Information de contact</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>
                  Nom <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setNom(e.target.value)}
                  required
                  value={nom || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  Prénom <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setPrenom(e.target.value)}
                  required
                  value={prenom || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  value={email || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  Anniversaire <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="date"
                  onChange={(e) => setSelectedDate(e.target.value)}
                  required
                  value={selectedDate || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  Fixe <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="number" onChange={(e) => setFixe(e.target.value)} value={fixe} />
              </div>
              <div className="form-group">
                <label>
                  Mobile <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  onChange={(e) => setMobile(e.target.value)}
                  required
                  value={mobile || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  Description <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  value={description || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  Commantaire <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCommentaire(e.target.value)}
                  required
                  value={commentaire || ""}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
                <a href="https://select2.github.io/">Select2 documentation</a> for
                more examples and information about the plugin. */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Information complémentaire</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>
                  Catégorie <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCategorie(e.target.value)}
                  required
                  value={categorie || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  Spécialité <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setSpeciality(e.target.value)}
                  required
                  value={speciality || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  Régime fiscal <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setRegimeFiscal(e.target.value)}
                  required
                  value={regimeFiscal || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  Matricule fiscal <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setMatriculeFiscal(e.target.value)}
                  required
                  value={matriculeFiscal || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  Remise client <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setRemise(e.target.value)}
                  required
                  value={remise || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  Delai de paiement <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setDelaiPaiement(e.target.value)}
                  required
                  value={delaiPaiement || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  Plafond crédit <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  onChange={(e) => setPlafondCredit(e.target.value)}
                  required
                  value={plafondCredit || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  Registre de commerce <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setRc(e.target.value)}
                  required
                  value={rc || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  RIB <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setRib(e.target.value)}
                  required
                  value={rib || ""}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
                <a href="https://select2.github.io/">Select2 documentation</a> for
                more examples and information about the plugin. */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Adresse</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>
                  Localité<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={localite}
                  onChange={(e) => setLocalite(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  Région <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label>
                  Gouvernorat <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={gouvernorat}
                  onChange={(e) => setGouvernorat(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  Secteur <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={secteur}
                  onChange={(e) => setSecteur(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  Zone <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={zone}
                  onChange={(e) => setZone(e.target.value)}
                  required
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
                <a href="https://select2.github.io/">Select2 documentation</a> for
                more examples and information about the plugin. */}
            </div>
          </div>
          <div>
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Image client</h3>
                <div className="card-tools">
                  {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                    <i className="fas fa-minus" />
                  </button>
                  <button type="button" className="btn btn-tool" data-card-widget="remove">
                    <i className="fas fa-times" />
                  </button> */}
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="form-group">
                  <div className="d-flex justify-content-center">
                    <img alt="image produit" src={image} className="avatar" />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        className="custom-file-input"
                        accept="image/*"
                        type="file"
                        id="exampleInputFile"
                        onChange={handleImgClient}
                      />
                      <label className="custom-file-label" htmlFor="exampleInputFile">
                        {imageName}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                {/* Visit{" "}
                <a href="https://select2.github.io/">Select2 documentation</a> for
                more examples and information about the plugin. */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn-danger text-center mr-2">
            <i className="fas fa-trash-alt" /> Annuler
          </button>
          <button type="button" className="btn btn-primary text-center" onClick={handleSubmit}>
            <i className="far fa-credit-card" /> Enregistrer
          </button>
        </div>
      </div>
    </>
  );
}

export default EditerFournisseur;
