import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import { getAllSettings, postSettings } from "../../Services/Pointeuse/SettingsApi";
import { validateDay, validateHoure } from "../../Utils/ConfigUtils";
import { getSession } from "../../Utils/SessionUtils";

function ConfigPointage() {
  const alert = useAlert();
  const [nbHoureWork, setnbHoureWork] = useState(null);
  const [nbJourWorkWeek, setnbJourWorkWeek] = useState(null);
  const [totalConge, settotalConge] = useState(null);
  const [startTime, setstartTime] = useState(null);
  const [endTime, setendTime] = useState(null);
  const [breakTime, setbreakTime] = useState(null);
  const [retardDay, setretardDay] = useState(null);
  const [retardMonth, setretardMonth] = useState(null);

  useEffect(() => {
    getAllSettings().then((res) => {
      setnbHoureWork(res.data.workHoure);
      setnbJourWorkWeek(res.data.dayPerWeeks);
      settotalConge(res.data.totalConge);
      setstartTime(res.data.startHoure);
      setendTime(res.data.endHoure);
      setbreakTime(res.data.breakTime);
      setretardDay(res.data.dayRetard);
      setretardMonth(res.data.monthRetard);
    });
  }, []);

  // const handleSave = (e) => {
  //   e.preventDefault();
  //   if (
  //     nbHoureWork === null ||
  //     startTime === null ||
  //     endTime === null ||
  //     nbJourWorkWeek === null ||
  //     breakTime === null ||
  //     totalConge === null ||
  //     retardMonth === null ||
  //     retardDay === null
  //   ) {
  //     //toast.error("vérifié les champs vide");
  //     alert.error("vérifié les champs vides");
  //   } else {
  //     postSettings(
  //       getSession("code_generated"),
  //       nbHoureWork,
  //       startTime,
  //       endTime,
  //       nbJourWorkWeek,
  //       breakTime,
  //       totalConge,
  //       retardMonth,
  //       retardDay
  //     ).then(() => {
  //       alert.success("configuration modifié");
  //     });
  //   }
  // };

  /**
   * It saves the configuration of the company.
   * @param e - The event object.
   */
  const handleSaveConfig = (e) => {
    e.preventDefault();
    if (
      nbHoureWork == null ||
      startTime == null ||
      endTime == null ||
      nbJourWorkWeek == null ||
      breakTime == null ||
      totalConge == null ||
      retardMonth == null ||
      retardDay == null
    ) {
      //toast.error("vérifié les champs vide");
      alert.error("vérifié les champs vides");
    } else {
      console.log("ici");
      if (
        validateTime(startTime) &&
        validateTime(endTime) &&
        validateTime(breakTime) &&
        validateTime(retardDay) &&
        validateTime(retardMonth) &&
        validateHoure(nbHoureWork) &&
        validateDay(nbJourWorkWeek)
      ) {
        postSettings(
          getSession("code_generated"),
          nbHoureWork,
          startTime,
          endTime,
          nbJourWorkWeek,
          breakTime,
          totalConge,
          retardMonth,
          retardDay
        ).then(() => {
          alert.success("configuration ajouté");
        });
      } else {
        alert.error("vérifié les champs non valides");
      }
    }
  };

  /**
   * *Validate the time format.*
   *
   * The function takes a string as an argument and returns a boolean value
   * @param time - The time to validate.
   * @returns a boolean value.
   */
  function validateTime(time) {
    const timeReg = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/.test(time);
    return timeReg;
  }

  return (
    <form onSubmit={handleSaveConfig}>
      <div className="row">
        <div className="col-4">
          <div className="form-group">
            <label className="form-label">
              Nombre d'heures de travail par jour<span className="text-danger"> *</span>
            </label>
            <input
              type="number"
              className="form-control"
              id="name1"
              placeholder="ex : 1"
              required
              value={nbHoureWork}
              onChange={(e) => setnbHoureWork(e.target.value)}
              style={
                validateHoure(nbHoureWork) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }
              }
            />
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label className="form-label">
              Nombre des jours de travail par semaine<span className="text-danger"> *</span>
            </label>
            <input
              type="number"
              className="form-control"
              id="name1"
              placeholder="ex : 1"
              required
              value={nbJourWorkWeek}
              onChange={(e) => setnbJourWorkWeek(e.target.value)}
              style={
                validateDay(nbJourWorkWeek) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }
              }
            />
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label className="form-label">
              Total Congé<span className="text-danger"> *</span>
            </label>
            <input
              type="number"
              className="form-control"
              id="name1"
              placeholder="ex : 1"
              required
              value={totalConge}
              onChange={(e) => settotalConge(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          <div className="form-group">
            <label className="form-label">
              Heure début<span className="text-danger"> *</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="name1"
              placeholder="08:00:00 (hh:mm:ss)"
              required
              value={startTime}
              onChange={(e) => setstartTime(e.target.value)}
              style={
                validateTime(startTime) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }
              }
            />
            <small className="form-text text-muted  mb-5">note : pour 8h choisir 08:00:00</small>
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label className="form-label">
              Heure fin<span className="text-danger"> *</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="name1"
              placeholder="17:00:00 (hh:mm:ss)"
              required
              value={endTime}
              onChange={(e) => setendTime(e.target.value)}
              style={validateTime(endTime) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }}
            />
            <small className="form-text text-muted  mb-5">note : pour 17h choisir 17:00:00</small>
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label className="form-label">
              Durée de pause<span className="text-danger"> *</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="name1"
              placeholder="01:00:00 (hh:mm:ss)"
              required
              value={breakTime}
              onChange={(e) => setbreakTime(e.target.value)}
              style={
                validateTime(breakTime) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }
              }
            />
            <small className="form-text text-muted  mb-5">note : pour une heure choisir 01:00:00</small>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className="form-label">
              Retard par jour<span className="text-danger"> *</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="name1"
              placeholder="00:15:00 (hh:mm:ss)"
              required
              value={retardDay}
              onChange={(e) => setretardDay(e.target.value)}
              style={
                validateTime(retardDay) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }
              }
            />
            <small className="form-text text-muted  mb-5">note : pour une demi-heure choisir 00:30:00</small>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label">
              Retard par mois<span className="text-danger"> *</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="name1"
              placeholder="03:00:00 (hh:mm:ss)"
              required
              value={retardMonth}
              onChange={(e) => setretardMonth(e.target.value)}
              style={
                validateTime(retardMonth) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }
              }
            />
            <small className="form-text text-muted  mb-5">note : pour 3 heures choisir 03:00:00</small>
          </div>
        </div>
      </div>

      <div className="card-footer text-right">
        <button type="submit" className="btn btn-primary">
          Enregistrer
        </button>
      </div>
    </form>
  );
}

export default ConfigPointage;
