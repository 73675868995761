import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteUserById, editUserStatus, getUsersByCode, getUsersPersonnelByCode } from "../../Services/Pointeuse/UsersApi";
import { filterUserByWord } from "../../Utils/SortObject";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import ImageComponent from "../Styles/ImageComponent";
import swal from "sweetalert";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import { getfilter } from "../../Utils/ObjectFilter";
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import AddUser from "./AddUser";
import CopieContent from "../Exports/CopieContent";
import PrintContent from "../Exports/PrintContent";
import { getSession } from "../../Utils/SessionUtils";
import { getAllValideCongeByCode } from "../../Services/Pointeuse/CongeApi";

function ListUsers() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [males, setmales] = useState(null);
  const [femelle, setfemelle] = useState(null);
  const [inactif, setinactif] = useState(null);
  const [size, setsize] = useState(20);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [conge, setConge] = useState(null);

  /**
   * The function sets the size of the data to be retrieved from the API.
   * @param e - The event object that contains the data we want to use.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  useEffect(() => {
    //get all conge
    getAllValideCongeByCode().then((res) => {
      console.log(res.data);
      setConge(res.data);
    });
  }, []);

  const getConge = (id) => {
    //user_code_id
    const filterUserId = conge.filter((el) => {
      return el.user_code_id == id;
    });
    return filterUserId.length;
  };
  const getSolde = (id, solde) => {
    const filterUserId = conge.filter((el) => {
      return el.user_code_id == id;
    });
    return solde - filterUserId.length;
  };

  /**
   * It gets the data from the API and then sorts it by the isactif column.
   */
  const getData = () => {
    setLoading(true);
    getUsersPersonnelByCode()
      .then((res) => {
        const result = res.data;
        const sortActifUser = result.sort((a, b) => b.isactif - a.isactif);
        setdata(sortActifUser);
        setallInitialData(res.data);
        setmales(getfilter(result, "gender", "male"));
        setfemelle(getfilter(result, "gender", "femele"));
        setinactif(getfilter(result, "isactif", "0"));
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  /**
   * The onChangePage function is called when the user clicks on the page numbers.
   * It takes the page of items returned by the paginate function as a parameter and
   * uses that to update the currentData state
   * @param pageOfItems - an array of items on the current page
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the search word.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    const dataSearch = filterUserByWord(allInitialData, e.target.value);
    setdata(dataSearch);
  };

  /**
   * It activates or deactivates a user.
   * @param id - The id of the user you want to edit.
   * @param valeur - The value of the checkbox.
   */
  const handleActivate = (id, valeur) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Voulez vous vraiment modifier ce enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (valeur == 1) {
          editUserStatus(id, "0").then(() => {
            swal("Opération effectuée avec succès!", {
              icon: "success",
            });
            getData();
          });
        } else {
          editUserStatus(id, "1").then(() => {
            swal("Opération effectuée avec succès!", {
              icon: "success",
            });
            getData();
          });
        }
      }
    });
  };
  /**
   * It deletes a user from the database.
   * @param id - The id of the user to delete.
   */

  const handleDeleteUser = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteUserById(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  /**
   * *This function is used to toggle the collapse state of the sidebar.*
   */
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Collaborateurs</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="#" className="btn btn-primary mr-3" data-toggle="modal" data-target="#newusermodal">
                Ajouter collaborateur
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total collaborateur</span>
                    <h3 className="mb-0 mt-1 text-success">{data.length}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto float-right">
                    <i className="las la-users" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total Homme</span>
                    <h3 className="mb-0 mt-1 text-primary">{males?.length}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-primary-transparent my-auto  float-right">
                    <i className="las la-male" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Totale Femme</span>
                    <h3 className="mb-0 mt-1 text-secondary">{femelle?.length}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-secondary-transparent my-auto  float-right">
                    <i className="las la-female" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total inactifs</span>
                    <h3 className="mb-0 mt-1 text-danger">{inactif?.length}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-danger-transparent my-auto  float-right">
                    <i className="las la-user-friends" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddUser getData={getData}></AddUser>

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">Liste des collaborateurs</h4>
              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">Note:</label>
                </div>
                <div>
                  <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> activé
                  </span>
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> non activé
                  </span>
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            Afficher{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            entrées
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Recherche..."
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>
                          <ExportCsv data={data} name={"List_collaborateurs"} />
                          <ExportPdf data={data} name={"List_collaborateurs"} columns={["nom", "prenom", "email", "fonction", "mobile"]} />
                          <CopieContent />
                          {/* <PrintContent /> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0">Actions</th>
                              {/* <th className="border-bottom-0 w-5">#Collab ID</th> */}
                              <th className="border-bottom-0">Collab Nom/Prénom</th>
                              <th className="border-bottom-0">Fonction</th>
                              <th className="border-bottom-0">Genre</th>
                              <th className="border-bottom-0">Téléphone</th>
                              <th className="border-bottom-0">Date de naissance</th>
                              <th className="border-bottom-0">Statut</th>
                              <th className="border-bottom-0">Nb-J Congé</th>
                              <th className="border-bottom-0">Consommé congé</th>
                              <th className="border-bottom-0">Solde conge</th>
                              <th className="border-bottom-0">Type Contrat</th>
                              <th className="border-bottom-0">Date d'embauche</th>
                              <th className="border-bottom-0">Date fin contrat</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el, idx) => (
                              <tr key={idx} role="row" className="odd">
                                <td className="text-left">
                                  {/* <Link
                                    to="#"
                                    className="action-btns"
                                    data-toggle="modal"
                                    data-target="#viewsalarymodal"
                                  >
                                    <i className="feather feather-eye text-default" data-tip="voir" title="View" />
                                  </Link> */}

                                  <Link
                                    to={"/admin/users/" + el.id}
                                    className="action-btns"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="éditer collaborateur"
                                  >
                                    <i className="feather feather-edit text-info" />
                                  </Link>

                                  {/* <Link to="#" className="action-btns" data-tip="supprimer">
                                    <i className="feather feather-x text-danger" />
                                  </Link> */}

                                  <Link
                                    to="#"
                                    className="action-btns"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="activer/désactiver"
                                    onClick={() => handleActivate(el.id, el.isactif)}
                                  >
                                    {/* <i className="feather feather-slack text-danger" /> */}
                                    <i className="fa fa-ban"></i>
                                  </Link>

                                  <Link to={"/admin/chat/" + el.id} className="action-btns" data-toggle="tooltip" data-placement="top" title="chat">
                                    <i className="feather feather-message-square text-success" />
                                  </Link>

                                  {el.id != getSession("id") && (
                                    <Link
                                      to="#"
                                      className="action-btns"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="supprimer collaborateur"
                                      onClick={() => handleDeleteUser(el.id)}
                                    >
                                      <i className="feather feather-trash text-danger" />
                                    </Link>
                                  )}
                                </td>
                                {/* <td>#{el.id}</td> */}
                                <td>
                                  <div className="d-flex">
                                    <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={el.img}></ImageComponent>
                                    <div className="mr-8 mt-0 mt-sm-1 d-block">
                                      <h6 className="mb-1 fs-14">
                                        {el.nom} {el.prenom}
                                      </h6>
                                      <p className="text-muted mb-0 fs-12">{el.email}</p>
                                    </div>
                                  </div>
                                </td>

                                <td>{el.fonction}</td>
                                <td>{el.gender}</td>
                                <td>{el.mobile}</td>
                                <td>{el.birth_date}</td>

                                <td>
                                  {el.isactif == 1 ? (
                                    <span className="badge badge-success">Active</span>
                                  ) : (
                                    <span className="badge badge-danger">Non Active</span>
                                  )}
                                </td>

                                <td>{el.solde_conge}</td>
                                <td>{conge && getConge(el.id)}</td>
                                <td>{conge && getSolde(el.id, el.solde_conge)}</td>
                                <td>{el.type_contrat}</td>
                                <td>{el.date_embauche?.split(" ")[0]}</td>
                                <td>{el.date_fincontrat?.split(" ")[0]}</td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th className="border-bottom-0">Actions</th>
                              {/* <th className="border-bottom-0 w-5">#Collab ID</th> */}
                              <th className="border-bottom-0">Collab Nom/Prénom</th>
                              <th className="border-bottom-0">Fonction</th>
                              <th className="border-bottom-0">Genre</th>
                              <th className="border-bottom-0">Téléphone</th>
                              <th className="border-bottom-0">Date de naissance</th>
                              <th className="border-bottom-0">Statut</th>
                              <th className="border-bottom-0">Nb-J Congé</th>
                              <th className="border-bottom-0">Type Contrat</th>
                              <th className="border-bottom-0">Date d'embauche</th>
                              <th className="border-bottom-0">Date fin contrat</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          Affichage de 1 à {size} sur {data.length} entrées
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListUsers;
