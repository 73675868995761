import React, { useEffect, useState } from "react";
import { deleteDocumentById, getDocumentByCode } from "../../Services/Pointeuse/DocumentApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import * as FileSaver from "file-saver";
import swal from "sweetalert";
import * as timeago from "timeago.js";
import { Link } from "react-router-dom";
import UploadFiles from "./UploadFiles";

function ListFiles() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [searchText, setsearchText] = useState("");

  useEffect(() => {
    getData();
  }, []);

  /**
   * It gets the data from the API and sets the data to the state.
   */
  const getData = () => {
    setLoading(true);
    getDocumentByCode()
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
        console.log(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  /**
   * It calls the getData function to get the next page of data.
   */
  const onChangePage = () => {
    // update state with new page of items
    getData();
  };
  /**
   * It filters the data based on the search text.
   * @param e - The event object that contains the target element.
   */
  const handleSearch = (e) => {
    setsearchText(e.target.value);
    if (e.target.value) {
      const dataSearch = data.filter((el) => {
        return el.file.split("Z")[1].toLowerCase().includes(searchText.toLowerCase()) || el.type.toLowerCase().includes(searchText.toLowerCase());
      });
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  /**
   * It deletes a document from the database.
   * @param id - The id of the document to delete.
   */
  const handleDeleteFile = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteDocumentById(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  /**
   * It saves the file to the local machine.
   * @param file - The file to download.
   */
  const saveFile = (file) => {
    FileSaver.saveAs(process.env.REACT_APP_API + file, file.split("Z")[file.length - 1]);
  };

  /**
   * Given a file type, return all the files of that type
   * @param value - The value of the dropdown menu.
   */
  const getFileByType = (value) => {
    const newArray = allInitialData.filter((el) => {
      return el.type === value;
    });
    setdata(newArray);
  };

  /**
   * Given a value, return all the files that have that value as their extension
   * @param value - The value of the dropdown menu.
   */
  const getFileByExtension = (value) => {
    const newArray = allInitialData.filter((el) => {
      return el.file.split(".").pop() === value;
    });
    setdata(newArray);
  };

  return (
    <div>
      <div className="page-header d-lg-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Gestionnaire de fichiers</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className=" btn-list">
            <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
              {" "}
              <i className="feather feather-mail" />{" "}
            </button>
            <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
              {" "}
              <i className="feather feather-phone-call" />{" "}
            </button>
            <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
              {" "}
              <i className="feather feather-info" />{" "}
            </button>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-3">
          <div className="card">
            <div className="card-body d-flex">
              <div className="chart-circle chart-circle-sm chart-circle-primary ml-0 mr-4" data-value="0.85" data-thickness={5} data-color="#3366ff">
                <div className="mx-auto chart-circle-value text-center fs-14">85%</div>
              </div>
              <div className="mt-3">
                <h5 className="mb-1 font-weight-bold">Storage</h5>
                <p className="mb-0">
                  <span className="text-muted">13.65gb</span> / <span className="text-muted">16gb</span>
                </p>
              </div>
            </div>
            <div className="card-body">
              <div className="list-group list-group-transparent mb-0 file-manger">
                <Link
                  to="#"
                  onClick={() => getFileByExtension("png")}
                  className="list-group-item list-group-item-action d-flex align-items-center px-0"
                >
                  <span className="icons">
                    <i className="ri-image-line" />
                  </span>{" "}
                  PNG
                </Link>
                <Link
                  to="#"
                  onClick={() => getFileByExtension("jpeg")}
                  className="list-group-item list-group-item-action d-flex align-items-center px-0"
                >
                  <span className="icons">
                    <i className="ri-image-line" />
                  </span>{" "}
                  JPEG
                </Link>
                {/* <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center px-0">
                  <span className="icons">
                    <i className="ri-live-line" />
                  </span>{" "}
                  Videos
                </Link> */}
                <Link
                  to="#"
                  onClick={() => getFileByExtension("pdf")}
                  className="list-group-item list-group-item-action d-flex align-items-center px-0"
                >
                  <span className="icons">
                    <i className="ri-folders-line" />
                  </span>{" "}
                  PDF
                </Link>
                <Link
                  to="#"
                  onClick={() => getFileByExtension("pdf")}
                  className="list-group-item list-group-item-action d-flex align-items-center px-0"
                >
                  <span className="icons">
                    <i className="ri-folders-line" />
                  </span>{" "}
                  WORD
                </Link>
                <Link
                  to="#"
                  onClick={() => getFileByExtension("pdf")}
                  className="list-group-item list-group-item-action d-flex align-items-center px-0"
                >
                  <span className="icons">
                    <i className="ri-folders-line" />
                  </span>{" "}
                  EXCEL
                </Link>
                <Link
                  to="#"
                  onClick={() => getFileByExtension("txt")}
                  className="list-group-item list-group-item-action d-flex align-items-center px-0"
                >
                  <span className="icons">
                    <i className="ri-folders-line" />
                  </span>{" "}
                  TEXT
                </Link>
                {/* <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center px-0">
                  <span className="icons">
                    <i className="ri-file-music-line" />
                  </span>{" "}
                  Music
                </Link> */}
                {/* <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center px-0">
                  <span className="icons">
                    <i className="ri-shield-keyhole-line" />
                  </span>{" "}
                  APKs
                </Link> */}
                {/* <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center px-0">
                  <span className="icons">
                    <i className="ri-download-2-line" />
                  </span>{" "}
                  Downloads
                </Link> */}
                {/* <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center px-0">
                  <span className="icons">
                    <i className="ri-indent-decrease" />
                  </span>{" "}
                  More
                </Link>
                <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center px-0">
                  <span className="icons">
                    <i className="ri-lock-2-line" />
                  </span>{" "}
                  Hiddent Files
                </Link> */}
              </div>
            </div>
            <div className="card-body">
              <div className="list-group list-group-transparent mb-0 mail-inbox">
                <Link
                  to="#"
                  onClick={() => getFileByType("conge")}
                  className="list-group-item list-group-item-action d-flex align-items-center px-0 py-2"
                >
                  <span className="w-3 h-3 brround bg-primary mr-2" />
                  Congé
                </Link>
                <Link
                  to="#"
                  onClick={() => getFileByType("autorisation")}
                  className="list-group-item list-group-item-action d-flex align-items-center px-0 py-2"
                >
                  <span className="w-3 h-3 brround bg-secondary mr-2" />
                  Autorisation
                </Link>
                <Link to="#" onClick={() => getData()} className="list-group-item list-group-item-action d-flex align-items-center px-0 py-2">
                  <span className="w-3 h-3 brround bg-success mr-2" />
                  Tous
                </Link>
                {/*  <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center px-0 py-2">
                  <span className="w-3 h-3 brround bg-info mr-2" />
                  Transfer files
                </Link>
                <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center px-0 py-2">
                  <span className="w-3 h-3 brround bg-warning mr-2" />
                  Deep Clean
                </Link>
                <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center px-0 py-2">
                  <span className="w-3 h-3 brround bg-danger mr-2" />
                  Favourities
                </Link>
                <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center px-0 py-2">
                  <span className="w-3 h-3 brround bg-primary mr-2" />
                  Settings
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9">
          <div className="row">
            <div className="col mb-4">
              <UploadFiles onChangePage={onChangePage}></UploadFiles>
              {/* <Link to="#" className="btn btn-light">
                <i className="fe fe-folder" /> New folder
              </Link> */}
            </div>
            <div className="col col-auto mb-4">
              <div className="form-group w-100">
                <div className="input-icon">
                  <span className="input-icon-addon">
                    <i className="fe fe-search" />
                  </span>
                  <input onChange={handleSearch} type="text" className="form-control" placeholder="Rechercher fichier" />
                </div>
              </div>
            </div>
          </div>
          {loading && <CustomSyncLoader></CustomSyncLoader>}
          {!loading && error && <ErrorCustum msg={`Erreur: ${error}`}></ErrorCustum>}
          {!loading && !error && data && (
            <div className="row">
              {data.map((el, idx) => (
                <div key={idx} className="col-xl-3 col-md-4 col-sm-6">
                  <div className="card border p-0 shadow-none">
                    <div className="d-flex align-items-center px-4 pt-4">
                      {/* <label className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          name="example-checkbox2"
                          defaultValue="option2"
                        />
                        <span className="custom-control-label" />
                      </label> */}
                      <div className="float-right ml-auto">
                        <div className="btn-group ml-3 mb-0">
                          <a href="#" className="option-dots" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-ellipsis-v" />
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            {/* <a className="dropdown-item" href="#">
                              <i className="fe fe-edit mr-2" /> Edit
                            </a> */}
                            {/* <Link className="dropdown-item" to="#">
                              <i className="fe fe-share mr-2" /> Partager
                            </Link> */}
                            <Link className="dropdown-item" to="#" onClick={() => saveFile(el.file)}>
                              <i className="fe fe-download mr-2" /> Télécharger
                            </Link>
                            <Link className="dropdown-item" to="#" onClick={() => handleDeleteFile(el.id)}>
                              <i className="fe fe-trash mr-2" /> Supprimer
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body pt-0 text-center">
                      <div className="file-manger-icon">
                        {["png", "jpg", "jpeg"].some((element) => el.file.split(".").pop().includes(element)) && (
                          <img src="https://coderthemes.com/highdmin/layouts/assets/images/file_icons/jpg.svg" alt="icon" />
                        )}
                        {el.file.split(".").pop() === "pdf" && (
                          <img src="https://coderthemes.com/highdmin/layouts/assets/images/file_icons/pdf.svg" alt="icon" />
                        )}
                        {el.file.split(".").pop() === "docx" && (
                          <img src="https://coderthemes.com/highdmin/layouts/assets/images/file_icons/doc.svg" alt="icon" />
                        )}
                      </div>
                      <h6 className="mb-1 font-weight-semibold mt-4">{el.file.split("Z")[1]}</h6>
                      <span className="text-muted">{timeago.format(el.created_at, "fr_FR")}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ListFiles;
