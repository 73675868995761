import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get a document type by code
 * @returns The response is an array of objects.
 */
export async function getDocumentTypeByCode() {
  try {
    const response = await axios.get(api_url + "documenttype/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add a document type to the database
 * @param nom - the name of the document type
 * @param categorie - the category of the document type
 * @returns The response from the server.
 */
export async function addDocumentType(nom, categorie) {
  const credentiel = {
    code_generated: getSession("code_generated"),
    nom: nom,
    categorie: categorie,
  };

  try {
    const response = await axios.post(api_url + "documenttype", credentiel, config);
    console.log(response);
    toast.success("document ajouté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * `deleteDocumentTypeById` deletes a document type by id
 * @param id - The id of the document type to delete.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "id": "string",
 *   "name": "string",
 *   "description": "string",
 *   "created": "string",
 *   "modified": "string",
 *   "createdBy": "string",
 *   "modifiedBy": "string",
 *   "version": 0
 */
export async function deleteDocumentTypeById(id) {
  try {
    const response = await axios.delete(api_url + "documenttype/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
