import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all the vaccin by code
 * @returns The response is an array of objects.
 */
export async function getAllVaccinByCode() {
  try {
    const response = await axios.get(api_url + "vaccin/code/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * It gets a single vaccin by id.
 * @param id - The id of the vaccin you want to get.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "id": "1",
 *     "name": "Vaccin 1",
 *     "description": "Vaccin 1 description",
 *     "price": "10.00",
 *     "stock": "10",
 *     "createdAt": "2020-
 */
export async function getVaccinById(id) {
  try {
    const response = await axios.get(api_url + "vaccin/" + id, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add a vaccination to the database
 * @param user_id - The id of the user who is adding the vaccine.
 * @param nom_vaccin - The name of the vaccine
 * @param dose_vaccin - The dose of the vaccine.
 * @param date_dose_1 - Date of the first dose
 * @param date_dose_2 - Date of the second dose
 * @param date_dose_3 - Date of the third dose
 * @param num_inscrit - the number of the child
 * @param centre_vaccination - The name of the vaccination center.
 * @param certificat_pdf - the pdf file of the certificate
 * @returns The response is an object that contains the data that was returned by the API.
 */
export async function addVaccin(
  user_id,
  nom_vaccin,
  dose_vaccin,
  date_dose_1,
  date_dose_2,
  date_dose_3,
  num_inscrit,
  centre_vaccination,
  certificat_pdf
) {
  const frmData = new FormData();
  frmData.append("code_generated", localStorage.getItem("code_generated"));
  frmData.append("user_id", user_id);
  frmData.append("nom_vaccin", nom_vaccin);
  frmData.append("dose_vaccin", dose_vaccin);
  frmData.append("date_dose_1", date_dose_1);
  frmData.append("date_dose_2", date_dose_2);
  frmData.append("date_dose_3", date_dose_3);
  frmData.append("num_inscrit", num_inscrit);
  frmData.append("centre_vaccination", centre_vaccination);
  // frmData.append("qr_code", qr_code);
  frmData.append("certificat_pdf", certificat_pdf);
  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.post(api_url + "vaccin", frmData, options);
    console.log(response);
    toast.success("vaccination ajouté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Update a certificate pdf file
 * @param certificat_pdf - the file to upload
 * @param id - the id of the vaccine to update
 * @returns The response is an object that contains the data, a status code, and a header.
 */
export async function updateCertificatPdf(certificat_pdf, id) {
  const frmData = new FormData();
  frmData.append("certificat_pdf", certificat_pdf);
  frmData.append("id", id);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.put(api_url + "vaccin/pdf", frmData, options);
    toast.success("mise à jour");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It edits a vaccin by id.
 * @param nom_vaccin - nom du vaccin
 * @param dose_vaccin - the dose of the vaccin
 * @param date_dose_1 - date of the first dose
 * @param date_dose_2 - date_dose_2,
 * @param date_dose_3 - date of the third dose
 * @param num_inscrit - the number of the child
 * @param centre_vaccination - the name of the vaccination center
 * @param id - id of the vaccin to be updated
 * @returns The response is an object that contains the data that was returned by the server.
 */
export async function editVaccinById(nom_vaccin, dose_vaccin, date_dose_1, date_dose_2, date_dose_3, num_inscrit, centre_vaccination, id) {
  const credentiel = {
    nom_vaccin: nom_vaccin,
    dose_vaccin: dose_vaccin,
    date_dose_1: date_dose_1,
    date_dose_2: date_dose_2,
    date_dose_3: date_dose_3,
    num_inscrit: num_inscrit,
    centre_vaccination: centre_vaccination,
    id: id,
  };

  try {
    const response = await axios.put(api_url + "vaccin", credentiel, config);
    console.log(response);
    toast.success("mise à jour");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It deletes a vaccin from the database.
 * @param id - The id of the vaccine to delete.
 * @returns The response is an object with the status code and the data.
 */
export async function deleteVaccinById(id) {
  try {
    const response = await axios.delete(api_url + "vaccin/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
