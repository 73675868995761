import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getProjetByCode } from "../../Services/Pointeuse/ProjetApi";
import { addTaskMedia, deleteTaskMediaByTaskId, getTaskMediaByTaskId } from "../../Services/Pointeuse/TaskMediaApi";
import { addTaskToUser, getTasksById, senFcmTask, upadateTaskById } from "../../Services/Pointeuse/TasksApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import * as FileSaver from "file-saver";
import swal from "sweetalert";
import moment from "moment";
import firebase from "../../firebase";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import ImageComponent from "../Styles/ImageComponent";
import { getSession, getToken } from "../../Utils/SessionUtils";
import axios from "axios";

function DetailTask({ taskId }) {
  const history = useHistory();
  const [object, setobject] = useState();
  const myRefFile = useRef();
  const [description, setdescription] = useState(null);
  const [etat, setetat] = useState(null);
  const [commentaire, setcommentaire] = useState(null);
  const [dateDebut, setdateDebut] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [dateFin, setdateFin] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const myRefDelete = useRef(null);
  const [userId, setuserId] = useState(null);
  const [projects, setprojects] = useState(null);
  const [projectId, setprojectId] = useState(null);
  const [color, setcolor] = useState(null);
  const [project, setproject] = useState("");
  const [users, setusers] = useState(null);
  const [type, settype] = useState("task");
  const [data, setdata] = useState(null);
  const [priority, setpriority] = useState("0");
  const [filesTask, setfilesTask] = useState(null);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getTaskMediaByTaskId(taskId).then((res) => {
      setfilesTask(res.data);
    });
  };

  useEffect(() => {
    getProjetByCode().then((res) => {
      setproject(res.data);
    });
  }, []);

  useEffect(() => {
    getUsersByCode().then((res) => {
      setusers(res.data);
    });
  }, []);

  const handleUploadFiles = () => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i].file;
      addTaskMedia(file, taskId).then((res) => {
        console.log(res);
        setFiles(null);
        getData();
      });
    }
  };

  const handleDeleteFile = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteTaskMediaByTaskId(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  /**
   * It saves the file to the local machine.
   * @param file - The file to download.
   */
  const saveFile = (file) => {
    FileSaver.saveAs(process.env.REACT_APP_API + file, file.split("Z")[file.length - 1]);
  };

  const sendFirebaseNotification = (text, receiver) => {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then(() => {
        senFcmTask(text, receiver);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * It sends the data to the API.
   */
  const handelUpdate = () => {
    console.log(commentaire);
    upadateTaskById(userId, object, description, etat, commentaire, dateDebut, dateFin, color, projectId, type, priority, taskId).then((res) => {
      //console.log(res.data);
      //history.push("/admin/task");
      sendFirebaseNotification(object, userId);
      handleUploadFiles();
    });
  };
  useEffect(() => {
    getTasksById(taskId).then((res) => {
      console.log(res.data);
      setdata(res.data);
      setuserId(res.data.user_code_id);
      setcolor(res.data.color);
      setcommentaire(res.data.commentaire);
      setdateDebut(res.data.date_debut || moment(new Date()).format("YYYY-MM-DD"));
      setdateFin(res.data.date_fin || moment(new Date()).format("YYYY-MM-DD"));
      setdescription(res.data.description);
      setetat(res.data.etat);
      setobject(res.data.object);
      setpriority(res.data.priority);
      setprojectId(res.data.projet_id);
      settype(res.data.type);
    });
  }, []);

  return (
    <div className="card-body">
      {data && (
        <div className="main-profile-bio mb-0">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Titre</label>
                <input className="form-control" placeholder="Text" value={object} onChange={(e) => setobject(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Coleur</label>
                <input className="form-control" placeholder="color" value={color} type="color" onChange={(e) => setcolor(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>
                    Projet<span className="text-danger">*</span>
                  </label>

                  <select onChange={(e) => setprojectId(e.target.value)} className="selectpicker form-control" value={projectId}>
                    <option value="0">Choisir un projet</option>
                    {project &&
                      project.map((el, idx) => (
                        <option key={idx} value={el.id}>
                          {el.nom}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>
                    Collaborateur<span className="text-danger">*</span>
                  </label>

                  <select onChange={(e) => setuserId(e.target.value)} className="selectpicker form-control" value={userId}>
                    <option value="0">Choisir un collaborateur</option>
                    {users &&
                      users.map((el, idx) => (
                        <option key={idx} value={el.id}>
                          {el.nom} {el.prenom}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Priorité:</label>
                <select
                  name="attendance"
                  className="form-control custom-select select2"
                  data-placeholder="Choisir priorité"
                  onChange={(e) => setpriority(e.target.value)}
                  value={priority}
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Type:</label>
                <select
                  name="attendance"
                  className="form-control custom-select select2"
                  data-placeholder="Choisir type de tâche"
                  onChange={(e) => settype(e.target.value)}
                  value={type}
                >
                  <option value={"task"}>Tâche</option>
                  <option value={"bug"}>Bug</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Date début:</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="feather feather-calendar" />
                    </div>
                  </div>
                  <input
                    className="form-control fc-datepicker"
                    placeholder="DD-MM-YYY"
                    type="date"
                    onChange={(e) => setdateDebut(e.target.value)}
                    value={dateDebut}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Date fin:</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="feather feather-calendar" />
                    </div>
                  </div>
                  <input
                    className="form-control fc-datepicker"
                    placeholder="DD-MM-YYY"
                    type="date"
                    onChange={(e) => setdateFin(e.target.value)}
                    value={dateFin}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">Description</label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows={3}
              value={description}
              onChange={(e) => setdescription(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">Commentaire</label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows={3}
              value={commentaire}
              onChange={(e) => setcommentaire(e.target.value)}
            />
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Choisir Statut:</label>
                <select
                  name="attendance"
                  className="form-control custom-select select2"
                  data-placeholder="Choisir Statut"
                  onChange={(e) => setetat(e.target.value)}
                  value={etat}
                >
                  <option label="Choisir Statut" />
                  <option value="afaire">à faire</option>
                  <option value="encours">en cours</option>
                  <option value="revoir">à revoir</option>
                  <option value="validee">validé</option>
                  <option value="terminee">terminé</option>
                  <option value="tester">à tester</option>
                </select>
              </div>
            </div>
          </div>

          {/* <div className="custom-controls-stacked d-md-flex">
            <label className="form-label mt-1 mr-5">Statut :</label>
            <label className="custom-control custom-radio success mr-4">
              <input type="radio" className="custom-control-input" name="example-radios1" checked onClick={() => setetat("afaire")} />
              <span className="custom-control-label">à faire</span>
            </label>
            <label className="custom-control custom-radio success mr-4">
              <input type="radio" className="custom-control-input" name="example-radios1" defaultValue="option2" onClick={() => setetat("encours")} />
              <span className="custom-control-label">en cours</span>
            </label>
     
          </div> */}
        </div>
      )}

      <h5 className="mb-4 mt-5 font-weight-semibold">Pièces jointes</h5>
      <div className="attachments-doc">
        <div className="row">
          {filesTask &&
            filesTask.map((el, idx) => (
              <div key={el.idx} className="col-md-12 col-xl-4 mb-2">
                <div className="list-group-item  align-items-center">
                  <div className="d-md-flex">
                    {["png", "jpg", "jpeg"].some((element) => el.file.split(".").pop().includes(element)) && (
                      <ImageComponent atr={"avatar bg-transparent avatar-xl mr-2"} picture={el.file}></ImageComponent>
                      // <img
                      //   src={localStorage.getItem("baseUrl") + el.file}
                      //   onError={(e) => {
                      //     e.target.onerror = null;
                      //     e.target.src = "assets/images/users/avatar.png";
                      //   }}
                      //   loading="lazy"
                      //   alt="img"
                      //   className="avatar bg-transparent avatar-xl mr-2"
                      // />
                    )}
                    {el.file.split(".").pop() === "pdf" && (
                      <img src="assets/images/files/attachments/pdf.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "css" && (
                      <img src="assets/images/files/attachments/css.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "html" && (
                      <img src="assets/images/files/attachments/html.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "zip" && (
                      <img src="assets/images/files/attachments/zip.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "docx" && (
                      <img src="assets/images/files/attachments/docx.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "txt" && (
                      <img src="assets/images/files/attachments/txt.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}

                    {el.file.split(".").pop() === "js" && (
                      <img src="assets/images/files/attachments/js.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "apk" && (
                      <img src="assets/images/files/attachments/apk.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "svg" && (
                      <img src="assets/images/files/attachments/svg.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "pptx" && (
                      <img src="assets/images/files/attachments/pptx.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "xlsx" && (
                      <img src="assets/images/files/attachments/xlsx.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "rar" && (
                      <img src="assets/images/files/attachments/rar.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "sql" && (
                      <img src="assets/images/files/attachments/sql.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}

                    <Link
                      to="#"
                      className="font-weight-semibold fs-14 mt-5"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={el.file.split("Z")[1].split(".")[0]}
                    >
                      {el.file.split("Z")[1].split(".")[0].substring(0, 12)}
                      <span className="text-muted ml-2">(.{el.file.split("Z")[1].split(".")[1]})</span>
                    </Link>

                    {/* <Link to="#" className="font-weight-semibold fs-14 mt-5" data-toggle="tooltip" data-placement="top" title={el.file}>
                      {el.file}
                      <span className="text-muted ml-2">(.{el.file})</span>
                    </Link> */}
                    <div className="ml-auto d-flex mt-4 text-right">
                      <Link to="#" className="action-btns1">
                        <i className="feather feather-download-cloud text-primary" onClick={() => saveFile(el.file)} />
                      </Link>
                      <Link to="#" className="action-btns1  mr-0">
                        <i className="feather feather-trash-2 text-danger" onClick={() => handleDeleteFile(el.id)} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {/* <div className="custom-file">
          <input
            ref={myRefFile}
            className="custom-file-input"
            multiple
            accept="*"
            type="file"
            id="exampleInputFile"
            onChange={(e) => handleUploadFiles(e)}
          />
        </div> */}

        {/* <div className="row mt-5" onClick={() => myRefFile.current.click()}>
          <div className="col-md-12 col-xl-4">
            <div className="list-group-item  align-items-center text-center">
              <Link to="#" className="text-center fs-35 text-success">
                <i className="fe feather-plus-circle" />
              </Link>
            </div>
          </div>
        </div> */}
        <div className="col-md-12 col-xl-12">
          <FilePond
            files={files}
            onupdatefiles={setFiles}
            allowMultiple={true}
            maxFiles={10}
            name="file"
            dropOnPage
            server={{
              process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                // fieldName is the name of the input field
                // file is the actual file object to send
                const api_url = process.env.REACT_APP_API_BASE_URL_V1 + "taskmedia";
                const frmData = new FormData();
                frmData.append("code_generated", getSession("code_generated"));
                frmData.append("task_id", taskId);
                frmData.append("user_id", getSession("id"));
                frmData.append("file", file);

                const request = new XMLHttpRequest();
                request.open("POST", api_url);
                request.setRequestHeader("Authorization", "Bearer " + getToken());

                // Should call the progress method to update the progress to 100% before calling load
                // Setting computable to false switches the loading indicator to infinite mode
                request.upload.onprogress = (e) => {
                  progress(e.lengthComputable, e.loaded, e.total);
                };

                // Should call the load method when done and pass the returned server file id
                // this server file id is then used later on when reverting or restoring a file
                // so your server knows which file to return without exposing that info to the client
                request.onload = function () {
                  if (request.status >= 200 && request.status < 300) {
                    // the load method accepts either a string (id) or an object
                    load(request.responseText);
                    //alert("ccccccccc");
                    setTimeout(() => {
                      getData();
                      setFiles(null);
                    }, 1000);
                  } else {
                    // Can call the error method if something is wrong, should exit after
                    error("oh no");
                  }
                };

                request.send(frmData);
                //axios.post(api_url, frmData, cc);

                // Should expose an abort method so the request can be cancelled
                return {
                  abort: () => {
                    // This function is entered if the user has tapped the cancel button
                    request.abort();
                    // Let FilePond know the request has been cancelled
                    abort();
                  },
                };
              },
            }}
            dropValidation
            labelIdle='Faites glisser et déposez vos fichiers ou <span class="filepond--label-action">Parcourir</span>'
          />
        </div>
      </div>
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="text-right">
            {/* <button type="button" id="submit" name="submit" className="btn btn-secondary">
                      Cancel
                    </button> */}
            <button type="button" id="submit" name="submit" className="btn btn-primary" onClick={handelUpdate}>
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailTask;
