/**
 * Given a partial value and a percentage, return the partial value multiplied by the percentage
 * @param partialValue - The value that you want to calculate the percentage of.
 * @param percent - The percentage of the total value that the partial value represents.
 * @returns The percentage of the partial value.
 */
export function percentage(partialValue, percent) {
  return (partialValue / 100) * percent;
}

/**
 * Calculate the tva of a product
 * @param totalHt - The total HT of the line
 * @param tvaProduct - The tva of the product
 * @param actionRemiseEntete - 1 if the discount is applied to the total, 0 if it is applied to the
 * sub-total
 * @param remise - The discount percentage
 * @param sousTotalHt - the subtotal HT of the invoice
 * @returns the value of the tva.
 */
export function calulateTva(totalHt, tvaProduct, actionRemiseEntete, remise, sousTotalHt) {
  var prixTva = "";
  if (actionRemiseEntete == "1") {
    prixTva = (totalHt - totalHt * (remise / 100)) * (tvaProduct / 100);
  } else {
    let calculeRemise = Number(remise) / Number(sousTotalHt);
    prixTva = (totalHt - totalHt * calculeRemise) * (tvaProduct / 100);
  }
  return prixTva;
}

/**
 * Calculate the HT value of the invoice based on the HT value of the invoice and the discount
 * @param totalHt - The total HT value of the invoice
 * @param actionRemiseEntete - 1 if the discount is applied to the total HT, 0 if it is applied to the
 * sub-total HT
 * @param remise - The percentage of the discount.
 * @param sousTotalHt - The total HT of the order
 * @returns The total HT value.
 */
export function calculateHt(totalHt, actionRemiseEntete, remise, sousTotalHt) {
  var totalHtValue = "";
  if (actionRemiseEntete == "1") {
    totalHtValue = totalHt - totalHt * (remise / 100);
  } else {
    let calculeRemise = Number(remise) / Number(sousTotalHt);
    totalHtValue = totalHt - totalHt * calculeRemise;
  }
  return totalHtValue;
}

/**
 * Given a partial value and a percentage, return the partial value plus the percentage of the partial
 * value
 * @param partialValue - The value that you want to add to.
 * @param percent - The percentage of the value you want to add.
 * @returns The result of the function.
 */
export function margePercentage(partialValue, percent) {
  let result = (partialValue / 100) * percent;
  return Number(partialValue) + Number(result);
}

/**
 * Multiply two numbers
 * @param value1 - The first number to be multiplied.
 * @param value2 - The number to multiply by.
 * @returns Nothing.
 */
export function MultiplicateNumber(value1, value2) {
  return Number(value1) * Number(value2);
}
/**
 * It adds two numbers together.
 * @param value1 - The first value to add.
 * @param value2 - The second number to add to value1.
 * @returns The sum of the two numbers.
 */
export function sumNumber(value1, value2) {
  return Number(value1) + Number(value2);
}
/**
 * Subtracts the second number from the first number
 * @param value1 - The first number to be subtracted.
 * @param value2 - The value to subtract from.
 * @returns Nothing.
 */
export function soustractNumber(value1, value2) {
  return Number(value1) - Number(value2);
}
/**
 * Divide the first number by the second number
 * @param value1 - The first number to be divided.
 * @param value2 - The value to divide by.
 * @returns The result of the division.
 */
export function divideNumber(value1, value2) {
  return Number(value1) / Number(value2);
}

/**
 * Calculate the remise of a product
 * @param value1 - the price of the product
 * @param value2 - the price of the product
 * @param value3 - 1 if the discount is a percentage, 0 if it is a fixed amount.
 * @returns The result of the calculation.
 */
export function calculateRemise(value1, value2, value3) {
  if (value3 == "1") {
    let result = (value1 / 100) * value2;
    return Number(value1) - Number(result);
  } else {
    return Number(value1) - Number(value2);
  }
}
/**
 * Calculate the total HT price of a product
 * @param value1 - The value of the first input field.
 * @param value2 - The value of the HT column.
 * @param value3 - 1 if the value is a percentage, 0 if it's a fixed value.
 * @returns The total HT value.
 */
export function calculateTotalHt(value1, value2, value3) {
  if (value3 == "1") {
    let result = (value1 / 100) * value2;
    return Number(value1) + Number(result);
  } else {
    return Number(value1) + Number(value2);
  }
}

/**
 * Convert a number to a string with a minimum of 3 decimal places
 * @param value1 - The value to be converted to a digit.
 * @returns a string that is formatted to 3 decimal places.
 */
export function convertToDigit(value1) {
  if (value1) {
    return Number(value1).toLocaleString(
      undefined, // leave undefined to use the visitor's browser
      // locale or a string like 'en-US' to override it.
      { minimumFractionDigits: 3 }
    );
  } else {
    return "";
  }
}

/**
 * Convert a float value to a string with a minimum of 3 decimal places
 * @param value1 - The value to be converted.
 * @returns a string that is formatted to 3 decimal places.
 */
export function convertFloatToDigit(value1) {
  if (value1) {
    const newValue = value1.replace(".", "");

    const result = Number(value1).toLocaleString(
      undefined, // leave undefined to use the visitor's browser
      // locale or a string like 'en-US' to override it.
      { minimumFractionDigits: 3 }
    );
    return result.replace("/", "");
  } else {
    return "";
  }
}

/**
 * If the value is null, return 0. Otherwise, return the value converted to a number with 3 decimal
 * places
 * @param value1 - The value to convert to a number.
 * @returns a string that is formatted to 3 decimal places.
 */
export function CheckNullConvertToDigit(value1) {
  if (value1) {
    return Number(value1).toLocaleString(
      undefined, // leave undefined to use the visitor's browser
      // locale or a string like 'en-US' to override it.
      { minimumFractionDigits: 3 }
    );
  } else {
    return "0";
  }
}

/**
 * It substracts the second value from the first value.
 * @param value1 - The first value to be subtracted.
 * @param value2 - The value to subtract from.
 * @returns The result of the operation.
 */
export function soustractMontant(value1, value2) {
  let result = Number(value1.replace(/[\s,]+/g, "").trim()) - Number(value2.replace(/[\s,]+/g, "").trim());
  return result.toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 0 }
  );
}

/**
 * It substracts the second value from the first value.
 * @param value1 - The value of the first number.
 * @param value2 - The value to subtract from value1.
 * @returns The result of the operation.
 */
export function soustractSolde(value1, value2) {
  let result = Number(value1) - Number(value2);
  return result.toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 3 }
  );
}
