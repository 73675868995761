import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllPointageHistory } from "../../Services/Pointeuse/PointageApi";
import { getAllSettings } from "../../Services/Pointeuse/SettingsApi";
import { getUsersActifByCode, getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import ImageComponent from "../Styles/ImageComponent";
import AddPointage from "./AddPointage";
import SingleMonthPointage from "./SingleMonthPointage";

var dateObj = new Date();
var result = dateObj.toISOString().split("T")[0];
var month = result.split("-")[1];
var year = result.split("-")[0];
var newdate = year + "-" + month;

function PointageMonth() {
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [date, setdate] = useState(newdate);

  const [dataPointages, setDataPointages] = useState(null);

  /**
   * It gets the data from the API and sets the data to the state.
   */
  const getData = () => {
    setLoading(true);
    getUsersByCode()
      .then((res) => {
        setdata(res.data);
        console.log(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  /**
   * The function takes in a date and sets the date state to that date
   * @param date - The date that the user has selected.
   */
  const handleChangeDate = (date) => {
    setLoading(true);
    setdate(date);
    setLoading(false);
  };

  useEffect(() => {
    getAllPointageHistory().then((res) => {
      setDataPointages(res.data);
    });
  }, []);

  return (
    <div>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Pointage")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="#" className="btn btn-primary mr-3" data-toggle="modal" data-target="#addPointageModal">
                {t("Pointage")}
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      {/* <div className="hrattview-buttons">
        <Link to="#" className="active ml-5">
          Attendance Overview
        </Link>
        <Link to="hr-attuser.html">Attendance By User</Link>
      </div> */}
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            {/* <div className="card-body">
              <div className="row mt-5">
                <div className="col-md-6 col-lg-3">
                  <div className="form-group">
                    <label className="form-label">Employee Name:</label>
                    <select className="form-control custom-select select2" data-placeholder="Select Employee">
                      <option label="Select Employee" />
                      <option value={1}>Faith Harris</option>
                      <option value={2}>Austin Bell</option>
                      <option value={3}>Maria Bower</option>
                      <option value={4}>Peter Hill</option>
                      <option value={5}>Victoria Lyman</option>
                      <option value={6}>Adam Quinn</option>
                      <option value={7}>Melanie Coleman</option>
                      <option value={8}>Max Wilson</option>
                      <option value={9}>Amelia Russell</option>
                      <option value={10}>Justin Metcalfe</option>
                      <option value={11}>Ryan Young</option>
                      <option value={12}>Jennifer Hardacre</option>
                      <option value={13}>Justin Parr</option>
                      <option value={14}>Julia Hodges</option>
                      <option value={15}>Michael Sutherland</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group">
                    <label className="form-label">Month:</label>
                    <select className="form-control custom-select select2" data-placeholder="Select Month">
                      <option label="Select Month" />
                      <option value={1}>January</option>
                      <option value={2}>February</option>
                      <option value={3}>March</option>
                      <option value={4}>April</option>
                      <option value={5}>May</option>
                      <option value={6}>June</option>
                      <option value={7}>July</option>
                      <option value={8}>August</option>
                      <option value={9}>September</option>
                      <option value={10}>October</option>
                      <option value={11}>November</option>
                      <option value={12}>December</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group">
                    <label className="form-label">Year:</label>
                    <select className="form-control custom-select select2" data-placeholder="Select Year">
                      <option label="Select Year" />
                      <option value={1}>2024</option>
                      <option value={2}>2023</option>
                      <option value={3}>2022</option>
                      <option value={4}>2021</option>
                      <option value={5}>2020</option>
                      <option value={6}>2019</option>
                      <option value={7}>2018</option>
                      <option value={8}>2017</option>
                      <option value={9}>2016</option>
                      <option value={10}>2015</option>
                      <option value={11}>2014</option>
                      <option value={12}>2013</option>
                      <option value={13}>2012</option>
                      <option value={14}>2011</option>
                      <option value={15}>2019</option>
                      <option value={16}>2010</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group mt-5">
                    <Link to="#" className="btn btn-primary btn-block">
                      Search
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 col-lg-3">
                  <div className="form-group">
                    <label className="form-label">{t("Choisir le moins")}:</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="feather feather-calendar" />
                        </div>
                      </div>
                      <input
                        className="form-control fc-datepicker"
                        placeholder="DD-MM-YYYY"
                        type="month"
                        onChange={(e) => handleChangeDate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")}:</label>
                </div>
                <div>
                  <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> {t("présent")}
                  </span>
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> {t("absent")}
                  </span>

                  <span className="badge badge-warning-light mr-2">
                    <i className="feather feather-x-circle text-warning" /> {t("non pointage sortie")}
                  </span>
                  {/* <span className="badge badge-orange-light mr-2">
                    <i className="fa fa-star text-orange" /> jour férié
                  </span>
                  <span className="badge badge-secondary-light mr-2">
                    <i className="fa fa-calendar text-secondary" /> congé
                  </span> */}

                  <span className="badge badge-primary-light mr-2">
                    <i className="fa fa-adjust text-primary" /> {t("encours")}
                  </span>
                  <span className="badge badge-success-light mr-2">
                    <i className="fa fa-calendar text-success" /> {t("weekend")}
                  </span>
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive hr-attlist">
                  <table className="table  table-vcenter text-nowrap table-bordered border-top border-bottom" id="hr-attendance">
                    <thead>
                      <tr>
                        <th className="border-bottom-0">{t("nom/prénom")}</th>
                        <th className="border-bottom-0 w-5">1</th>
                        <th className="border-bottom-0 w-5">2</th>
                        <th className="border-bottom-0 w-5">3</th>
                        <th className="border-bottom-0 w-5">4</th>
                        <th className="border-bottom-0 w-5">5</th>
                        <th className="border-bottom-0 w-5">6</th>
                        <th className="border-bottom-0 w-5">7</th>
                        <th className="border-bottom-0 w-5">8</th>
                        <th className="border-bottom-0 w-5">9</th>
                        <th className="border-bottom-0 w-5">10</th>
                        <th className="border-bottom-0 w-5">11</th>
                        <th className="border-bottom-0 w-5">12</th>
                        <th className="border-bottom-0 w-5">13</th>
                        <th className="border-bottom-0 w-5">14</th>
                        <th className="border-bottom-0 w-5">15</th>
                        <th className="border-bottom-0 w-5">16</th>
                        <th className="border-bottom-0 w-5">17</th>
                        <th className="border-bottom-0 w-5">18</th>
                        <th className="border-bottom-0 w-5">19</th>
                        <th className="border-bottom-0 w-5">20</th>
                        <th className="border-bottom-0 w-5">21</th>
                        <th className="border-bottom-0 w-5">22</th>
                        <th className="border-bottom-0 w-5">23</th>
                        <th className="border-bottom-0 w-5">24</th>
                        <th className="border-bottom-0 w-5">25</th>
                        <th className="border-bottom-0 w-5">26</th>
                        <th className="border-bottom-0 w-5">27</th>
                        <th className="border-bottom-0 w-5">28</th>
                        <th className="border-bottom-0 w-5">29</th>
                        <th className="border-bottom-0 w-5">30</th>
                        <th className="border-bottom-0 w-5">31</th>
                        <th className="border-bottom-0">Total</th>
                      </tr>
                    </thead>
                    {dataPointages && (
                      <tbody>
                        {data.map((el, idx) => (
                          <SingleMonthPointage user={el} currentDate={date} dataPointages={dataPointages} />
                        ))}
                      </tbody>
                    )}

                    <tfoot>
                      <tr>
                        <th className="border-bottom-0">{t("nom/prénom")}</th>
                        <th className="border-bottom-0 w-5">1</th>
                        <th className="border-bottom-0 w-5">2</th>
                        <th className="border-bottom-0 w-5">3</th>
                        <th className="border-bottom-0 w-5">4</th>
                        <th className="border-bottom-0 w-5">5</th>
                        <th className="border-bottom-0 w-5">6</th>
                        <th className="border-bottom-0 w-5">7</th>
                        <th className="border-bottom-0 w-5">8</th>
                        <th className="border-bottom-0 w-5">9</th>
                        <th className="border-bottom-0 w-5">10</th>
                        <th className="border-bottom-0 w-5">11</th>
                        <th className="border-bottom-0 w-5">12</th>
                        <th className="border-bottom-0 w-5">13</th>
                        <th className="border-bottom-0 w-5">14</th>
                        <th className="border-bottom-0 w-5">15</th>
                        <th className="border-bottom-0 w-5">16</th>
                        <th className="border-bottom-0 w-5">17</th>
                        <th className="border-bottom-0 w-5">18</th>
                        <th className="border-bottom-0 w-5">19</th>
                        <th className="border-bottom-0 w-5">20</th>
                        <th className="border-bottom-0 w-5">21</th>
                        <th className="border-bottom-0 w-5">22</th>
                        <th className="border-bottom-0 w-5">23</th>
                        <th className="border-bottom-0 w-5">24</th>
                        <th className="border-bottom-0 w-5">25</th>
                        <th className="border-bottom-0 w-5">26</th>
                        <th className="border-bottom-0 w-5">27</th>
                        <th className="border-bottom-0 w-5">28</th>
                        <th className="border-bottom-0 w-5">29</th>
                        <th className="border-bottom-0 w-5">30</th>
                        <th className="border-bottom-0 w-5">31</th>
                        <th className="border-bottom-0">{t("Total")}</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <AddPointage getData={getData} />
    </div>
  );
}

export default PointageMonth;
