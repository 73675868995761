import moment from "moment";

/**
 * Get the token from local storage
 * @returns The token is being returned.
 */
export function getToken() {
  return localStorage.getItem("token");
}
/**
 * Get the value of a session variable from the browser's local storage
 * @param value - The name of the session variable you want to get.
 * @returns The value of the key that is being passed in.
 */
export function getSession(value) {
  return localStorage.getItem(value);
}

/**
 * It checks if the user is logged in and if the session is still valid.
 * @returns The function isLogin() returns a boolean value.
 */
export const isLogin = () => {
  const td = getSession("td");
  const today = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  //td avant today => truee
  var isafter = moment(td).isAfter(today);

  if (getSession("isLoginPointeuse") == "true" && isafter) {
    return true;
  }
  return false;
};

/**
 * It checks if the user is logged in without time.
 * @returns a boolean value.
 */
export const isLoginWithoutTime = () => {
  if (getSession("isLoginPointeuse") == "true") {
    return true;
  }
  return false;
};

/**
 * It checks if the user is an admin.
 * @returns A boolean value.
 */
export const isAdmin = () => {
  if (getSession("role_code") == "admin") {
    return true;
  }
  return false;
};
