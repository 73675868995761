import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

function ExportCsv({ data, name }) {
  const csvLinkEl = useRef(null);

  /**
   * Download a report as a CSV file
   */
  const downloadReport = async () => {
    setTimeout(() => {
      csvLinkEl?.current.link.click();
    });
  };

  return (
    <>
      <Link to="#" className="action-btns" onClick={downloadReport} data-toggle="tooltip" data-placement="top" title="csv">
        <i className="fa fa-file-excel-o text-success" />
        {/* // <img src="assets/images/files/file2.png" alt="img" className="w-5 h-5" /> */}
      </Link>
      <CSVLink filename={`${name}.csv`} data={data} ref={csvLinkEl} />
    </>
  );
}

export default ExportCsv;
