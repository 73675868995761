import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { editUserStatus, getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { filterAutorisationByWord, filterUserByWord } from "../../Utils/SortObject";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import ImageComponent from "../Styles/ImageComponent";
import swal from "sweetalert";
import { acceptAutorisation, getAllAutorisation, senFcmAutorisation } from "../../Services/Pointeuse/AutorisationApi";
import PieChartJsAutorisationHoure from "../Charts/CustumChartJs/PieChartJsAutorisationHoure";
import PieChartJsAutorisationStatus from "../Charts/CustumChartJs/PieChartJsAutorisationStatus";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import firebase from "../../firebase";
import { senFcmConge } from "../../Services/Pointeuse/CongeApi";
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import CopieContent from "../Exports/CopieContent";

function Autorisation() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(8);
  const [isCollapsed, setisCollapsed] = useState(false);

  /**
   * It gets all the autorisation data from the API and sets it to the data variable.
   */
  const getData = () => {
    setLoading(true);
    getAllAutorisation()
      .then((res) => {
        setdata(res.data);
        console.log(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  /**
   * The onChangePage function is called when the user clicks on the page numbers.
   * It takes the page of items returned by the paginate function as a parameter and
   * uses that to update the currentData state
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the search term.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    if (e.target.value) {
      const dataSearch = filterAutorisationByWord(allInitialData, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };
  /**
   * It filters the data based on the selected value.
   * @param e - The event object.
   */
  const handleSelectAutorisation = (e) => {
    if (e.target.value == 1) {
      setdata(allInitialData.filter((el) => el.type === "demande"));
    } else if (e.target.value == 2) {
      setdata(allInitialData.filter((el) => el.type === "autorisation" && el.etat === 1));
    } else if (e.target.value == 3) {
      setdata(allInitialData.filter((el) => el.type === "autorisation" && el.etat === 0));
    } else {
      setdata(allInitialData);
    }
  };

  /**
   * It sets the size of the data to be retrieved from the API.
   * @param e - The event object.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  /**
   * It accepts the autorisation.
   * @param id - The id of the autorisation you want to accept.
   * @param userid - The userid of the user you want to send a notification to.
   */
  const handleAccept = (id, userid) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Voulez vous vraiment accepté autorisation!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptAutorisation(id, "1").then(() => {
          //toast.success("Congé accepté");
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
          sendFirebaseNotification("accepté", userid);
        });
      }
    });
  };

  /**
   * It sends a notification to the user.
   * @param type - The type of notification to send.
   * @param receiver - The user's device token.
   */
  const sendFirebaseNotification = (type, receiver) => {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then(() => {
        senFcmAutorisation(type, receiver);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * It allows the user to accept the autorisation.
   * @param id - The id of the autorisation you want to accept.
   * @param userid - The userid of the user who is being notified.
   */
  const handleDenied = (id, userid) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Voulez vous vraiment refusé autorisation!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptAutorisation(id, "0").then(() => {
          //toast.success("Congé accepté");
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
          sendFirebaseNotification("refusé", userid);
        });
      }
    });
  };
  /**
   * *This function is used to toggle the collapse state of the sidebar.*
   */
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Autorisations</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              {/* <a href="hr-addemployee.html" className="btn btn-primary mr-3">
                Add New Employee
              </a> */}
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total Employees</span>
                    <h3 className="mb-0 mt-1 text-success">5,678</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="las la-users" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total Homme Employees</span>
                    <h3 className="mb-0 mt-1 text-primary">3,876</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-primary-transparent my-auto  float-right">
                    <i className="las la-male" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total Female Employees</span>
                    <h3 className="mb-0 mt-1 text-secondary">1,396</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-secondary-transparent my-auto  float-right">
                    <i className="las la-female" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total New Employees</span>
                    <h3 className="mb-0 mt-1 text-danger">398</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-danger-transparent my-auto  float-right">
                    <i className="las la-user-friends" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-0 pb-0">
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-primary-transparent">31</span>
                  <h5 className="mb-0 mt-3">Total Working Days</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5 ">
                  <span className="avatar avatar-md bradius fs-20 bg-success-transparent">24</span>
                  <h5 className="mb-0 mt-3">Présent Days</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-danger-transparent">2</span>
                  <h5 className="mb-0 mt-3">Absent Days</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-warning-transparent">0</span>
                  <h5 className="mb-0 mt-3">Half Days</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5 ">
                  <span className="avatar avatar-md bradius fs-20 bg-orange-transparent">2</span>
                  <h5 className="mb-0 mt-3">Late Days</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-pink-transparent">5</span>
                  <h5 className="mb-0 mt-3">Holidays</h5>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-5 col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">From:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control fc-datepicker" placeholder="DD-MM-YYYY" type="date" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">To:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control fc-datepicker" placeholder="DD-MM-YYYY" type="date" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Select Job Type:</label>
                        <select
                          name="attendance"
                          className="form-control custom-select select2"
                          data-placeholder="Select Job Type"
                        >
                          <option label="Select Job Type" />
                          <option value={1}>Full-Time</option>
                          <option value={2}>Part-Time</option>
                          <option value={3}>Freelancer</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Select Status:</label>
                        <select
                          name="attendance"
                          className="form-control custom-select select2"
                          data-placeholder="Select Status"
                        >
                          <option label="Select Status" />
                          <option value={1}>Active</option>
                          <option value={2}>InActive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-md-12">
                  <div className="form-group mt-5">
                    <Link to="#" className="btn btn-primary btn-block">
                      Recherche
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">List autorisations</h4>
              <div className="card-options">
                <Link
                  to="#"
                  className="card-options-fullscreen mr-2"
                  data-toggle="card-fullscreen"
                  onClick={handleCollapse}
                >
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">Note:</label>
                </div>
                <div>
                  <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> accepté
                  </span>
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> refusé
                  </span>
                  <span className="badge badge-warning-light mr-2">
                    <i className="fa fa-star text-warning" /> en cours
                  </span>
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            Afficher{" "}
                            <select
                              value={size}
                              className="form-control"
                              id="exampleFormControlSelect1"
                              onChange={handleChangeSize}
                            >
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            entrées
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Recherche..."
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv data={data} name={"List_autorisations"} />
                          <ExportPdf
                            data={data}
                            name={"List_autorisations"}
                            columns={["nom", "prenom", "objet", "heure_debut", "nb_heure", "type"]}
                          />
                          <CopieContent />

                          {/* <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0 w-5"></th>
                              <th className="border-bottom-0">Motif</th>
                              <th className="border-bottom-0">Date</th>
                              <th className="border-bottom-0">Heure début</th>
                              <th className="border-bottom-0">Nombre heures (H)</th>
                              <th className="border-bottom-0">Status</th>
                              <th className="border-bottom-0">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el) => (
                              <tr key={el.id}>
                                <td>
                                  <div className="d-flex">
                                    <ImageComponent
                                      atr={"avatar avatar-md brround mr-3"}
                                      picture={el.img}
                                    ></ImageComponent>
                                    <div className="mr-8 mt-0 mt-sm-1 d-block">
                                      <h6 className="mb-1 fs-14">
                                        {el.nom} {el.prenom}
                                      </h6>
                                      <p className="text-muted mb-0 fs-12">{el.email}</p>
                                    </div>
                                  </div>
                                </td>

                                {/* <td>
                                  <Link to={"/admin/users/" + el.user_code_id}>
                                    {el.nom} {el.prenom}
                                  </Link>
                                </td> */}
                                <td>{el.objet}</td>
                                <td className="text-center">{el.date}</td>
                                <td className="text-center">{el.heure_debut}</td>
                                <td className="text-center">{el.nb_heure}</td>
                                <td className="text-center">
                                  {el.type === "demande" ? (
                                    <span className="badge badge-warning">demande</span>
                                  ) : el.etat === 1 ? (
                                    <span className="badge badge-success">accepté</span>
                                  ) : (
                                    <span className="badge badge-danger">refusé</span>
                                  )}
                                </td>
                                <td className="text-left">
                                  {el.type === "demande" ? (
                                    <>
                                      <Link
                                        to="#"
                                        className="action-btns"
                                        data-tip="accepter"
                                        onClick={() => handleAccept(el.id, el.user_code_id)}
                                      >
                                        {/* <i className="feather feather-edit text-info" /> */}
                                        <i className="fa fa-check-circle text-success"></i>
                                      </Link>
                                      <Link
                                        to="#"
                                        className="action-btns"
                                        data-tip="refuser"
                                        onClick={() => handleDenied(el.id, el.user_code_id)}
                                      >
                                        {/* <i className="feather feather-edit text-info" /> */}
                                        <i className="fa fa-ban text-danger"></i>
                                      </Link>
                                    </>
                                  ) : (
                                    <p></p>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          Affichage de 1 à {size} sur {data.length} entrées
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6 col-md-12 col-lg-6">
          <div className="card">
            <div className="card-header border-0">
              <h4 className="card-title">Status par autorisation</h4>
              {/* <div className="card-options">
                <div className="dropdown">
                  {" "}
                  <a href="#" className="btn btn-outline-light" data-toggle="dropdown" aria-expanded="false">
                    {" "}
                    Week <i className="feather feather-chevron-down" />{" "}
                  </a>
                  <ul className="dropdown-menu dropdown-menu-right" role="menu">
                    <li>
                      <a href="#">Monthly</a>
                    </li>
                    <li>
                      <a href="#">Yearly</a>
                    </li>
                    <li>
                      <a href="#">Weekly</a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className="card-body">
              <div className="mt-5">
                <PieChartJsAutorisationStatus />
              </div>
              <div className="sales-chart mt-4 row text-center">
                {/* <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-primary mr-2 my-auto" />
                  On progress
                </div> */}
                {/* <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-secondary mr-2 my-auto" />
                  male
                </div>
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-light4  mr-2 my-auto" />
                  femelle
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-md-12 col-lg-6">
          <div className="card">
            <div className="card-header border-0">
              <h4 className="card-title">Nombre d'heure par autorisation</h4>
              {/* <div className="card-options">
                <div className="dropdown">
                  {" "}
                  <a href="#" className="btn btn-outline-light" data-toggle="dropdown" aria-expanded="false">
                    {" "}
                    View All <i className="feather feather-chevron-down" />{" "}
                  </a>
                  <ul className="dropdown-menu dropdown-menu-right" role="menu">
                    <li>
                      <a href="#">Monthly</a>
                    </li>
                    <li>
                      <a href="#">Yearly</a>
                    </li>
                    <li>
                      <a href="#">Weekly</a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className="card-body">
              <div className="mt-5">
                <PieChartJsAutorisationHoure />
              </div>
              {/* <div className="sales-chart mt-4 row text-center">
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-primary mr-2 my-auto" />
                  On progress
                </div>
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-secondary mr-2 my-auto" />
                  male
                </div>
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-light4  mr-2 my-auto" />
                  femelle
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Autorisation;
