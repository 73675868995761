import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
//draft js
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//filepond
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { addTicket } from "../../Services/Pointeuse/TicketApi";
import { addTicketMedia } from "../../Services/Pointeuse/TicketMediaApi";

function AddTicket() {
  const history = useHistory();
  const alert = useAlert();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState(null);
  //   let editorStateDraft = EditorState.createEmpty();
  //   const [editorState, setEditorState] = useState(editorStateDraft);
  const [sujet, setsujet] = useState(null);
  const [date, setdate] = useState(null);
  const [priority, setpriority] = useState(null);
  const [category, setcategory] = useState(null);

  useEffect(() => {
    const blocksFromHtml = htmlToDraft("<p></p>");
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorStateDraft = EditorState.createWithContent(contentState);
    setEditorState(editorStateDraft);
  }, []);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleSave = () => {
    if (sujet == null || priority == null || category == null || date == null) {
      alert.error("veuillez remplir tous les champs");
    } else {
      const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      addTicket(sujet, description, priority, category, date)
        .then((res) => {
          //
          if (files) {
            addTicketMedia(res.data.id, files).then((res) => {
              console.log(res);
              history.push("/admin/ticket");
            });
          } else {
            history.push("/admin/ticket");
          }
          console.log(res);
        })
        .catch((error) => {
          alert.error(error);
        });
    }
  };

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Ajouter ticket</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/ticket" className="btn btn-primary mr-3">
                Tickets
              </Link>
              <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                <i className="feather feather-mail" />
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                <i className="feather feather-phone-call" />
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                <i className="feather feather-info" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-12">
        <div className="card">
          <div className="card-header  border-0">
            <h4 className="card-title">Nouveau ticket</h4>
          </div>
          <div className="card-body">
            <div className="form-group">
              <div className="row">
                <div className="col-md-3">
                  <label className="form-label mb-0 mt-2">Sujet</label>
                </div>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="sujet de ticket"
                    value={sujet}
                    onChange={(e) => setsujet(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-md-3">
                  <label className="form-label mb-0 mt-2">Date échéance</label>
                </div>
                <div className="col-md-9">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="sujet de ticket"
                    value={date}
                    onChange={(e) => setdate(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-md-3">
                  <label className="form-label mb-0 mt-2">Priorité</label>
                </div>
                <div className="col-md-9">
                  <select
                    className="form-control custom-select select2"
                    data-placeholder="Choisir Priorité"
                    onChange={(e) => setpriority(e.target.value)}
                  >
                    <option label="Choisir Priorité" />
                    <option value="high">Urgente</option>
                    <option value="meduim">Moyenne</option>
                    <option value="low">Facultatif</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-md-3">
                  <label className="form-label mb-0 mt-2">Catégorie</label>
                </div>
                <div className="col-md-9">
                  <select
                    className="form-control custom-select select2"
                    data-placeholder="Choisir Catégorie"
                    onChange={(e) => setcategory(e.target.value)}
                  >
                    <option label="Choisir Catégorie" />
                    <option value="supports">Support</option>
                    <option value="services">Services</option>
                    <option value="techniques">Techniques</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group ticket-summernote">
              <div className="row">
                <div className="col-md-3">
                  <label className="form-label mb-0 mt-2">Description</label>
                </div>
                <div className="col-md-9">
                  <div className="summernote" />
                  <Editor
                    className="form-control"
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    //onChange={setEditorState}
                    onEditorStateChange={onEditorStateChange}
                  />
                  {/* <textarea disabled value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} />
                  <div
                    className="post__description"
                    dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) }}
                  /> */}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-md-3">
                  <label className="form-label mb-0 mt-2">Upload Image</label>
                </div>
                <div className="col-md-9">
                  <FilePond
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple={true}
                    maxFiles={10}
                    name="files"
                    labelIdle='Faites glisser et déposez vos fichiers ou <span class="filepond--label-action">Parcourir</span>'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col-md-3" />
              <div className="col-md-9">
                <Link to="#" className="btn btn-primary btn-lg" onClick={handleSave}>
                  Ajouter Ticket
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddTicket;
