import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { addDocument } from "../../Services/Pointeuse/DocumentApi";

function UploadFiles(props) {
  console.log(props);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [type, settype] = useState("conge");
  const [description, setdescription] = useState("");
  const refHide = useRef(null);
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setimageName] = useState("Choisir un document ...");

  /**
   * It sets the files to upload to the files variable and sets the imageUrl to the first file in the
   * files array.
   * @param event - The event that triggered the function.
   */
  const handleUploadFiles = (event) => {
    setFilesToUpload(event.target.files);
    setImageUrl(event.target.files[0]);
    setimageName(event.target.files[0].name);
  };

  /**
   * * Add a document to the database
   */
  const submitFiles = () => {
    addDocument(imageUrl, type, description).then(() => {
      refHide.current.click();
      props.onChangePage();
    });
  };

  return (
    <>
      <Link to="#" className="btn btn-primary" data-toggle="modal" data-target="#modalUpload">
        <i className="fe fe-plus" /> Ajouter un nouveau fichier
      </Link>

      <div
        className="modal fade"
        id="modalUpload"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Ajouter des documents
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <div className="custom-file">
                  <input
                    className="custom-file-input"
                    accept="image/*"
                    type="file"
                    id="exampleInputFile"
                    onChange={handleUploadFiles}
                  />
                  <label className="custom-file-label" htmlFor="exampleInputFile">
                    {imageName}
                  </label>

                  {/* <input
                    className="custom-file-input"
                    accept="image/*"
                    type="file"
                    multiple
                    id="exampleInputFile"
                    onChange={handleUploadFiles}
                  />
                  <label className="custom-file-label" htmlFor="exampleInputFile"></label> */}

                  <small className="form-text text-muted">
                    Documents autorisés: jpg, jpeg, png, pdf, docx. Minimum 1 fichier.
                  </small>
                  {/* {filesToUpload && (
                      <ul>
                        {filesToUpload.map((el, i) => (
                          <li key={i}>{el[i].names}</li>
                        ))}
                      </ul>
                    )} */}
                </div>
              </div>

              <div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Type document</label>
                  <select
                    value={type}
                    className="form-control"
                    id="exampleFormControlSelect1"
                    onChange={(e) => settype(e.target.value)}
                  >
                    <option value="conge">Congé</option>
                    <option value="autorisation">Autorisation</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">Description</label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    defaultValue={""}
                    onChange={(e) => setdescription(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={refHide}>
                Annuler
              </button>
              <button type="button" className="btn btn-primary" onClick={submitFiles}>
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default UploadFiles;
