import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import { getEnteteCommercialAchat, getEnteteCommercialVente } from "../../Services/Facturation/FacturationEnteteApi";
import { addObjectif, deleteObjectifByYear, getAllObjectif } from "../../Services/Pointeuse/ObjectifApi";
import { getObjectData } from "../../Utils/Data/ObjectData";
import { convertToDigit } from "../../Utils/NumberUtils";
import {
  calculateCroissance,
  calculateRealisationCroissance,
  calculateRealisationVSObjectif,
  calculateTotalCroissance,
  groupeByMonth,
  sortByMonth,
  sumO,
  sumObjectif,
} from "../../Utils/ObjectifUtils";
import ObjectifChart1 from "./ObjectifChart1";
import ObjectifChart2 from "./ObjectifChart2";
import PieApexGender from "../Charts/CustumApex/PieApexGender";

function Objectif() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [totalObjectif, settotalObjectif] = useState(0);
  const [totalCroissance, settotalCroissance] = useState(0);
  const [currentYear] = useState(new Date().getFullYear());
  const [inputList, setInputList] = useState([]);
  const [factures, setfactures] = useState([]);
  const [totalRealisation, settotalRealisation] = useState(null);
  const [vsCurrentYear, setvsCurrentYear] = useState(null);
  const [vsLastYear, setvsLastYear] = useState(null);

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    getEnteteCommercialVente().then((res) => {
      const groupedMonth = groupeByMonth(res.data, currentYear);
      setfactures(groupedMonth);
      settotalRealisation(groupedMonth.reduce((a, b) => +a + +b.montant_total_ht, 0));
    });
  }, []);

  /**
   * It gets all the data from the API and then groups the data by month.
   */
  const getAllData = () => {
    setLoading(true);

    getEnteteCommercialVente().then((resEntete) => {
      const groupedMonth = groupeByMonth(resEntete.data, currentYear);
      //console.log(groupedMonth);
      setfactures(groupedMonth);
      const totalOfRealisation = groupedMonth.reduce((a, b) => +a + +b.montant_total_ht, 0);
      settotalRealisation(totalOfRealisation);
      getAllObjectif()
        .then((res) => {
          setdata(res.data);
          console.log(res.data);
          const result = res.data;
          // const objectiveCurrentYear = result.filter((el) => {
          //   return (el.annee = currentYear);
          // });
          // console.log(objectiveCurrentYear);
          var objectData = getObjectData(sortByMonth(result));
          console.log(objectData);
          settotalObjectif(sumObjectif(objectData, "objectif"));
          settotalCroissance(
            calculateTotalCroissance(sumObjectif(objectData, "objectif"), sumObjectif(objectData, "realisation1"))
          );
          setvsCurrentYear(calculateRealisationVSObjectif(totalOfRealisation, sumObjectif(objectData, "objectif")));
          setvsLastYear(calculateRealisationCroissance(totalOfRealisation, sumObjectif(objectData, "realisation1")));
          setInputList(objectData);
        })
        .catch((error) => setError(error))
        .finally(() => setLoading(false));
    });
  };

  // handle input change
  /**
   * It updates the inputList with the new values.
   * @param e - The event object that contains the data of the event that triggered the function.
   * @param index - The index of the input list that we want to change.
   */
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    console.log(list);
    list[index][name] = value;
    const resultSumObjectif = sumObjectif(list, "objectif");
    settotalObjectif(resultSumObjectif);
    const totalCroissance = calculateCroissance(list[index]["objectif"], list[index]["realisation1"]);
    list[index]["croissance"] = totalCroissance;
    const totalOfRealisation = sumObjectif(list, "realisation1");
    settotalCroissance(calculateTotalCroissance(resultSumObjectif, totalOfRealisation));
    setInputList(list);
  };

  /**
   * It adds the objectifs to the database.
   */
  const handleSubmitObjectif = () => {
    const newList = inputList.filter((el) => {
      return el.objectif != "0";
    });
    //delete 2021
    deleteObjectifByYear(currentYear).then(() => {
      newList.forEach((el) => {
        addObjectif(el.objectif, "0", el.mois, currentYear);
      });
    });
    //add 2021
  };

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Objectif</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list mt-3 mt-lg-0">
              {/* <a href="hr-addpayroll.html" className="btn btn-primary mr-3">
                Add New Payroll
              </a>
              <button className="btn btn-secondary mr-3" data-toggle="modal" data-target="#excelmodal">
                <i className="las la-file-excel" /> Download Monthly Excel Report
              </button> */}
              <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                {" "}
                <i className="feather feather-mail" />{" "}
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                {" "}
                <i className="feather feather-phone-call" />{" "}
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                {" "}
                <i className="feather feather-info" />{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
          <div className="card">
            <div className="card-header border-0">
              <h4 className="card-title">Objectif / Réalisation</h4>
            </div>
            <div className="card-body">
              <div className="mt-5">
                <div className="card-body table-responsive p-0">
                  {loading && <CustomSyncLoader></CustomSyncLoader>}
                  {!loading && error && <ErrorCustum></ErrorCustum>}
                  {!loading && !error && data && (
                    <table className="table table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center", fontSize: 12 }}>Mois</th>
                          <th style={{ textAlign: "center", fontSize: 12 }}>
                            Réalisation {new Date().getFullYear() - 2}
                          </th>
                          <th style={{ textAlign: "center", fontSize: 12 }}>
                            Réalisation {new Date().getFullYear() - 1}
                          </th>
                          <th style={{ textAlign: "center", fontSize: 12 }}>Objectif {currentYear}</th>
                          <th style={{ textAlign: "center", fontSize: 12 }}>Croissance</th>
                          <th style={{ textAlign: "center", fontSize: 12 }}>Réalisation {currentYear}</th>

                          <th
                            style={{ textAlign: "center", fontSize: 12 }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={`Réalisation vs objectif ${currentYear}`}
                          >
                            vs {currentYear}
                          </th>
                          <th
                            style={{ textAlign: "center", fontSize: 12 }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={`Croissance vs ${new Date().getFullYear() - 1}`}
                          >
                            vs {new Date().getFullYear() - 1}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {inputList.map((x, i) => {
                          return (
                            <tr key={i} style={{ padding: 0 }}>
                              <td>
                                <input
                                  style={{ minWidth: 90 }}
                                  className="form-control"
                                  name="mois"
                                  value={x.mois}
                                  onChange={(e) => handleInputChange(e, i)}
                                  disabled
                                />
                              </td>
                              <td>
                                <input
                                  style={{ textAlign: "right" }}
                                  className="form-control"
                                  name="realisation2"
                                  value={convertToDigit(x.realisation2)}
                                  onChange={(e) => handleInputChange(e, i)}
                                  disabled
                                />
                              </td>
                              <td>
                                <input
                                  style={{ textAlign: "right" }}
                                  className="form-control"
                                  name="realisation1"
                                  value={convertToDigit(x.realisation1)}
                                  onChange={(e) => handleInputChange(e, i)}
                                  disabled
                                />
                              </td>

                              <td>
                                <input
                                  style={{ textAlign: "right" }}
                                  className="form-control"
                                  type="number"
                                  name="objectif"
                                  placeholder="Objectif en TND"
                                  value={x.objectif}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {x.croissance && x.croissance != "-100" && (
                                  <>
                                    {x.croissance > 0 ? (
                                      <i style={{ color: "green" }} className="feather feather-arrow-up">
                                        {x.croissance}
                                      </i>
                                    ) : (
                                      <i style={{ color: "red" }} className="feather feather-arrow-down">
                                        {x.croissance}
                                      </i>
                                    )}
                                  </>
                                )}
                              </td>
                              {factures && (
                                <td>
                                  <input
                                    style={{ textAlign: "right" }}
                                    className="form-control"
                                    name="croissance"
                                    value={convertToDigit(factures[i]?.montant_total_ht)}
                                    disabled
                                  />
                                </td>
                              )}

                              <td style={{ textAlign: "center" }}>
                                {factures[i]?.montant_total_ht && (
                                  <>
                                    {calculateRealisationVSObjectif(factures[i]?.montant_total_ht, x.objectif) > 100 ? (
                                      <i style={{ color: "green" }} className="feather feather-arrow-up">
                                        {calculateRealisationVSObjectif(factures[i]?.montant_total_ht, x.objectif)}
                                      </i>
                                    ) : (
                                      <i style={{ color: "red" }} className="feather feather-arrow-down">
                                        {calculateRealisationVSObjectif(factures[i]?.montant_total_ht, x.objectif)}
                                      </i>
                                    )}
                                  </>
                                )}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {factures[i]?.montant_total_ht && (
                                  <>
                                    {calculateRealisationCroissance(factures[i]?.montant_total_ht, x.realisation1) >
                                    0 ? (
                                      <i style={{ color: "green" }} className="feather feather-arrow-up">
                                        {calculateRealisationCroissance(factures[i]?.montant_total_ht, x.realisation1)}
                                      </i>
                                    ) : (
                                      <i style={{ color: "red" }} className="feather feather-arrow-down">
                                        {calculateRealisationCroissance(factures[i]?.montant_total_ht, x.realisation1)}
                                      </i>
                                    )}
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <thead>
                        <tr>
                          {/* total */}
                          <th>Total</th>
                          {/* realisation 2019 */}
                          <th>
                            <span className="badge badge-pill badge-warning">
                              {convertToDigit(sumObjectif(inputList, "realisation2"))} TND
                            </span>
                          </th>
                          {/* realisation 2020 */}
                          <th>
                            <span className="badge badge-pill badge-warning">
                              {convertToDigit(sumObjectif(inputList, "realisation1"))} TND
                            </span>
                          </th>
                          {/* objectif 2021 */}
                          <th>
                            <span className="badge badge-pill badge-warning"> {convertToDigit(totalObjectif)} TND</span>
                          </th>
                          {/* croissance */}
                          <th style={{ textAlign: "center" }}>
                            {totalCroissance && totalCroissance != "-100" && (
                              <>
                                {totalCroissance > 0 ? (
                                  <i style={{ color: "green" }} className="feather feather-arrow-up">
                                    {totalCroissance}
                                  </i>
                                ) : (
                                  <i style={{ color: "red" }} className="feather feather-arrow-down">
                                    {totalCroissance}
                                  </i>
                                )}
                              </>
                            )}
                          </th>
                          {/* realisation 2021 */}
                          <th>
                            <span className="badge badge-pill badge-warning">
                              {" "}
                              {convertToDigit(totalRealisation)} TND
                            </span>
                          </th>
                          {/* r vs 2021  */}
                          <th style={{ textAlign: "center" }}>
                            {vsCurrentYear && (
                              <>
                                {vsCurrentYear > 100 ? (
                                  <i style={{ color: "green" }} className="feather feather-arrow-up">
                                    {vsCurrentYear}
                                  </i>
                                ) : (
                                  <i style={{ color: "red" }} className="feather feather-arrow-down">
                                    {vsCurrentYear}
                                  </i>
                                )}
                              </>
                            )}
                          </th>
                          {/* croissance vs 2020 */}
                          <th style={{ textAlign: "center" }}>
                            {vsLastYear && (
                              <>
                                {vsLastYear > 0 ? (
                                  <i style={{ color: "green" }} className="feather feather-arrow-up">
                                    {vsLastYear}
                                  </i>
                                ) : (
                                  <i style={{ color: "red" }} className="feather feather-arrow-down">
                                    {vsLastYear}
                                  </i>
                                )}
                              </>
                            )}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  )}
                </div>

                {/* /.card-body */}
                <div className="card-footer clearfix">
                  <button type="submit" className="btn btn-primary float-right" onClick={handleSubmitObjectif}>
                    Enregistrer
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* /.card */}
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
          {/* <ObjectifChart1 objectifs={inputList} factures={factures}></ObjectifChart1>
          <ObjectifChart2 objectifs={inputList} factures={factures}></ObjectifChart2> */}

          <div className="card">
            <div className="card-header border-0">
              <h4 className="card-title">Objectif par mois</h4>
            </div>
            <div className="card-body">
              <div className="mt-5">
                <ObjectifChart1 objectifs={inputList} factures={factures}></ObjectifChart1>
              </div>
              <div className="sales-chart mt-4 row text-center">
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-success mr-2 my-auto" />
                  Realisation {new Date().getFullYear() - 1}
                </div>
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-primary mr-2 my-auto" />
                  Realisation {new Date().getFullYear()}
                </div>
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-warning  mr-2 my-auto" />
                  objectif {new Date().getFullYear()}
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header border-0">
              <h4 className="card-title">Objectif par mois</h4>
            </div>
            <div className="card-body">
              <div className="mt-5">
                <ObjectifChart2 objectifs={inputList} factures={factures}></ObjectifChart2>
              </div>
              <div className="sales-chart mt-4 row text-center">
                {/* <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-success mr-2 my-auto" />
                  Realisation 2020
                </div> */}
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-success mr-2 my-auto" />
                  Realisation {new Date().getFullYear()}
                </div>
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-warning  mr-2 my-auto" />
                  objectif {new Date().getFullYear()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Objectif;
