import React, { useEffect, useState } from "react";
import { Prompt } from "react-router";
import { ToastContainer } from "react-toastify";
import { getFacturationFournisseurByCode } from "../../../Services/Facturation/FacturationClientApi";

import { getSession } from "../../../Utils/SessionUtils";
import {
  editSoldeEnteteByCode,
  editSoldeEnteteByCodeFacture,
  getEnteteByClientCode,
  getEnteteCommercialAchat,
} from "../../../Services/Facturation/FacturationEnteteApi";
import { addPaiement } from "../../../Services/Facturation/FacturationPaiementApi";
import { getEntrepriseByCode } from "../../../Services/Pointeuse/EntrepriseApi";
import { getPercent, soustractPercent } from "../../../Utils/PaiementUtils";

function AddPaiement(props) {
  const [isChecked, setisChecked] = useState(false);
  const { net_a_payer, facture_code, client_code } = props.location.state?.el || {};
  const [dataFournisseur, setDataFournisseur] = useState([]);
  const [dataFacturation, setDataFacturation] = useState([]);
  const [state, setState] = useState({
    societe_code: getSession("code_generated"),
    banque: "",
    mode_paiement: "",
    numero_paiement: "",
    reference_banque: "",
    montant: net_a_payer,
    rs: 0,
    date_reception: "",
    date_echeance: "",
    fournisseur: client_code,
    num_piece: facture_code,
    exercice: "",
    commentaire: "",
    emplacement: "",
    status: "",
    compte: "",
    sous_compte: "",
  });

  useEffect(() => {
    getFacturationFournisseurByCode().then((res) => {
      setDataFournisseur(res.data);
    });
  }, []);

  useEffect(() => {
    getEnteteCommercialAchat().then((res) => {
      const result = res.data.filter((el) => {
        return el.type == "facture" && el.solde < el.net_a_payer;
      });
      const options = result.map((d) => ({
        value: d.facture_code,
        label: d.date.split("-")[0] + "_" + d.facture_code + "_" + d.montant_total_ttc,
        dateValue: d.date.split("-")[0],
        ttcValue: d.montant_total_ttc,
        numfactureValue: d.facture_code,
      }));
      setDataFacturation(options);
    });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const list = { ...state };
    list[name] = value;

    // list["montant"] = soustractPercent(list.montant);
    // list["rs"] = getPercent(list.rs);
    setState(list);

    if (!client_code) {
      getEnteteByClientCode(list.fournisseur).then((res) => {
        const result = res.data.filter((el) => {
          return el.type == "facture" && el.solde < el.net_a_payer;
        });
        const options = result.map((d) => ({
          value: d.facture_code,
          label: d.date.split("-")[0] + "_" + d.facture_code + "_" + d.montant_total_ttc,
          dateValue: d.date.split("-")[0],
          ttcValue: d.montant_total_ttc,
          numfactureValue: d.facture_code,
        }));
        setDataFacturation(options);
      });
    }

    // setState((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));
  };

  useEffect(() => {
    getEntrepriseByCode().then((res) => {
      const list = { ...state };
      list["banque"] = res.data.banque;
      setState(list);
    });
  }, []);

  const handleSubmitPaiement = (e) => {
    e.preventDefault();
    // const list = { ...state };
    // list["montant"] = soustractPercent(list.montant);
    // list["rs"] = getPercent(list.rs);
    addPaiement(state).then((el) => {
      editSoldeEnteteByCodeFacture(state.montant, state.num_piece).then(() => {});
    });
  };

  const handleCheck = (event) => {
    if (isChecked === true) {
      const list = { ...state };
      // const oldRs = list.rs;
      // console.log(oldRs);
      list["montant"] = Number(list["rs"]) + Number(list["montant"]);
      list["rs"] = 0;
      setState(list);
    } else {
      const list = { ...state };
      list["rs"] = getPercent(list.montant);
      list["montant"] = soustractPercent(list.montant);
      setState(list);
    }
    setisChecked(!isChecked);
  };
  return (
    <div className="container-fluid mt-4">
      <div class="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Ajouter paiement</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <form style={{ marginTop: 50 }} onSubmit={handleSubmitPaiement}>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label>Fournisseur</label>

                      {dataFournisseur && (
                        <select
                          name="fournisseur"
                          className="form-control"
                          id="exampleFormControlSelect0"
                          onChange={handleInputChange}
                          value={state.fournisseur}
                          required
                        >
                          <option>Choisir ...</option>
                          {dataFournisseur.map((option) => (
                            <option value={option.code}>
                              {option.nom} {option.prenom}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label>Numéro pièce</label>
                      {dataFacturation && (
                        <select
                          name="num_piece"
                          className="form-control"
                          id="exampleFormControlSelect1"
                          onChange={handleInputChange}
                          value={state.num_piece}
                          required
                        >
                          <option value={"default"}>Choisir Facture</option>
                          {dataFacturation.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label>Banque</label>
                      <input
                        value={state.banque}
                        type="text"
                        className="form-control"
                        required
                        name="banque"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label>Mode de paiement</label>
                      <select className="form-control" name="mode_paiement" required onChange={handleInputChange}>
                        <option>Choisir ...</option>
                        <option value="cheque">Chèque</option>
                        <option value="virement">Virement</option>
                        <option value="traite">Traite</option>
                        <option value="espece">Espèce</option>
                        <option value="rs">RS</option>
                        <option value="autre">Autre</option>
                      </select>{" "}
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label>Numéro de paiement</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        name="numero_paiement"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  {/* 
                  <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label>Référence banque</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        name="reference_banque"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div> */}

                  <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Montant</label>
                      <input
                        type="number"
                        className="form-control"
                        required
                        name="montant"
                        onChange={handleInputChange}
                        value={state.montant}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group mt-2">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                          defaultChecked={isChecked}
                          onChange={handleCheck}
                        />

                        {/* <input
                          onClick={handleCheck}
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                        /> */}

                        <label className="form-check-label" htmlFor="flexCheckDefault">
                          RS
                        </label>
                      </div>
                      <input
                        disabled
                        type="number"
                        className="form-control"
                        required
                        name="rs"
                        onChange={handleInputChange}
                        value={state.rs}
                      />
                    </div>
                  </div>

                  {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Exercice</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        name="exercice"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div> */}

                  <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Date</label>
                      <input
                        type="date"
                        className="form-control"
                        required
                        name="date_reception"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Date versement</label>
                      <input
                        type="date"
                        className="form-control"
                        required
                        name="date_versement"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div> */}
                  <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Date_écheance</label>
                      <input
                        type="date"
                        className="form-control"
                        required
                        name="date_echeance"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Emplacement</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        name="emplacement"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Status</label>
                      <input type="text" className="form-control" required name="status" onChange={handleInputChange} />
                    </div>
                  </div> */}
                  {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Validation</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        name="validation"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Compte</label>
                      <input type="text" className="form-control" required name="compte" onChange={handleInputChange} />
                    </div>
                  </div> */}

                  {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Sous compte</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        name="sous_compte"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div> */}
                  <div className="col-12 col-sm-6 col-md-6 col-lg-9 col-xl-9">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Commentaire</label>
                      <textarea
                        required
                        name="commentaire"
                        onChange={handleInputChange}
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  Ajouter
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPaiement;
