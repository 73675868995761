import axios from "axios";
import { type } from "os";
import { getCurrentDateTime } from "../../Utils/DateUtils";
import { getToken, getSession } from "../../Utils/SessionUtils";
import moment from "moment";
const api_url_v2 = process.env.REACT_APP_API_BASE_URL_V2;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

export async function getBanqueByCode() {
  try {
    const response = await axios.get(api_url_v2 + "banques/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getBanqueById(id) {
  try {
    const response = await axios.get(api_url_v2 + "banques/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function addBanque(date_operation, abr, reference, libelle, date_valeur, beneficiaire, operation, cout, debit, credit) {
  const credentiel = {
    code_generated: getSession("code_generated"),
    date_operation: date_operation,
    abr: abr,
    reference: reference,
    libelle: libelle,
    date_valeur: date_valeur,
    beneficiaire: beneficiaire,
    centre_cout: cout,
    operation: operation,
    debit: debit,
    credit: credit,
  };

  try {
    const response = await axios.post(api_url_v2 + "banques", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function updateBanque(dateOperation, abr, reference, libelle, dateValeur, fournisseurCode, operation, cout, debit, credit, id) {
  const credentiel = {
    code_generated: getSession("code_generated"),
    date_operation: dateOperation,
    abr: abr,
    reference: reference,
    libelle: libelle,
    date_valeur: dateValeur,
    beneficiaire: fournisseurCode,
    operation: operation,
    centre_cout: cout,
    debit: debit,
    credit: credit,
    id: id,
  };

  try {
    const response = await axios.patch(api_url_v2 + "banques", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function deleteBanqueById(id) {
  try {
    const response = await axios.delete(api_url_v2 + "banques/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
