import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { addTaskToUser } from "../../Services/Pointeuse/TasksApi";

function AddTask({ from, getData }) {
  const history = useHistory();
  const [object, setobject] = useState();
  const myRefDelete = useRef(null);

  /**
   * It sends the data to the API.
   */
  const handelSave = () => {
    //console.log(userId, object, description, etat, commentaire, dateDebut, dateFin, color, projectId);
    addTaskToUser(object).then((res) => {
      myRefDelete.current.click();
      if (from) {
        getData();
      } else {
        history.push("/admin/task/" + res.data.id);
      }

      //props.getData();
    });
  };
  return (
    <div className="modal fade" id="newtaskmodal">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Ajouter tâche</h5>
            <button className="close" data-dismiss="modal" aria-label="Close" ref={myRefDelete}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-label">Titre</label>
                  <input className="form-control" placeholder="Text" onChange={(e) => setobject(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-outline-primary" data-dismiss="modal">
              Fermer
            </button>
            <button className="btn btn-success" onClick={handelSave}>
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddTask;
