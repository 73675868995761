import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

function ApexPie4(props) {
  const [chartOptions, setchartOptions] = useState({
    series: props.Apexseries,
    options: {
      chart: {
        // width: 300,
        type: "polarArea",
        sparkline: {
          enabled: true,
        },
      },
      labels: props.Apexlabels,
      fill: {
        opacity: 1,
        colors: props.Apexcolors,
      },
      stroke: {
        width: 0,
        colors: undefined,
      },
      yaxis: {
        show: false,
      },
      legend: {
        position: "bottom",
      },
      plotOptions: {
        polarArea: {
          rings: {
            strokeWidth: 0,
          },
        },
      },
      theme: {
        monochrome: {
          enabled: true,
          shadeTo: "light",
          shadeIntensity: 0.6,
        },
      },
    },
  });

  return (
    <div id="chart">
      {props && (
        <ReactApexChart
          options={chartOptions.options}
          series={chartOptions.series}
          type="polarArea"
          height={251}
          // width={300}
        />
      )}
    </div>
  );
}

export default ApexPie4;

// import React from "react";
// import ReactApexChart from "react-apexcharts";

// class ApexPie4 extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       series: [3, 6],

//       options: {
//         chart: {
//           // width: 300,
//           type: "polarArea",
//           sparkline: {
//             enabled: true,
//           },
//         },
//         labels: ["chomé non payé", "chomé payé"],
//         fill: {
//           opacity: 1,
//           colors: ["#F7284A", "#0DCD94"],
//         },
//         stroke: {
//           width: 0,
//           colors: undefined,
//         },
//         yaxis: {
//           show: false,
//         },
//         legend: {
//           position: "bottom",
//         },
//         plotOptions: {
//           polarArea: {
//             rings: {
//               strokeWidth: 0,
//             },
//           },
//         },
//         theme: {
//           monochrome: {
//             enabled: true,
//             shadeTo: "light",
//             shadeIntensity: 0.6,
//           },
//         },
//       },
//     };
//   }

//   render() {
//     return (
//       <div id="chart">
//         <ReactApexChart
//           options={this.state.options}
//           series={this.state.series}
//           type="polarArea"
//           height={251}
//           // width={300}
//         />
//       </div>
//     );
//   }
// }

// export default ApexPie4;
