import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { getFacturationClientByCode } from "../../Services/Facturation/FacturationClientApi";
import Select from "react-select";
import { addCaisse } from "../../Services/Pointeuse/CaisseApi";

function AddCaisse() {
  const history = useHistory();
  const alert = useAlert();

  const [dataFournisseur, setDataFournisseur] = useState([]);
  const [fournisseurFullName, setFournisseurFullName] = useState("");
  const [fournisseurCode, setFournisseurCode] = useState("");

  const [date, setdate] = useState(null);
  const [description, setdescription] = useState(null);
  const [cout, setcout] = useState(null);
  const [operation, setoperation] = useState("debit");
  const [debit, setdebit] = useState(0);
  const [credit, setcredit] = useState(0);
  const [type, settype] = useState("espece");
  const [isDebit, setisDebit] = useState(false);
  const [caisse, setcaisse] = useState("entreprise");

  const handleSubmitEncaissement = (e) => {
    e.preventDefault();
    addCaisse(date, fournisseurCode, description, cout, operation, caisse, debit, credit, type).then((res) => {
      alert.success("Ajouté");
      history.push("/admin/caisse");
    });
  };

  const handleChangeFournisseur = (event) => {
    setFournisseurCode(event.target.value);
  };

  useEffect(() => {
    getFacturationClientByCode().then((res) => {
      setDataFournisseur(res.data);
      console.log(res.data);
    });
  }, []);

  const handleChangeOperation = (e) => {
    const value = e.target.value;
    setoperation(value);

    if (value === "debit") {
      setdebit(0);
      setisDebit(false);
    } else {
      setcredit(0);
      setisDebit(true);
    }
  };

  return (
    <div className="col-xl-12 col-md-12 col-lg-12 mt-4">
      <div className="card">
        <div className="card-header  border-0">
          <h4 className="card-title">Ajouter Caisse</h4>
        </div>
        <div className="card-body">
          <div className="container-fluid">
            <div class="row">
              {/* <div class="col-sm-12 col-lg-6">
              <img style={{ marginTop: 130 }} src="/dist/img/encaissement/encaissement.svg"></img>
            </div> */}
              <div class="col-sm-12 col-lg-12">
                <form style={{ marginTop: 50 }} onSubmit={handleSubmitEncaissement}>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label>Client / Fournisseur</label>
                        {dataFournisseur && (
                          <select className="form-control" id="exampleFormControlSelect0" onChange={handleChangeFournisseur}>
                            <option>Choisir Client</option>
                            {dataFournisseur.map((option) => (
                              <option value={option.code}>
                                {option.nom} {option.prenom}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Date</label>
                        <input type="date" className="form-control" required placeholder="ex : 100.100" onChange={(e) => setdate(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Description</label>
                        <input type="text" className="form-control" onChange={(e) => setdescription(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Centre cout</label>
                        <input type="text" className="form-control" onChange={(e) => setcout(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Opération</label>
                        <select className="form-control" value={operation} required onChange={handleChangeOperation}>
                          <option value="debit">Débit</option>
                          <option value="credit">Crédit</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Type</label>
                        <select className="form-control" value={type} required onChange={(e) => settype(e.target.value)}>
                          <option value="cheque">Chèque</option>
                          <option value="virement">Virement</option>
                          <option value="espece">Espèce</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Débit</label>
                        <input
                          disabled={isDebit}
                          type="number"
                          className="form-control"
                          value={debit}
                          required
                          onChange={(e) => setdebit(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Crédit</label>
                        <input
                          disabled={!isDebit}
                          type="number"
                          className="form-control"
                          required
                          value={credit}
                          onChange={(e) => setcredit(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Caisse</label>
                        <select className="form-control" value={caisse} required onChange={(e) => setcaisse(e.target.value)}>
                          <option value="entreprise">Entreprise</option>
                          <option value="bureau">Bureau</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* {files && <img src={URL.createObjectURL(files[0].file)} alt=""></img>} */}

                  <button type="submit" className="btn btn-primary">
                    Ajouter
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCaisse;
