import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getPointageByUserId } from "../../Services/Pointeuse/PointageApi";
import { calcBusinessDays, filterPointageByMonth } from "../../Utils/GlobalTableUtils";
import { isCheckin } from "../../Utils/PointageMonthUtils";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import ImageComponent from "../Styles/ImageComponent";

// var dateObj = new Date();
// var result = dateObj.toISOString().split("T")[0];
// var month = result.split("-")[1];
// var year = result.split("-")[0];
// var newdate = year + "-" + month;

function SingleMonthPointage({ user, currentDate, dataPointages }) {
  const [pointages, setpointages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // const filterById = dataPointages.filter((el) => el.user_code_id == user.id);
    // const filtredPointage = filterPointageByMonth(filterById, currentDate);
    // var y = [...filtredPointage].reverse();
    // setpointages(y);

    setLoading(true);
    getPointageByUserId(user.id)
      .then((res) => {
        const filtredPointage = filterPointageByMonth(res.data, currentDate);
        var y = [...filtredPointage].reverse();
        setpointages(y);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [currentDate]);

  return (
    <>
      {pointages && (
        <tr key={user.id}>
          <td>
            <div className="d-flex">
              <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={user.img}></ImageComponent>
              <div className="mr-3 mt-0 mt-sm-2 d-block">
                <h6 className="mb-1 fs-14">
                  {user.nom} {user.prenom}
                </h6>
              </div>
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "01", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "01", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "01", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "01", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "01", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "02", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "02", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "02", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "02", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "02", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "03", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "03", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "03", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "03", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "03", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "04", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "04", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "04", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "04", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "04", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "05", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "05", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "05", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "05", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "05", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "06", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "06", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "06", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "06", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "06", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "07", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "07", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "07", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "07", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "07", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "08", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "08", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "08", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "08", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "08", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "09", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "09", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "09", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "09", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "09", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            {isCheckin(pointages, "10", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
            {isCheckin(pointages, "10", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
            {isCheckin(pointages, "10", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
            {isCheckin(pointages, "10", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
            {isCheckin(pointages, "10", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "11", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "11", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "11", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "11", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "11", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "12", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "12", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "12", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "12", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "12", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            {isCheckin(pointages, "13", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
            {isCheckin(pointages, "13", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
            {isCheckin(pointages, "13", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
            {isCheckin(pointages, "13", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
            {isCheckin(pointages, "13", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "14", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "14", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "14", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "14", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "14", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "15", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "15", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "15", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "15", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "15", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "16", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "16", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "16", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "16", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "16", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            {isCheckin(pointages, "17", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
            {isCheckin(pointages, "17", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
            {isCheckin(pointages, "17", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
            {isCheckin(pointages, "17", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
            {isCheckin(pointages, "17", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "18", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "18", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "18", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "18", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "18", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "19", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "19", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "19", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "19", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "19", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "20", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "20", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "20", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "20", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "20", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "21", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "21", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "21", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "21", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "21", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "22", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "22", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "22", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "22", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "22", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "23", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "23", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "23", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "23", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "23", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "24", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "24", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "24", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "24", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "24", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            {isCheckin(pointages, "25", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
            {isCheckin(pointages, "25", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
            {isCheckin(pointages, "25", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
            {isCheckin(pointages, "25", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
            {isCheckin(pointages, "25", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "26", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "26", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "26", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "26", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "26", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            {isCheckin(pointages, "27", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
            {isCheckin(pointages, "27", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
            {isCheckin(pointages, "27", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
            {isCheckin(pointages, "27", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
            {isCheckin(pointages, "27", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "28", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "28", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "28", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "28", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "28", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "29", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "29", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "29", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "29", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "29", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "30", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "30", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "30", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "30", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "30", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>
          <td>
            <div className="hr-listd">
              {isCheckin(pointages, "31", currentDate) === 3 && <span className="feather feather-check-circle text-success " />}
              {isCheckin(pointages, "31", currentDate) === 2 && <span className="feather feather-x-circle text-warning" />}
              {isCheckin(pointages, "31", currentDate) === 1 && <span className="feather feather-x-circle text-danger" />}
              {isCheckin(pointages, "31", currentDate) === 4 && <span className="fa fa-adjust text-primary" />}
              {isCheckin(pointages, "31", currentDate) === 5 && <span className="fa fa-calendar text-success" />}
            </div>
          </td>

          <td>
            <h6 className="mb-0">
              <span className="text-primary">{pointages.length}</span>
              <span className="my-auto fs-8 font-weight-normal text-muted">/</span>
              <span>{calcBusinessDays(currentDate)}</span>
            </h6>
          </td>
        </tr>
      )}
    </>
  );
}

export default SingleMonthPointage;
