import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { deleteFamilleById, getAllFamille } from "../../../Services/Facturation/FacturationFamille";
import { filterByLibelle } from "../../../Utils/SortObject";
import CopieContent from "../../Exports/CopieContent";
import ExportCsv from "../../Exports/ExportCsv";
import ExportPdf from "../../Exports/ExportPdf";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";
import Pagination from "../../Others/Pagination";
import AddFamille from "./AddFamille";
import EditerFamille from "./EditerFamille";

function ListFamille() {
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [size, setsize] = useState(8);
  const [tableId, setTableId] = useState(null);

  const handleOpenModal = (id) => {
    setTableId(id);
  };

  useEffect(() => {
    getData();
  }, []);

  /**
   * It gets all the familles from the API and sets the data to the data state.
   */
  const getData = () => {
    setLoading(true);
    getAllFamille()
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  /**
   * The function sets the size of the data to be retrieved from the API.
   * @param e - The event object that contains the data we want to use.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  /**
   * The `onChangePage` function is called when the user clicks on the page numbers.
   * It takes the page of items as an argument and updates the `currentData` state variable with this new
   * page of items
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the value of the input.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    if (e.target.value != "") {
      const dataSearch = filterByLibelle(allInitialData, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  /**
   * It deletes a famille from the database.
   * @param id - The id of the row to delete.
   */
  const handleDelete = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteFamilleById(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  return (
    <>
      <AddFamille getData={getData}></AddFamille>
      {tableId && <EditerFamille tableId={tableId} getData={getData} />}
      {/* <div className="d-flex mb-6 mt-5">
        <div className="mr-3">
          <label className="form-label">Note:</label>
        </div>
        <div>
          <span className="badge badge-success-light mr-2">
            <i className="feather feather-check-circle text-success" /> activé
          </span>
          <span className="badge badge-danger-light mr-2">
            <i className="feather feather-x-circle text-danger" /> non activé
          </span>

        </div>
      </div> */}

      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <div className="table-responsive">
          <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="dataTables_length" id="hr-table_length">
                  <label>
                    Afficher{" "}
                    <select
                      value={size}
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={handleChangeSize}
                    >
                      <option value={8}>8</option>
                      <option value={20}>20</option>
                      <option value={40}>40</option>
                      <option value={60}>60</option>
                      <option value={100}>100</option>
                    </select>{" "}
                    entrées
                  </label>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div id="hr-table_filter" className="dataTables_filter">
                  <label className="mr-2">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Recherche..."
                      aria-controls="hr-table"
                      onChange={handleSearch}
                    />
                  </label>
                  <ExportCsv data={data} name={"List_familles"} />
                  <ExportPdf
                    data={data}
                    name={"List_familles"}
                    columns={["libelle", "code", "gamme_libelle", "color", "created_at"]}
                  />
                  <CopieContent />
                  {/* <PrintContent /> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <table
                  className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                  id="hr-table"
                  role="grid"
                  aria-describedby="hr-table_info"
                >
                  <thead>
                    <tr>
                      <th>img</th>
                      <th>Nom</th>
                      <th>Gamme </th>
                      <th>color </th>
                      <th className="text-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((el) => (
                      <tr key={el.id}>
                        <td>
                          {" "}
                          <img
                            className="avatar avatar-md brround mr-3"
                            src={
                              el.image ? localStorage.getItem("baseUrl") + el.image : "/assets/images/products/7.jpg"
                            }
                            alt="user img"
                          />
                        </td>
                        <td>{el.libelle}</td>
                        <td>{el.gamme_libelle}</td>
                        <td>
                          <i
                            className="las la-box fa-3x"
                            style={{
                              color: el.color,
                            }}
                          ></i>
                        </td>

                        <td className="project-actions text-right">
                          <Link
                            to="#"
                            onClick={() => handleOpenModal(el.id)}
                            className="action-btns"
                            data-toggle="modal"
                            data-target="#editFamilleModal"
                          >
                            <i className="feather feather-edit text-info"></i>
                          </Link>
                          <Link to="#" className="action-btns" onClick={() => handleDelete(el.id)}>
                            <i className="feather feather-trash text-danger"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-5">
                <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                  Affichage de 1 à {size} sur {data.length} entrées
                </div>
              </div>
              <div className="col-sm-12 col-md-7">
                <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-4">
        <a href="#" className="btn btn-success" data-toggle="modal" data-target="#familleModal">
          Ajouter
        </a>
      </div>
    </>
  );
}

export default ListFamille;
