var _ = require("lodash");

/**
 * It groups the data by the number of piece and sums the amount of payment for each group.
 * @param data - The data to be grouped.
 * @returns A list of objects with the following structure:
 * ```
 * {
 *   num_piece: number,
 *   montant_paiement: number,
 * }
 * ```
 */
export function groupByNumPiece(data) {
  const groupItems = _.chain(data).groupBy("num_piece").value();
  var finalResult = Object.entries(groupItems).map(function (group) {
    return {
      num_piece: group[0],
      montant_paiement: group[1]
        .map((o) => o?.montant)
        .reduce((a, c) => {
          return Number(a) + Number(c);
        }, 0),
    };
  });

  return finalResult;
}

/**
 * It groups the data by the code_facture and sums the montant_encaissement for each group.
 * @param data - The data to be grouped.
 * @returns An array of objects with the following structure:
 * ```
 * {
 *   numero_piece: string,
 *   montant_encaissement: number,
 * }
 * ```
 */
export function groupByNumeroPiece(data) {
  const groupItems = _.chain(data).groupBy("code_facture").value();
  var finalResult = Object.entries(groupItems).map(function (group) {
    return {
      numero_piece: group[0],
      montant_encaissement: group[1]
        .map((o) => o?.montant)
        .reduce((a, c) => {
          return Number(a) + Number(c);
        }, 0),
    };
  });
  return finalResult;
}

/**
 * If the value is not null, return the value, otherwise return 0
 * @param value - The value to be checked.
 * @returns the value of the variable if it exists, otherwise it returns 0.
 */
export function checkMontanPaiement(value) {
  if (value) {
    return value;
  } else {
    return "0";
  }
}
