import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get the personnel document for a user by user id
 * @param id - The id of the user.
 * @returns The response is an array of objects.
 */
export async function getPersonnelDocByUserId(id) {
  try {
    const response = await axios.get(api_url + "personneldoc/userid/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add a personnel document to the database
 * @param userId - The id of the user who is adding the document.
 * @param image - The image of the personnel document.
 * @param type - The type of document.
 * @param description - The description of the document
 * @returns The response from the server.
 */
export async function addPersonnelDoc(userId, image, type, description) {
  const frmData = new FormData();

  frmData.append("code_generated", getSession("code_generated"));
  frmData.append("user_id", userId);
  frmData.append("image", image);
  frmData.append("type", type);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.post(api_url + "personneldoc", frmData, options);
    toast.success("Document ajoutée");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * `deletePersonnelDocById` deletes a personnel document from the database
 * @param id - The id of the document to delete.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "id": "string",
 *     "name": "string",
 *     "description": "string",
 *     "created": "string",
 *     "modified": "string",
 *     "createdBy": "string",
 *     "modifiedBy": "string",
 */
export async function deletePersonnelDocById(id) {
  try {
    const response = await axios.delete(api_url + "personneldoc/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
