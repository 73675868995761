import React, { useEffect, useState } from "react";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";
import Pagination from "../../Others/Pagination";
import ButtonHeaderComponent from "../../Styles/ButtonHeaderComponent";
import ImageComponent from "../../Styles/ImageComponent";
import { Link } from "react-router-dom";
import { getFacturationFournisseurByCode, deleteClientById } from "../../../Services/Facturation/FacturationClientApi";
import { filterFournisseurByWord } from "../../../Utils/SortObject";
import swal from "sweetalert";
import ExportCsv from "../../Exports/ExportCsv";
import ExportPdf from "../../Exports/ExportPdf";
import CopieContent from "../../Exports/CopieContent";

function ListFournisseur() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(8);
  const [isCollapsed, setisCollapsed] = useState(false);

  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getFacturationFournisseurByCode()
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    if (e.target.value) {
      const dataSearch = filterFournisseurByWord(data, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };
  // const handleDelete = (id) => {
  //   deleteClientById(id);
  //   setdata(
  //     data.filter((el) => {
  //       return el.id != id;
  //     })
  //   );
  // };
  const handleDelete = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteClientById(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
        });
      }
    });
  };
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };
  return (
    <div>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Fournisseur</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link
                to="/admin/addfournisseur"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#addclientmodal"
              >
                <i className="feather feather-plus fs-15 my-auto mr-2" />
                Ajouter fournisseur
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">Liste des fournisseurs</h4>
              <div className="card-options">
                <Link
                  to="#"
                  className="card-options-fullscreen mr-2"
                  data-toggle="card-fullscreen"
                  onClick={handleCollapse}
                >
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>
            {/* <div className="card-body">
              <div className="row">
                <div className="col-xl-7 col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">From:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control fc-datepicker" placeholder="DD-MM-YYYY" type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">To:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control fc-datepicker" placeholder="DD-MM-YYYY" type="text" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-12">
                  <div className="form-group">
                    <label className="form-label">Select Status:</label>
                    <select
                      name="attendance"
                      className="form-control custom-select select2"
                      data-placeholder="Select Status"
                    >
                      <option label="Select Status" />
                      <option value={1}>Active</option>
                      <option value={2}>InActive</option>
                    </select>
                  </div>
                </div>
                <div className="col-xl-2 col-md-12">
                  <div className="form-group mt-5">
                    <Link to="#" className="btn btn-primary btn-block">
                      Search
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="card-body">
              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            Afficher{" "}
                            <select
                              value={size}
                              className="form-control"
                              id="exampleFormControlSelect1"
                              onChange={handleChangeSize}
                            >
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            entrées
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Recherche..."
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv data={data} name={"List_fournisseurs"} />
                          <ExportPdf
                            data={data}
                            name={"List_fournisseurs"}
                            columns={["code", "nom", "prenom", "email", "mobile"]}
                          />
                          <CopieContent />

                          {/* <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0 w-5">#ID</th>
                              <th className="border-bottom-0">Nom/Prénom</th>
                              <th className="border-bottom-0">Email</th>
                              <th className="border-bottom-0">Mobile</th>
                              <th className="border-bottom-0">Plafond crédit</th>
                              <th className="border-bottom-0">Delai de paiement</th>
                              <th className="border-bottom-0">Remise</th>
                              <th className="border-bottom-0">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el, idx) => (
                              <tr key={idx}>
                                <td># {el.id}</td>
                                <td>
                                  <a href="client-profile.html" className="d-flex">
                                    <ImageComponent
                                      atr={"avatar avatar-md brround mr-3"}
                                      picture={el.image}
                                    ></ImageComponent>
                                    <div className="mr-3 mt-0 mt-sm-2 d-block">
                                      <h6 className="mb-1 fs-14">
                                        {el.nom} {el.prenom}
                                      </h6>
                                    </div>
                                  </a>
                                </td>
                                <td>{el.email}</td>
                                <td>{el.mobile}</td>
                                <td>
                                  <span className="badge badge-danger-light">{el.plafond_credit}</span>
                                </td>
                                <td>{el.delai_paiement}</td>
                                <td>{el.remise_client}</td>
                                <td>
                                  <div className="d-flex">
                                    {/* <a
                                      href="client-view.html"
                                      className="action-btns1"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="View"
                                    >
                                      <i className="feather feather-eye text-primary" />
                                    </a> */}
                                    <Link
                                      to={"/admin/fournisseur/" + el.id}
                                      className="action-btns1"
                                      data-toggle="modal"
                                      data-target="#editclientmodal"
                                    >
                                      <i
                                        className="feather feather-edit-2  text-success"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit"
                                      />
                                    </Link>
                                    <Link
                                      to="#"
                                      className="action-btns1"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                      onClick={() => handleDelete(el.id)}
                                    >
                                      <i className="feather feather-trash-2 text-danger" />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          Affichage de 1 à {size} sur {data.length} entrées
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListFournisseur;
