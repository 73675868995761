import React, { useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ajouterGamme } from "../../../Services/Facturation/FacturationGammeApi";

function AddGamme({ getData }) {
  const refHideModal = useRef();
  const [color, setColor] = useState("");
  const [libelle, setLibelle] = useState("");

  /**
   * It adds a new gamme to the database.
   * @param e - The event object that contains the data of the event that triggered the function.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("fff");
    if (libelle == "") {
      toast.error("nom vide");
    } else if (color == "") {
      toast.error("coleur vide");
    } else {
      ajouterGamme(libelle, color).then((res) => {
        // history.push("/facturation/gamme")
        getData();
        refHideModal.current.click();
      });
    }
  };

  /**
   * It sets the color variable to the value of the color picker.
   * @param event - The event that triggered the callback.
   */
  const handleChangeComplete = (event) => {
    setColor(event.target.value);
  };

  return (
    <div
      className="modal fade"
      id="gammeModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="gammeModalTitle"
      aria-hidden="true"
    >
      <ToastContainer></ToastContainer>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Ajouter gamme
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Nom </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Taper ..."
                  onChange={(e) => setLibelle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Coleur</label>
                <input
                  type="color"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  onChange={handleChangeComplete}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={refHideModal}>
              Annuler
            </button>
            <button onClick={handleSubmit} className="btn btn-primary">
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddGamme;
