import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  BarChart,
  Bar,
} from "recharts";
import { convertToDigit } from "../../Utils/NumberUtils";

/**
 * It creates a bar chart with the following properties:
 * - The data is the factures array
 * - The x axis is the month
 * - The y axis is the amount of the facture
 * - The first bar is the amount of the facture
 * - The second bar is the objectif
 * @returns The `ObjectifChart2` component is returning a `BarChart` component.
 */
function ObjectifChart2({ objectifs, factures }) {
  const [data, setdata] = useState([]);
  useEffect(() => {
    setdata(factures.map((item, i) => Object.assign({}, item, objectifs[i])));
  }, [objectifs, factures]);
  return (
    <BarChart
      width={650}
      height={400}
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 50,
      }}
    >
      <defs>
        <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#8884d8" stopOpacity={0.9} />
          <stop offset="75%" stopColor="#8884d8" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#82ca9d" stopOpacity={0.9} />
          <stop offset="75%" stopColor="#82ca9d" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient id="color3" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#ffc658" stopOpacity={0.9} />
          <stop offset="75%" stopColor="#ffc658" stopOpacity={0.1} />
        </linearGradient>
      </defs>

      <CartesianGrid opacity={0.1} vertical={false} />
      <XAxis dataKey="mois" angle={-90} textAnchor="end" axisLine={false} tickLine={false} tickCount={data.length} />
      <YAxis orientation="right" axisLine={false} tickLine={false} tickCount={data.length} />
      <Tooltip content={<CustomTooltip />} />

      <Bar type="monotone" dataKey="montant_total_ht" stroke="#82ca9d" fill="#82ca9d" fill="url(#color2)" />

      <Bar type="monotone" dataKey="objectif" stroke="#ffc658" fill="#ffc658" fill="url(#color3)" />
    </BarChart>
  );
}
/**
 * The function returns a div element with two paragraphs.
 * The first paragraph contains the label of the date and the second paragraph contains the value of
 * the objectif and the realisation
 * @returns The CustomTooltip component is being returned.
 */
function CustomTooltip({ active, payload, label }) {
  if (active) {
    return (
      <div className="">
        {/* <h4>{format(parseISO(label), "eeee, d MMM, yyyy")}</h4> */}
        <p>Objectif 2022 : {convertToDigit(payload[1]?.value)} TND</p>
        <p>Réalisation 2022 : {convertToDigit(payload[0]?.value)} TND</p>
      </div>
    );
  }
  return null;
}
export default ObjectifChart2;
