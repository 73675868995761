import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get sequence by code
 */
export async function getSequenceByCode() {
  try {
    const response = await axios.get(api_url + "facturation/sequence/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It edits the sequence of the invoice, devis, order and BL.
 * @param seq_facture - The sequence number for the invoice
 * @param seq_devis - the sequence number for the devis
 * @param seq_commande - The sequence number for the commande
 * @param seq_bl - The sequence of the BL
 * @returns The response is an object that contains the status code, the response data, the response
 * headers, and the request.
 */
export async function editerSequence(seq_facture, seq_devis, seq_commande, seq_bl) {
  const credentiel = {
    seq_facture: seq_facture,
    seq_devis: seq_devis,
    seq_commande: seq_commande,
    seq_bl: seq_bl,
    code_generated: getSession("code_generated"),
  };

  try {
    const response = await axios.patch(api_url + "facturation/sequence/", credentiel, config);
    console.log(response);
    toast.success("Séquence modifiée");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
