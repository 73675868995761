import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { convertAmount } from "../../../Utils/ConvertToCurrency_third";
//var nombre_en_lettre = require("../../../Utils/ConvertToCurrency_second.js");

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "column",
    marginTop: 12,
  },
  reportTitle: {
    fontSize: 11,
    textAlign: "left",
    textTransform: "uppercase",
  },
});

const InvoiceLettrePrice = ({ invoice }) => {
  console.log(invoice.totalTTC);
  return (
    <View style={styles.titleContainer}>
      {invoice.totalTTC && (
        <Text style={styles.reportTitle}>
          ARRÊTE LA PRÉSENTE FACTURE A LA SOMME DE {convertAmount(invoice.totalTTC)}
        </Text>
      )}
    </View>
  );
};

export default InvoiceLettrePrice;
