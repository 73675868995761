import React from "react";
import ListFamille from "../Famille/ListFamille";
import ListGamme from "../Gamme/ListGamme";
import ListMarque from "../Marque/ListMarque";
import ListSousFamille from "../SousFamille/ListSousFamille";

/**
 * This function returns a React component that renders a list of products
 * @returns The ProductParams component is returning a div element with a class of "page-header
 * d-xl-flex d-block".
 */
function ProductParams() {
  return (
    <div>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Paramètres produits</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                {" "}
                <i className="feather feather-mail" />{" "}
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                {" "}
                <i className="feather feather-phone-call" />{" "}
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                {" "}
                <i className="feather feather-info" />{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3">
          <div className="card">
            <div
              className="nav flex-column admisetting-tabs"
              id="settings-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a className="nav-link active" data-toggle="pill" href="#tab-1" role="tab">
                <i className="nav-icon las la-cubes" /> Familles
              </a>
              <a className="nav-link" data-toggle="pill" href="#tab-2" role="tab">
                <i className="nav-icon las la-cubes" /> Sous familles
              </a>
              <a className="nav-link" data-toggle="pill" href="#tab-3" role="tab">
                <i className="nav-icon las la-cubes" /> Gammes
              </a>
              <a className="nav-link" data-toggle="pill" href="#tab-4" role="tab">
                <i className="nav-icon las la-cubes" /> Marques
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-9">
          <div className="tab-content adminsetting-content" id="setting-tabContent">
            <div className="tab-pane fade show active" id="tab-1" role="tabpanel">
              <div className="card">
                <div className="card-header  border-0">
                  <h4 className="card-title">Liste des Familles</h4>
                </div>
                <div className="card-body">
                  <ListFamille></ListFamille>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="tab-2" role="tabpanel">
              <div className="card">
                <div className="card-header  border-0">
                  <h4 className="card-title">Liste des sous familles</h4>
                </div>
                <div className="card-body">
                  <ListSousFamille></ListSousFamille>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="tab-3" role="tabpanel">
              <div className="card">
                <div className="card-header  border-0">
                  <h4 className="card-title">Liste des gammes</h4>
                </div>
                <div className="card-body">
                  <ListGamme></ListGamme>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="tab-4" role="tabpanel">
              <div className="card">
                <div className="card-header  border-0">
                  <h4 className="card-title">Liste des maques</h4>
                </div>
                <div className="card-body">
                  <ListMarque></ListMarque>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductParams;
