import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all acompte from the database
 * @returns The response is an array of objects.
 */
export async function getAllAcompte() {
  try {
    const response = await axios.get(api_url + "acompte/code/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Send a notification to a specific user using the FCM API
 * @param text - The message you want to send.
 * @param receiver - The receiver of the notification.
 * @returns The response from the server.
 */
export async function sendFcmAcompte(text, receiver) {
  const notification = {
    data: {
      title: "Demande Acompte sur salaire est ",
      message: text,
    },
    to: "/topics/" + localStorage.getItem("code_generated") + "user" + receiver,
  };
  const fcm_server_key =
    "AAAAyFwzY3I:APA91bGIPThuQusFacH13cip7zdnDNY81mhV1xJV5pQXjqoHaIUwcibidgFahbxnjAxWODcIR0pew1a3tYlVUQNPLpx7nIanisZieGr0ZaMcwSqDvmcvyr9YjJK3yfoEbwu82sWDhL7E";

  const g = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `key=${fcm_server_key}`,
    },
  };
  try {
    const response = await axios.post("https://fcm.googleapis.com/fcm/send", notification, g);
    console.log(response);
    toast.success("Notification envoyée");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Accept an acompte
 * @param id - the id of the acompte to accept
 * @param montant - the amount of the acompte
 * @returns The response from the server.
 */
export async function acceptAcompte(id, montant) {
  const credentiel = {
    valide: "1",
    type: "acompte",
    montant: montant,
    id: id,
  };
  try {
    const response = await axios.patch(api_url + "acompte", credentiel, config);
    console.log(response);
    // toast.success("Congé accepté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It refuse acompte
 * @param id - the id of the acompte
 * @param montant - the amount of the acompte
 * @returns The response from the server.
 */
export async function refuseAcompte(id, montant) {
  const credentiel = {
    valide: "0",
    type: "acompte",
    montant: montant,
    id: id,
  };
  try {
    const response = await axios.patch(api_url + "acompte", credentiel, config);
    // console.log(response);
    // toast.success("Congé accepté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It deletes an acompte from the database.
 * @param id - The id of the acompte you want to delete.
 * @returns The response from the API.
 */
export async function deleteAcompteById(id) {
  try {
    const response = await axios.delete(api_url + "acompte/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
