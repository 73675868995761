import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get product by code
 */
export async function getAllProduct() {
  try {
    const response = await axios.get(api_url + "facturation/produit/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get all the product achat from the database
 * @returns The response is an array of objects.
 */
export async function getAllProductAchat() {
  try {
    const response = await axios.get(api_url + "facturation/produit/produitachat/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get all the product achat by code client
 * @param code - The code of the client.
 * @returns The response is an array of objects.
 */
export async function getAllProductAchatByCodeClient(code) {
  try {
    const response = await axios.get(api_url + "facturation/produit/produitachatclient/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * * Add a product to the database
 * @param code - The code of the product.
 * @param code_a_barre - The code_a_barre of the product.
 * @param libelle - The name of the product.
 * @param sousfamille_code - The code of the subfamily.
 * @param marque_code - The code of the marque.
 * @param gamme_code - The code of the gamme.
 * @param famille_code - The code of the family of the product.
 * @param fournisseur_code - The code of the supplier
 * @param unite - Unité de mesure
 * @param tva - The tax rate.
 * @param qte_min - The minimum quantity of the product that must be in stock.
 * @param image - The image of the product.
 * @param fiche - The file name of the product image.
 * @param hateur - height of the product
 * @param largeur - the width of the product
 * @param profondeur - The depth of the product.
 * @param prix_achat_ht - The purchase price without taxes
 * @param prix_achat_ttc - The price of the product in the store.
 * @param prix_vente_ht_1 - The price of the product in the first sale unit.
 * @param prix_vente_ht_2 - The second price in HT
 * @param prix_vente_ht_3 - The third price in HT
 * @param prix_vente_ht_4 - The fourth price of the product.
 * @param prix_vente_ht_5 - The price of the product in the fifth position of the price list.
 * @param prix_vente_ttc_1 - The price of the product in the first sale unit.
 * @param prix_vente_ttc_2 - The price of the product without taxes.
 * @param prix_vente_ttc_3 - The price of the product in the third position of the price list.
 * @param prix_vente_ttc_4 - The price of the product in the fourth position of the price list.
 * @param prix_vente_ttc_5 - The price of the product at the fifth price level.
 * @param marge_1 - Margin 1
 * @param marge_2 - Margin 2
 * @param marge_3 - Margin 3
 * @param marge_4 - The margin for the 4th price.
 * @param marge_5 - The margin for the fifth price.
 * @param poids - The weight of the product in kg.
 * @param prix_vente_public - The price of the product in the store.
 * @param prix_promotion - The price of the product when it's on promotion.
 * @param code_promotion - The code of the promotion.
 * @param suivie_stock - 0 or 1
 * @param abonnement - 0 or 1
 * @param achat - 0 or 1
 * @returns The response from the server.
 */
export async function ajouterProduit(
  code,
  code_a_barre,
  libelle,
  sousfamille_code,
  marque_code,
  gamme_code,
  famille_code,
  fournisseur_code,
  unite,
  tva,
  qte_min,
  image,
  fiche,
  hateur,
  largeur,
  profondeur,
  prix_achat_ht,
  prix_achat_ttc,
  prix_vente_ht_1,
  prix_vente_ht_2,
  prix_vente_ht_3,
  prix_vente_ht_4,
  prix_vente_ht_5,
  prix_vente_ttc_1,
  prix_vente_ttc_2,
  prix_vente_ttc_3,
  prix_vente_ttc_4,
  prix_vente_ttc_5,
  marge_1,
  marge_2,
  marge_3,
  marge_4,
  marge_5,
  poids,
  prix_vente_public,
  prix_promotion,
  code_promotion,
  suivie_stock,
  abonnement,
  achat
) {
  const frmData = new FormData();

  frmData.append("code", code);
  frmData.append("code_a_barre", code_a_barre);
  frmData.append("code_erp", "");
  frmData.append("libelle", libelle);
  frmData.append("hors_vente", "0");
  frmData.append("sousfamille_code", sousfamille_code);
  frmData.append("marque_code", marque_code);
  frmData.append("gamme_code", gamme_code);
  frmData.append("famille_code", famille_code);
  frmData.append("fournisseur_code", fournisseur_code);
  frmData.append("soussociete_code", getSession("code_generated"));
  frmData.append("type", "");
  frmData.append("unite", unite);
  frmData.append("tva", tva);
  frmData.append("qte_stock", "0");
  frmData.append("qte_vendu", "0");
  frmData.append("code_garantie", "");
  frmData.append("date_debut_pack", "");
  frmData.append("date_fin_pack", "");
  frmData.append("qte_min", qte_min);
  frmData.append("activite_code", "");
  frmData.append("image", image);
  frmData.append("fiche", fiche);
  frmData.append("hateur", hateur);
  frmData.append("largeur", largeur);
  frmData.append("profondeur", profondeur);
  frmData.append("prix_achat_ht", prix_achat_ht);
  frmData.append("prix_achat_ttc", prix_achat_ttc);
  frmData.append("prix_vente_ht_1", prix_vente_ht_1);
  frmData.append("prix_vente_ht_2", prix_vente_ht_2);
  frmData.append("prix_vente_ht_3", prix_vente_ht_3);
  frmData.append("prix_vente_ht_4", prix_vente_ht_4);
  frmData.append("prix_vente_ht_5", prix_vente_ht_5);
  frmData.append("prix_vente_ttc_1", prix_vente_ttc_1);
  frmData.append("prix_vente_ttc_2", prix_vente_ttc_2);
  frmData.append("prix_vente_ttc_3", prix_vente_ttc_3);
  frmData.append("prix_vente_ttc_4", prix_vente_ttc_4);
  frmData.append("prix_vente_ttc_5", prix_vente_ttc_5);
  frmData.append("marge_1", marge_1);
  frmData.append("marge_2", marge_2);
  frmData.append("marge_3", marge_3);
  frmData.append("marge_4", marge_4);
  frmData.append("marge_5", marge_5);
  frmData.append("poids", poids);
  frmData.append("prix_vente_public", prix_vente_public);
  frmData.append("num_serie", "");
  frmData.append("prix_promotion", prix_promotion);
  frmData.append("code_promotion", code_promotion);
  frmData.append("is_pack", "0");
  frmData.append("chargement", "0");
  frmData.append("colisage", "0");
  frmData.append("actif", "1");
  frmData.append("nbrpoints", "0");
  frmData.append("ordre", "0");
  frmData.append("soumis_r", "0");
  frmData.append("soumis_retenue", "0");
  frmData.append("soumis_gratuite", "0");
  frmData.append("soumis_cadeaux", "0");
  frmData.append("suivie_stock", suivie_stock);
  frmData.append("abonnement", abonnement);
  frmData.append("achat", achat);
  frmData.append("deleted_at", "2021-01-21");
  frmData.append("updated_at", "2021-01-21");

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.post(api_url + "facturation/produit/", frmData, options);
    console.log(response);
    toast.success("Produit ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * `deleteProduitById` is a function that deletes a produit by id
 * @param id - The id of the product you want to delete.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "id": "string",
 *     "nom": "string",
 *     "prix": "string",
 *     "description": "string",
 *     "image": "string",
 *     "categorie": {
 *       "id": "string",
 */
export async function deleteProduitById(id) {
  try {
    const response = await axios.delete(api_url + "facturation/produit/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets a product by id.
 * @param id - The id of the product you want to get.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "produit": {
 *       "id": 1,
 *       "nom": "Produit 1",
 *       "prix": 100,
 *       "description": "Description du produit 1",
 *       "image": "http://localhost:3000/images/
 */
export async function getProduitById(id) {
  try {
    const response = await axios.get(api_url + "facturation/produit/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It updates a product in the database.
 * @param codeBare - the code barre of the product
 * @param libelle - the name of the product
 * @param sousFamilleCode - the code of the subfamily
 * @param marqueCode - the code of the marque
 * @param gammeCode - The code of the gamme
 * @param familleCode - the code of the family
 * @param fournisseurCode - The code of the supplier
 * @param unite - unite
 * @param tauxTva - tauxTva,
 * @param qteMin - The minimum quantity of the product that can be purchased.
 * @param fiche - fiche,
 * @param hauteur - height
 * @param largeur - the width of the product
 * @param profondeur - profondeur,
 * @param prixAchatHt - prixAchatHt,
 * @param prixAchattc - prixAchatHt * (1 + tauxTva / 100)
 * @param prixHt1 - prix_vente_ht_1
 * @param prixHt2 - prixHt2,
 * @param prixHt3 - prixHt3,
 * @param prixHt4 - prixHt4,
 * @param prixHt5 - prixHt5,
 * @param prixttc1 - prix de vente TTC 1
 * @param prixttc2 - prix de vente TTC 2
 * @param prixttc3 - prix vente TTC 3
 * @param prixttc4 - prix_vente_ttc_4
 * @param prixttc5 - prix de vente TTC 5
 * @param marge1 - marge 1
 * @param marge2 - marge_2
 * @param marge3 - marge3,
 * @param marge4 - marge4,
 * @param marge5 - marge5,
 * @param poids - weight of the product
 * @param prixVentePublic - The price of the product in the store.
 * @param prixPromotion - prixPromotion,
 * @param codePromotion - The code of the promotion.
 * @param suivie - 0 or 1
 * @param abonnement - "0"
 * @param achat - achat
 * @param id - id of the product
 * @returns The response is an object that contains the data that was returned from the server.
 */
export async function updateProduitById(
  codeBare,
  libelle,
  sousFamilleCode,
  marqueCode,
  gammeCode,
  familleCode,
  fournisseurCode,
  unite,
  tauxTva,
  qteMin,
  fiche,
  hauteur,
  largeur,
  profondeur,
  prixAchatHt,
  prixAchattc,
  prixHt1,
  prixHt2,
  prixHt3,
  prixHt4,
  prixHt5,
  prixttc1,
  prixttc2,
  prixttc3,
  prixttc4,
  prixttc5,
  marge1,
  marge2,
  marge3,
  marge4,
  marge5,
  poids,
  prixVentePublic,
  prixPromotion,
  codePromotion,
  suivie,
  abonnement,
  achat,
  id
) {
  const credentiel = {
    code_a_barre: codeBare,
    code_erp: "",
    libelle: libelle,
    hors_vente: "0",
    sousfamille_code: sousFamilleCode,
    marque_code: marqueCode,
    gamme_code: gammeCode,
    famille_code: familleCode,
    fournisseur_code: fournisseurCode,
    soussociete_code: localStorage.getItem("code_generated"),
    type: "",
    unite: unite,
    tva: tauxTva,
    qte_stock: "0",
    qte_vendu: "0",
    code_garantie: "",
    date_debut_pack: "",
    date_fin_pack: "",
    qte_min: qteMin,
    activite_code: "",
    fiche: fiche,
    hateur: hauteur,
    largeur: largeur,
    profondeur: profondeur,
    prix_achat_ht: prixAchatHt,
    prix_achat_ttc: prixAchattc,
    prix_vente_ht_1: prixHt1,
    prix_vente_ht_2: prixHt2,
    prix_vente_ht_3: prixHt3,
    prix_vente_ht_4: prixHt4,
    prix_vente_ht_5: prixHt5,
    prix_vente_ttc_1: prixttc1,
    prix_vente_ttc_2: prixttc2,
    prix_vente_ttc_3: prixttc3,
    prix_vente_ttc_4: prixttc4,
    prix_vente_ttc_5: prixttc5,
    marge_1: marge1,
    marge_2: marge2,
    marge_3: marge3,
    marge_4: marge4,
    marge_5: marge5,
    poids: poids,
    prix_vente_public: prixVentePublic,
    num_serie: "",
    prix_promotion: prixPromotion,
    code_promotion: codePromotion,
    is_pack: "0",
    chargement: "0",
    colisage: "0",
    actif: "1",
    nbrpoints: "0",
    ordre: "0",
    soumis_r: "0",
    soumis_retenue: "0",
    soumis_gratuite: "0",
    soumis_cadeaux: "0",
    suivie_stock: suivie,
    abonnement: abonnement,
    achat: achat,
    id: id,
  };
  try {
    const response = await axios.patch(api_url + "facturation/produit/", credentiel, config);
    console.log(response);
    toast.success("modifié");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Update an image of a product by its id
 * @param image - The image file that you want to upload.
 * @param id - The id of the image to update.
 * @returns The response is an object that contains the data, a status code, and a header.
 */
export async function updateImageProduitById(image, id) {
  const frmData = new FormData();
  frmData.append("id", id);
  frmData.append("image", image);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.put(api_url + "facturation/produit/image/", frmData, options);
    toast.success("mise à jour");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
