import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get Gamme by code
 */
export async function getAllGamme() {
  try {
    const response = await axios.get(api_url + "facturation/gamme/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets a gamme by its id.
 * @param id - The id of the gamme you want to get.
 * @returns The gamme object.
 */
export async function getGammeById(id) {
  try {
    const response = await axios.get(api_url + "facturation/gamme/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It adds a new gamme to the database.
 * @param libelle - The name of the gamme
 * @param color - the color of the gamme
 * @returns The response is an object with the following structure:
 */
export async function ajouterGamme(libelle, color) {
  const credentiel = {
    soussociete_code: getSession("code_generated"),
    libelle: libelle,
    color: color,
  };

  try {
    const response = await axios.post(api_url + "facturation/gamme/", credentiel, config);
    console.log(response);
    toast.success("Gamme ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It deletes a gamme by id.
 * @param id - The id of the gamme to delete.
 * @returns The response from the server.
 */
export async function deleteGammeById(id) {
  try {
    const response = await axios.delete(api_url + "facturation/gamme/" + id, config);
    console.log(response);
    toast.success("Gamme supprimé");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error);
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Edit a gamme
 * @param id - id of the gamme to edit
 * @param libelle - the name of the gamme
 * @param color - the color of the gamme
 * @returns The response is an object with the following properties:
 */
export async function editGamme(id, libelle, color) {
  const credentiel = {
    libelle: libelle,
    color: color,
    id: id,
  };

  try {
    const response = await axios.patch(api_url + "facturation/gamme/", credentiel, config);
    console.log(response);
    toast.success("Gamme modifié");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
