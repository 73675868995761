import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { deleteTasById, getTasksByCode, updateEtatTaskById } from "../../Services/Pointeuse/TasksApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import ImageComponent from "../Styles/ImageComponent";
import AddTask from "./AddTask";

function NewTask(props) {
  const [content, setcontent] = useState("");

  /**
   * *This function is called when the user clicks the close button on the dialog.*
   */
  const handleClose = () => {
    props.handleClose(false);
  };
  /**
   * It sets the content state to the value of the input.
   * @param e - The event object that contains the data for the event.
   */
  const handleChange = (e) => {
    let val = e.target.value;
    setcontent(val);
  };
  /**
   * It prevents the default action of submitting the form and then it calls the handleNewTask function
   * from the App.js file.
   * @param e - The event object that was triggered.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleNewTask(content);
    setcontent("");
    props.handleClose(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="input-group mb-3">
        <input
          value={content}
          onChange={handleChange}
          type="text"
          className="form-control"
          placeholder="ajouter une nouvelle tâche"
          aria-describedby="basic-addon2"
        />
        <div className="input-group-append">
          <span className="input-group-text" id="basic-addon2">
            <i className="fas fa-plus" />
          </span>
        </div>
      </div>
    </form>
  );
}

function KanbanTasks() {
  const [tasks, settasks] = useState([{ object: "Learn Angular", etat: "encours", color: "#17A2B8" }]);

  const [isClicked, setisClicked] = useState(false);
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getTasksByCode()
      .then((res) => {
        console.log(res.data);
        setdata(res.data);
        setallInitialData(res.data);
        settasks(res.data);
        //console.log(res);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDragStart = (e, id) => {
    e.dataTransfer.setData("id", id);
    //console.log(tasks);
  };

  const onDrop = (e, cat) => {
    let id = e.dataTransfer.getData("id");

    let newTasks = tasks.filter((task) => {
      if (task.object == id) {
        task.etat = cat;
        updateEtatTaskById(task.etat, task.id);
        // if (cat == "terminee") {
        //   task.color = "#ffff";
        // } else if (cat == "td") {
        //   task.color = "#ffff";
        // } else {
        //   task.color = "#ffff";
        // }
      }
      return task;
    });
    console.log(newTasks);

    settasks(newTasks);
  };

  const handleAddNew = () => {
    let val = isClicked ? false : true;
    setisClicked(val);
  };

  const handleClose = (obj) => {
    setisClicked(obj);
  };

  const handleNewTask = (content) => {
    const task = {};
    task.object = content;
    task.color = "#17A2B8";
    task.etat = "td";
    const allTasks = [...tasks, task];
    settasks(allTasks);
  };
  const render = () => {
    const tasksList = {
      afaire: [],
      encours: [],
      revoir: [],
      validee: [],
      terminee: [],
      tester: [],
    };

    const handleDelete = (id) => {
      swal({
        title: "Ëtes-vous sûr ?",
        text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteTasById(id).then(() => {
            swal("Opération effectuée avec succès!", {
              icon: "success",
            });
            getData();
          });
        }
      });
    };

    tasks.forEach((t, idx) => {
      tasksList[t.etat].push(
        <div key={t?.idx} onDragStart={(e) => onDragStart(e, t.object)} draggable className="card overflow-hidden">
          <div className="card-status card-status-left" style={{ backgroundColor: `${t.color}` }} />
          <div className="card-body">
            <div className="d-flex  mb-1">
              <p className="-13 text-muted mb-0">
                {t?.priority == 0 && <i className="fa fa-arrow-up mr-1 text-success" />}
                {t?.priority == 1 && <i className="fa fa-arrow-up mr-1 text-warning" />}
                {t?.priority == 2 && <i className="fa fa-arrow-up mr-1 text-orange" />}
                {t?.priority == 3 && <i className="fa fa-arrow-up mr-1 text-danger" />}

                {t?.priority}
                <ul className="task-status">
                  {t?.type == "bug" && (
                    <li>
                      <a href="#" className="bg-danger" />
                    </li>
                  )}
                  {t?.type == "task" && (
                    <li>
                      <a href="#" className="bg-primary" />
                    </li>
                  )}
                </ul>
                <span className="ml-4">{t.date_debut && t.date_debut.split(" ")[0]}</span>
              </p>
              <div className="dropdown dropleft ml-auto">
                <button aria-expanded="false" aria-haspopup="true" className="btn ripple p-0" data-toggle="dropdown">
                  <i className="feather-more-horizontal fs-18" />
                </button>
                <ul className="dropdown-menu fs-13">
                  <li>
                    <Link to={"/admin/task/" + t?.id}>
                      <i className="feather feather-eye mr-2" />
                      voir
                    </Link>
                  </li>
                  {/* <li>
                    <a href="#">
                      <i className="feather feather-send mr-2" />
                      Send
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="feather feather-edit-2 mr-2" />
                      Edit
                    </a>
                  </li> */}
                  <li>
                    <Link to="#" onClick={() => handleDelete(t?.id)}>
                      <i className="feather feather-trash-2 mr-2" />
                      Remove
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <Link to={"/admin/task/" + t?.id}>
              <h5 className="fs-14 mt-3 mb-1 text-capitalize">{t.object}</h5>
              {/* <p className="fs-13 mt-2 text-muted">{t.description}</p> */}
              {/* <div>
              {t.type == "bug" && (
                <ul className="task-status">
                  <li>
                    <a href="#" className="bg-danger" />
                  </li>
                  <li>
                    <a href="#" className="bg-danger" />
                  </li>
                  <li>
                    <a href="#" className="bg-danger" />
                  </li>
                  <li>
                    <a href="#" className="bg-danger" />
                  </li>
                </ul>
              )}
              {t.type == "task" && (
                <ul className="task-status">
                  <li>
                    <a href="#" className="bg-primary" />
                  </li>
                  <li>
                    <a href="#" className="bg-primary" />
                  </li>
                  <li>
                    <a href="#" className="bg-primary" />
                  </li>
                  <li>
                    <a href="#" className="bg-primary" />
                  </li>
                </ul>
              )}
            </div> */}
              <div className="d-flex">
                <div className="mt-1">
                  <span className="badge badge-md badge-danger-light">{t.date_fin && t?.date_fin.split(" ")[0]}</span>
                  {/* <span className="messages">
                    <i className="fe fe-message-square" /> 6
                  </span> */}
                </div>
                <div className="avatar-list avatar-list-stacked ml-auto">
                  <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={t?.img}></ImageComponent>
                </div>
              </div>
              {/* <p className="fs-13 text-muted mt-3">
              <i className="feather feather-clock  mr-2" />
              Dead line {t?.date_fin}
            </p> */}
            </Link>
          </div>
        </div>
      );
    });
    const loader = isClicked ? <NewTask handleNewTask={handleNewTask} handleClose={handleClose} /> : null;
    return (
      <>
        <div className="page-header d-xl-flex d-block">
          <div className="page-leftheader">
            <h4 className="page-title">Tâche Kanban</h4>
          </div>
          <div className="page-rightheader ml-md-auto">
            <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
              <div className="btn-list">
                <a href="#" className="btn btn-primary " data-toggle="modal" data-target="#newtaskmodal">
                  <i className="feather feather-plus fs-15 my-auto mr-2" />
                  Ajouter tâche
                </a>
                <a href="#" className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                  {" "}
                  <i className="feather feather-mail" />{" "}
                </a>
                <a href="#" className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                  {" "}
                  <i className="feather feather-phone-call" />{" "}
                </a>
                <a href="#" className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                  {" "}
                  <i className="feather feather-info" />{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="examples">
          <div className="parent1 row">
            <div className="wrapper col-xl-2">
              <div className="card task-board-card" onDragOver={(e) => onDragOver(e)} onDrop={(e) => onDrop(e, "afaire")}>
                <div className="card-header border-0">
                  <h4 className="card-title mb-6">à faire</h4>
                </div>
                <div className="card-body task-board-body pt-0 task-in" style={{ overflowY: "scroll" }}>
                  <div id="left-defaults">{tasksList.afaire}</div>
                </div>
                {/* <a
                    href="#"
                    className="btn btn-white  btn-lg addtaskbtn"
                    data-toggle="modal"
                    data-target="#addtaskmodal"
                  >
                    <i className="fe fe-plus-circle" /> Add Task
                  </a> */}
              </div>
            </div>
            <div className="wrapper col-xl-2">
              <div className="card task-board-card" onDragOver={(e) => onDragOver(e)} onDrop={(e) => onDrop(e, "encours")}>
                <div className="card-header border-0">
                  <h4 className="card-title mb-6">en cours</h4>
                </div>
                <div className="card-body task-board-body pt-0 task-hold" style={{ overflowY: "scroll" }}>
                  <div id="right-defaults">{tasksList.encours}</div>
                </div>
                {/* <a
                    href="#"
                    className="btn btn-white  btn-lg addtaskbtn"
                    data-toggle="modal"
                    data-target="#addtaskmodal"
                  >
                    <i className="fe fe-plus-circle" /> Add Task
                  </a> */}
              </div>
            </div>
            <div className="wrapper col-xl-2">
              <div className="card task-board-card" onDragOver={(e) => onDragOver(e)} onDrop={(e) => onDrop(e, "terminee")}>
                <div className="card-header border-0">
                  <h4 className="card-title mb-6">terminer</h4>
                </div>
                <div className="card-body task-board-body pt-0 task-on" style={{ overflowY: "scroll" }}>
                  <div id="left-events">{tasksList.terminee}</div>
                </div>
                {/* <a
                    href="#"
                    className="btn btn-white  btn-lg addtaskbtn"
                    data-toggle="modal"
                    data-target="#addtaskmodal"
                  >
                    <i className="fe fe-plus-circle" /> Add Task
                  </a> */}
              </div>
            </div>
            <div className="wrapper col-xl-2">
              <div className="card task-board-card" onDragOver={(e) => onDragOver(e)} onDrop={(e) => onDrop(e, "revoir")}>
                <div className="card-header border-0">
                  <h4 className="card-title mb-6">à revoir</h4>
                </div>
                <div className="card-body task-board-body pt-0 task-complete" style={{ overflowY: "scroll" }}>
                  <div id="right-events">{tasksList.revoir}</div>
                </div>
                {/* <a
                    href="#"
                    className="btn btn-white  btn-lg addtaskbtn"
                    data-toggle="modal"
                    data-target="#addtaskmodal"
                  >
                    <i className="fe fe-plus-circle" /> Add Task
                  </a> */}
              </div>
            </div>
            <div className="wrapper col-xl-2">
              <div className="card task-board-card" onDragOver={(e) => onDragOver(e)} onDrop={(e) => onDrop(e, "tester")}>
                <div className="card-header border-0">
                  <h4 className="card-title mb-6">à tester</h4>
                </div>
                <div className="card-body task-board-body pt-0 task-complete" style={{ overflowY: "scroll" }}>
                  <div id="right-events">{tasksList.tester}</div>
                </div>
                {/* <a
                    href="#"
                    className="btn btn-white  btn-lg addtaskbtn"
                    data-toggle="modal"
                    data-target="#addtaskmodal"
                  >
                    <i className="fe fe-plus-circle" /> Add Task
                  </a> */}
              </div>
            </div>
            <div className="wrapper col-xl-2">
              <div className="card task-board-card" onDragOver={(e) => onDragOver(e)} onDrop={(e) => onDrop(e, "validee")}>
                <div className="card-header border-0">
                  <h4 className="card-title mb-6">validé</h4>
                </div>
                <div className="card-body task-board-body pt-0 task-complete" style={{ overflowY: "scroll" }}>
                  <div id="right-events">{tasksList.validee}</div>
                </div>
                {/* <a
                    href="#"
                    className="btn btn-white  btn-lg addtaskbtn"
                    data-toggle="modal"
                    data-target="#addtaskmodal"
                  >
                    <i className="fe fe-plus-circle" /> Add Task
                  </a> */}
              </div>
            </div>
          </div>
        </div>
        <AddTask getData={getData} from={true}></AddTask>
      </>
    );
  };

  return (
    <div className="card-body table-responsive p-0">
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && <>{render()}</>}
    </div>
  );
}

export default KanbanTasks;
