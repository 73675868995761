var _ = require("lodash");

/**
 * Given an array of objects, group the objects by the value of a field
 * @param inputArray - The array of objects that you want to group.
 * @returns an object with the following structure:
 * ```
 * {
 *   "tvaProduct": [
 *     {
 *       "tvaProduct": "tvaProduct1",
 *       "tvaProductId": "tvaProductId1",
 *       "tvaProductName": "tvaProductName1",
 *       "tvaProductDescription": "tva
 */
export function groupByValue(inputArray) {
  var outObject = inputArray.reduce(function (a, e) {
    // GROUP BY estimated key (estKey), well, may be a just plain key
    // a -- Accumulator result object
    // e -- sequentally checked Element, the Element that is tested just at this itaration
    // new grouping name may be calculated, but must be based on real value of real field
    let estKey = e["tvaProduct"];
    (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
    return a;
  }, {});
  // list distinct tva
  return outObject;
}

/**
 * Given an object, an action and a value, the function returns a list of strings, each string
 * representing a line of the invoice
 * @param object - the array of objects that you want to group
 * @param actionRemise - 1 or 2
 * @param valueRemise - the value of the discount
 * @param sousTotalHt - the total amount of the invoice without taxes
 * @returns a list of all the TVA values with the TVA value and the total HT without the TVA value.
 */
export function getGoupedValue(object, actionRemise, valueRemise, sousTotalHt) {
  var outObject = object.reduce(function (a, e) {
    // GROUP BY estimated key (estKey), well, may be a just plain key
    // a -- Accumulator result object
    // e -- sequentally checked Element, the Element that is tested just at this itaration
    // new grouping name may be calculated, but must be based on real value of real field
    let estKey = e["tvaProduct"];
    (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
    return a;
  }, {});

  let firstKeys = Object.keys(outObject);
  let listTva = [];
  let listAllTva = [];
  for (let i = 0; i < firstKeys.length; i++) {
    //1 TVA 19%
    //console.log(object[firstKeys[i]].length + " TVA " + firstKeys[i] + "%")
    listTva.push(outObject[firstKeys[i]].length + " TVA " + firstKeys[i] + "%");
    var count = 0;
    for (let j = 0; j < outObject[firstKeys[i]].length; j++) {
      //console.log(object[firstKeys[i]][j].totalHt)
      count += outObject[firstKeys[i]][j].totalHt;
    }
    //console.log(count);
    let result = (count / 100) * firstKeys[i];
    let withoutTva = "";
    if (actionRemise == "1") {
      let pourcent = (result / 100) * valueRemise;
      withoutTva = result - pourcent;
    } else {
      let x = Number(valueRemise) / sousTotalHt;
      withoutTva = Number(result) - Number(result * x);
    }

    listAllTva.push(outObject[firstKeys[i]].length + " TVA " + firstKeys[i] + "% " + withoutTva.toFixed(3));
  }

  return listAllTva;
}

/**
 * Given an array of objects, group the objects by the value of a property and return an array of
 * objects, each of which contains an array of objects
 * @param inputArray - The array of objects to be grouped.
 * @returns A list of dictionaries.
 */
export function groupByCodeAbonnement(inputArray) {
  var grouped = _.mapValues(_.groupBy(inputArray, "code_abonnement"), (clist) =>
    clist.map((car) => _.omit(car, "code_abonnement"))
  );
  return [grouped];
}

/**
 * Rename a key in an object
 * @param oldObj - The object that contains the old key.
 * @param oldName - The name of the key to be renamed.
 * @param newName - The new name of the key.
 * @returns The new object with the renamed key.
 */
export function renameObjectKey(oldObj, oldName, newName) {
  const newObj = {};

  Object.keys(oldObj).forEach((key) => {
    const value = oldObj[key];

    if (key === oldName) {
      newObj[newName] = value;
    } else {
      newObj[key] = value;
    }
  });

  return newObj;
}
