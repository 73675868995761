import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteNoteById, getNoteById } from "../../Services/Pointeuse/NoteApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import swal from "sweetalert";
import { filterTeletravailByWord } from "../../Utils/SortObject";
import AddNote from "./AddNote";

function ListTodo() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [achetes, setachetes] = useState([]);
  const [encours, setencours] = useState([]);
  const [anullers, setanullers] = useState([]);
  const [size, setsize] = useState(8);
  const [isOpened, setIsOpened] = useState(false);
  const [id, setId] = useState(null);

  /**
   * It gets the data from the API and sets it to the data variable.
   */
  const getData = () => {
    setLoading(true);
    getNoteById()
      .then((res) => {
        console.log(res.data);
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  /**
   * The onChangePage function is called when the user clicks on the page numbers.
   * It takes the page of items returned by the paginate function as a parameter and
   * uses that as the new state for the currentData variable
   * @param pageOfItems - an array of items on the current page
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the word that is typed in the search bar.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    setdata(allInitialData.filter((el) => el.title.toLowerCase().includes(e.target.value.toLowerCase())));
  };

  /**
   * Refresh the data in the table
   */
  const refreshData = () => {
    getData();
  };

  /**
   * It deletes a note from the database.
   * @param id - The id of the note to be deleted.
   */
  const handleDeleteNote = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteNoteById(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
        });
      }
    });
  };
  return (
    <>
      <div className="page-header d-lg-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Liste des notes</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className=" btn-list">
            <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
              {" "}
              <i className="feather feather-mail" />{" "}
            </button>
            <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
              {" "}
              <i className="feather feather-phone-call" />{" "}
            </button>
            <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
              {" "}
              <i className="feather feather-info" />{" "}
            </button>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-md-12 col-xl-3 col-lg-4">
          <div className="card">
            <div className="list-group list-group-transparent mb-0 mail-inbox pb-3">
              <div className="mt-4 mb-4 ml-4 mr-4 text-center">
                <Link to="#" className="btn btn-primary btn-block" data-toggle="modal" data-target="#newNotemodal">
                  Ajouter note
                </Link>
              </div>
              <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center active">
                <span className="icons">
                  <i className="ri-server-line" />
                </span>{" "}
                Toutes les notes <span className="ml-auto badge badge-success">{data.length}</span>
              </Link>
              <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center">
                <span className="icons">
                  <i className="ri-time-line" />
                </span>{" "}
                Aujourd'hui
                {/* <span className="ml-auto badge badge-danger">3</span> */}
              </Link>
              <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center">
                <span className="icons">
                  <i className="ri-time-line" />
                </span>{" "}
                Cette semaine
              </Link>
              <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center">
                <span className="icons">
                  <i className="ri-time-line" />
                </span>{" "}
                Ce mois
              </Link>
              {/* <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center">
                <span className="icons">
                  <i className="ri-archive-line" />
                </span>{" "}
                Archive
              </Link>
              <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center">
                <span className="icons">
                  <i className="ri-delete-bin-line" />
                </span>{" "}
                Trash
              </Link> */}
            </div>
            <div className="card-body border-top">
              <div className="list-group list-group-transparent mb-0 mail-inbox">
                <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center px-0 py-2">
                  <span className="w-3 h-3 brround bg-warning mr-2" /> Note en cours
                </Link>
                <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center px-0 py-2">
                  <span className="w-3 h-3 brround bg-success mr-2" /> Note terminée
                </Link>
                {/* <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center px-0 py-2">
                  <span className="w-3 h-3 brround bg-success mr-2" /> Completed Tasks
                </Link>
                <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center px-0 py-2">
                  <span className="w-3 h-3 brround bg-info mr-2" /> Hold Tasks
                </Link>
                <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center px-0 py-2">
                  <span className="w-3 h-3 brround bg-warning mr-2" /> Task Issue
                </Link>
                <Link to="#" className="list-group-item list-group-item-action d-flex align-items-center px-0 py-2">
                  <span className="w-3 h-3 brround bg-danger mr-2" /> Settings
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-8 col-xl-9">
          <div className="card">
            <div className="card-body p-6">
              <div className="inbox-body">
                <div className="row">
                  <div className="col mb-4">
                    <div className="form-group w-200">
                      <div className="input-icon">
                        <span className="input-icon-addon">
                          <i className="fe fe-search" />
                        </span>
                        <input type="text" className="form-control" placeholder="Recherche note" onChange={handleSearch} />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col col-auto mb-4">
                    <div className="btn-group hidden-phone">
                      <a data-toggle="dropdown" href="#" className="btn btn-white" aria-expanded="false">
                        Trier par
                        <i className="fa fa-angle-down " />
                      </a>
                      <ul className="dropdown-menu dropdown-menu-right">
                        <li>
                          <Link to="#">Nom</Link>
                        </li>
                        <li className="divider" />
                        <li>
                          <Link to="#">Date</Link>
                        </li>
                      
                      </ul>
                    </div>
                  </div> */}
                </div>

                {loading && <CustomSyncLoader></CustomSyncLoader>}
                {!loading && error && <ErrorCustum></ErrorCustum>}
                {!loading && !error && data && (
                  <div className="table-responsive">
                    <table className="table table-inbox table-hover text-nowrap mb-0">
                      <tbody>
                        {currentData.map((el, idx) => (
                          <tr>
                            <td className="inbox-small-cells w-7">
                              <label className="custom-control custom-checkbox mb-0">
                                <input type="checkbox" className="custom-control-input" name="example-checkbox2" defaultValue="option2" />
                                <span className="custom-control-label" />
                              </label>
                            </td>
                            <td className="inbox-small-cells w-4">
                              <Link to="#" className="list-group-item-action d-flex align-items-center px-0 py-2">
                                <span className="w-3 h-3 brround mr-2" style={{ backgroundColor: `${el.color}` }} />
                              </Link>
                            </td>
                            <td className="view-message dont-show font-weight-semibold">
                              {/* <img
                                className="avatat avatar-sm brround mr-2"
                                src="assets/images/users/6.jpg"
                                alt="img"
                              /> */}
                              {el.title}
                            </td>
                            <td className="view-message">
                              {el.date_debut} - {el.date_fin}
                            </td>
                            <td className="view-message">
                              {el.status == "afaire" ? (
                                <span className="badge badge-warning badge-pill"> en cours</span>
                              ) : (
                                <span className="badge badge-success badge-pill"> terminé</span>
                              )}
                            </td>
                            <td className="view-message text-right font-weight-semibold">
                              <span className="feather feather-trash-2 text-muted" onClick={() => handleDeleteNote(el.id)} />
                            </td>
                          </tr>
                        ))}

                        {/* <tr className>
        <td className="inbox-small-cells w-7">
          <label className="custom-control custom-checkbox mb-0">
            <input
              type="checkbox"
              className="custom-control-input"
              name="example-checkbox2"
              defaultValue="option2"
            />
            <span className="custom-control-label" />
          </label>
        </td>
        <td className="inbox-small-cells w-4">
          <i className="fa fa-star text-warning" />
        </td>
        <td className="view-message dont-show font-weight-semibold">
          <img className="avatat avatar-sm brround mr-2" src="assets/images/users/7.jpg" alt="img" />
          Veronica Kimery
        </td>
        <td className="view-message">Inventore Veritatis Et Quasi</td>
        <td className="view-message">
          <span className="badge badge-success badge-pill"> Completed</span>
        </td>
        <td className="view-message text-right font-weight-semibold">
          <span className="feather feather-trash-2 text-muted" />
        </td>
      </tr> */}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
        </div>
      </div>
      {/* modal */}
      <AddNote refreshData={refreshData}></AddNote>
    </>
  );
}

export default ListTodo;
