import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Add a product to the stock
 * @param produit_code - The code of the product to add to the stock.
 * @param quantite - The number of items to add to the stock.
 * @returns The response is an object that contains the data that was returned by the server.
 */
export async function addStock(produit_code, quantite) {
  const credentiel = {
    date: "",
    etat: "",
    depot_code: getSession("id"),
    commercial_code: getSession("id"),
    produit_code: produit_code,
    quantite: quantite,
    deleted_at: "2020-01-01",
    created_at: "",
    updated_a: "",
  };

  try {
    const response = await axios.post(api_url + "facturation/stock/", credentiel, config);
    console.log(response);
    toast.success("Produit ajouté au stock");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * * It increments the stock quantity of a product by a given quantity
 * @param produit_code - The code of the product to be incremented.
 * @param quantite - the quantity to increment
 * @returns The response from the server.
 */
export async function incrementStockQauntity(produit_code, quantite) {
  const credentiel = {
    produit_code: produit_code,
    quantite: quantite,
  };

  try {
    const response = await axios.patch(api_url + "facturation/stock/increment", credentiel, config);
    console.log(response);
    toast.success("stock modifié");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It decrement the stock quantity of a product.
 * @param produit_code - The code of the product to be decremented.
 * @param quantite - the quantity to decrement
 * @returns The response is an object that contains the data, status, and other information returned by
 * the server.
 */
export async function decrementStockQauntity(produit_code, quantite) {
  const credentiel = {
    produit_code: produit_code,
    quantite: quantite,
  };

  try {
    const response = await axios.patch(api_url + "facturation/stock/decrement", credentiel, config);
    console.log(response);
    toast.success("stock modifié");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
