import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getEnteteCommercialVente, getEnteteCommercialVenteDevis } from "../../../../../Services/Facturation/FacturationEnteteApi";
import { filterFactureByWord } from "../../../../../Utils/SortObject";
import { convertToDigit } from "../../../../../Utils/NumberUtils";
import { sumMontantHT, sumMontantTTC, sumMontantTVA } from "../../../../../Utils/ObjectOperation";
import CustomSyncLoader from "../../../../Others/CustomSyncLoader";
import ErrorCustum from "../../../../Others/ErrorCustum";
import Pagination from "../../../../Others/Pagination";
import ButtonHeaderComponent from "../../../../Styles/ButtonHeaderComponent";
import ExportCsv from "../../../../Exports/ExportCsv";
import ExportPdf from "../../../../Exports/ExportPdf";
import CopieContent from "../../../../Exports/CopieContent";

function ListDevisVente() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(40);
  const [isCollapsed, setisCollapsed] = useState(false);

  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getEnteteCommercialVenteDevis()
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    if (e.target.value.length != "") {
      const dataSearch = filterFactureByWord(allInitialData, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Devis vente</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/ajoutdevisvente" className="btn btn-primary mr-3">
                Ajouter devis
              </Link>
              <Link to="/admin/addproduct" className="btn btn-warning mr-3">
                Ajouter produit
              </Link>
              <Link to="/admin/addclient" className="btn btn-warning mr-3">
                Ajouter client
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant HT</span>
                    <h3 className="mb-0 mt-1 text-success">{sumMontantHT(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant TVA</span>
                    <h3 className="mb-0 mt-1 text-primary">{sumMontantTVA(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-primary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant TTC</span>
                    <h3 className="mb-0 mt-1 text-secondary">{sumMontantTTC(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-secondary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            {/* /.card-header */}

            <div className="row">
              <div className="col-xl-12 col-md-12 col-lg-12">
                <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
                  <div className="card-header  border-0">
                    <h4 className="card-title">List devis vente</h4>

                    <div className="card-options">
                      <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                        <i className="fe fe-maximize" />
                      </Link>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="d-flex mb-6 mt-5">
                      <div className="mr-3">
                        <label className="form-label">Note:</label>
                      </div>
                      <div>
                        <span className="badge badge-success-light mr-2">
                          <i className="feather feather-check-circle text-success" /> payé
                        </span>
                        <span className="badge badge-danger-light mr-2">
                          <i className="feather feather-x-circle text-danger" /> échu
                        </span>
                        <span className="badge badge-warning-light mr-2">
                          <i className="fa fa-star text-warning" /> partial
                        </span>
                        {/* <span className="badge badge-orange-light mr-2">
                    <i className="fa fa-adjust text-orange" /> ---&gt; Half Day
                  </span> */}
                      </div>
                    </div>

                    {loading && <CustomSyncLoader></CustomSyncLoader>}
                    {!loading && error && <ErrorCustum></ErrorCustum>}
                    {!loading && !error && data && (
                      <div className="table-responsive">
                        <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                          <div className="row">
                            <div className="col-sm-12 col-md-6">
                              <div className="dataTables_length" id="hr-table_length">
                                <label>
                                  Afficher{" "}
                                  <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                                    <option value={8}>8</option>
                                    <option value={20}>20</option>
                                    <option value={40}>40</option>
                                    <option value={60}>60</option>
                                    <option value={100}>100</option>
                                  </select>{" "}
                                  entrées
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <div id="hr-table_filter" className="dataTables_filter">
                                <label className="mr-2">
                                  {/* <select className="custom-select m-2 p-2" onChange={handleSelectStaus}>
                              <option value={0}>Tous</option>
                              <option value={1}>En cours</option>
                              <option value={2}>Payée</option>
                              <option value={3}>Partiel</option>
                            </select>

                            <select className="custom-select" onChange={handleSelectDate}>
                              <option value={0}>Année</option>
                              <option value={1}>{new Date().getFullYear()}</option>
                              <option value={2}>{new Date().getFullYear() - 1}</option>
                            </select> */}

                                  <input
                                    type="search"
                                    className="form-control"
                                    placeholder="Recherche..."
                                    aria-controls="hr-table"
                                    onChange={handleSearch}
                                  />
                                  {/* <input
                              className="form-control floating"
                              type="date"
                              onChange={(e) => filterByDate(e.target.value)}
                            /> */}
                                </label>

                                <ExportCsv data={data} name={"List_devis_ventes"} />
                                <ExportPdf
                                  data={data}
                                  name={"List_devis_ventes"}
                                  columns={[
                                    "facture_code",
                                    "client_nom",
                                    "montant_total_ht",
                                    "montant_total_tva",
                                    "montant_total_ttc",
                                    "montant_encaissement",
                                    "net_a_payer",
                                  ]}
                                />
                                <CopieContent />

                                {/* <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                                id="hr-table"
                                role="grid"
                                aria-describedby="hr-table_info"
                              >
                                <thead>
                                  <tr>
                                    <th>N° Facture</th>
                                    <th>Client </th>
                                    <th>
                                      Montant HT <br></br>
                                      <span className="badge badge-pill badge-danger">{sumMontantHT(data)}</span>
                                    </th>
                                    <th>
                                      Montant TVA <br></br>
                                      <span className="badge badge-pill badge-danger">{sumMontantTVA(data)}</span>
                                    </th>
                                    <th>
                                      Montant TTC <br></br>
                                      <span className="badge badge-pill badge-danger">{sumMontantTTC(data)}</span>
                                    </th>
                                    <th>Statut</th>
                                    <th>Date</th>
                                    <th className="text-right"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {currentData.map((el) => (
                                    <tr key={el.id}>
                                      <td>{el.facture_code}</td>
                                      <td>{el.client_nom}</td>
                                      <td>{convertToDigit(el.montant_total_ht)}</td>
                                      <td>{convertToDigit(el.montant_total_tva)}</td>
                                      <td>{convertToDigit(el.montant_total_ttc)}</td>
                                      <td>{el.status}</td>
                                      <td>{el.date.split(" ")[0]}</td>

                                      <td className="project-actions text-center">
                                        <Link to={"/admin/editerdevisvente/" + el.code} className="btn btn-default btn-xs">
                                          <i className="feather feather-edit text-info" />
                                        </Link>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <th>N° Facture</th>
                                    <th>Client </th>
                                    <th>
                                      Montant HT <br></br>
                                      <span className="badge badge-pill badge-danger">{sumMontantHT(data)}</span>
                                    </th>
                                    <th>
                                      Montant TVA <br></br>
                                      <span className="badge badge-pill badge-danger">{sumMontantTVA(data)}</span>
                                    </th>
                                    <th>
                                      Montant TTC <br></br>
                                      <span className="badge badge-pill badge-danger">{sumMontantTTC(data)}</span>
                                    </th>
                                    <th>Statut</th>
                                    <th>Date</th>
                                    <th className="text-right"></th>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-5">
                              <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                                Affichage de 1 à {size} sur {data.length} entrées
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.card */}
        </div>
      </div>
    </>
  );
}

export default ListDevisVente;
