import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getAnnonceById,
  getCommantaireAnnonceById,
  getAllAnnonces,
  addAnnonceComment,
} from "../../Services/Pointeuse/AnnonceApi";

function AnnonceDetail(props) {
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setimageName] = useState("Ajouter une image");
  const [description, setdescription] = useState("");
  const [comments, setcomments] = useState([]);
  const [posts, setposts] = useState([]);
  const [commentText, setcommentText] = useState("");

  useEffect(() => {
    setLoading(true);
    getAnnonceById(props.match.params.id)
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getData();
  }, []);

  /**
   * It gets the comments of the annonce with the id of the annonce that is in the url.
   */
  const getData = () => {
    getCommantaireAnnonceById(props.match.params.id).then((res) => {
      setcomments(res.data);
    });
  };

  useEffect(() => {
    getAllAnnonces().then((res) => {
      setposts(res.data);
    });
  }, []);

  /**
   * It adds a comment to the annonce.
   */
  const handleAddComment = () => {
    addAnnonceComment(props.match.params.id, commentText).then(() => {
      getData();
    });
  };

  return (
    <>
      <div className="page-header d-lg-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Détail annonce #{props.match.params.id}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className=" btn-list">
            <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
              {" "}
              <i className="feather feather-mail" />{" "}
            </button>
            <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
              {" "}
              <i className="feather feather-phone-call" />{" "}
            </button>
            <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
              {" "}
              <i className="feather feather-info" />{" "}
            </button>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="card overflow-hidden">
            <div className="item7-card-img px-4 pt-4">
              <a href="#" />
              <img
                src={
                  data.img == null || data.img == ""
                    ? "assets/images/photos/annonce.svg"
                    : localStorage.getItem("baseUrl") + data.img
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "assets/images/photos/annonce.svg";
                }}
                loading="lazy"
                alt="img"
                className="cover-image br-7 w-100"
              />
            </div>
            <div className="card-body">
              <div className="item7-card-desc d-md-flex mb-5">
                <a href="#" className="d-flex mr-3 mb-2">
                  <span className="feather feather-calendar text-muted mr-2 fs-18" />
                  <div className="mt-0">{data.date_debut}</div>
                </a>
                <a href="#" className="d-flex mb-2">
                  <span className="feather feather-calendar text-muted mr-2 fs-18" />
                  <div className="mt-0">{data.date_fin}</div>
                </a>
                <div className="ml-auto mb-2">
                  <Link className="mr-0 d-flex" to="#">
                    <span className="feather feather-message-square text-muted mr-2 fs-18" />
                    <div className="mt-0">{comments.length} Commentaires</div>
                  </Link>
                </div>
              </div>
              <a href="#" className="mt-4">
                <h5 className="font-weight-semibold">{data.title}</h5>
              </a>
              <p>{data.description}</p>

              <div className="media py-3 mt-0 border-top">
                {/* <div className="media-user mr-2">
                  <div className="avatar-list avatar-list-stacked">
                    <span className="avatar brround" style={{ backgroundImage: "url(assets/images/users/12.jpg)" }} />
                    <span className="avatar brround" style={{ backgroundImage: "url(assets/images/users/2.jpg)" }} />
                    <span className="avatar brround" style={{ backgroundImage: "url(assets/images/users/9.jpg)" }} />
                    <span className="avatar brround" style={{ backgroundImage: "url(assets/images/users/2.jpg)" }} />
                  </div>
                </div> */}
                <div className="ml-auto">
                  <Link to="#" className="d-none d-md-inline-block ml-3">
                    <span className="feather feather-heart text-muted" />
                  </Link>
                  <Link to="#" className="d-none d-md-inline-block ml-3">
                    <span className="feather feather-thumbs-up text-muted" />
                  </Link>
                  <Link to="#" className="d-none d-md-inline-block ml-3">
                    <span className="feather feather-share-2 text-muted" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/*Comments*/}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{comments.length} Commentaires</h3>
            </div>
            <div className="card-body">
              {comments.map((el, idx) => (
                <div key={idx} className="d-sm-flex mt-0 p-5 sub-review-section border border-bottom-0 br-bl-0 br-br-0">
                  <div className="d-flex mr-3">
                    <a href="#">
                      <img
                        className="media-object brround avatar-md"
                        alt="64x64"
                        src={el.img ? localStorage.getItem("baseUrl") + el.img : "assets/images/users/avatar.png"}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "assets/images/users/avatar.png";
                        }}
                      />{" "}
                    </a>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 font-weight-semibold">
                      {el.nom} {el.prenom}
                      <span
                        className="fs-14 ml-0"
                        data-toggle="tooltip"
                        data-placement="top"
                        title
                        data-original-title="verified"
                      >
                        <i className="fa fa-check-circle-o text-success" />
                      </span>
                      <span className="fs-14 ml-2">
                        {" "}
                        4.5 <i className="fa fa-star text-yellow" />
                      </span>
                    </h5>
                    <p className="font-13  mb-2 mt-2">{el.commentaire}</p>
                    <a href="#" className="mr-2 mt-1">
                      <span className="badge badge-primary">Helpful</span>
                    </a>
                    <a href="#" className="mr-2 mt-1" data-toggle="modal" data-target="#Comment">
                      <span className="badge badge-light">Comment</span>
                    </a>
                    <a href="#" className="mr-2 mt-1" data-toggle="modal" data-target="#report">
                      <span className="badge badge-light">Report</span>
                    </a>
                    <div className="btn-group btn-group-sm mb-1 ml-auto float-sm-right mt-1">
                      <button className="btn btn-light">
                        <span className="feather feather-thumbs-up fs-16" />
                      </button>
                      <button className="btn btn-light">
                        <span className="feather feather-thumbs-down fs-16" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/*/Comments*/}
          <div className="card mb-lg-0">
            <div className="card-header border-bottom-0">
              <h3 className="card-title">Ajouter commentaire</h3>
            </div>
            <div className="card-body">
              <div className="mt-2">
                {/* <div className="form-group">
                  <input type="text" className="form-control" id="name1" placeholder="Your Name" />
                </div>
                <div className="form-group">
                  <input type="email" className="form-control" id="email" placeholder="Email Address" />
                </div> */}
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="example-textarea-input"
                    rows={6}
                    placeholder="écrire commentaire"
                    onChange={(e) => setcommentText(e.target.value)}
                  />
                </div>
                <Link to="#" className="btn btn-primary" onClick={handleAddComment}>
                  Envoyer
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AnnonceDetail;
