import { calculateCroissance } from "../ObjectifUtils";

export function getObjectData(result) {
  //realisation n-1 2000  24->35
  //realisation n-2  2021  12->25
  return [
    {
      mois: "janvier",
      realisation1: result[24]?.realise,
      realisation2: result[12]?.realise,
      objectif: result[36]?.objectif ?? "0",
      croissance: calculateCroissance(result[36]?.objectif ?? "0", result[24]?.realise),
    },
    {
      mois: "février",
      realisation1: result[25]?.realise,
      realisation2: result[13]?.realise,
      objectif: result[37]?.objectif ?? "0",
      croissance: calculateCroissance(result[37]?.objectif ?? "0", result[25]?.realise),
    },
    {
      mois: "mars",
      realisation1: result[26]?.realise,
      realisation2: result[14]?.realise,
      objectif: result[38]?.objectif ?? "0",
      croissance: calculateCroissance(result[38]?.objectif ?? "0", result[26]?.realise),
    },
    {
      mois: "avril",
      realisation1: result[27]?.realise,
      realisation2: result[15]?.realise,
      objectif: result[39]?.objectif ?? "0",
      croissance: calculateCroissance(result[39]?.objectif ?? "0", result[27]?.realise),
    },
    {
      mois: "mai",
      realisation1: result[28]?.realise,
      realisation2: result[16]?.realise,
      objectif: result[40]?.objectif ?? "0",
      croissance: calculateCroissance(result[40]?.objectif ?? "0", result[28]?.realise),
    },
    {
      mois: "juin",
      realisation1: result[29]?.realise,
      realisation2: result[17]?.realise,
      objectif: result[41]?.objectif ?? "0",
      croissance: calculateCroissance(result[41]?.objectif ?? "0", result[29]?.realise),
    },
    {
      mois: "juillet",
      realisation1: result[30]?.realise,
      realisation2: result[18]?.realise,
      objectif: result[42]?.objectif ?? "0",
      croissance: calculateCroissance(result[42]?.objectif ?? "0", result[30]?.realise),
    },
    {
      mois: "aout",
      realisation1: result[31]?.realise,
      realisation2: result[19]?.realise,
      objectif: result[43]?.objectif ?? "0",
      croissance: calculateCroissance(result[43]?.objectif ?? "0", result[31]?.realise),
    },
    {
      mois: "septembre",
      realisation1: result[32]?.realise,
      realisation2: result[20]?.realise,
      objectif: result[44]?.objectif ?? "0",
      croissance: calculateCroissance(result[44]?.objectif ?? "0", result[32]?.realise),
    },
    {
      mois: "octobre",
      realisation1: result[33]?.realise,
      realisation2: result[21]?.realise,
      objectif: result[45]?.objectif ?? "0",
      croissance: calculateCroissance(result[45]?.objectif ?? "0", result[33]?.realise),
    },
    {
      mois: "novembre",
      realisation1: result[34]?.realise,
      realisation2: result[22]?.realise,
      objectif: result[46]?.objectif ?? "0",
      croissance: calculateCroissance(result[46]?.objectif ?? "0", result[34]?.realise),
    },
    {
      mois: "décembre",
      realisation1: result[35]?.realise,
      realisation2: result[23]?.realise,
      objectif: result[47]?.objectif ?? "0",
      croissance: calculateCroissance(result[47]?.objectif ?? "0", result[35]?.realise),
    },
  ];
}
