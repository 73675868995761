import React from "react";
import { Link } from "react-router-dom";

/**
 * * This function is used to render the error page when an error occurs
 * @returns The ErrorCustum function is returning a div element with a class of text-center.
 */
function ErrorCustum() {
  return (
    <div className="text-center">
      <div className="fs-100  mb-5 text-primary h1">oops!</div>
      <h1 className="h3  mb-3 font-weight-semibold">Error 500: Internal Server Error</h1>
      <p className="h5 font-weight-normal mb-7 leading-normal">
        You may have mistyped the address or the page may have moved.
      </p>
      <Link className="btn btn-primary" to="#" onClick={() => window.location.reload(false)}>
        <i className="fe fe-rotate-ccw mr-1" />
        Rafraîchir
      </Link>
    </div>
  );
}

export default ErrorCustum;
