import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get client by id
 * @param {} id
 */
export async function getFournisseurById(id) {
  try {
    const response = await axios.get(api_url + "facturation/fournisseur/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get Facturation client by code
 */
export async function getFacturationFournisseurByCode() {
  try {
    const response = await axios.get(api_url + "facturation/fournisseur/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get Facturation client by code
 */
export async function getFacturationFournisseurByCodeId(code) {
  try {
    const response = await axios.get(api_url + "facturation/fournisseur/codeid/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add a new facturation fournisseur
 * @param code - The code of the fournisseur
 * @param activite_code - The code of the activity.
 * @param tarification - The type of the customer.
 * @param magasin - The store code
 * @param nom - The name of the customer.
 * @param prenom - The customer's first name.
 * @param email - The email of the customer.
 * @param anniversaire - Date of birth
 * @param fixe - The phone number of the client.
 * @param mobile - The mobile number of the client.
 * @param description - Description of the customer
 * @param rib - RIB
 * @param codeErp - The code of the ERP that the client is using.
 * @param adresse_facturation - The address of the customer.
 * @param localite - The city where the client is located.
 * @param region - The region of the store.
 * @param gouvernorat - The name of the Governorate.
 * @param secteur - The sector of the customer.
 * @param zone - The zone of the store.
 * @param categorie - The category of the customer.
 * @param specialite - The speciality of the fournisseur
 * @param regime_fiscale - The fiscal regime of the customer.
 * @param matricule_fiscale - The VAT number of the customer.
 * @param commentaire - The commentaire field is used to add a comment to the customer.
 * @param image - The image of the fournisseur
 * @param remise_client - The discount percentage for the client.
 * @param delai_paiement - The number of days before the due date of the invoice.
 * @param plafond_credit - The maximum amount of credit that the customer can have.
 * @param rc - The rc is the code of the region.
 * @returns The response from the API.
 */
export async function addFacturationFournisseur(
  code,
  activite_code,
  tarification,
  magasin,
  nom,
  prenom,
  email,
  anniversaire,
  fixe,
  mobile,
  description,
  rib,
  codeErp,
  adresse_facturation,
  localite,
  region,
  gouvernorat,
  secteur,
  zone,
  categorie,
  specialite,
  regime_fiscale,
  matricule_fiscale,
  commentaire,
  image,
  remise_client,
  delai_paiement,
  plafond_credit,
  rc
) {
  const frmData = new FormData();

  frmData.append("code", code);
  frmData.append("code_a_barre", "");
  frmData.append("activite_code", activite_code);
  frmData.append("user_code", getSession("id"));
  frmData.append("soussociete_code", getSession("code_generated"));
  frmData.append("nom", nom);
  frmData.append("prenom", prenom);
  frmData.append("statut", "Fournisseur");
  frmData.append("virtuelle", "0");
  frmData.append("description", description);
  frmData.append("routing_code", "");
  frmData.append("tarification", tarification);
  frmData.append("magasin", magasin);
  frmData.append("code_tva", "");
  frmData.append("code_erp", codeErp);
  frmData.append("tax", "");
  frmData.append("adresse_facturation", adresse_facturation);
  frmData.append("email", email);
  frmData.append("anniversaire", anniversaire);
  frmData.append("potentiel", "");
  frmData.append("potentiel_societe", "");
  frmData.append("fixe", fixe);
  frmData.append("mobile", mobile);
  frmData.append("latitude", "");
  frmData.append("longitude", "");
  frmData.append("rib", rib);
  frmData.append("isactif", "");
  frmData.append("bloque", "");
  frmData.append("solde_releve", "");
  frmData.append("engagement_client", "");
  frmData.append("encours_actuelement", "0");
  frmData.append("autorisation", "");
  frmData.append("plafond_credit", plafond_credit);
  frmData.append("exception_credit", "0");
  frmData.append("encours_actuelement_cheque", "0");
  frmData.append("autorisation_cheque", "0");
  frmData.append("plafond_credit_cheque", "0");
  frmData.append("encours_actuelement_traite", "0");
  frmData.append("autorisation_traite", "0");
  frmData.append("plafond_credit_traite", "0");
  frmData.append("autorisation_paiement_instance", "0");
  frmData.append("plafond_espece", "0");
  frmData.append("delai_paiement", delai_paiement);
  frmData.append("ticket_cadeaux", "0");
  frmData.append("rc", rc);
  frmData.append("cin", "");
  frmData.append("localite", localite);
  frmData.append("region", region);
  frmData.append("gouvernorat", gouvernorat);
  frmData.append("secteur", secteur);
  frmData.append("zone", zone);
  frmData.append("categorie", categorie);
  frmData.append("categoriecommercial_code", "");
  frmData.append("specialite", specialite);
  frmData.append("regime_fiscale", regime_fiscale);
  frmData.append("fidelitePoint", "0");
  frmData.append("matricule_fiscale", matricule_fiscale);
  frmData.append("remember_token", "");
  frmData.append("presentoire", "");
  frmData.append("passager", "");
  frmData.append("refrigerateur", "");
  frmData.append("acces_metrage", "0");
  frmData.append("wavesoft", "");
  frmData.append("changement_prix", "");
  frmData.append("code_sms", "");
  frmData.append("issync", "0");
  frmData.append("autorisation_espece", "0");
  frmData.append("remise_client", remise_client);
  frmData.append("commentaire", commentaire);
  frmData.append("date_creation", "2020-01-07");
  frmData.append("deleted_at", "2020-01-07");
  frmData.append("created_at", "");
  frmData.append("updated_at", "2020-01-07");
  frmData.append("image", image);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.post(api_url + "facturation/fournisseur/", frmData, options);
    console.log(response);
    toast.success("Fournisseur ajouté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("1");
      toast.error(error);
      toast.error(error.response.data);
      toast.error(error.response.message);

      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("2");
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      toast.error("3");
      console.log("Error", error.message);
    }
    toast.error("4");
    console.log(error.config);
    return error;
  }
}
