import React, { useEffect, useState } from "react";
import {
  addPersonnelDoc,
  deletePersonnelDocById,
  getPersonnelDocByUserId,
} from "../../Services/Pointeuse/PersonnelDocApi";
import * as FileSaver from "file-saver";
import { Link } from "react-router-dom";
import swal from "sweetalert";
function CardDocument({ idUser }) {
  const [documents, setdocuments] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setimageName] = useState("Choisir un document ...");
  const [type, settype] = useState("identite");

  const handleUploadFiles = (event) => {
    setFilesToUpload(URL.createObjectURL(event.target.files[0]));
    setImageUrl(event.target.files[0]);
    setimageName(event.target.files[0].name);
  };

  /**
   * * Add a new document to the database
   */
  const submitFiles = () => {
    addPersonnelDoc(idUser, imageUrl, type).then(() => {
      getAllDocuments();
    });
  };

  /**
   * It deletes a document from the database.
   * @param id - The id of the document to delete.
   */
  const handleDeleteFile = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deletePersonnelDocById(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getAllDocuments();
        });
      }
    });
  };

  useEffect(() => {
    getAllDocuments();
  }, [idUser]);

  /**
   * Get all documents for a user
   */
  const getAllDocuments = () => {
    getPersonnelDocByUserId(idUser).then((resDocument) => {
      setdocuments(resDocument.data);
    });
  };

  /**
   * It saves the file to the local machine.
   * @param file - The file to download.
   */
  const saveFile = (file) => {
    FileSaver.saveAs(process.env.REACT_APP_API + file, file.split("Z")[file.length - 1]);
  };
  return (
    <div className="card-body">
      <div className="row">
        {documents.map((el) => (
          <div key={el.id} className="col-xl-3 col-md-4 col-sm-6">
            <div className="card border p-0 shadow-none">
              <div className="d-flex align-items-center px-4 pt-4">
                {/* <label className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    name="example-checkbox2"
                    defaultValue="option2"
                  />
                  <span className="custom-control-label" />
                </label> */}
                <div className="float-right ml-auto">
                  <div className="btn-group ml-3 mb-0">
                    <Link
                      to="#"
                      className="option-dots"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-ellipsis-v" />
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                      {/* <a className="dropdown-item" href="#">
                        <i className="fe fe-edit mr-2" /> Edit
                      </a> */}
                      {/* <Link to="#" className="dropdown-item">
                        <i className="fe fe-share mr-2" /> Partager
                      </Link> */}
                      <Link to="#" className="dropdown-item" onClick={() => saveFile(el.image)}>
                        <i className="fe fe-download mr-2" /> Télécharger
                      </Link>
                      <Link to="#" className="dropdown-item" onClick={() => handleDeleteFile(el.id)}>
                        <i className="fe fe-trash mr-2" /> Supprimer
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0 text-center">
                <div className="file-manger-icon">
                  {/* <img src="assets/images/files/file.png" alt="img" className="br-7" /> */}
                  {["png", "jpg", "jpeg", "PNG"].some((element) => el.image.split(".").pop().includes(element)) && (
                    <img src="https://coderthemes.com/highdmin/layouts/assets/images/file_icons/jpg.svg" alt="icon" />
                  )}
                  {el.image.split(".").pop() === "pdf" && (
                    <img src="https://coderthemes.com/highdmin/layouts/assets/images/file_icons/pdf.svg" alt="icon" />
                  )}
                  {el.image.split(".").pop() === "docx" && (
                    <img src="https://coderthemes.com/highdmin/layouts/assets/images/file_icons/doc.svg" alt="icon" />
                  )}
                </div>
                <h6 className="mb-1 font-weight-semibold mt-4">{el.image.split("Z")[1]}</h6>

                {el.type == "contrat" && <span className="text-muted">Contrat</span>}
                {el.type == "identite" && <span className="text-muted">Identité</span>}
                {el.type == "permis" && <span className="text-muted">Permis</span>}
                {el.type == "rib" && <span className="text-muted">RIB</span>}
                {el.type == "autre" && <span className="text-muted">Autre</span>}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="form-group">
        <div className="row">
          {/* <div className="col-md-3">
            <div className="form-label mb-0 mt-2">Ajouter documents</div>
          </div> */}
          <div className="col-md-12">
            {/* <div className="form-group">
              <div className="custom-file">
                <input
                  className="custom-file-input"
                  accept="image/*"
                  type="file"
                  id="exampleInputFile"
                  onChange={handleUploadFiles}
                />
                <label className="custom-file-label" htmlFor="exampleInputFile">
                  {imageName}
                </label>

                <small className="form-text text-muted">
                  Documents autorisés: jpg, jpeg, png, pdf, docx. Maximum 1 fichier.
                </small>
              </div>
            </div> */}

            <div className="input-group file-browser">
              <input
                type="text"
                className="form-control browse-file"
                placeholder={imageName || "choisir un fichier ..."}
              />
              <label className="input-group-append">
                <span className="btn btn-primary">
                  choisir fichier <input type="file" style={{ display: "none" }} onChange={handleUploadFiles} />
                </span>
              </label>
            </div>
            <small className="form-text text-muted  mb-5">
              Documents autorisés: jpg, jpeg, png, pdf. Maximum 1 fichier.
            </small>

            <div>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Type document</label>
                <select
                  value={type}
                  className="form-control"
                  id="exampleFormControlSelect1"
                  onChange={(e) => settype(e.target.value)}
                >
                  <option value="identite">Identité</option>
                  <option value="contrat">Contrat</option>
                  <option value="permis">Permis</option>
                  <option value="rib">Rib</option>
                  <option value="autre">Autre</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-right">
        <Link to="#" className="btn btn-primary mr-2" onClick={submitFiles}>
          Enregistrer
        </Link>
        {/* <a href="#" className="btn btn-danger">
                          Cancle
                        </a> */}
      </div>
    </div>
  );
}

export default CardDocument;
