import React, { Component, useState } from "react";
import { ToastContainer } from "react-toastify";
import { addLignePaie } from "../../Services/Pointeuse/LignePaieApi";
import { addBaremeIrpp } from "./../../Services/Pointeuse/BaremeIrppApi";

function AddLignePaie() {
  const [code, setcode] = useState("");
  const [code_paie, setcode_paie] = useState("");
  const [base, setbase] = useState("");
  const [gain, setgain] = useState("");
  const [retenue, setretenue] = useState("");
  const [compte_comptable, setcompte_comptable] = useState("");

  /**
   * It calls the addLignePaie function and passes the code, code_paie, base, gain, retenue,
   * compte_comptable as arguments.
   */
  const handelSave = () => {
    addLignePaie(code, code_paie, base, gain, retenue, compte_comptable).then((res) => {
      console.log(res);
    });
  };
  return (
    <div
      class="modal fade"
      id="lignePaieModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="lignePaieModalTitle"
      aria-hidden="true"
    >
      <ToastContainer></ToastContainer>
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Ajouter Ligne de paie
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div className="form-group">
              <label htmlFor="inputAddress">Code</label>
              <input type="text" className="form-control" onChange={(e) => setcode(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="inputAddress">Code de paie</label>
              <input type="text" className="form-control" onChange={(e) => setcode_paie(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="inputAddress">Base</label>
              <input type="text" className="form-control" onChange={(e) => setbase(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="inputAddress">Gain</label>
              <input type="text" className="form-control" onChange={(e) => setgain(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="inputAddress">Retenue</label>
              <input type="text" className="form-control" onChange={(e) => setretenue(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="inputAddress">Compte Comptable</label>
              <input type="text" className="form-control" onChange={(e) => setcompte_comptable(e.target.value)} />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Annuler
            </button>
            <button type="button" class="btn btn-primary" onClick={handelSave}>
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddLignePaie;
