/**
 * filter by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterUserByWord = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) =>
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase()) ||
      el.fonction.toLowerCase().includes(string.toLowerCase()) ||
      el.mobile.toLowerCase().includes(string.toLowerCase()) ||
      el.email.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

export const filterUser = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) =>
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase()) ||
      el.email.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter conge by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterCongeByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) =>
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase()) ||
      el.motif.toLowerCase().includes(string.toLowerCase()) ||
      el.email.toLowerCase().includes(string.toLowerCase())
  );
};

/**
 * filter project by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterProjectByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) =>
      el.commentaire.toLowerCase().includes(string.toLowerCase()) ||
      el.description.toLowerCase().includes(string.toLowerCase()) ||
      el.nom.toLowerCase().includes(string.toLowerCase())
  );
};

/**
 * filter project by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterAutorisationByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) =>
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase()) ||
      el.description.toLowerCase().includes(string.toLowerCase()) ||
      el.objet.toLowerCase().includes(string.toLowerCase()) ||
      el.nb_heure == string
  );
};

/**
 * filter task by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterTasksByWord = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) =>
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase()) ||
      el.description.toLowerCase().includes(string.toLowerCase()) ||
      el.commentaire.toLowerCase().includes(string.toLowerCase()) ||
      el.object.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter task by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterAnnoncesByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) => el.title.toLowerCase().includes(string.toLowerCase()) || el.description.toLowerCase().includes(string.toLowerCase())
  );
};

/**
 * filter pointage by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterPointageByWord = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) =>
      el.email.toLowerCase().includes(string.toLowerCase()) ||
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase())
  );

  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter ligne de paie by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterLignePaieByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) =>
      el.code.toLowerCase().includes(string.toLowerCase()) ||
      el.code_paie == string ||
      el.base == string ||
      el.gain == string ||
      el.retenue == string ||
      el.compte_comptable == string
  );
};

/**
 * filter baremme irpp by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterBaremeIrppByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) => el.code.toLowerCase().includes(string.toLowerCase()) || el.min == string || el.max == string || el.taux == string || el.taux_sup == string
  );
};

/**
 * filter baremme irpp by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterProductByWord = (arrayObject, string) => {
  const filtered = arrayObject.filter((el) => el.libelle.toLowerCase().includes(string.toLowerCase()) || el.code == string);
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter fournisseur by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterFournisseurByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) =>
      el.email.toLowerCase().includes(string.toLowerCase()) ||
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase())
  );
};

/**
 * filter tax by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterTaxByWord = (arrayObject, string) => {
  return arrayObject.filter((el) => el.libelle.toLowerCase().includes(string.toLowerCase()) || el.taux == string);
};

/**
 * filter facture by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterFactureByWord = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) => el.client_nom.toLowerCase().includes(string.toLowerCase()) || el.facture_code.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

export const filterCongeTitleByTitle = (arrayObject, string) => {
  const filtered = arrayObject.filter((el) => el.title.toLowerCase().includes(string.toLowerCase()));
  if (filtered.length === 0 || string.length == 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter facture by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterByLibelle = (arrayObject, string) => {
  return arrayObject.filter((el) => el.libelle.toLowerCase().includes(string.toLowerCase()));
};

/**
 * filter encaissement by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterEncaissement = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) =>
      (el?.nom || "").toLowerCase().includes(string.toLowerCase()) ||
      (el?.num_facture || "").toLowerCase().includes(string.toLowerCase()) ||
      el?.code_facture === string ||
      el?.reference_banque === string
  );

  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter facture by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterAbonnemntByWord = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) =>
      el.client_code.toLowerCase().includes(string.toLowerCase()) ||
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.code_abonnement.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter task by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterVaccinByWord = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) =>
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase()) ||
      el.nom_vaccin?.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter task by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterTeletravailByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) =>
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase()) ||
      el.motif?.toLowerCase().includes(string.toLowerCase())
  );
};

export const filterVirementByCode = (arrayObject, string) => {
  const filtered = arrayObject.filter((el) => el.code.toLowerCase().includes(string.toLowerCase()));
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

export const sortObjectByValue = (arrayObject, value) => {
  const res = arrayObject.sort(function (a, b) {
    return b[value] - a[value];
  });
  const filter = res.filter((el) => {
    return parseInt(el.occurrence) != "1";
  });
  return filter;
};

/**
 * filter task by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterByDocument = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) => el.nom.toLowerCase().includes(string.toLowerCase()) || el.categorie.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter task by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterCaisse = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) => el?.description.toLowerCase().includes(string.toLowerCase()) || el?.cente_cout.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};
